import React, { useEffect, useState } from 'react';
import { Grid, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StyledPostJobPage, { ColorlibConnector, ColorlibStepIconRoot } from './StyledPostJobPage';
import { SecondaryButton } from '../../../components/Button';
import stepChecked from '../../../assets/svg/stepChecked.svg';
import PrivateLayout from '../../../layout/PrivateLayout';
import CreatePostJob from './Create';
import RedWarningIcon from '../../../components/icons/RedWarningIcon';
import { ROUTE_PATH } from '../../../constants/route';
import { getRecruiterJob } from '../../../store/reducers/recruiterPostJob/apiThunk';

const { JOB_SUMMARY } = ROUTE_PATH;

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <img src={stepChecked} alt="" /> : icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ['Basic Details', 'Job Details', 'Location', 'Availability', 'Subscription'];

const Postjob = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [viewSummaryId, setViewSummaryId] = useState(null);
  const [isJob, setIsJob] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // useEffect(() => {
  //   const jobId = localStorage.getItem('jobId');
  //   setViewSummary(jobId);
  // }, []);

  // const handleStepChange = (step) => {
  //   setActiveStep(step);
  // };

  useEffect(() => {
    dispatch(getRecruiterJob())
      .then((res) => {
        setIsJob(!!res?.payload?.data);
        const { _id: jobId } = res?.payload?.data;
        setViewSummaryId(jobId);
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
      });
  }, [location]);

  return (
    <StyledPostJobPage>
      <Grid container spacing={2} className="page-header-wrap" justifyContent="space-between">
        <Grid item xs={12} sm={10}>
          <div className="stepperWrap">
            <div className="title">Create Job Listing</div>
            <Stack sx={{ width: '100%' }} spacing={4}>
              <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </div>
        </Grid>
        <Grid
          container
          xs={2}
          xl={1.3}
          lg={2}
          marginLeft={{ xs: '16px', sm: '-16px' }}
          className="previewButton-wrap"
          spacing={2}
          alignItems="center">
          <SecondaryButton
            disabled={!isJob}
            sx={{
              width: '150px',
              background: 'transparent',
              border: `1px solid ${!isJob ? '#757575' : '#415DF3'}`,
              borderRadius: '10px',
              '@media (max-width: 480px)': {
                fontSize: '14px',
              },
            }}
            onClick={() => navigate((`${JOB_SUMMARY}?job-id=${viewSummaryId}`))}>
            Preview Job
          </SecondaryButton>
        </Grid>
      </Grid>
      <div className="page-warning">
        <div className="warning-icon">
          <RedWarningIcon />
        </div>
        <div className="warning-text">
          The validity of each job is 90 days. Edit the job before the completion of 90 days to keep
          it live if needed.
        </div>
      </div>
      <CreatePostJob stepNumber={activeStep} setActiveStep={setActiveStep} />
    </StyledPostJobPage>
  );
};
export default PrivateLayout(Postjob);
