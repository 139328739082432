/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Pagination } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { useNavigate, useLocation } from 'react-router-dom';
import PrivateLayout from '../../../layout/PrivateLayout';
import StyledSavedJobs from './StyledSavedJobs';
import share from '../../../assets/svg/share-circle.svg';
import saved from '../../../assets/svg/savedBlue.svg';
import close from '../../../assets/svg/close-circle.svg';
import coin from '../../../assets/svg/coin-jobs.svg';
import locationPoint from '../../../assets/svg/location-jobs.svg';
import calendar from '../../../assets/svg/calendar-jobs.svg';
import noSavedJobs from '../../../assets/svg/nosaved-jobs.svg';
import JobdetailsModal from '../jobsOverview/JobdetailsModal';
import JobShareModal from '../jobsOverview/JobShareModal';
import { fetchSavedJobs, fetchSaveJob } from '../../../store/reducers/fractionTalent/apiThunk';
import StyledJobsOverview from '../jobsOverview/StyledJobsOverview';
import { StyledPaginationBox } from '../trackAppliedJob/StyledTracksJobs';
import { checkCompensationType, durationCheck, stringAvatar } from '../../../helper';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
// import save from '../../../assets/svg/save-circle.svg';

const rowsPerPage = 10;

const SavedJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [savedJobsList, setSavedJobsList] = useState([]);
  const [jobDetails, setJobDetails] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setloading] = useState(true);
  const [openDialogShare, setOpenDialogShare] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState(null);
  const [savedJobsLocal, setSavedJobsLocal] = useState([]);
  const [shareId, setShareId] = useState(null);

  const { userLandingData: { data: { subscriptionDetails = {} } = {} } = {} }
  = useSelector(selectorExpertProfile);

  if (!subscriptionDetails) {
    navigate('/fraction-talent/my-job-profile?activeStep=3');
  }

  const getSuggestedJob = async () => {
    setloading(true);
    const query = `limit=${rowsPerPage}&page=${currentPage}`;
    const response = await dispatch(fetchSavedJobs(query));
    const { payload: { status = false, data: { data = {}, count = 0, page = 0 } } = {} } = response;
    setPaginationData({
      count,
      page,
    });
    setloading(false);
    if (status) {
      setSavedJobsList(data);
      setSavedJobsLocal(data);
    }
  };

  useEffect(() => {
    getSuggestedJob();
    window.scrollTo(0, 0);
  }, [rowsPerPage, currentPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleJobDetails = (item) => {
    // const params = new URLSearchParams(location.search);
    // if (item.jobId) {
    //   setJobDetails(item.jobId);
    //   const { jobId: { _id: id = '' } } = item;
    //   params.set('job-id', id);
    //   navigate(`${location.pathname}?${params.toString()}`);
    // } else {
    //   const { _id: id = '' } = item;
    //   params.set('job-id', id);
    //   setJobDetails(item);
    // }
    // navigate(`${location.pathname}?${params.toString()}`);
    // setOpenDialog(true);
    if (item.jobId) {
      const {
        jobId: { _id: id },
      } = item;
      navigate(`/job-detail?job-id=${id}`);
    } else {
      const { _id: id } = item;
      navigate(`/job-detail?job-id=${id}`);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setJobDetails({});
  };

  const handleOpenShareDialog = (item) => {
    const params = new URLSearchParams(location.search);
    if (item.jobId) {
      const { jobId: { _id: id } } = item;
      params.set('job-id', id);
      const jobid = params.get('job-id');
      setShareId(jobid);
    } else {
      const { _id: id } = item;
      params.set('job-id', id);
      const jobid = params.get('job-id');
      setShareId(jobid);
    }
    setOpenDialogShare(true);
  };

  const handleCloseShareDialog = () => {
    setOpenDialogShare(false);
  };

  const removeSavedJobs = (itemToRemove) => {
    setSavedJobsLocal((prevItems) => prevItems.filter((_, index) => index !== itemToRemove));
  };

  const handleSaveJob = async (item, isAlreadySaved) => {
    const { jobId: { _id: id = '' } } = item;
    const data = {
      jobId: id,
      save: isAlreadySaved,
    };
    const response = await dispatch(fetchSaveJob(data));
    const { payload: { status = false } = {} } = response;
    if (status) {
      getSuggestedJob();

      ToastNotifySuccess('Job status (saved/unsaved) updated successfully.');
    }
  };

  return (
    <StyledSavedJobs>
      <div className="page-wrap">
        <Grid container columnSpacing={3}>
          <Grid item xs={12}>
            <div className="suggested-jobs-wrap">
              <div className="section-title">Saved Jobs</div>
              { savedJobsLocal.length ?
                <Grid
                  container
                  spacing={2}
                  sx={{ padding: { xs: '16px', md: '20px' } }}
                  className="job-card-list-wrap">
                  {savedJobsLocal.map((item, idx) => (
                    <Grid item xs={12} md={savedJobsLocal.length > 1 ? 6 : 12} key={idx}>
                      <div className="job-card-wrap">
                        <div className="card-top">
                          <div className="right">
                            {item?.jobId?.companyLogo ?
                              <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${item?.jobId?.companyLogo}`} alt="" />
                              : <Avatar {...stringAvatar(item?.jobId?.companyName)} />}
                            <div>
                              <div className="job-title">{item?.jobId?.jobRole}</div>
                              <div className="job-postedby">
                                {item?.jobId?.companyName}
                              </div>
                            </div>
                          </div>
                          <div className="left">
                            <img className="brand-image" src={share} alt="" onClick={() => handleOpenShareDialog(item)} />
                            <img
                              className="brand-image"
                              // src={item?.jobId?.isSaved ? saved : save}
                              src={saved}
                              alt=""
                              onClick={() => (item?.jobId?.isJobSaved ?
                                handleSaveJob(item, true)
                                : handleSaveJob(item, false))} />
                            <img className="brand-image" src={close} alt="" onClick={() => removeSavedJobs(idx)} />
                          </div>
                        </div>
                        <div className="skill-chips-box">
                          {item?.jobId?.skills?.map((skill) => <span className="chips">{skill}</span>)}
                        </div>
                        <div className="card-bottom">
                          <div className="job-info-content">
                            <div className="icon-text-box-wrap">
                              <div className="icon-text-box">
                                <img alt="" src={locationPoint} />
                                <div className="sub-text">{item?.jobId?.jobCity}, {item?.jobId?.jobState} <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>
                                  <span style={{ textTransform: 'capitalize' }}>{item?.jobId?.locationType}</span>
                                </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={coin} />
                                <div className="sub-text">₹ {item?.jobId?.compensationRate}/{`${checkCompensationType(item?.jobId?.compensationType)}`} </div>
                              </div>
                              <div className="icon-text-box">
                                <img alt="" src={calendar} />
                                <div className="sub-text">
                                  {item?.jobId?.availabilityPeriod} {' '}
                                  {durationCheck(item?.jobId?.availableFor, item?.jobId?.availabilityPeriod)}
                                </div>
                              </div>
                            </div>
                            <div className="view-details" onClick={() => handleJobDetails(item)}>View Details</div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid> :
                <div className="no-result-box">
                  {!loading && !savedJobsList.length && <img className="brand-image" src={noSavedJobs} alt="" />}
                </div>}
            </div>
          </Grid>
        </Grid>
      </div>

      {savedJobsList.length !== 0 ? (
        <StyledPaginationBox>
          <div className="jobs-pagination-box">
            <div className="left-wrap">
              <TablePagination
                component="div"
                count={paginationData?.count}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
            />
            </div>
            <div className="right-wrap">
              <Pagination variant="outlined" shape="rounded" size="medium" onChange={handleChangePage} />
            </div>
          </div>
        </StyledPaginationBox>
      ) : (
        ''
      )}

      <StyledJobsOverview>
        <JobdetailsModal
          openDialog={openDialog}
          closeDialog={handleCloseDialog}
          jobDetails={jobDetails}
          handleShare={() => handleOpenShareDialog(jobDetails)}
          handleSaveJob={() => handleSaveJob(jobDetails, !!(jobDetails.isJobSaved))}
        />
        <JobShareModal
          openDialog={openDialogShare}
          closeDialog={handleCloseShareDialog}
          id={shareId}
        />
      </StyledJobsOverview>
    </StyledSavedJobs>
  );
};

export default PrivateLayout(SavedJobs, false);
