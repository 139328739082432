/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable max-len */
// import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { useState } from 'react';
import { ROUTE_PATH } from '../../../constants/route';
import { BorderButton, PrimaryButton } from '../../Button';
import logo from '../../../assets/svg/logo.svg';
// import { selectorAuthentication } from '../../../store/reducers/authentication';
import { fetchLogout } from '../../../store/reducers/authentication/apiThunk';
import StyledHeader from './StyledHeader';
// import Modal from '../../Modal';

const { LOGIN, DASHBOARD, RECRUITER_DASHBOARD } = ROUTE_PATH;

function Header() {
  const naivgate = useNavigate();
  const dispatch = useDispatch();
  const isRecruiter = localStorage.getItem('recruiter');
  // const [openLogoutModal, setOpenLogoutModal] = useState(false);

  // const {
  //   auth: {
  //     data: {
  //       token = '',
  //     } = {},
  //   } = {},
  // } = useSelector(selectorAuthentication);

  const handleLogout = (type) => {
    switch (type) {
      case 'login':
        naivgate(LOGIN);
        // setOpenLogoutModal(true);
        break;
      case 'logout':
        dispatch(fetchLogout());
        break;
      case 'myAccount':
        naivgate(DASHBOARD);
        break;
      case 'home':
        naivgate('/');
        break;
      case 'RecruiterMyAccount':
        naivgate(RECRUITER_DASHBOARD);
        break;
      default:
        break;
    }
  };

  // const handleCloseLogoutDialoag = () => {
  //   setOpenLogoutModal(false);
  // };

  // const logoutModalContent = () => (
  //   <>
  //     <div className="title">We&apos;re under development, launching soon!</div>
  //     <div className="description">Stay tuned....</div>
  //     <PrimaryButton
  //       sx={{
  //         width: '120px',
  //         '@media screen and (max-width: 480px)': {
  //           width: '80px',
  //         },
  //       }}
  //       onClick={() => handleCloseLogoutDialoag()}
  //     >
  //       Close

  //     </PrimaryButton>
  //   </>
  // );

  return (
    <StyledHeader>
      {/* <Modal
        openDialog={openLogoutModal}
        closeDialog={handleCloseLogoutDialoag}
        dialogContent={logoutModalContent()}
        noHeader={false}
        closeButVisible
        dialogCss="logoutDialogWrap"
      /> */}
      <div className="page-header-wrap">
        <div className="header-content">
          <img src={logo} className="logoImage" alt="Logo" onClick={() => handleLogout('home')} />
        </div>
        <div className="header-footer">
          {!localStorage.getItem('auth') ? (
            <>
              <BorderButton
                sx={{
                  width: '188px',
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '16px',
                }}
                onClick={() => {
                  localStorage.removeItem('recruiter');
                  handleLogout('login');
                }}>
                Login as Expert
              </BorderButton>
              {/* <PrimaryButton
                sx={{ maxWidth: '145px', fontFamily: 'Plus Jakarta Sans' }}
                onClick={() => handleLogout('login')}>
                Sign Up
              </PrimaryButton> */}
              <PrimaryButton
                sx={{ width: '188px', fontFamily: 'Plus Jakarta Sans', fontSize: '16px' }}
                onClick={() => {
                  localStorage.setItem('recruiter', 'true');
                  handleLogout('login');
                }}>
                Start as Recruiter
              </PrimaryButton>
            </>
          ) : (
            <div>
              <PrimaryButton
                sx={{ maxWidth: '150px' }}
                onClick={() => {
                  if (isRecruiter) {
                    handleLogout('RecruiterMyAccount');
                  } else {
                    handleLogout('myAccount');
                  }
                }}>
                My Account
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </StyledHeader>
  );
}

export default Header;
