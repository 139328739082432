import { combineReducers } from '@reduxjs/toolkit';
import authentication from './authentication';
import loader from './loader';
import expertProfile from './expertProfile';
import fractionTalent from './fractionTalent';
import recruiterPostJob from './recruiterPostJob';

const rootReducer = combineReducers({
  authentication,
  loader,
  expertProfile,
  fractionTalent,
  recruiterPostJob,
});

export default rootReducer;
