/* eslint-disable import/prefer-default-export */
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#415DF3',
    },
    secondary: {
      main: '#fff',
    },
    customColor: {
      primaryPink: '#FFF2F2',
      primaryWhite: '#FFFFFF',
      secondaryWhite: '#F2F2F2',
      tertiaryWhite: '#EDEDED',
      primaryBlack: '#1C1C1C',
      secondaryBlack: '#0B0B0B',
      tertiaryBlack: '#0A0A0A',
      primaryGrey: '#3c3b3b',
      secondaryGrey: '#525252',
      primaryLightGray: '#B4B4B4',
      secondaryLightGray: '#242424',
      secondaryGreyText: '#A6A6A6',
      tertiaryGreyText: '#A1A1A1',
      primaryOrange: '#F8F4F0',
      primaryYellow: '#F69824',
      secondaryOrange: '#FFE1C3',
      primaryTransparent: 'transparent',
      secondaryTwo: '#EAEAEA',
      secondaryThree: '#3F3F3F',
      secondaryFour: '#262626',
      stateLinks: '#63BDFF',
      stateError: '#DA3333',
      errorRed: '#ff3c3c',
      bg_red: 'rgba(218, 51, 51, 0.10)',
      secondayLinks: '#1976D2',
      black: '#000',
      blackLight: '#BBBBBB',
      link_1: '#1F90FF',
      black_1: '#D9D9D9',
      black_2: '#1E1E1E',
      black_3: '#282828',
      black_4: '#eaeaf0',
      white_1: '#F1F6F9',
      white_2: '#EBEBEE',
      white_4: '#FFFFFFCC',
      grey_1: '#CECECE',
      grey_2: '#979797',
      grey_3: '#171717',
      grey_4: '#FFFFFF1A',
      grey_5: '#F4F4F4',
      grey_6: '#E2E4E7',
      grey_7: '#757575',
      primaryGradient: 'linear-gradient(140.56deg, #1E1E1E 22.57%, #000000 79.47%)',
      secondaryGradient: 'linear-gradient(103.17deg, rgba(30, 30, 30, 0) 38.02%, #1C1C1C 106.59%)',
      tertiaryGradient: 'linear-gradient(180deg, #FFF 0%, #FFF5EC 114.75%)',
      link_color: '#1F90FF',
      blue1: '#1A1E22',
      blue2: '#334FE5',
      blue3: 'rgba(13, 43, 200, 0.30)',
      blue4: 'rgba(205, 210, 240, 0.50)',
      blue5: 'rgba(13, 43, 200, 0.10)',
      purpleLight: '#CDD2F0',
      success: '#00AF31',
    },
    boxShadow: {
      main: '0px 7px 10px rgba(40, 41, 61, 0.08)',
      secondary: '0px 0px 23px 0px rgba(0, 0, 0, 0.06)',
      tertiary: '0px 10px 23px 0px rgba(0, 0, 0, 0.06)',
      fourth: '0px 6.435px 20.913px 0px rgba(0, 0, 0, 0.05)',
      fifth: '0px 0px 40px 10px rgba(89, 48, 11, 0.08)',
      sixth: '0px 10px 23px 0px rgba(0, 0, 0, 0.05)',
      seventh: '0px 10px 23px 0px rgba(0, 0, 0, 0.09)',
      eight: '0px 4.667px 4.667px 0px rgba(0, 0, 0, 0.10)',
    },
  },
});
