import React from 'react';
import { PrimaryButton, SecondaryButton } from '../Button';
import StyledLandingButton from './StyledLandingButton';

export default function index({
  showBackButton = true,
  showNextButton = true,
  stepNumber = 0,
  setActiveStep,
  handleSubmit,
  disableSave = false,
  disableNext = false,
  pay = false,
}) {
  const handleNextSection = () => {
    if (stepNumber < 3) {
      setActiveStep(stepNumber + 1);
    } else {
      setActiveStep(0);
    }
  };

  const handleBackSection = () => {
    if (stepNumber > 0) {
      setActiveStep(stepNumber - 1);
    } else {
      setActiveStep(0);
    }
  };
  const handleButtonPress = async () => {
    if (!disableNext) {
      if (!disableSave) {
        await handleSubmit();
        await handleNextSection();
      } else {
        handleNextSection();
      }
    }
  };

  return (
    <StyledLandingButton>
      <div className={`${pay ? 'pay-buttons-wrap' : 'buttons-wrap'}`}>
        {showBackButton ? (
          <div className="left-btn">
            <SecondaryButton
              onClick={() => handleBackSection()}
              sx={{
                border: '1px solid #415DF3',
              }}>
              Back
            </SecondaryButton>
          </div>
        ) : (
          ''
        )}
        <div className="right-btn">
          {/* <div className="footer-btn">
            {!disableSave ? (
              <SecondaryButton
                onClick={async () => {
                  await handleNextSection();
                  await handleSubmit();
                }}
                sx={{
                  border: '1px solid #415DF3',
                }}
                disabled={disableNext}>
                Save & Next
              </SecondaryButton>
            ) : (
              ''
            )}
          </div> */}
          {showNextButton && (
            <div className="footer-btn footer-save" style={{ width: pay ? '200px' : '' }}>
              <PrimaryButton onClick={handleButtonPress} disabled={disableNext}>
                {!disableSave
                  ? stepNumber === 0
                    ? 'Save'
                    : 'Save & next'
                  : stepNumber === 3
                    ? pay
                      ? 'Continue & Pay'
                      : 'Continue'
                    : 'Next'}
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </StyledLandingButton>
  );
}
