import { createSlice } from '@reduxjs/toolkit';
// import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import { fetchJobOverview, fetchJobProfile, fetchSearchingFilters } from './apiThunk';

const initialData = {
  fractionTalent: {},
  jobOverView: {},
  searchingFilters: {},
};
const fractionTalentSlice = createSlice({
  name: 'fractionTalent',
  initialState: { ...initialData },
  reducers: {
    resetFractionTalent: () => initialData,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchJobProfile.fulfilled, (state, action) => {
      state.fractionTalent = action.payload;
    });
    builder.addCase(fetchJobOverview.fulfilled, (state, action) => {
      state.jobOverView = action.payload;
    });
    builder.addCase(fetchSearchingFilters.fulfilled, (state, action) => {
      state.searchingFilters = action.payload;
    });
  },
});

const { reducer, actions } = fractionTalentSlice;
const selectorFractionTalent = (state) => state.fractionTalent;
const { resetFractionTalent } = actions;
export { resetFractionTalent, selectorFractionTalent };

export default reducer;
