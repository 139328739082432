import { styled } from '@mui/material/styles';
import subscriptionBG from '../../../assets/svg/subscriptionBG.svg';
import planBg from '../../../assets/svg/planbg.svg';

const StyledSubscription = styled('div')(
  ({ theme }) => `
    .main-container {
      margin:0px auto;
      justify-content: center;
      @media screen and (max-width: 786px){
        margin:0px 16px;
      }
      .subscard-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background:#ffffff;
        border-radius: 24px;
        .subscard-wrap-top{
          background: url(${subscriptionBG});
          background-repeat: no-repeat;
          background-size: cover;
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
          width: 100%;
          .heading {
            color: ${theme.palette.customColor.primaryBlack};
            font-family: Sora;
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            padding:24px 24px 8px 16px;
            @media screen and (max-width: 786px){
              font-size: 16px;
              font-weight: 600;
              width: 100%;
              padding:16px;
            }
          }
          .sub-title{
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color:#525252;
            padding:0px 0px 24px 16px;
          }
        }
        .plan {
          padding:0px 16px 16px;
          width:100%;
          .plan-cards {
            display:grid;
            grid-template-columns: repeat(3, 1fr);
            gap:20px;
            @media screen and (max-width: 900px){
              grid-template-columns: repeat(1,1fr);
            }
            .plan-card {
              background-color:#ffffff;
              border: 1px solid ${theme.palette.customColor.grey_6};
              border-radius: 12px;
              cursor:pointer;
              .offer-strip-box{
                height:34px;
                border-radius: 12px 12px 0px 0px;
              }
              .offer-strip {
                background-color: #00AF3114;
                height:36px;
                color: ${theme.palette.customColor.success};
                font-family: Plus Jakarta Sans;
                font-size: 12px;
                font-weight: 500;
                line-height:15px;
                border-radius: 12px 12px 0px 0px;
                display:flex;
                align-items:center;
                justify-content: center;
              }
              .select-plan {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 16px 14px 16px;
                min-height:77px;
                @media screen and (max-width: 786px){
                  min-height:0px;
                }
              }
              .plan-duration {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Plus Jakarta Sans;
                font-size: 18px;
                font-weight: 600;
                line-height: 22.68px;
                text-align: left;
                display:flex;
                justify-content:space-between;
                width:100%;
                .current-plan{
                  background:#1C1C1C;
                  border-radius:5px;
                  padding:6px 10px;
                  font-family: Plus Jakarta Sans;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 15.12px;
                  text-align: left;
                  color:#ffffff;
                  border:1px solid #E2E4E7;
                  text-transform:uppercase;
                }
                .upgrade-plan{
                  background:#415DF31A;
                  border-radius:5px;
                  padding:6px 10px;
                  font-family: Plus Jakarta Sans;
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 15.12px;
                  text-align: left;
                  color:#415DF3;
                  border:1px solid #E2E4E7;
                  text-transform:uppercase;
                }
              }
              .plan-amount {
                text-align: left;
                padding:0px 16px;
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Sora;
                font-size: 24px;
                font-weight: 700;
                line-height: 30.24px;
                text-align: left;
                span {
                  color: ${theme.palette.customColor.primaryBlack};;
                  font-family:Plus Jakarta Sans;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 30.24px;
                }
              }
              .select-plan{
                padding:16px;
              }
              .plan-details{
                padding:0px 16px 16px;
                .title{
                  font-family: Plus Jakarta Sans;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 20.16px;
                  text-align: left;
                  color:#1C1C1C;
                  margin-bottom:8px;
                }
                .plan-details-list{
                  display:flex;
                  align-items:center;
                  justify-content: space-between;
                  margin:8px 0px;
                  gap: 28px;
                  
                  .left{
                    font-family: Plus Jakarta Sans;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 28px;
                    text-align: left;
                    color:#525252;
                  }
                  .right{
                    font-family: Plus Jakarta Sans;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20.16px;
                    text-align: left;
                    color:#525252;
                    
                  }
                  .blue{
                    color:#415DF3;
                  }
                }
              }
            }
          }
        }
        .active{
          background: url(${planBg});
          background-repeat: no-repeat;
          background-size: cover;
          .offer-strip {
            background-color:#ffffff;
          }
        }
      }
      .btns{
        display: flex;
      }

      .faq-wrap{
        margin-top:32px;
        padding:24px;
        background:#FFFFFF;
        border-radius: 24px;
        @media screen and (max-width: 786px){
          padding:16px;
        }
        .title{
          font-family: Sora;
          font-size: 24px;
          font-weight: 700;
          line-height: 40px;
          text-align: left;
          color:#0D141C;
          margin-bottom:16px;
        }
        .MuiAccordion-root .MuiAccordionSummary-root{
          background-color: #FFFFFF;
          color: #0D141C;
          
        }
        .MuiCollapse-entered {
          padding:0px 15px 12px;
        }
        .MuiCollapse-root{
          background-color: #FFFFFF;
          color: #0D141C;
          font-family: Plus Jakarta Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          color: #525252;
          padding: 0px;
          @media screen and (max-width: 786px){
            font-size: 12px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
          }
        }
        .MuiAccordionSummary-root{
          font-family: Plus Jakarta Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          color:#0D141C;
          font-family: Plus Jakarta Sans;
          @media screen and (max-width: 786px){
            font-size: 12px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
          }
        }
        .MuiAccordionSummary-expandIconWrapper svg{
          fill:#0D141C;
        }
        .MuiAccordionSummary-content {
          margin:0px;
        }
        .Mui-expanded{
          min-height:25px;
          .MuiAccordionSummary-content {
            margin:12px 0px 0px;
          }
        }
      }  
    }    
  `,
);

export default StyledSubscription;
