/* eslint-disable arrow-body-style */
import React from 'react';

const CircleCloseIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="25"
        height="25"
        rx="12.5"
        stroke="#E2E4E7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.76465 17.2361L17.2352 8.76562"
        stroke="#1C1C1C"
        strokeWidth="1.41176"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2352 17.2361L8.76465 8.76562"
        stroke="#1C1C1C"
        strokeWidth="1.41176"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleCloseIcon;
