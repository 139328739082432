/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PrivateLayout from '../../layout/PrivateLayout';
import Chart from './chart';
import StyledDashboard from './StyledDashboard';
import {
  fetchLandingPageData,
  fetchDashboardStatistics,
  fetchDashboardSessions,
} from '../../store/reducers/expertProfile/apiThunk';
import { selectorExpertProfile } from '../../store/reducers/expertProfile/index';
import { selectorAuthentication } from '../../store/reducers/authentication';
import copyImg from '../../assets/svg/copy.svg';
import TextField from '../../components/TextField';
import CustomizedTables from '../../components/Table';
import { PrimaryButton } from '../../components/Button';
import profileVisitsIcon from '../../assets/svg/profileVisitsIcon.svg';
import amountIcon from '../../assets/svg/coinIcon.svg';
import notdata from '../../assets/svg/notdata.svg';
import CustomTooltip from '../../components/Tooltip/Tooltip';
import { fetchJobProfile } from '../../store/reducers/fractionTalent/apiThunk';

const Dashboard = () => {
  const dispatch = useDispatch();
  const naivgate = useNavigate();
  const [fetchedSessionData, setFetchedSessionData] = useState([]);
  const [copyText, setCopyText] = useState('Copy to clipboard');

  const handleFetchTableData = async () => {
    const requestData = {
      limit: 5,
      page: 1,
      body: {
        type: 1,
      },
    };
    const dataFetched = await dispatch(fetchDashboardSessions(requestData)).then((val) => val);
    const { payload: { status = '' } = {} } = dataFetched;
    if (status && dataFetched?.payload?.data?.sessionList?.length !== 0) {
      const refracteredData = dataFetched?.payload?.data?.sessionList.map((obj) => {
        const momentObj = moment(obj.eventStartTime.dateTime);
        const date = momentObj.format('DD');
        const monthName = momentObj.format('MMMM');
        const year = momentObj.format('YYYY');
        const time = momentObj.format('hh:mm A');
        const hours = Math.floor(obj.sessionDuration / 60);
        const minutes = obj.sessionDuration % 60;

        return {
          sessionId: obj?._id,
          seekerId: obj?.seeker._id,
          expertId: obj?.expert._id,
          session: obj.title,
          date: `${monthName} ${date}, ${year}`,
          duration: `${hours !== 0 ? (hours === 1 ? `${hours} hour` : `${hours} hours`) : ''} ${minutes !== 0 ? (minutes === 1 ? `${minutes} min` : `${minutes} mins`) : ''} `,
          startTime: time,
          meetLink: obj.googleMeetLink,
        };
      });
      setFetchedSessionData(refracteredData);
    } else {
      setFetchedSessionData([]);
    }
  };

  useEffect(() => {
    handleFetchTableData();
    dispatch(fetchDashboardStatistics());
    dispatch(fetchLandingPageData());
    dispatch(fetchJobProfile());
  }, []);

  const { userData: { data: { username = '' } = {} } = {} } = useSelector(selectorAuthentication);
  const {
    userLandingData: { data: { whoAmI = '' } = {} } = {},
    dashboardStatistics: {
      data: {
        booked_session_count = 0,
        completed_session_count = 0,
        number_of_views_on_landing_page = 0,
        total_amount_earned = 0,
        // currency = 'ISD',
      } = {},
    } = {},
  } = useSelector(selectorExpertProfile);

  const handleCopyUrl = async () => {
    await navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/${username}`);
    setCopyText('Copied to clipboard');
  };

  const handleNavigate = (name) => {
    switch (name) {
      case 'landing':
        naivgate('/landing');
        break;
      case 'sessionList':
        naivgate('/dashboard/sessionsList');
        break;
      default:
    }
  };

  const renderDasboardData = () => (
    <div className="page-wrap">
      <div className="page-stats-wrap">
        <div className="left-section-wrap">
          <div className="content-wrap">
            <div className="page-url">
              <div className="heading">Get started with sharing your sessions.</div>
              <div className="username-url">
                <TextField
                  value={`${process.env.REACT_APP_URL}/${username}`}
                  disabled
                  icon={
                    <CustomTooltip title={copyText}>
                      <div
                        className="copyBtn"
                        onClick={() => handleCopyUrl()}
                        onMouseOut={() => setCopyText('Copy to clipboard')}>
                        <img src={copyImg} alt="" />
                        Copy
                      </div>
                    </CustomTooltip>
                  }
                />
              </div>
            </div>
          </div>
          <div className="header-wrap">
            <div className="profileVisits">
              <div className="left-wrap">
                <div className="count">{number_of_views_on_landing_page}</div>
                <div className="heading">Profile Visits</div>
              </div>
              <div>
                <img src={profileVisitsIcon} alt="" srcSet="" className="countTabsIcon" />
              </div>
            </div>
            <div className="profileVisits">
              <div className="left-wrap">
                <div className="count">{`${'INR'} ${total_amount_earned}`}</div>
                <div className="heading">Total amount earned</div>
              </div>
              <div>
                <img src={amountIcon} alt="" className="countTabsIcon" />
              </div>
            </div>
          </div>
        </div>
        <div className="right-section-wrap">
          <div className="chart">
            <div className="title">
              <div>Sessions</div>
              <div onClick={() => handleNavigate('sessionList')}>
                <ChevronRightRoundedIcon sx={{ fontSize: '30px', cursor: 'pointer' }} />
              </div>
            </div>
            <div className="content">
              <Chart
                totalSessionCount={booked_session_count}
                sessionTakenCount={completed_session_count}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="page-table-wrap">
        <div className="title">
          <div className="heading">Upcoming Sessions</div>
          <div className="seeAll" onClick={() => handleNavigate('sessionList')}>
            See All
            <ChevronRightRoundedIcon sx={{ fontSize: '30px' }} />
          </div>
        </div>
        <div className="table-wrap">
          {fetchedSessionData.length === 0 ? (
            <div>
              <img src={notdata} alt="" srcSet="" />
              <div className="noSessionsHeading">No Upcoming Sessions yet</div>
            </div>
          ) : (
            <CustomizedTables
              tableData={fetchedSessionData}
              tableDataUpdate={handleFetchTableData}
            />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <StyledDashboard>
      {whoAmI === '' ? (
        <div className="noData-wrap">
          <div className="heading">Start with creating your own landing page</div>
          <PrimaryButton
            sx={{
              width: '150px',
              '@media screen and (max-width: 767px)': {
                width: '100px',
              },
            }}
            onClick={() => handleNavigate('landing')}>
            Continue
          </PrimaryButton>
        </div>
      ) : (
        renderDasboardData()
      )}
    </StyledDashboard>
  );
};

export default PrivateLayout(Dashboard);
