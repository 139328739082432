/* eslint-disable arrow-body-style */
import React from 'react';

const CheckboxWeb = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="3.25" fill="white" />
      <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="3.25" stroke="#E2E4E7" strokeWidth="1.5" />
    </svg>

  );
};

export default CheckboxWeb;
