/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import PrivateLayout from '../../layout/PrivateLayout';
import { SecondaryButton } from '../../components/Button';
import stepChecked from '../../assets/svg/stepChecked.svg';
import { ColorlibConnector, ColorlibStepIconRoot } from '../landing/StyledLandingPage';
import StyledFractionTalent from './StyledFractionTalent';
import Create from './Create';
import { fetchJobProfile } from '../../store/reducers/fractionTalent/apiThunk';
import { selectorFractionTalent } from '../../store/reducers/fractionTalent';

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <img src={stepChecked} alt="" /> : icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = ['Basic details', 'Location', 'Availability', 'Subscription'];

const FractionTalent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [activeStep, setActiveStep] = useState(0);
  const { fractionTalent: { data: { expert = {} }, data: talentProfile = {} } } =
   useSelector(selectorFractionTalent);

  const handleStepChange = (step) => {
    if (talentProfile) {
      setActiveStep(step);
    }
  };

  useEffect(() => {
    dispatch(fetchJobProfile());
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Get the query parameter values
    if (location.search) {
      setActiveStep(parseInt(params.get('activeStep')));
    }
  }, [location]);

  return (
    <StyledFractionTalent>
      <Grid container spacing={2} className="page-header-wrap" justifyContent="space-between">
        <Grid item xs={12} sm={10}>
          <div className="stepperWrap">
            <div className="title">
              Please fill the required details for creating you Job profile
            </div>
            <Stack sx={{ width: '100%' }} spacing={4}>
              <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label, index) => (
                  <Step key={label} onClick={() => handleStepChange(index)}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </div>
        </Grid>
        <Grid
          container
          xs={2}
          xl={1.3}
          lg={2}
          marginLeft={{ xs: '16px', sm: '-16px' }}
          className="previewButton-wrap"
          spacing={2}
          alignItems="center">
          <SecondaryButton
            sx={{
              width: '150px',
              background: 'transparent',
              border: '1px solid #415DF3',
              borderRadius: '10px',
              '@media (max-width: 480px)': {
                fontSize: '14px',
              },
            }}
            onClick={() => {
              sessionStorage.setItem('isVisited', true);
              navigate(`/preview-talent?id=${expert._id}`);
            }}
            disabled={!talentProfile}>
            Preview
          </SecondaryButton>
        </Grid>
      </Grid>
      <Create stepNumber={activeStep} setActiveStep={setActiveStep} />
    </StyledFractionTalent>
  );
};

export default PrivateLayout(FractionTalent, false);
