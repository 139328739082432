import { styled } from '@mui/material/styles';

const StyledSignupPage = styled('div')(
  ({ theme }) => `
  .page-wrap {
    min-height: 100vh;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    @media screen and (max-width: 980px){
      flex-direction: column;
    }
  }

  .page-left-wrap{
    display: flex;
    @media screen and (max-width: 1290px){
      display: none;
    }
    .loginBg{
      width: 100%;
      height: 100vh;
    }
  }
  .page-right-wrap{
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 0;
    color: ${theme.palette.secondary.main};
    height: 100vh;
    overflow-y: auto;
    @media screen and (max-width: 480px){
      position: relative;
    }
    .page-right-content{
      padding-top: 80px;
      min-width: 486px;
      text-align: left;
      height: fit-content;
      @media screen and (max-width: 1000px){
        min-width: unset;
        width: 75%;
      }
      @media screen and (max-width: 480px){
        width: 93%;
        padding-top: 40px;
      }
      .logo-wrap{
        margin-bottom: 60px;
        @media screen and (max-width: 480px){
          margin-bottom: 40px;
        }
        .logoImage{
          width: 304px;
          @media screen and (max-width: 480px){
            width: 225px;
          }
        }
      }
      .heading-wrap{
        margin-bottom: 40px;
        .title{
          color: ${theme.palette.customColor.primaryBlack};
          font-family: Sora;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 8px;
          @media screen and (max-width: 480px){
            font-size: 20px;
          }
        }
        .subheading{
          color: ${theme.palette.customColor.secondaryGrey};
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;  
          @media screen and (max-width: 480px){
            font-size: 14px;
          }
        }
      }
      .profile-section-wrap{
        justify-content: space-between;
        display: flex;
        column-gap: 40px;
        .profilepic-wrap{
          position: relative;
          .profileImage{
            width: 110px;
            height: 110px;
            overflow: hidden;
            border-radius: 100px;
            object-fit: cover;
          }
          .fileUpload{
            position: absolute;
            width: 17%;
            top: 84px;
            right: 0px;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
          }
          .changeProfile{
            position: absolute;
            width: 29px;
            top: 81px;
            right: 0px;
            cursor: pointer;
          }
        }
        .name-wrap{
          width: 100%;
        }
      }
      .MuiInputLabel-root.Mui-error {
        color: ${theme.palette.customColor.tertiaryGreyText};
      }
      .domainHighlight{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: ${theme.palette.primary.main};
      }
      .username-outer-wrap{
        display:flex;
        align-items:center;
        .loader-box{
          margin-left:16px;
          max-width:50px
        }
      }
    }
    .country-wrap{
      margin: 30px 0px;
      .countryNote{
        font-size: 11px;
        color: ${theme.palette.customColor.secondaryGrey};
      }
    }
  }
  .termsCondition-wrap{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: ${theme.palette.customColor.secondaryGrey};
    text-align: left;
    margin-bottom: 20px;
  }
  .checkbox{
    padding: 0px 9px 0px 0px;
    color: ${theme.palette.customColor.primaryGrey};
  }
  .linkColor{
    font-weight: 500;
    color: ${theme.palette.customColor.primaryBlack};
    text-decoration: none;
    border-bottom: 1px solid ${theme.palette.customColor.primaryBlack};
  }
  .logout-wrap{
    margin-top: 70px;
    width: fit-content;
    height: fit-content;
    filter: invert(1);
    @media screen and (max-width: 480px){
      position: absolute;
      right: 20px;
      margin-top: 30px;
    }
  }
  .errorMsg{
    font-size: 12px;
    color: ${theme.palette.customColor.errorRed};
    font-weight: 500;
    margin-top: -10px;
  }
  .verifyEmail{
    font-size: 12px;
    text-align: right;
    span{
      padding: 5px;
      color: ${theme.palette.primary.main};
      cursor: pointer;
    }
  }
  .disableVerifyEmail{
    span{
      color: ${theme.palette.customColor.tertiaryGreyText};
    }
  }

  .startIconClass{
    color: ${theme.palette.primary.main};
    font-family: "Plus Jakarta Sans";
  }
`,
);

export default StyledSignupPage;
