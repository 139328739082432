/* eslint-disable arrow-body-style */
import React from 'react';

const BigCrossIcon = ({ width = '44', height = '44' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="44" rx="22" fill="#F4F4F4" />
      <path
        d="M14 30L30 14"
        stroke="#1C1C1C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 30L14 14"
        stroke="#1C1C1C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BigCrossIcon;
