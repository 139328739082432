import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles, Select } from '@material-ui/core';
import StyledTextField from './StyledTextField';
import '../../styles/_global.scss';
import CustomTooltip from '../Tooltip/Tooltip';

const useStyles = makeStyles({
  root: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& .MuiSelect-select': {
      height: '44px !important',
    },
    '& .price_amt .MuiInputBase-input': {
      height: '44px !important',
    },
    '& .MuiSvgIcon-root': {
      marginRight: '0px !important',
    },
  },
});

export default function TextInput(props) {
  const classes2 = useStyles();
  const {
    classes = '',
    label = '',
    id = '',
    icon = '',
    startIcon = '',
    maxLength,
    minLength,
    placeholderValue = '',
    important = false,
    wordCount = false,
    showTooltip = false,
    tooltip = '',
    currencies = [],
    selectedCurrency = '',
    onCurrencyChange = () => {},
    ...restData
  } = props;

  return (
    <StyledTextField className={classes} key={id}>
      <div className="input-Label_text label-space">
        {label}
        {important ? <span className="">*</span> : ''}
        {showTooltip ? <CustomTooltip title={tooltip} /> : ''}
      </div>
      <TextField
        fullWidth
        id={id}
        focused={false}
        autoFocus={false}
        className="custom-field"
        size="small"
        variant="standard"
        margin="normal"
        placeholder={placeholderValue}
        InputProps={{
          classes: {
            root: classes2.root,
          },
          endAdornment: icon ? (
            <InputAdornment position="end" className={wordCount ? 'wordCount' : ''}>
              {icon}
            </InputAdornment>
          ) : (
            ''
          ),
          startAdornment: (
            <>
              {currencies.length > 0 ? (
                <InputAdornment position="start">
                  <Select
                    value={selectedCurrency}
                    onChange={(e) => onCurrencyChange(e.target.value)}
                    variant="standard"
                    disableUnderline
                    style={{
                      fontSize: '14px',
                      marginRight: '5px',
                      width: '80px',
                      border: 'none',
                      background: 'lightgray',
                      marginLeft: '2px',
                    }}>
                    {currencies.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ) : null}
              {startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : null}
            </>
          ),
          autoComplete: 'off',
          disableUnderline: true,
          maxLength,
          minLength,
        }}
        {...restData}
        label=""
      />
    </StyledTextField>
  );
}
