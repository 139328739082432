import { styled } from '@mui/material/styles';

const StyledJobsOverview = styled('div')(
  ({ theme }) => `
  .page-wrap{
    margin:20px;
    @media screen and (max-width: 900px){
      margin:8px 0px 0px;
    }
    .mobile-profile-info{
      display:none;
      @media screen and (max-width: 900px){
        display:block;
      }
    }
    .web-profile-info{
      display:block;
      @media screen and (max-width: 900px){
        display:none;
      }
    }
    .overview-wrap{
      @media only screen and (min-width: 921px) and (max-width: 1099px)  {
        max-width:784px;
        .statics-card-wrap{
          padding-right:12px !important;
          img{
            display:none;
          }
        }
      }
    }
    .job-statics-box {
        .statics-card-wrap {
            border-radius:12px;
            background: ${theme.palette.secondary.main};
            padding:20px 18px;
            a{
              text-decoration:none;
            }
            .content {
                display:flex;
                align-items:center;
                justify-content:space-between;
                .active-stat{
                    font-family: Sora;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 30.24px;
                    text-align: left;
                    color: #1C1C1C;
                }
                .title{
                    font-family: Plus Jakarta Sans;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20.16px;
                    text-align: left;
                    color:#757575;
                    @media screen and (max-width: 900px){
                      font-family: Plus Jakarta Sans;
                      font-size: 12px;
                      font-weight: 700;
                      line-height: 15.12px;
                      text-align: left;
                    }
                }
            }
            @media screen and (max-width: 900px){
              padding:18px 0px 18px 12px;
              img{
                display:none;
              }
            }  
        }
    }

    .page-job-card-box {
        border-radius: 10px;
        background: ${theme.palette.secondary.main};
        display: flex;
        margin: 10px 0px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 480px){
          width: 100%;
          margin: auto;
          border-radius: 12px;
          margin-bottom: 20px;
        }
        .title{
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;  
          padding: 20px; 
          @media screen and (max-width: 480px){
            font-size: 17px;
            padding: 16px; 
          }
          .seeAll{
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";  
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            cursor: pointer;
            column-gap: 10px;
          }
        }
    
        // Job card box 

        .job-card-box {
          padding: 0px 20px;
          min-height:220px;
          display:flex;
          align-items:center;
          justify-content:center;
          background-position: right bottom;
          background-repeat: no-repeat;
          @media screen and (max-width: 480px){
            width: 100%;
            padding: 0px 16px;
            padding-bottom: 20px;
          }
            //  when there is no card
          .noSessionsHeading{
            color: ${theme.palette.customColor.secondaryGrey};
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 24px;
            margin-bottom: 36px;
            @media screen and (max-width: 480px){
              font-size: 18px;
            }
          }
        } 
        //   Job card 
        .job-card-list-wrap{ 
          .job-card-wrap{
            padding:12px;
            border-radius:10px;
            background: ${theme.palette.secondary.main};
            border:1px solid #E2E4E7;
            height:100%;
            .card-top{
                display: flex;
                justify-content: space-between;
                align-items: start;
                margin-bottom:16px;
                .right{
                    display: flex;
                    align-items: start;
                    max-width:calc(100% - 124px);
                    gap:8px;
                    .brand-image{
                        width: 40px;
                        height: 40px;
                        gap: 0px;
                        border-radius: 50%
                        @media screen and (max-width: 900px){
                          min-width:40px;
                          max-width:40px;
                        }
                    }
                    .job-title{
                        font-family: Sora;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20.16px;
                        text-align: left;
                        color: ${theme.palette.customColor.primaryBlack};
                    }
                    .job-postedby{
                        font-family: Plus Jakarta Sans;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17.64px;
                        text-align: left;
                        color: #525252;
                    }
                    .skill-match{
                        font-family: Plus Jakarta Sans;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 15.12px;
                        text-align: left;
                        color:#00AF31;
                    }
                }
                .left {
                    display:inline-flex;
                    align-items:center;
                    gap:10px;
                    img{
                      cursor:pointer;
                    }
                    .applied-chip{
                      border:1px solid #415DF3;
                      color:#415DF3;
                      background:#415DF31A;
                      padding:4px 8px;
                      font-family: Plus Jakarta Sans;
                      font-size: 10px;
                      font-weight: 700;
                      line-height: 12.6px;
                      letter-spacing: 0.08em;
                      text-align: center;
                      border-radius:40px;
                      text-transform:uppercase;
                    }
                }
            }
            .skill-chips-box{
                marging-top:16px;
                margin-bottom:12px;
                display:flex;
                align-items:center;
                gap:4px;
                overflow-x:scroll;
                max-width:790px;
                &::-webkit-scrollbar {
                  height: 4px;
                }
                .chips{
                    background:#F4F4F4;
                    padding:4px 8px;
                    font-family: Plus Jakarta Sans;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15.12px;
                    text-align: left;
                    border-radius:40px;
                    white-space: nowrap;
                    margin-bottom: 8px;
                }
            }
            .card-bottom {
              .job-info-content{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
              }
              .icon-text-box-wrap{
                display: flex;
                flex-direction:column;
                gap:8px;
                  .icon-text-box{
                    display: inline-flex;
                    align-items: center;
                    gap:4px;
                  }
              }
              .sub-text{
                font-family: Plus Jakarta Sans;
                font-size: 14px;
                font-weight: 500;
                line-height: 17.64px;
                text-align: left;
      
              }
              .view-details{
                font-family: Plus Jakarta Sans;
                font-size: 14px;
                font-weight: 600;
                line-height: 17.64px;
                text-align: left;
                text-decoration:underline;
                color:#415DF3;
                cursor:pointer;
                min-width:84px;
              }
              .date-text{
                font-family: Plus Jakarta Sans;
                font-size: 14px;
                font-weight: 500;
                line-height: 17.64px;
                text-align: left;
                color:#415DF3;
                margin-top:12px;
              }
            }
          }
        }
      }
    }

    .noData-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 50vh;
        .heading{
          color: ${theme.palette.customColor.primaryBlack};
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 25px;
          @media screen and (max-width: 481px){
            font-size: 18px;
            padding: 0px 20px;
          }
        }
      }  
    }

    .profile-info-wrap{
      padding:20px;
      border-radius:12px;
      background:#FFFFFF;
      .heading{
        font-family: Plus Jakarta Sans;
        font-size: 20px;
        font-weight: 600;
        line-height: 25.2px;
        text-align: left;
        color:#1C1C1C;
        margin-bottom:16px;
      }
      .profileImage{
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 40px;
        object-fit: cover;
        border: 1.5px solid ${theme.palette.customColor.grey_6};
      }
      .name{
        font-family: Sora;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.68px;
        text-align: center;
        color: #1C1C1C;
      }
      .tech{
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 17.64px;
        text-align: center;
        color:#415DF3;
        margin-bottom:16px;
      }
      .MuiButtonBase-root{
        min-height:34px;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
      .divider{
        height:1px;
        background:#E2E4E7;
        margin:24px auto 13px;
        width:100%;
      }
      .subheading{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.16px;
        text-align: left;
        color:#1C1C1C;
        margin-bottom:12px;
      }
      .details-heading{
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 17.64px;
        text-align: left;
        color:#757575;
      }
      .details-sub-heading{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.16px;
        text-align: left;
        color:#1C1C1C;
        margin-bottom:16px;
      }
      .skill-chips-box{
        padding-top:8px;
        margin-bottom:12px;
        display:flex;
        align-items:center;
        gap:4px;
        overflow-x:scroll;
        max-width:790px;
        &::-webkit-scrollbar {
          height: 4px;
        }
        .chips{
            background:#F4F4F4;
            padding:4px 8px;
            font-family: Plus Jakarta Sans;
            font-size: 12px;
            font-weight: 500;
            line-height: 15.12px;
            text-align: left;
            border-radius:40px;
            white-space: nowrap;
            margin-bottom: 8px;
        }
      }
    }
 
    .subscription-info-wrap{
      padding:20px;
      border-radius:12px;
      background:#FFFFFF;
      .heading{
        font-family: Plus Jakarta Sans;
        font-size: 20px;
        font-weight: 600;
        line-height: 25.2px;
        text-align: left;
        color:#1C1C1C;
        margin-bottom:16px;
      }
      .sub-heading{ 
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.64px;
        text-align: left;
        color:#525252;
        margin-bottom:8px;
      }
      .subscription-info-card{
        border-radius:12px;
        padding:12px;
        border:1px solid #E2E4E7;
        text-align: left;
        .plan-chip{
          background:#415DF333;
          padding:4px 8px;
          font-family: Plus Jakarta Sans;
          font-size: 12px;
          font-weight: 500;
          line-height: 15.12px;
          border-radius:40px;
          color:#415DF3;
        }
        .plan-duration{
          display:flex;
          align-items:center;
          gap:8px;
          justify-content:start;
          margin:12px auto;
          .currency:{
            font-family: Sora;
            font-size: 24px;
            font-weight: 700;
            line-height: 30.24px;
            text-align: left;
            color:#1C1C1C;
          }
          .time{
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-weight: 400;
            line-height: 17.64px;
            text-align: left;
            color:#525252;
          }
        }
        .MuiButtonBase-root{
          min-height:34px;
          font-family: Plus Jakarta Sans;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
        }
      }
      .subscription-details-card {
          margin-top:16px;
          background:#F4F4F4;
          padding:12px 16px;
          border-radius:12px;
          .heading{
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color:#757575;
            margin:0px;
          }
          .sub-heading{
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color:#1C1C1C;
            margin:0px;
          }
        }  
    }

  .job-details-modal {
    .MuiDialog-paper {    
      max-width:100vw;
      .jobdetails-modalContent-wrap{
        width: calc(99vw - 54px);
        overflow-x:hidden;
        color:#1C1C1C;
        .job-card-wrap{
          border-radius:10px;
          background: ${theme.palette.secondary.main};
          .card-top{
              display: flex;
              justify-content: space-between;
              align-items: start;
              flex-wrap:wrap;
              margin-bottom:16px;
              gap:16px;
              .right{
                  display: flex;
                  align-items: start;
                  flex-wrap:wrap;
                  max-width:calc(100% - 124px);
                  gap:8px;
                  .brand-image{
                      width: 50px;
                      height: 50px;
                      gap: 0px;
                      border-radius: 50%;
                  }
                  .title{
                    font-family: Sora;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 30.24px;
                    text-align: left;
                    color: ${theme.palette.customColor.primaryBlack};
                  }
                  .job-title{
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 25.2px;
                    text-align: left;
                    color: ${theme.palette.customColor.primaryBlack};
                  }
                  .job-postedby{
                      font-family: Plus Jakarta Sans;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 17.64px;
                      text-align: left;
                      color: #525252;
                  }
                  .skill-match{
                      font-family: Plus Jakarta Sans;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 17.66px;
                      text-align: left;
                      color:#00AF31;
                  }
              }
              .left {
                .icons-btns{
                  display:inline-flex;
                  align-items:center;
                  gap:10px;
                }
                .already-applied{
                    font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17.64px;
                    text-align: center;
                    color:#415DF3;
                    margin-top:4px;
                }
                  img{
                    cursor:pointer;
                    width:44px;
                    height:44px;
                  }
                  .applied-chip{
                    border:1px solid #415DF3;
                    color:#415DF3;
                    background:#415DF31A;
                    padding:4px 8px;
                    font-family: Plus Jakarta Sans;
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 12.6px;
                    letter-spacing: 0.08em;
                    text-align: center;
                    border-radius:40px;
                    text-transform:uppercase;
                  }
              }
          }
          .skill-chips-box{
              marging-top:16px;
              margin-bottom:12px;
              display:flex;
              align-items:center;
              gap:4px;
              overflow-x:scroll;
              max-width:790px;
              &::-webkit-scrollbar {
                height: 4px;
              }
              .chips{
                  background:#F4F4F4;
                  padding:4px 8px;
                  font-family: Plus Jakarta Sans;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 15.12px;
                  text-align: left;
                  border-radius:40px;
                  white-space: nowrap;
                  margin-bottom: 8px;
              }
          }
          .job-content-wrap{
            margin-top:32px;
            .skills-heading{
              font-family: Plus Jakarta Sans;
              font-size: 14px;
              font-weight: 600;
              line-height: 17.64px;
              text-align: left;
              color:#757575;
              margin-bottom: 8px;
            }
            .divider{
              height:1px;
              background:#E2E4E7;
              margin:24px auto 13px;
              max-width:100%;
            }
            .icon-text-box{
              display: inline-flex;
              align-items: center;
              gap:4px;
            }
            .sub-text{
              font-family: Plus Jakarta Sans;
              font-size: 16px;
              font-weight: 500;
              line-height: 20.16px;
              text-align: left;
              color:#1C1C1C;
              text-transform: capitalize;
            }
          }
        }
      }
      .more-details-wrap{
        margin-left:8px;
        box-shadow: 0px 0px 23px 0px #0000000F;
        background:#ffffff;
        padding:20px;
        border-radius:12px;
        .heading{
          font-family: Plus Jakarta Sans;
          font-size: 20px;
          font-weight: 600;
          line-height: 25.2px;
          text-align: left;
          color:#1C1C1C;
        }
        .content{
          .title{
            margin-top:20px;
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-weight: 600;
            line-height: 17.64px;
            text-align: left;
            color:background: #757575;
          }
          .desc{
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.16px;
            text-align: left;
            color:#1C1C1C;
          }

        }

      }
    }
    .job-links{
      font-family: Plus Jakarta Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 20.16px;
      text-align: left;
      color:#415DF3;
    }
    .doc-link{
      font-family: Plus Jakarta Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 20.16px;
      text-align: left;
      color: #415DF3;
      max-width:450px;
      text-overflow: ellipsis;
    }
  }
  .jobsharemodal{
    .share-icons-wrap{
      display:flex;
      align-items:center;
      justify-content:center;
      gap:8px;

    }
    .Mui-disabled {
      background:#ffffff !important;
    }
    .copy-box{
      margin-top:20px;
      width:390px;
      @media screen and (max-width: 900px){
        max-width:100%;
      } 
    }
    .copyBtn {
      color: #415DF3;
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: #F4F4F4;
      display: flex;
      align-items: center;
      cursor: pointer;
      background:#ffffff;
      padding-right:16px;
    }
  }
  .accordion-heading{
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color:${theme.palette.customColor.primaryBlack}

  }
}
`,
);

export default StyledJobsOverview;

export const StyledJobsDetailsModal = styled('div')(
  ({ theme }) => `
  .jobdetails-modalContent-wrap{
    padding:0px 44px 44px;
    color:#1C1C1C;
    @media screen and (max-width: 767px){
      padding:0px 16px 16px;
    }
    .job-card-wrap{
      border-radius:10px;
      background: ${theme.palette.secondary.main};
      .card-header{
        padding:44px 44px 0px 44px;
        margin:0px -44px;
        background: ${theme.palette.secondary.main};
        position:sticky;
        top:0px;
        z-index:2;
        @media screen and (max-width: 767px){
          display:none !important;
        }
      }
      .card-top{
          display: flex;
          justify-content: space-between;
          align-items: start;
          flex-wrap:wrap;
          padding-bottom:16px;
          gap:16px;
          @media screen and (max-width: 767px){
            padding-top: 20px;
          }
          .right{
              display: flex;
              align-items: start;
              flex-wrap:wrap;
              max-width:calc(100% - 124px);
              gap:8px;
              .brand-image{
                  width: 50px;
                  height: 50px;
                  gap: 0px;
                  border-radius: 50%;
              }
              .mobile-close-icon{
                display:none;
                @media screen and (max-width: 767px){
                  display: flex;
                  right: 16px;
                  position: absolute;
                }
              }
              .title{
                font-family: Sora;
                font-size: 24px;
                font-weight: 700;
                line-height: 30.24px;
                text-align: left;
                color: ${theme.palette.customColor.primaryBlack};
              }
              .job-title{
                font-size: 20px;
                font-weight: 600;
                line-height: 25.2px;
                text-align: left;
                color: ${theme.palette.customColor.primaryBlack};
              }
              .job-postedby{
                  font-family: Plus Jakarta Sans;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 17.64px;
                  text-align: left;
                  color: #525252;
              }
              .skill-match{
                  font-family: Plus Jakarta Sans;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 17.66px;
                  text-align: left;
                  color:#00AF31;
              }
          }
          .left {
            .icons-btns{
              display:inline-flex;
              align-items:center;
              gap:10px;
            }
            .mobile-buttons-box{
              display: block;
              @media screen and (max-width: 767px){
                display: flex;
              gap: 16px;
              }
            }
            .mobile-icons{
              display: none;
              @media screen and (max-width: 767px){
                display: inline-flex;
                gap: 16px;
              }
            }
            .already-applied{
                font-family: Plus Jakarta Sans;
                font-size: 14px;
                font-weight: 600;
                line-height: 17.64px;
                text-align: center;
                color:#415DF3;
                margin-top:4px;
                @media screen and (max-width: 767px){
                  text-align: left;
                }
            }
              img{
                cursor:pointer;
                width:44px;
                height:44px;
              }
              .applied-chip{
                border:1px solid #415DF3;
                color:#415DF3;
                background:#415DF31A;
                padding:4px 8px;
                font-family: Plus Jakarta Sans;
                font-size: 10px;
                font-weight: 700;
                line-height: 12.6px;
                letter-spacing: 0.08em;
                text-align: center;
                border-radius:40px;
                text-transform:uppercase;
              }
          }
      }
      .skill-chips-box{
          marging-top:16px;
          margin-bottom:12px;
          display:flex;
          align-items:center;
          gap:4px;
          overflow-x:scroll;
          max-width:790px;
          &::-webkit-scrollbar {
            height: 4px;
          }
          .chips{
              background:#F4F4F4;
              padding:4px 8px;
              font-family: Plus Jakarta Sans;
              font-size: 12px;
              font-weight: 500;
              line-height: 15.12px;
              text-align: left;
              border-radius:40px;
              white-space: nowrap;
              margin-bottom: 8px;
          }
      }
      .job-content-wrap{
        margin-top:32px;
        .skills-heading{
          font-family: Plus Jakarta Sans;
          font-size: 14px;
          font-weight: 600;
          line-height: 17.64px;
          text-align: left;
          color:#757575;
          margin-bottom: 8px;
        }
        .divider{
          height:1px;
          background:#E2E4E7;
          margin:24px auto 13px;
          max-width:100%;
        }
        .icon-text-box{
          display: inline-flex;
          align-items: center;
          gap:4px;
        }
        .sub-text{
          font-family: Plus Jakarta Sans;
          font-size: 16px;
          font-weight: 500;
          line-height: 20.16px;
          text-align: left;
          color:#1C1C1C;
          text-transform: capitalize;
        }
      }
    }
  }
  .more-details-wrap{
    margin-left:8px;
    box-shadow: 0px 0px 23px 0px #0000000F;
    background:#ffffff;
    padding:20px;
    border-radius:12px;
    .heading{
      font-family: Plus Jakarta Sans;
      font-size: 20px;
      font-weight: 600;
      line-height: 25.2px;
      text-align: left;
      color:#1C1C1C;
    }
    .content{
      .title{
        margin-top:20px;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 17.64px;
        text-align: left;
        color: #757575;
        
      }
      .desc{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.16px;
        text-align: left;
        text-transform: capitalize;
        color:#1C1C1C;
        word-break: break-all;
      }
    }
    .job-links{
      font-family: Plus Jakarta Sans;
      font-size: 16px;
      font-weight: 700;
      line-height: 20.16px;
      text-align: left;
      color:#415DF3;
      text-transform: lowercase;
    }
  }
  `,
);
