/* eslint-disable arrow-body-style */
import React from 'react';

const SmallCalenderIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33301 1.33203V3.33203"
        stroke="#415DF3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.667 1.33203V3.33203"
        stroke="#415DF3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33301 6.05859H13.6663"
        stroke="#415DF3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 5.66536V11.332C14 13.332 13 14.6654 10.6667 14.6654H5.33333C3 14.6654 2 13.332 2 11.332V5.66536C2 3.66536 3 2.33203 5.33333 2.33203H10.6667C13 2.33203 14 3.66536 14 5.66536Z"
        stroke="#415DF3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4635 9.13411H10.4694"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4635 11.1341H10.4694"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99666 9.13411H8.00265"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99666 11.1341H8.00265"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52987 9.13411H5.53585"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52987 11.1341H5.53585"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SmallCalenderIcon;
