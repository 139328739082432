/* eslint-disable arrow-body-style */
import React from 'react';

const SuggestedCandidateNavbarIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 15.718H14.3667C13.7 15.718 13.0667 15.9763 12.6 16.443L11.175 17.8513C10.525 18.493 9.46668 18.493 8.81668 17.8513L7.39166 16.443C6.925 15.9763 6.28333 15.718 5.625 15.718H5C3.61667 15.718 2.5 14.6096 2.5 13.243V4.14297C2.5 2.7763 3.61667 1.66797 5 1.66797H15C16.3833 1.66797 17.5 2.7763 17.5 4.14297V13.2346C17.5 14.6013 16.3833 15.718 15 15.718Z"
        stroke="#757575"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0579 7.45703C10.0246 7.45703 9.97455 7.45703 9.93288 7.45703C9.05788 7.4237 8.36621 6.71536 8.36621 5.83203C8.36621 4.93203 9.09122 4.20703 9.99122 4.20703C10.8912 4.20703 11.6162 4.94036 11.6162 5.83203C11.6246 6.71536 10.9329 7.43203 10.0579 7.45703Z"
        stroke="#757575"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.7082 9.96484C6.59987 10.7065 6.59987 11.9148 7.7082 12.6565C8.96654 13.4982 11.0332 13.4982 12.2915 12.6565C13.3999 11.9148 13.3999 10.7065 12.2915 9.96484C11.0332 9.13151 8.97487 9.13151 7.7082 9.96484Z"
        stroke="#757575"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuggestedCandidateNavbarIcon;
