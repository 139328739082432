import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, SecondaryButton } from '../Button';
import StyledRecruiterPostJobButtons from './StyledRecruiterPostJobButtons';
import { ROUTE_PATH } from '../../constants/route';

const { POSTJOB } = ROUTE_PATH;

export default function index({
  showBackButton = true,
  showNextButton = true,
  stepNumber = 0,
  setActiveStep,
  handleSubmit,
  disableSave = false,
  disableNext = false,
  pay = false,
  summary = false,
}) {
  const navigate = useNavigate();
  const handleNextSection = () => {
    if (stepNumber < 4) {
      setActiveStep(stepNumber + 1);
    } else {
      setActiveStep(0);
    }
  };

  const handleBackSection = () => {
    if (stepNumber > 0) {
      setActiveStep(stepNumber - 1);
    } else {
      setActiveStep(0);
    }
  };
  const handleButtonPress = async () => {
    if (!disableNext) {
      if (!disableSave) {
        await handleSubmit();
        await handleNextSection();
      } else {
        handleNextSection();
      }
    }
  };

  return (
    <StyledRecruiterPostJobButtons>
      <div className="buttons-wrap">
        {showBackButton ? (
          <div className="left-btn">
            <SecondaryButton
              onClick={() => (summary ? navigate(POSTJOB) : handleBackSection())}
              sx={{
                border: '1px solid #415DF3',
              }}>
              Back
            </SecondaryButton>
          </div>
        ) : (
          ''
        )}
        <div className="right-btn">
          {showNextButton && (
            <div className="footer-btn footer-save" style={{ width: pay ? '200px' : '' }}>
              <PrimaryButton onClick={handleButtonPress} disabled={disableNext}>
                {summary ? 'Post Job' : stepNumber === 4 ? 'Continue to Pay' : 'Next'}
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </StyledRecruiterPostJobButtons>
  );
}
