/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Stack } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LandingButtons from '../../../components/LandingButtons';
import StyledAvailability from './StyledAvailability';
import CustomTooltip from '../../../components/Tooltip/Tooltip';
import TextField from '../../../components/TextField';
import StyledRadioGroup from '../../../components/Common/AccountsDetailsModal/StyledRadioGroup';
import AutoComplete from '../../../components/AutoComplete';
import ToogleSwitch from '../../../components/ToogleSwitch/ToogleSwitch';
import { createJobProfile, fetchJobProfile } from '../../../store/reducers/fractionTalent/apiThunk';
import { selectorFractionTalent } from '../../../store/reducers/fractionTalent';
import { validationSchemaAvailability } from '../../../utils/validations/authValidation';

const chargeTypes = ['Per day', 'Per week', 'Per month'];

const workPreferenceOptions = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

function Availability({ stepNumber = 0, setActiveStep }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const { fractionTalent: { data: talentProfile = {} } = {} } = useSelector(selectorFractionTalent);

  const submitHandler = async (values) => {
    const { compensation, isNegotiable, compensationPeriod, availabilityType, availability } =
      values;
    const data = {
      formStep: 3,
      compensation,
      isNegotiable: isNegotiable ?? false,
      compensationPeriod,
      availabilityType,
      availability,
    };
    await dispatch(createJobProfile(data));
    dispatch(fetchJobProfile());
    setDisableSaveBtn(true);
  };

  const formik = useFormik({
    initialValues: {
      compensation: '',
      isNegotiable: false,
      compensationPeriod: '',
      availabilityType: 'daily',
      availability: '',
    },
    validationSchema: validationSchemaAvailability,
    onSubmit: submitHandler,
    enableReinitialize: true,
  });

  const {
    handleBlur,
    values: { compensation, isNegotiable, compensationPeriod, availabilityType, availability } = {},

    touched: {
      compensation: tucCompensation,
      compensationPeriod: tucCompensationPeriod,
      availability: tucAvailability,
    } = {},
    errors: {
      compensation: errCompensation,
      compensationPeriod: errCompensationPeriod,
      availability: errAvailability,
    } = {},
  } = formik;

  useEffect(() => {
    if (talentProfile) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        compensation: talentProfile.compensation,
        isNegotiable: talentProfile.isNegotiable ?? false,
        compensationPeriod: talentProfile.compensationPeriod,
        availabilityType: talentProfile.availabilityType || 'daily',
        availability: talentProfile.availability,
      }));
    }
  }, [talentProfile]);

  const handleRadioChange = (newValue) => {
    formik.setFieldValue('availabilityType', newValue);
  };

  const getTimeUnit = (preferenceValue) => {
    switch (preferenceValue) {
      case 'daily':
        return 'days';
      case 'weekly':
        return 'weeks';
      case 'monthly':
        return 'months';
      default:
        return null;
    }
  };
  const getTimeInUnit = (preferenceValue) => {
    switch (preferenceValue) {
      case 'monthly':
        return 'year';
      default:
        return 'months';
    }
  };

  const availableOptions = (preferenceValue) => {
    const maxLimit = preferenceValue === 'daily' ? 31 : preferenceValue === 'monthly' ? 12 : 4; // e.g., 30 days or 12 weeks/months
    return Array.from({ length: maxLimit }, (_, i) => ({
      label: `${i + 1}`,
      value: (i + 1).toString(),
    }));
  };

  const customHandleChange = (e, name) => {
    setDisableSaveBtn(false);
    formik.setFieldTouched(name, true);
    if (Number.isInteger(e.target.value)) {
      const changedValue = e.target.innerText;
      formik.setFieldValue(name, changedValue);
    } else {
      formik.setFieldValue(name, e.target.value);
    }
  };

  const handlePriceChange = (event) => {
    const { value } = event.target;
    formik.setFieldTouched('compensation', true);

    // Allow only digits (integer values) using regex
    if (/^\d*$/.test(value)) {
      formik.setFieldValue('compensation', value);
    } else {
      formik.setFieldError('compensation', 'Please enter a valid integer value.');
    }
  };

  return (
    <StyledAvailability>
      <div className="page-wrap">
        <div className="header-wrap">Information</div>
        <div className="content-wrap">
          <Grid container columnSpacing={2.5} rowSpacing={0}>
            <Grid item md={7} lg={4.5} xs={12} style={{ textAlign: 'left' }}>
              <div className="input-Label_text label-space">
                Available for*
                <CustomTooltip title="Mark your availability by selecting days, weeks, or months, then specify a number (e.g., 10 days or 2 weeks or 1 month) to set your schedule. You can later modify it with Recruiter as per need." placement={desktop ? 'right' : 'top'} />
              </div>
              <StyledRadioGroup
                options={workPreferenceOptions}
                value={availabilityType}
                onChange={(e) => {
                  handleRadioChange(e);
                  formik.setFieldValue('availability', '');
                }}
              />
            </Grid>
            <Grid item md={4.5} xs={12}>
              <AutoComplete
                name="availability"
                type="text"
                label={`Select number of ${getTimeUnit(availabilityType)} in a ${getTimeInUnit(availabilityType)} you are available`}
                classes="whoAmI"
                important
                placeholderValue={`Number of ${getTimeUnit(availabilityType)}`}
                showTooltip={false}
                optionsValue={availableOptions(availabilityType)}
                changeAutoComplete={(e) => {
                  customHandleChange(e, 'availability');
                }}
                handleBlur={handleBlur}
                value={availability}
                error={tucAvailability && !!errAvailability}
                helperText={tucAvailability && errAvailability}
                icon={<ExpandMoreOutlinedIcon />}
              />
            </Grid>
          </Grid>
        </div>
        <div className="form-heading">Compensation</div>
        <div className="content-wrap">
          <Grid container columnSpacing={2.5} rowSpacing={0} alignItems="start">
            <Grid item md={2.5} xs={12}>
              <AutoComplete
                name="compensationPeriod"
                type="text"
                label="Select type"
                classes="whoAmI"
                important
                placeholderValue="Charge per day"
                showTooltip={false}
                optionsValue={chargeTypes}
                changeAutoComplete={(e) => customHandleChange(e, 'compensationPeriod')}
                handleBlur={handleBlur}
                value={compensationPeriod}
                error={tucCompensationPeriod && !!errCompensationPeriod}
                helperText={tucCompensationPeriod && errCompensationPeriod}
                icon={<ExpandMoreOutlinedIcon />}
              />
            </Grid>
            <Grid item xs={12} md={5} alignItems="flex-start">
              <Box>
                <TextField
                  className="price_amt"
                  placeholder="0.00"
                  name="compensation"
                  label="Rate as per availability"
                  important
                  onChange={(e) => {
                    handlePriceChange(e);
                  }}
                  handleBlur={handleBlur}
                  value={compensation}
                  error={tucCompensation && !!errCompensation}
                  helperText={tucCompensation && errCompensation}
                  startIcon={<div className="rate-adornment">INR</div>}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignItems: 'center',
                  height: {
                    xs: '20px',
                    sm: '90px',
                  },
                }}>
                <ToogleSwitch
                  defaultChecked={false}
                  inputProps={{ 'aria-label': 'ant design' }}
                  checked={isNegotiable}
                  onChange={(e) => formik.setFieldValue('isNegotiable', e.target.checked)}
                />
                <div className="toogle-label">This pricing is negotiable</div>
                <CustomTooltip
                  title="Make your price negotiable to allow people negotiate the charges."
                  placement="bottom"
                />
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>

      <LandingButtons
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={() => submitHandler(formik.values)}
        disableNext={!formik.isValid || disableSaveBtn}
        disableSave={!formik.isValid || disableSaveBtn}
        showNextButton
        showBackButton
      />
    </StyledAvailability>
  );
}

export default Availability;
