/* eslint-disable arrow-body-style */
import React from 'react';

const DeleteIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 4.98307C14.725 4.70807 11.9333 4.56641 9.15 4.56641C7.5 4.56641 5.85 4.64974 4.2 4.81641L2.5 4.98307"
        stroke="#DA3333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08398 4.14297L7.26732 3.0513C7.40065 2.25964 7.50065 1.66797 8.90898 1.66797H11.0923C12.5007 1.66797 12.609 2.29297 12.734 3.05964L12.9173 4.14297"
        stroke="#DA3333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7077 7.61719L15.166 16.0089C15.0743 17.3172 14.9993 18.3339 12.6743 18.3339H7.32435C4.99935 18.3339 4.92435 17.3172 4.83268 16.0089L4.29102 7.61719"
        stroke="#DA3333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.60742 13.75H11.3824"
        stroke="#DA3333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91602 10.418H12.0827"
        stroke="#DA3333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeleteIcon;
