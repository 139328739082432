/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Checkbox,
  Grid,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  TablePagination,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import TextFeild from '../../../components/TextField';
import StyledSuggestedCandidates from './StyledSuggestedCandidates';
import { PrimaryButton } from '../../../components/Button';
import {
  fetchRecruiterSearchCandidates,
  fetchRecruiterSearchingFilters,
} from '../../../store/reducers/recruiterPostJob/apiThunk';
import PrivateLayout from '../../../layout/PrivateLayout';
import SmallGlobeIcon from '../../../components/icons/SmallGlobeIcon';
import SmallLocationIcon from '../../../components/icons/SmallLocationIcon';
import SmallCurrecyIcon from '../../../components/icons/SmallCurrecyIcon';
import SmallCalenderIcon from '../../../components/icons/SmallCalenderIcon';
import SmallEducation from '../../../components/icons/SmallEducation';
import CircleCloseIcon from '../../../components/icons/CircleCloseIcon';
import CircleAvatar from '../../../components/CircleAvatar';
import SuggestedCandidateProfile from './SuggestedCandidateProfile';
import { StyledPaginationBox } from '../../jobs/trackAppliedJob/StyledTracksJobs';
import FilterMobile from './FilterMobile';
import selectFilterIcon from '../../../assets/svg/filterIcon.svg';
import BigCrossIcon from '../../../components/icons/BigCrossIcon';
import increase from '../../../assets/svg/increase.svg';
import decresae from '../../../assets/svg/decrease.svg';
import SearchIcon from '../../../components/icons/SearchIcon';

// const Experience = [
//   { name: '0', value: 0 },
//   { name: '1-5 Years', value: 1 },
//   { name: 'Between 5 to 8 years', value: 2 },
//   { name: 'Between 8 to 12 years', value: 3 },
//   { name: 'More than 12 years', value: 4 },
// ];
const JobPreference = [
  { name: 'On-site', value: 'onSite' },
  { name: 'Remote', value: 'remote' },
  { name: 'Hybrid', value: 'hybrid' },
];
const Availibility = [
  { name: 'Daily', value: 'daily' },
  { name: 'Weekly', value: 'weekly' },
  { name: 'Monthly', value: 'monthly' },
];

const rowsPerPage = 10;

const SuggestedCandidate = () => {
  const dispatch = useDispatch();
  const [showMoreDomain, setShowMoreDomain] = useState(false);
  const [showMoreIndustry, setShowMoreIndustry] = useState(false);
  const [showMoreJobRole, setShowMoreJobRole] = useState(false);
  const [locationInput, setLocationInput] = useState('');
  const [experienceInput, setExperienceInput] = useState();
  const [locations, setLocations] = useState([]);
  const [checkedDomains, setCheckedDomains] = useState([]);
  const [checkedIndustries, setCheckedIndustries] = useState([]);
  const [checkedJobPreference, setCheckedJobPreference] = useState([]);
  const [checkedJobRole, setCheckedJobRole] = useState([]);
  const [checkedAvailability, setCheckedAvailability] = useState([]);
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [visibleCandidates, setVisibleCandidates] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const [selectedDetail, SetSelectedDetail] = useState(null);
  const [clearFiltersTriggered, setClearFiltersTriggered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryDomain, setSearchQueryDomain] = useState('');
  const [searchQueryJobRole, setSearchQueryJobRole] = useState('');
  const navigate = useNavigate();

  const searchingFilters = useSelector(
    (state) => state?.recruiterPostJob?.recruiterFilterData?.data?.data,
  );

  useEffect(() => {
    dispatch(fetchRecruiterSearchingFilters());
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    setVisibleCandidates(searchResults);
  }, [searchResults]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const filterData = async (externalFilters = null) => {
    setShowMoreDomain(false);
    setShowMoreJobRole(false);
    setShowMoreIndustry(false);
    const params = new URLSearchParams();
    const selectedexpereince = experienceInput !== 0 ? [experienceInput] : [];

    const filters = externalFilters || {
      domains: checkedDomains,
      industries: checkedIndustries,
      jobPreferences: checkedJobPreference,
      availability: checkedAvailability,
      experience: selectedexpereince,
      locations: checkedLocations,
      jobRole: checkedJobRole,
    };
    if (filters.domains.length > 0) {
      params.append('domains', filters.domains.join(','));
    }
    if (filters.industries.length > 0) {
      params.append('industries', filters.industries.join(','));
    }
    if (filters.jobPreferences.length > 0) {
      params.append('jobTypes', filters.jobPreferences.join(','));
    }
    if (filters.availability.length > 0) {
      params.append('availabilities', filters.availability.join(','));
    }
    if (filters.experience.length > 0) {
      params.append('experiences', filters.experience.join(','));
    }
    if (filters.jobRole.length > 0) {
      params.append('jobRoles', filters.jobRole.join(','));
    }
    if (filters.locations.length > 0) {
      params.append('location', filters.locations.join(','));
    }

    if (typeof searchInput === 'string' && searchInput.trim()) {
      params.append('search', searchInput.trim());
    }
    params.append('limit', 10);
    params.append('page', currentPage);
    const query = params.toString();
    const response = await dispatch(fetchRecruiterSearchCandidates(query));
    const {
      status,
      data: { data, count, page = '0' },
    } = response?.payload || {};

    const pageNumber = +page;

    setPaginationData({
      count,
      page: pageNumber,
    });
    if (status) {
      setSearchResults(data);
    }
  };
  // prettier-ignore
  const filteredList = searchingFilters?.industryList.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  // prettier-ignore
  const filteredListDomain = searchingFilters?.domainList.filter((item) =>
    item.name.toLowerCase().includes(searchQueryDomain.toLowerCase()));
  // prettier-ignore
  const filteredListJobRole = searchingFilters?.jobRoleList.filter((item) =>
    item.name.toLowerCase().includes(searchQueryJobRole.toLowerCase()));

  const handleCloseMobileFilter = () => {
    setOpenMobileFilter(false);
  };

  const handleToggle = (category, value) => {
    const updateChecked = (checkedState, setCheckedState) => {
      const currentIndex = checkedState.indexOf(value);
      const newChecked = [...checkedState];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setCheckedState(newChecked);
    };

    switch (category) {
      case 'domain':
        updateChecked(checkedDomains, setCheckedDomains);
        break;
      case 'industry':
        updateChecked(checkedIndustries, setCheckedIndustries);
        break;
      case 'jobPreference':
        updateChecked(checkedJobPreference, setCheckedJobPreference);
        break;
      case 'availability':
        updateChecked(checkedAvailability, setCheckedAvailability);
        break;
      case 'location':
        updateChecked(checkedLocations, setCheckedLocations);
        break;
      case 'jobRole':
        updateChecked(checkedJobRole, setCheckedJobRole);
        break;
      default:
        break;
    }
  };

  const handleAddLocation = () => {
    if (locationInput.trim()) {
      const newLocation = locationInput.trim();
      setLocations((prevLocations) => [...prevLocations, newLocation]);
      setCheckedLocations((prevChecked) => [...prevChecked, newLocation]);
      setLocationInput('');
    }
  };

  const removeCard = (candidateId) => {
    setVisibleCandidates((prevCandidates) => {
      return prevCandidates.filter((candidate) => candidate._id !== candidateId);
    });
  };

  const handleClearFilters = () => {
    setCheckedDomains([]);
    setCheckedIndustries([]);
    setCheckedJobPreference([]);
    setCheckedAvailability([]);
    setCheckedLocations([]);
    setLocations([]);
    setCheckedJobRole([]);
    setLocationInput('');
    setExperienceInput();
    setShowMoreDomain(false);
    setShowMoreJobRole(false);
    setShowMoreIndustry(false);
    setClearFiltersTriggered(true);
  };

  useEffect(() => {
    if (clearFiltersTriggered) {
      filterData();
      setClearFiltersTriggered(false);
    }
  }, [clearFiltersTriggered]);

  const durationCheckSuggestion = (item, suggNo) => {
    const isPlural = suggNo > 1;
    switch (item) {
      case 'daily':
        return isPlural ? 'Days' : 'Day';
      case 'weekly':
        return isPlural ? 'Weeks' : 'Week';
      default:
        return isPlural ? 'Months' : 'Month';
    }
  };

  const checkCompensationType = (item) => {
    switch (item) {
      case 'Per day':
        return 'day';
      case 'Per month':
        return 'month';
      default:
        return 'week';
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInput) {
        filterData();
      } else {
        setClearFiltersTriggered(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchInput]);

  const handleSelectedDetails = (item) => {
    SetSelectedDetail(item);
  };

  useEffect(() => {
    filterData();
  }, [currentPage]);

  const handleIncrease = () => {
    if (!experienceInput) {
      setExperienceInput(1);
    } else {
      setExperienceInput((prev) => Number(prev) + 1);
    }
  };

  const handleDecrease = () => {
    setExperienceInput((prev) => Math.max(Number(prev) - 1, 0));
  };

  const RenderExp = () => (
    <div style={{ display: 'flex', gap: '10px', marginRight: '10px' }}>
      <img alt="" src={decresae} style={{ cursor: 'pointer' }} onClick={() => handleDecrease()} />
      <img alt="" src={increase} style={{ cursor: 'pointer' }} onClick={() => handleIncrease()} />
    </div>
  );

  return (
    <>
      <StyledSuggestedCandidates>
        <div className="page-wrap">
          <div className="filter-icon" onClick={() => setOpenMobileFilter(true)}>
            <img src={selectFilterIcon} alt="filterIcon" />
          </div>
          <div className="filter-search">
            <TextField
              fullWidth
              size="small"
              placeholder="Search by Name, Email, Job Role, Industry, Domain"
              sx={{
                marginBottom: '10px !importatnt',
                minHeight: '0px !important',
                background: 'white !important',
                // borderRadius: '22px',
                borderRadius: '12px',
                height: '50px',
                '.MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  height: '50px',
                },
              }}
              InputProps={{
                sx: {
                  backgroundColor: 'white !important',
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <Grid container columnSpacing={3}>
            <Grid item md={3} xs={12}>
              <div className="filters-wrap">
                <div className="section-title"> Filter</div>
                <div className="section-content">
                  <div className="section-heading">Domain</div>
                  {searchingFilters?.domainList?.slice(0, 4).map((item) => (
                    <ListItemButton
                      key={item._id}
                      role="listitem"
                      onClick={() => handleToggle('domain', item.name)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checkedDomains.includes(item.name)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={` ${item?.name}`} />
                    </ListItemButton>
                  ))}
                  {searchingFilters?.domainList?.length > 4 && (
                    <div
                      onClick={() => {
                        setShowMoreIndustry(false);
                        setShowMoreJobRole(false);
                        setShowMoreDomain(true);
                      }}
                      style={{ cursor: 'pointer', color: 'blue' }}>
                      {`+${searchingFilters?.domainList?.length - 4} more`}
                    </div>
                  )}
                  {showMoreDomain ? (
                    <div className="show-more-box">
                      <div className="show-more-content">
                        <div className="show-more-header">
                          <TextFeild
                            placeholderValue="Search"
                            className="show-more-search"
                            value={searchQueryDomain}
                            onChange={(e) => setSearchQueryDomain(e.target.value)}
                          />
                          <div
                            onClick={() => setShowMoreDomain(false)}
                            style={{ cursor: 'pointer' }}>
                            <BigCrossIcon width="35" height="35" />
                          </div>
                        </div>
                        <div className="show-more-checkbox">
                          {filteredListDomain.length === 0 && searchQueryDomain.length > 0 ? (
                            <div>No results found for "{searchQueryDomain}"</div>
                          ) : (
                            filteredListDomain.map((item) => (
                              <ListItemButton
                                key={item._id}
                                role="listitem"
                                onClick={() => handleToggle('domain', item.name)}>
                                <ListItemIcon>
                                  <Checkbox
                                    checked={checkedDomains.includes(item.name)}
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                              </ListItemButton>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="section-heading">Experience</div>
                  <TextFeild
                    name="experienceYears"
                    type="number"
                    placeholderValue="0"
                    value={experienceInput || ''}
                    onChange={({ target: { value } }) => {
                      setExperienceInput(value === '' ? null : Number(value));
                    }}
                    icon={<RenderExp />}
                    sx={{
                      marginBottom: '10px !importatnt',
                      minHeight: '0px !important',
                      '& .MuiInput-input:focus': {
                        outline: 'none',
                      },
                    }}
                  />
                  <div className="section-heading">Industry</div>
                  {searchingFilters?.industryList?.slice(0, 4).map((item) => (
                    <ListItemButton
                      key={item._id}
                      role="listitem"
                      onClick={() => handleToggle('industry', item.name)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checkedIndustries.includes(item.name)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={` ${item?.name}`} />
                    </ListItemButton>
                  ))}
                  {searchingFilters?.industryList?.length > 4 && (
                    <div
                      onClick={() => {
                        setShowMoreDomain(false);
                        setShowMoreJobRole(false);
                        setShowMoreIndustry(true);
                      }}
                      style={{ cursor: 'pointer', color: 'blue' }}>
                      {`+${searchingFilters?.industryList?.length - 4} more`}
                    </div>
                  )}
                  {showMoreIndustry ? (
                    <div className="show-more-box">
                      <div className="show-more-content">
                        <div className="show-more-header">
                          <TextFeild
                            placeholderValue="Search"
                            className="show-more-search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <div
                            onClick={() => setShowMoreIndustry(false)}
                            style={{ cursor: 'pointer' }}>
                            <BigCrossIcon width="35" height="35" />
                          </div>
                        </div>
                        <div className="show-more-checkbox">
                          {filteredList.length === 0 && searchQuery.length > 0 ? (
                            <div>No results found for "{searchQuery}"</div>
                          ) : (
                            filteredList.map((item) => (
                              <ListItemButton
                                key={item._id}
                                role="listitem"
                                onClick={() => handleToggle('industry', item.name)}>
                                <ListItemIcon>
                                  <Checkbox
                                    checked={checkedIndustries.includes(item.name)}
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                              </ListItemButton>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="section-heading">Job Role</div>
                  {searchingFilters?.jobRoleList?.slice(0, 4).map((item) => (
                    <ListItemButton
                      key={item._id}
                      role="listitem"
                      onClick={() => handleToggle('jobRole', item.name)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checkedJobRole.includes(item.name)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={` ${item?.name}`} />
                    </ListItemButton>
                  ))}
                  {searchingFilters?.jobRoleList?.length > 4 && (
                    <div
                      onClick={() => {
                        setShowMoreDomain(false);
                        setShowMoreIndustry(false);
                        setShowMoreJobRole(true);
                      }}
                      style={{ cursor: 'pointer', color: 'blue' }}>
                      {`+${searchingFilters?.jobRoleList?.length - 4} more`}
                    </div>
                  )}
                  {showMoreJobRole ? (
                    <div className="show-more-box">
                      <div className="show-more-content">
                        <div className="show-more-header">
                          <TextFeild
                            placeholderValue="Search"
                            className="show-more-search"
                            value={searchQueryJobRole}
                            onChange={(e) => setSearchQueryJobRole(e.target.value)}
                          />
                          <div
                            onClick={() => setShowMoreJobRole(false)}
                            style={{ cursor: 'pointer' }}>
                            <BigCrossIcon width="35" height="35" />
                          </div>
                        </div>
                        <div className="show-more-checkbox">
                          {filteredListJobRole.length === 0 && searchQueryJobRole.length > 0 ? (
                            <div>No results found for "{searchQueryJobRole}"</div>
                          ) : (
                            filteredListJobRole.map((item) => (
                              <ListItemButton
                                key={item._id}
                                role="listitem"
                                onClick={() => handleToggle('jobRole', item.name)}>
                                <ListItemIcon>
                                  <Checkbox
                                    checked={checkedJobRole.includes(item.name)}
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                              </ListItemButton>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="section-heading">Job Preference</div>
                  {JobPreference?.map((item, index) => (
                    <ListItemButton
                      key={index}
                      role="listitem"
                      onClick={() => handleToggle('jobPreference', item.value)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checkedJobPreference.includes(item.value)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={` ${item?.name}`} />
                    </ListItemButton>
                  ))}
                  <div className="section-heading">Availability</div>
                  {Availibility?.map((item, index) => (
                    <ListItemButton
                      key={index}
                      role="listitem"
                      onClick={() => handleToggle(item.value)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checkedAvailability.includes(item.value)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={` ${item?.name}`} />
                    </ListItemButton>
                  ))}
                  <div className="section-heading">Location</div>
                  <TextFeild
                    value={locationInput}
                    onChange={(e) => setLocationInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleAddLocation()}
                    fullWidth
                    size="small"
                    placeholderValue="Search"
                    sx={{ marginBottom: '10px !importatnt', minHeight: '0px !important' }}
                  />
                  {locations.map((location, index) => (
                    <ListItemButton
                      key={index}
                      role="listitem"
                      onClick={() => handleToggle('location', location)}>
                      <ListItemIcon>
                        <Checkbox
                          checked={checkedLocations.includes(location)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={location} />
                    </ListItemButton>
                  ))}
                </div>
                <div className="filter-buttons">
                  <span className="clear-btn" onClick={() => handleClearFilters()}>
                    Clear
                  </span>
                  <PrimaryButton sx={{ width: '90px' }} onClick={() => filterData()}>
                    Apply Filter
                  </PrimaryButton>
                </div>
              </div>
            </Grid>
            <Grid item md={9} sm={12}>
              <div className="suggested-jobs-wrap">
                <div className="section-title">Suggested Candidates</div>
                <div className="job-card-list-wrap">
                  {visibleCandidates?.length > 0 ? (
                    visibleCandidates?.map((suggestionCandidate) => (
                      <div className="suggested-candidates-card">
                        <div className="card-head">
                          <div className="user">
                            <div className="dp">
                              <CircleAvatar
                                src={suggestionCandidate?.expertDetails?.profileImagePath}
                                name={suggestionCandidate?.expertDetails?.firstName}
                              />
                            </div>
                            <div className="dp-domain">
                              <div className="user-work">
                                {suggestionCandidate?.expertDetails?.firstName}{' '}
                                {suggestionCandidate?.expertDetails?.lastName}
                              </div>
                              <div>{suggestionCandidate?.jobRole}</div>
                            </div>
                          </div>
                          <div
                            className="cross-icon"
                            onClick={() => removeCard(suggestionCandidate._id)}>
                            <CircleCloseIcon />
                          </div>
                        </div>
                        <div className="job-skills-2">
                          {suggestionCandidate?.skills?.map((item) => (
                            <div className="user-application-2">{item}</div>
                          ))}
                        </div>
                        <div className="user-info">
                          <div className="user-informations">
                            <div className="education">
                              <SmallEducation />
                              <div className="information-text">{suggestionCandidate?.domain}</div>
                            </div>
                            <div className="design">
                              <SmallGlobeIcon />
                              <div className="information-text">{suggestionCandidate?.jobRole}</div>
                            </div>
                            <div className="location">
                              <SmallLocationIcon />
                              <div className="information-text">
                                {`${suggestionCandidate?.city}, ${suggestionCandidate?.state}`}
                              </div>
                            </div>
                            <div className="user-charge">
                              <SmallCurrecyIcon />
                              <div className="information-text">
                                {`₹ ${suggestionCandidate?.compensation}/
                          ${checkCompensationType(suggestionCandidate?.compensationType)}`}
                              </div>
                            </div>
                            <div className="user-availability">
                              <SmallCalenderIcon />
                              <div className="information-text">
                                {suggestionCandidate?.availability}{' '}
                                {durationCheckSuggestion(
                                  suggestionCandidate?.availabilityType,
                                  suggestionCandidate?.availability,
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="suggestion-view-details-btn"
                            onClick={() => {
                              setOpenDialog(true);
                              handleSelectedDetails(suggestionCandidate);
                              navigate(
                                `/applicant-detail?id=${suggestionCandidate?.expertDetails?._id}`,
                                { state: { scandidate: suggestionCandidate } },
                              );
                            }}>
                            View Details
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-record">No Records Found</div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <SuggestedCandidateProfile
          openDialog={openDialog}
          closeDialog={handleCloseDialog}
          jobDetails={selectedDetail}
        />
      </StyledSuggestedCandidates>
      <FilterMobile
        openFilterDialog={openMobileFilter}
        closeFilterDialog={handleCloseMobileFilter}
        filters={searchingFilters}
        onApplyFilters={(data) => filterData(data)}
      />
      {visibleCandidates?.length !== 0 ? (
        <StyledPaginationBox>
          <div className="jobs-pagination-box">
            <div className="left-wrap">
              <TablePagination
                component="div"
                count={paginationData?.count}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
              />
            </div>
            <div className="right-wrap">
              <Pagination
                variant="outlined"
                shape="rounded"
                size="medium"
                page={currentPage}
                count={Math.ceil(paginationData?.count / rowsPerPage)}
                onChange={(event, newPage) => {
                  handleChangePage(event, newPage);
                }}
              />
            </div>
          </div>
        </StyledPaginationBox>
      ) : (
        ''
      )}
    </>
  );
};

export default PrivateLayout(SuggestedCandidate);
