/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Avatar, Dialog } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import share from '../../../assets/svg/share-circle.svg';
import save from '../../../assets/svg/save-circle.svg';
import close from '../../../assets/svg/gray-close-circle.svg';
import { PrimaryButton } from '../../../components/Button';
import coin from '../../../assets/svg/coin-jobs.svg';
import saved from '../../../assets/svg/savedBlue.svg';
import locationPoint from '../../../assets/svg/location-jobs.svg';
import { StyledJobsDetailsModal } from './StyledJobsOverview';
import { checkCompensationType, durationCheck, stringAvatar } from '../../../helper';
import { fetchJobDetails } from '../../../store/reducers/fractionTalent/apiThunk';
import { selectorAuthentication } from '../../../store/reducers/authentication';

const JobdetailsModal = ({
  openDialog,
  closeDialog,
  handleShare,
  handleSaveJob,
  handleApplyJob,
}) => {
  const JobdetailsModalContent = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [jobId, setJobId] = useState(null);
    const [jobDetails, setJobDetails] = useState({});
    const { userData: { data: { _id: expertId } = {} } = {} } = useSelector(selectorAuthentication);

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      // Get the query parameter values
      if (location.search) {
        setJobId(params.get('job-id'));
      }
    }, [location]);

    const getJobDetails = async () => {
      try {
        const query = `${jobId}?expertId=${expertId}`;
        const response = await dispatch(fetchJobDetails(query));
        const {
          payload: { data = {}, status = false },
        } = response;
        if (status) {
          setJobDetails(data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    useEffect(() => {
      getJobDetails();
    }, [jobId]);

    const fetchSavejob = async () => {
      await handleSaveJob();
      await getJobDetails();
    };
    const fetchApplyJob = async () => {
      await handleApplyJob();
      await getJobDetails();
    };

    return (
      <StyledJobsDetailsModal>
        <div className="jobdetails-modalContent-wrap">
          <div className="job-card-wrap">
            <div className="card-header card-top">
              <div className="right">
                <div className="title">Job Details</div>
              </div>
              <div className="left">
                <div className="icons-btns">
                  <img className="brand-image" src={share} alt="" onClick={handleShare} />
                  <img
                    className="brand-image"
                    src={jobDetails?.isJobSaved ? saved : save}
                    alt=""
                    onClick={fetchSavejob}
                  />
                  <img className="brand-image" src={close} alt="" onClick={closeDialog} />
                </div>
              </div>
            </div>
            <div className="card-top">
              <div className="right">
                {jobDetails?.companyLogo ?
                  <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${jobDetails?.companyLogo}`} alt="" />
                  : <Avatar {...stringAvatar(jobDetails?.companyName)} />}
                <div className="mobile-close-icon">
                  <img
                    className="brand-image"
                    src={close}
                    alt=""
                    onClick={closeDialog} />
                </div>
                <div>
                  <div className="job-title">{jobDetails?.jobRole}</div>
                  <div className="job-postedby">{jobDetails?.companyName}</div>
                </div>
              </div>
              <div className="left">
                <div className="mobile-buttons-box">
                  <PrimaryButton
                    disabled={jobDetails.isApplied}
                    onClick={fetchApplyJob}
                    sx={{
                      width: {
                        xs: '230px',
                        sm: '450px',
                      } }}>
                    {jobDetails.isApplied ? 'Applied' : 'Apply Job' }
                  </PrimaryButton>
                  <div className="mobile-icons">
                    <img className="brand-image" src={share} alt="" onClick={handleShare} />
                    <img className="brand-image" src={jobDetails?.isJobSaved ? saved : save} alt="" onClick={fetchSavejob} />
                  </div>
                </div>
                {jobDetails.isApplied ? (
                  <div className="already-applied">You have already applied to this job</div>
                ) : (
                  ''
                )}

                {jobDetails.portalLink ? (
                  <Grid mt={2}>
                    <PrimaryButton
                      // prettier-ignore
                      onClick={() => window.open(jobDetails.portalLink, '_blank', 'rel=noopener noreferrer')}
                      sx={{
                        width: {
                          xs: '230px',
                          sm: '450px',
                        } }}>
                      Apply on website
                    </PrimaryButton>
                  </Grid>
                ) : null}
              </div>
            </div>
            <div className="job-content-wrap">
              <Grid container>
                <Grid item md={7} xs={12}>
                  <div>
                    <div className="skills-heading">Skills required</div>
                    <div className="skill-chips-box">
                      {jobDetails?.skills?.map((skill) => (
                        <span key={skill} className="chips">
                          {skill}
                        </span>
                      ))}
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Company/Job location</div>
                    <div className="icon-text-box">
                      <img alt="" src={locationPoint} />
                      <div className="sub-text">
                        {jobDetails?.jobCity}, {jobDetails?.jobState}{' '}
                        <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>{' '}
                        <span>{jobDetails.locationType}</span>
                      </div>
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Compensation</div>
                    <div className="icon-text-box">
                      <img alt="" src={coin} />
                      <div className="sub-text">
                        ₹ {jobDetails?.compensationRate}/
                        {`${checkCompensationType(jobDetails?.compensationType)}`}
                      </div>
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Job time</div>
                    <div className="skill-chips-box">
                      {jobDetails?.availabilityPeriod}{' '}
                      {durationCheck(jobDetails?.availableFor, jobDetails?.availabilityPeriod)}
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Domain</div>
                    <div className="sub-text">{jobDetails?.domain}</div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Company website</div>
                    <div className="desc">
                      <a
                        className="job-links"
                        href={jobDetails?.companyWebsite}
                        target="_blank"
                        rel="noreferrer">
                        {jobDetails?.companyWebsite}
                      </a>
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Check the job posting on company’s portal</div>
                    {/* <div className="doc-link"> </div> */}
                    <div className="desc">
                      <a
                        className="job-links"
                        href={jobDetails?.portalLink}
                        target="_blank"
                        rel="noreferrer">
                        {jobDetails?.portalLink}
                      </a>
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Job Description</div>
                    {jobDetails?.jobDescription}
                  </div>
                </Grid>
                <Grid item md={1} xs={0} />
                <Grid item md={4} xs={12}>
                  <div className="more-details-wrap">
                    <div className="heading">More Details</div>
                    <div className="content">
                      <div>
                        <div className="title">Designation</div>
                        <div className="desc">{jobDetails?.designation}</div>
                      </div>
                      <div>
                        <div className="title">Contact</div>
                        <div className="desc">{jobDetails?.phoneNumber}</div>
                      </div>
                      <div>
                        <div className="title">Company Address</div>
                        <div className="desc">{jobDetails?.companyAddress}</div>
                      </div>
                      <div>
                        <div className="title">Company Type</div>
                        <div className="desc">{jobDetails?.companyType}</div>
                      </div>
                      <div>
                        <div className="title">LinkedIn profile</div>
                        <div className="desc">
                          <a
                            className="job-links"
                            href={jobDetails?.linkedinProfile}
                            target="_blank"
                            rel="noreferrer">
                            {jobDetails?.linkedinProfile}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </StyledJobsDetailsModal>
    );
  };

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={closeDialog}
      sx={{
        marginTop: '64px',
        '& .MuiDialog-paper': {
          borderTopLeftRadius: {
            xs: '0px',
            sm: '64px',
          },
          borderTopRightRadius: {
            xs: '0px',
            sm: '64px',
          },
        },
      }}>
      {JobdetailsModalContent()}
    </Dialog>
  );
};

export default JobdetailsModal;
