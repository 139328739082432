import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PrimaryButton = styled(Button)(
  (props) => `
  background: ${props.theme.palette.primary.main};
  border-radius: 10px;
  min-height: 45px;
  text-transform: none;
  width: 100%;
  font-weight: 600;
  font-family: "Plus Jakarta Sans";
  font-Size: 16px;
  line-height: normal;
  color: ${props.theme.palette.secondary.main};
  white-space: nowrap;
  box-shadow: 0px 7px 44px 0px ${props.theme.palette.customColor.blue3};
  &:hover {
    background-color: ${props.theme.palette.primary.main};
    border: 1px solid ${props.theme.palette.customColor.primaryWhite};
    color: ${props.theme.palette.secondary.main};
  }
  &.Mui-disabled{
    background-color: ${props.theme.palette.customColor.tertiaryGreyText};
    color: ${props.theme.palette.customColor.primaryWhite};
    cursor: not-allowed !important;
    border: none !important;
    pointer-events:visible;
  }
`,
);

export const SecondaryButton = styled(Button)(
  (props) => `
  border-radius: 10px;
  text-transform: none;
  min-height: 45px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  box-shadow: none;
  font-family: "Plus Jakarta Sans";
  color: ${props.theme.palette.customColor.primaryBlack};
  column-gap: 8px;
  ${props.border ? `border: 1px solid${props.theme.palette.primary.main}` : '1px solid #415DF3'};
  border-radius: ${props.border ? '10px' : ''};
  &:hover{
    background: none;
    color: ${props.theme.palette.customColor.primaryBlack};
  }
  &.Mui-disabled{
    color: ${props.theme.palette.customColor.grey_7};
    background: ${props.theme.palette.customColor.grey_5}
    border: 1px solid ${props.theme.palette.customColor.grey_7};
  }
`,
);

export const TrinaryButton = styled(Button)(
  (props) => `
  background: ${props.theme.palette.customColor.secondaryBlack};
  color: ${props.theme.palette.customColor.primaryWhite};
  border-radius: 4px;
  text-transform: none;
  height: 45px;
  width: 100%;
  font-weight: 700;
  fontSize: 16px;
  lineHeight: 24px;
  boxShadow: none;
  justify-content: space-evenly;
  border: 1px solid rgba(255, 255, 255, 0.1);
  &:hover{
    background-color: rgb(218, 51, 51);
    color: white;
  }
  &.Mui-disabled{
    color: rgb(255 255 255 / 40%);
  }
`,
);

export const BorderButton = styled(Button)(
  ({ minHeight = '45px', ...props }) => `
  background: white;
  border: 1px solid ${props.theme.palette.primary.main};
  border-radius: 10px;
  text-transform: none;
  min-height: ${minHeight}
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  color: ${props.theme.palette.customColor.primaryBlack};
  box-shadow: none;

  &:hover {
    background-color: ${props.theme.palette.primary.main};
    color: white;
  }

  &.Mui-disabled {
    color: ${props.theme.palette.customColor.grey_7};
    background: ${props.theme.palette.customColor.grey_5};
    border: 1px solid ${props.theme.palette.customColor.grey_7};
  }
`,
);
