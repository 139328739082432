const ROUTE_PATH = {
  PAGE404: '*',
  LANDING: '/',
  PREVIEW: '/:username/',
  PREVIEW_SERVICES: '/services',
  PREVIEW_ABOUT: '/about-expert',
  PREVIEW_LINKS: '/links',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  RECRUITER_DASHBOARD: '/recruiter-dashboard',
  SESSIONSLIST: '/dashboard/sessionsList',
  SIGNUP: '/signup',
  LINKEDIN: '/linkedin',
  TERMS_AND_CONDITIONS: '/terms-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  LANDING_PAGE: '/landing',
  CALENDAR: '/calendar',
  PREVIEW_PAGE: '/preview',
  ABOUT_US: '/about',
  OUR_BLOGS: '/blogs',
  BLOG_DETAILS: '/blogs/:id',
  SCHEDULE_SEEKER: '/schedule',
  SEEKER_SUBSCRIPTION: '/seeker-subscription',
  CONFIRMATION_FORM: '/confirmation-page',
  DATA_DISCLOSURE: '/disclosure',
  FEEDBACKS: '/feedbacks',
  ADD_FEEDBACKS: '/session-feedback',
  EXPERT_FEEDBACK: '/expert-feedback',
  FRACTION_TALENT: '/fraction-talent',
  PREVIEW_TALENT: '/preview-talent',
  JOB_OVERVIEW: '/fraction-talent/job-overview',
  APPLY_JOB: '/fraction-talent/apply-job',
  APPLIED_JOB: '/fraction-talent/track-job',
  SUBSCRIPTION: '/fraction-talent/subscription',
  SAVED_JOB: '/fraction-talent/saved-job',
  MY_JOB_PROFILE: '/fraction-talent/my-job-profile',
  POSTAJOB: '/postjob',
  JOB_SUMMARY: '/summary',
  RECRUITER_SUGGESTED_CANDIDATES: '/suggested-candidates',
  RECRUITER_SUBSCRIPTION: '/recruiter-subscription',
  RECRUITER_TRACK_APPLICATION: '/track-job-applications',
  JOB_DETAILS: '/job-details',
  JOB_DETAILS_REDIRCTED: '/job-detail',
  RECRUITER_APPLICANT_DETAILS: '/applicant/details',
  RECRUITER_APPLICANT_DETAILS_LINK: '/applicant-detail',
  EXPERT_DETAILS: '/expert-details',
};

const API_VERSION = {
  V1: '/api/app/v1',
};

const API_PATH = {
  CREATE_PROFILE: `${API_VERSION.V1}/create-profile`,
  EDIT_PROFILE: `${API_VERSION.V1}/edit-profile`,
  RECRUITER_EDIT_PROFILE: `${API_VERSION.V1}/recruiter/edit-profile`,
  LOGIN: `${API_VERSION.V1}/login`,
  RECRUITER_LOGIN: `${API_VERSION.V1}/recruiter/login`,
  SIGNUP: `${API_VERSION.V1}/signup`,
  RECRUITER_SIGNUP: `${API_VERSION.V1}/recruiter/signup`,
  GET_PROFILE: `${API_VERSION.V1}/profile`,
  GET_RECRUITER_PROFILE: `${API_VERSION.V1}/recruiter/profile`,
  LOGOUT: `${API_VERSION.V1}/logout`,
  RECRUITER_LOGOUT: `${API_VERSION.V1}/recruiter/logout`,
  GET_S3_URL: `${API_VERSION.V1}/putObjectUrl`,
  CHECK_USERNAME: `${API_VERSION.V1}/username-check`,
  SEND_OTP: `${API_VERSION.V1}/send-otp-email`,
  VERIFY_OTP: `${API_VERSION.V1}/verify-otp`,
  GET_LANDING_SUGGESTIONS: `${API_VERSION.V1}/suggestions`,
  LANDING_PAGE: `${API_VERSION.V1}/landing-page`,
  UPDATE_SERVICE: `${API_VERSION.V1}/service`,
  LINK_TO_CALENDAR: `${API_VERSION.V1}/link-to-calender`,
  CONNECT_ACCOUNT_LINK: `${API_VERSION.V1}/generate-connect-account-link`,
  SEEKER_EXPERT_LIST: `${API_VERSION.V1}/free-slots`,
  EXPERT_EVENT_DETAIL: `${API_VERSION.V1}/session/`,
  GET_EXPERT_SESSION: `${API_VERSION.V1}/expert/session-list`,
  CREATE_SESSION: `${API_VERSION.V1}/session/`,
  CANCEL_SESSION_EXPERT: `${API_VERSION.V1}/cancel-session`,
  SESSION_EVENT_DETAIL: `${API_VERSION.V1}/seeker/session/`,
  GET_DASHBOARD_STATISTICS: `${API_VERSION.V1}/get-expert-dashboard-statistics`,
  // SESSION_PAYMENT_INTENT: `${API_VERSION.V1}/seeker/stripe/payment-intents`,
  // PAYMENT_RESPONSE: `${API_VERSION.V1}/transaction`,
  GET_SESSIONS_LIST_DASHBOARD: `${API_VERSION.V1}/expert/session-list-by-type`,
  CREATE_ISSUE: `${API_VERSION.V1}/ticket`,
  COUNTRIES_LIST: `${API_VERSION.V1}/countries`,
  SPEAKER: `${API_VERSION.V1}/book-expert-public-speaker`,
  BANK_DETAILS: `${API_VERSION.V1}/bank-details`,
  SEKKER_PAYMENT_INTENT: `${API_VERSION.V1}/seeker/razorpay/payment-intents`,
  POSTED_FEEDBACK: `${API_VERSION.V1}/list-posted-feedback`,
  POST_FEEDBACK: `${API_VERSION.V1}/add-feedback`,
  EXPERT_FEEDBACK_LIST: `${API_VERSION.V1}/list-experts-feedback`,
  DELETE_FEEDBACK: `${API_VERSION.V1}/delete-feedback`,
  CHECK_FEEDBACK: `${API_VERSION.V1}/check-feedback/`,
  JOB_PROFILE: `${API_VERSION.V1}/get-job-profile`,
  CREATE_JOB_PROFILE: `${API_VERSION.V1}/create-job-profile`,
  SEARCH_DROPDOWN_EXPERT: `${API_VERSION.V1}/admin/`,
  POST_FEEDBACK_DASHBOARD: `${API_VERSION.V1}/post-feedback`,
  CREATE_RECRUITER_JOB: `${API_VERSION.V1}/recruiter/post-job`,
  EXPERT_JOB_OVERVIEW: `${API_VERSION.V1}/expert/job-overview`,
  APPLY_JOB: `${API_VERSION.V1}/expert/apply-job/`,
  SAVE_JOB: `${API_VERSION.V1}/expert/save-job`,
  GET_RECRUITER_JOB_DETAILS: `${API_VERSION.V1}/job-details/`,
  GET_RECRUITER_JOB: `${API_VERSION.V1}/recruiter/job-details`,
  GET_RECRUITER_DASHBOARD_PROFILE: `${API_VERSION.V1}/recruiter/dashboard`,
  SEARCHING_FILTERS: `${API_VERSION.V1}/recruiter/get-searching-filters`,
  SEARCH_RECRUITER_CANDIDATES: `${API_VERSION.V1}/recruiter/suggested-candidates?`,
  SUGGESTED_JOBS: `${API_VERSION.V1}/suggested-jobs`,
  EXPERT_SAVED_JOBS: `${API_VERSION.V1}/expert/saved-jobs`,
  TRACKING_JOBS: `${API_VERSION.V1}/expert/get-job-applications/`,
  RECRUITER_CHANGE_JOB_STATUS: `${API_VERSION.V1}/recruiter/update-job-application`,
  RECRUITER_CONTACT: `${API_VERSION.V1}/recruiter/contact-candidate/`,
  RECRUITER_DETAILS_RESUME: `${API_VERSION.V1}/expert/download-resume/`,
  RECRUITER_JOB_APPLICATIONS: `${API_VERSION.V1}/recruiter/active-job-applications/`,
  RECRUITER_UPDATE_APPLICATION_STATUS: `${API_VERSION.V1}/recruiter/update-job-application/`,
  RECRUITER_EXPORT_APPLICANTS: `${API_VERSION.V1}/recruiter/export-job-applications`,
  EXPERT_DETAILS: `${API_VERSION.V1}/expert-details/`,
  SUBSCRIPTION_PLANS: `${API_VERSION.V1}/subscription/plans`,
  CREATE_SUBSCRIPTION: `${API_VERSION.V1}/subscription/create`,
  CANCEL_SUBSCRIPTION: `${API_VERSION.V1}/subscription/cancel/`,
};

export { ROUTE_PATH, API_PATH };
