import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const StyledPostJobPage = styled('div')(
  ({ theme }) => `
  padding: 32px 40px;
  color: ${theme.palette.customColor.primaryWhite};
  @media screen and (max-width: 480px){
    padding: 0px;
  }

  .page-header-wrap{
    margin: 0px;
    @media screen and (max-width: 480px){
      padding: 16px;
      flex-direction: column;
      width: 100%;
    }
    .MuiGrid-root{
      padding: 0px;
    }
    .previewButton-wrap{
      @media screen and (max-width: 600px){
        margin-left: 0px;
        margin-top: 10px;
        display: inline-table;
      }
    }
  }

  .MuiStepLabel-label{
    color: ${theme.palette.customColor.grey_7};
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media screen and (max-width: 480px){
      // white-space: wrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      font-size: 10px;
      width: 100%;
    }
  }

  .MuiStepLabel-label.Mui-active {
    color: ${theme.palette.primary.main} !important;
  }
  .MuiStepLabel-label, .Mui-completed {
  }
  .Mui-completed{
    color: #1c1c1c !important;
  }

  .MuiStep-root{
    padding: 0px;
    @media screen and (max-width: 480px){
      margin-bottom: 20px;
    }
    .MuiStepLabel-root{
      align-items: start;
      @media screen and (max-width: 480px){
      }
      .MuiStepLabel-labelContainer{
        text-align: left;
        @media screen and (max-width: 480px){
        }
      }
    }
  }
  .MuiStepConnector-root {
    left: calc(-90% + 30px);
    right: calc(90% + 32px);
    border-radius: 20px;
    overflow: hidden;    
    @media screen and (max-width: 480px){
      left: -4.2rem;
      right: 2rem;
      width: 3.125rem;
    }
    @media screen and (max-width: 430px){
      left: -3.6rem;
      width: 3rem;
    }
    @media screen and (max-width: 400px){
      left: -3.2rem;
      width: 2.8rem;
    }
    .MuiStepConnector-line{
      @media screen and (max-width: 480px){
        display: block;
      }
    }
  }
  .page-warning {
    margin-top: 30px;
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    border: 1px solid ${theme.palette.customColor.stateError};
    border-radius: 10px;
    background-color: ${theme.palette.customColor.primaryPink};
    padding: 5px 10px;

     @media screen and (max-width: 480px){
       width: 90%;
       margin: 17px;
     }
    .warning-text{
      color: ${theme.palette.customColor.primaryBlack};
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      }
    .warning-icon {
      margin-top: 8px;
    }  
       @media screen and (max-width: 600px){
        .warning-icon {
          width: 54px;
        }
      }
  }
  .stepperWrap {
    text-align: left;

    .title{
      color: ${theme.palette.customColor.primaryBlack};
      font-family: "Plus Jakarta Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 12px;
      @media screen and (max-width: 480px){
        margin-bottom: 35px;
      }
      @media screen and (max-width: 1200px){
        font-size: 23px;
      }
      @media screen and (max-width: 1150px){
        font-size: 21px;
      }
      @media screen and (max-width: 1150px){
        font-size: 18px;
      }
    }
    .MuiStack-root{
      width: 66%;
      min-width: 635px;
      @media screen and (max-width: 768px){
        width: 100%;
        min-width: unset;
        overflow-x: auto;
      }
      @media screen and (max-width: 480px){
        min-width: unset;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .mobileHrLine{
      display: none;
      @media screen and (max-width: 480px){
        display: block;
        width: 60px;
        height: 3px;
        background: white;
        border-radius: 40px;
        margin: 0px 10px;    
      }
    }
    .MuiStepper-root{
      .MuiStepLabel-root {
        .Mui-completed{
          div{
            border:0;
            background: transparent;
            img{
              width: 40px;
              height: 40px;
            }
          }
        }
      }
      @media screen and (max-width: 600px){
        .MuiStepLabel-root {
        .Mui-completed{
          div{
            border:0;
            img{
              width: 28px;
              height: 28px;
            }
          }
        }
      }
      }
    }
  }
  `,
);

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 17,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.customColor.black_1,
    borderRadius: 1,
  },
  '@media (max-width: 480px)': {
    [`& .${stepConnectorClasses.line}`]: {
      display: 'none',
    },
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
    },
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: `${theme.palette.secondary.main}`,
  zIndex: 1,
  color: theme.palette.customColor.grey_7,
  fontWeight: 700,
  fontSize: '14px',
  border: `2px solid ${theme.palette.customColor.grey_6}`,
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: 'none',
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: 'none',
  }),
  '@media (max-width: 480px)': {
    width: 24,
    height: 24,
  },
}));

export default StyledPostJobPage;

export { ColorlibConnector, ColorlibStepIconRoot };
