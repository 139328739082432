import { RouterProvider } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';
import routes from './routes';
import { theme } from './theme';
import Loader from './components/Loader';

function App(props) {
  const { updateLoading: { isLoading = false } = {} } = props;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="App">
          {isLoading ? <Loader is_loading={isLoading} /> : null}
          <RouterProvider router={routes} />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  updateLoading: state.loader,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
