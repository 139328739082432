import { styled } from '@mui/material/styles';

const StyledHeader = styled('div')(
  () => `
    .page-header-wrap{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px 100px;
      @media screen and (max-width: 550px){
        padding: 20px 30px;
      }
      @media screen and (max-width: 480px){
        padding: 20px 16px;
      }
      .header-content{
        text-align: left;
        .logoImage{
          // width: 60%;
          cursor: pointer;
          @media screen and (max-width: 480px){
            width: 100%;
          }
        }
      }
      .header-footer{
        display: flex;
        flex-wrap: wrap;
        width: 570px;
        justify-content: end;
        gap: 10px;
      }
      .title{
        font-size: 15px;
      }
`,
);

export default StyledHeader;
