/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { usePlacesWidget } from 'react-google-autocomplete';
import AutoComplete from '../../../../components/AutoComplete';
import StyledLocation from '../../../fractionTalent/location/StyledLocation';
import { validationSchemaLocation } from '../../../../utils/validations/authValidation';
import StyledRadioGroup from '../../../../components/Common/AccountsDetailsModal/StyledRadioGroup';
import { setRecruiterLocation } from '../../../../store/reducers/recruiterPostJob';
import RecruiterPostJobButtons from '../../../../components/RecruiterPostJobButtons';
import LocationAutocomplete from '../../../../components/LocationAutocomplete';

const Location = ({ stepNumber = 2, setActiveStep }) => {
  const dispatch = useDispatch();
  const recruiterLocation = useSelector((state) => state.recruiterPostJob.recruiterLocation);
  const previousData = useSelector((state) => state?.recruiterPostJob?.previousJobDta?.data);
  const [disableState, setDisableState] = useState(true);

  const workPreferenceOptions = [
    { value: 'remote', label: 'Remote' },
    { value: 'onSite', label: 'On-site' },
    { value: 'hybrid', label: 'Hybrid' },
  ];

  const submitHandler = async (values) => {
    const { city, state, workPreference, preferredJobLocation } = values;

    const postData = {
      jobCity: city,
      jobState: state,
      locationType: workPreference,
      preferredJobLocation,
    };
    dispatch(setRecruiterLocation(postData));
  };

  const formik = useFormik({
    initialValues: {
      city: '',
      state: '',
      workPreference: 'remote',
      preferredJobLocation: '',
    },
    validationSchema: validationSchemaLocation,
  });

  useEffect(() => {
    if (Object.keys(recruiterLocation).length > 0) {
      formik.setValues({
        city: recruiterLocation?.jobCity || '',
        state: recruiterLocation?.jobState || '',
        workPreference: recruiterLocation?.locationType || 'remote',
        preferredJobLocation: recruiterLocation?.preferredJobLocation || '',
      });
    } else if (previousData) {
      formik.setValues({
        city: previousData?.jobCity || '',
        state: previousData?.jobState || '',
        workPreference: previousData?.locationType || 'remote',
        preferredJobLocation: previousData?.preferredJobLocation || '',
      });
    }
  }, [recruiterLocation]);

  const {
    handleBlur,
    values: { city, state, workPreference, preferredJobLocation } = {},

    touched: { city: tucCity, state: tucState, preferredJobLocation: tucPreferredJobLocation } = {},
    errors: { city: errCity, state: errState, preferredJobLocation: errPreferredJobLocation } = {},
  } = formik;

  const customHandleChange = (e, name) => {
    if (Number.isInteger(e.target.value)) {
      const changedValue = e.target.innerText;
      formik.setFieldValue(name, changedValue);
    } else {
      formik.setFieldValue(name, e.target.value);
    }
  };

  const handleRadioChange = (newValue) => {
    formik.setFieldValue('workPreference', newValue);
    if (newValue === 'remote') {
      formik.setFieldValue('preferredJobLocation', '');
    }
  };

  const { ref: cityRef } = usePlacesWidget({
    apiKey: 'AIzaSyBfndC_Qxf9tApzas17Jrmpttahz1fMdFY',
    onPlaceSelected: (place) => {
      const selectedCity =
        place?.address_components?.find((comp) => comp.types.includes('locality'))?.long_name || '';
      // prettier-ignore
      const selectedState = place?.address_components?.find((comp) => comp.types.includes('administrative_area_level_1'))?.long_name || '';
      // Update form fields
      formik.setFieldValue('city', selectedCity);
      formik.setFieldValue('state', selectedState);
      setDisableState(true);
    },
  });

  const { ref: perfRef } = usePlacesWidget({
    apiKey: 'AIzaSyBfndC_Qxf9tApzas17Jrmpttahz1fMdFY',
    onPlaceSelected: (place) => {
      const selectedCity =
        place?.address_components?.find((comp) => comp.types.includes('locality'))?.long_name || '';
      formik.setFieldValue('preferredJobLocation', selectedCity);
    },
  });

  return (
    <StyledLocation>
      <div className="page-wrap">
        <div className="header-wrap">Location Preferences</div>
        <div className="sub-header-wrap">Job Location</div>
        <div className="content-wrap">
          <Grid container columnSpacing={2} rowSpacing={0}>
            <Grid item md={4.5} xs={11}>
              <LocationAutocomplete
                ref={cityRef}
                name="city"
                type="text"
                label="City"
                important
                onChange={(e) => {
                  customHandleChange(e, 'city');
                  setDisableState(false);
                }}
                onBlur={handleBlur}
                value={city}
                defaultValue={city}
                error={tucCity && !!errCity}
                helperText={tucCity && errCity}
              />
            </Grid>
            <Grid item md={4.5} xs={12}>
              <AutoComplete
                disabled={disableState}
                name="state"
                type="text"
                label="State"
                important
                changeAutoComplete={(e) => customHandleChange(e, 'state')}
                onBlur={handleBlur}
                value={state}
                error={tucState && !!errState}
                helperText={tucState && errState}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <div className="input-Label_text label-space">Job type*</div>
              <StyledRadioGroup
                options={workPreferenceOptions}
                value={workPreference}
                onChange={(e) => handleRadioChange(e)}
              />
            </Grid>
            {/* {workPreference !== 'remote' ? ( */}
            <Grid item md={4.5} xs={12}>
              <LocationAutocomplete
                disabled={workPreference === 'remote'}
                showTooltip={workPreference === 'remote'}
                tooltip="Job Location is required for hybrid & On-site"
                ref={perfRef}
                name="preferredJobLocation"
                type="text"
                label="Job location"
                important={workPreference !== 'remote'}
                onChange={(e) => customHandleChange(e, 'preferredJobLocation')}
                onBlur={handleBlur}
                value={preferredJobLocation}
                defaultValue={preferredJobLocation}
                error={tucPreferredJobLocation && !!errPreferredJobLocation}
                helperText={tucPreferredJobLocation && errPreferredJobLocation}
                />
            </Grid>
            {/* ) : null} */}
          </Grid>
        </div>
      </div>
      <RecruiterPostJobButtons
        showBackButton
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={() => submitHandler(formik.values)}
        disableNext={
          workPreference === 'remote' ? !(city && state) : !(city && state && preferredJobLocation)
        }
      />
    </StyledLocation>
  );
};

export default Location;
