import { styled } from '@mui/material/styles';

const StyledLoginPage = styled('div')(
  ({ theme }) => `
  .page-wrap {
    min-height: 100vh;
    display: flex;
    flex-flow: row wrap;
  }

  .page-left-wrap{
    @media screen and (max-width: 1190px){
      display: none;
    }
    .loginBg{
      width: 100%;
      height: 100vh;
    }
  }
  .page-right-wrap{
    display: flex;
    justify-content: center;
    color: ${theme.palette.secondary.main};
    margin: 0px auto;
    .page-right-content{
      padding-top: 80px;
      min-width: 26vw;
      text-align: left;
      @media screen and (max-width: 1280px){
        min-width: 40vw;
      }
      @media screen and (max-width: 480px){
        padding-top: 50px;
      }
      .logo-wrap{
        margin-bottom: 60px;
        @media screen and (max-width: 480px){
          margin-bottom: 40px;
        }
        .logoImage{
          width: 304px;
          @media screen and (max-width: 480px){
            width: 225px;
          }
        }
      }
      .title{
        color: ${theme.palette.customColor.primaryBlack};
        font-family: Sora;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media screen and (max-width: 480px){
          font-size: 20px;
        }
      }
      .subTitle{
        color: ${theme.palette.customColor.secondaryGrey};
        font-family: "Plus Jakarta Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 8px;
        margin-bottom: 40px;
        @media screen and (max-width: 480px){
          font-size: 14px;
        }
      }
      .loginButton{
        border-radius: 12px;
        border: 1.5px solid ${theme.palette.customColor.grey_6};
        background: ${theme.palette.secondary.main};
        box-shadow: ${theme.palette.boxShadow.tertiary};
        width: 30vw;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        text-align: center;
        color: ${theme.palette.customColor.primaryBlack};
        font-family: "Plus Jakarta Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 16px;
        @media screen and (max-width: 1280px){
          width: auto;
        }
        @media screen and (max-width: 1000px){
          width: 80vw;
        }
        @media screen and (max-width: 480px){
          font-size: 14px;
        }
        .btn-icon{
          padding-right: 10px;
        }
      }
    }
  }
`,
);

export default StyledLoginPage;
