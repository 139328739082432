import { styled } from '@mui/material/styles';
import subscriptionBG from '../../../assets/svg/subscriptionBG.svg';

const StyledSubscription = styled('div')(
  ({ theme }) => `
    .main-container {
      margin:32px;
      @media screen and (max-width: 786px){
        margin:0px 16px;
      }
      .subscard-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background:#ffffff;
        border-radius: 24px;
        .subscard-wrap-top{
          background: url(${subscriptionBG});
          background-repeat: no-repeat;
          background-size: cover;
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
          .heading {
            color: ${theme.palette.customColor.primaryBlack};
            font-family: Sora;
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            padding:24px 24px 8px 24px;
            width: 80%;
            @media screen and (max-width: 786px){
              font-size: 16px;
              font-weight: 600;
              width: 100%;
              padding:16px;
            }
          }
          .subtitle{
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color:#525252;
            margin:0px 0px 24px 24px;
          }
        }
        .divider{
          width: 100%;
          height:1px;
          background:#E2E4E7;
          margin-bottom:24px;
        }
        .plan {
          padding:0px 24px 24px;
          width:100%;
          .plan-heading {
            color: ${theme.palette.customColor.primaryBlack};
            font-family:Plus Jakarta Sans;
            font-size: 18px;
            font-weight: 600;
            text-align: left;
            line-height:24px;
            color:#1C1C1C;
          }
          .plan-cards {
            margin-top: 16px; 
            display: flex;
            align-items: end;
            gap:18px;
            // justify-content: space-between;        
            flex-wrap: wrap;
            .plan-card {
              background:#ffffff;
              min-width: 200px;
              border: 1px solid ${theme.palette.customColor.grey_6};
              border-radius: 12px;
              padding:16px;
              @media screen and (max-width: 786px){
                width:100%;
              }
              .plan-duration {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Plus Jakarta Sans;
                font-size: 18px;
                font-weight: 600;
                line-height: 22.68px;
                text-align: left;
                margin-bottom:14px;
              }
              .plan-amount {
                text-align: left;
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Sora;
                font-size: 24px;
                font-weight: 700;
                line-height: 30.24px;
                text-align: left;
              }
            }
            .plan-card-date{
              background:#ffffff;
              min-width: 200px;
              border: 1px solid ${theme.palette.customColor.grey_6};
              border-radius: 12px;
              padding:11px 16px;
              display:flex;
              flex-wrap:wrap;
              .plan-duration {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: Plus Jakarta Sans;
                font-size: 18px;
                font-weight: 600;
                line-height: 22.68px;
                text-align: left;
                margin-bottom:14px;
              }
              .ver-divider{
                background:#E2E4E7;
                width:1px;
                margin:0 70px;
                height:75px;
                @media screen and (max-width: 786px){
                  width:100%;
                  margin:16px 0px;
                  height:1px;
                }
                
              }
              .plan-date{
                font-family: Sora;
                font-size: 20px;
                font-weight: 600;
                line-height: 25.2px;
                text-align: left;
              }
            }
          }
        }
      }
      .btns{
        display: flex;
        gap:24px;
        margin-top:24px;
        max-width:400px;
        width:100%;
        float:right;
        .MuiButton-root.Mui-disabled{
          background: #757575;
          border: 1px solid #757575;
          color:#ffffff;
        }
      }

      .faq-wrap{
        margin-top:32px;
        padding:24px;
        background:#FFFFFF;
        border-radius: 24px;
        @media screen and (max-width: 786px){
          padding:16px;
        }
        .title{
          font-family: Sora;
          font-size: 24px;
          font-weight: 700;
          line-height: 40px;
          text-align: left;
          color:#0D141C;
          margin-bottom:16px;
        }
        .MuiAccordion-root .MuiAccordionSummary-root{
          background-color: #FFFFFF;
          color: #0D141C;
          
        }
        .MuiCollapse-entered {
          padding:0px 15px 12px;
        }
        .MuiCollapse-root{
          background-color: #FFFFFF;
          color: #0D141C;
          font-family: Plus Jakarta Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          color: #525252;
          padding: 0px;
          @media screen and (max-width: 786px){
            font-size: 12px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
          }
        }
        .MuiAccordionSummary-root{
          font-family: Plus Jakarta Sans;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          text-align: left;
          color:#0D141C;
          font-family: Plus Jakarta Sans;
          @media screen and (max-width: 786px){
            font-size: 12px;
            font-weight: 400;
            line-height: 21px;
            text-align: left;
          }
        }
        .MuiAccordionSummary-expandIconWrapper svg{
          fill:#0D141C;
        }
        .MuiAccordionSummary-content {
          margin:0px;
        }
        .Mui-expanded{
          min-height:25px;
          .MuiAccordionSummary-content {
            margin:12px 0px 0px;
          }
        }
      }  
    }    
  `,
);

export default StyledSubscription;
