import React, { useEffect, useState } from 'react';

const CircleAvatar = ({ src, name, size = 40, MobileSize = 40 }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const appliedSize = windowWidth <= 1200 ? MobileSize : size;
  const initial = name && name.trim() ? name.charAt(0).toUpperCase() : '';

  return (
    <div
      style={{
        width: appliedSize,
        height: appliedSize,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f0f0',
        color: '#555',
        fontSize: appliedSize / 2.5,
        fontWeight: 'bold',
        overflow: 'hidden',
        textTransform: 'uppercase',
      }}>
      {src ? (
        <img
          src={`${process.env.REACT_APP_IMAGE_URL}/${src}`}
          alt={name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '50%',
          }}
        />
      ) : (
        <span>{initial || 'C'}</span>
      )}
    </div>
  );
};

export default CircleAvatar;
