/* eslint-disable arrow-body-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PrivateLayout from '../../../layout/PrivateLayout';
import { BorderButton, PrimaryButton } from '../../../components/Button';
import SmallCalenderIcon from '../../../components/icons/SmallCalenderIcon';
import SmallCurrecyIcon from '../../../components/icons/SmallCurrecyIcon';
import StyledJobApplication from './StyledJobApplication';
import StyledRadioGroup from '../../../components/Common/AccountsDetailsModal/StyledRadioGroup';
import ExportIcon from '../../../components/icons/ExportIcon';
import ColorDropdown from '../../../components/ColorDropdown';
import {
  fetchExportApplicants,
  fetchRecruiterApplications,
  getRecruiterJob,
  updateRecruiterApplicationStatus,
} from '../../../store/reducers/recruiterPostJob/apiThunk';
import { getRecruiterDashboardProfile } from '../../../store/reducers/recruiterProfile/apiThunk';
import CircleAvatar from '../../../components/CircleAvatar';
import ActiveJobDetails from './activeJobDetails';
import ApplicationDetailPage from './ApplicationDetailPage';
import SmallLocationIcon from '../../../components/icons/SmallLocationIcon';
import { StyledPaginationBox } from '../../jobs/trackAppliedJob/StyledTracksJobs';

const jobTrackingOptions = [
  { value: 'all', label: 'All' },
  { value: 'applied', label: 'Applied' },
  { value: 'interviewScheduled', label: 'Interview scheduled' },
  { value: 'underReview', label: 'Under review' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
];
const rowsPerPage = 10;
const TrackJobApplication = () => {
  const dispatch = useDispatch();
  const [trackingStatus, settrackingStatus] = useState('all');
  const [isJob, setIsJob] = useState(false);
  const [activeJobData, setActiveJobData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [applications, setApplications] = useState([]);
  const [applicationCount, setApplicationCount] = useState();
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [paginationData, setPaginationData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const handleRadioChange = (newValue) => {
    settrackingStatus(newValue);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    dispatch(getRecruiterJob())
      .then((res) => {
        setIsJob(!!res?.payload?.data);
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
      });
  }, []);

  useEffect(() => {
    if (isJob) {
      dispatch(getRecruiterDashboardProfile())
        .then((res) => {
          if (res?.payload?.status) {
            setActiveJobData(res?.payload?.data?.data?.job);
          }
        })
        .catch((error) => {
          console.error('Error fetching profile:', error);
        });
    }
  }, [isJob]);

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const capitalizeWorkPreference = (workPreference) => {
    if (!workPreference) return '';
    if (workPreference === 'onSite') {
      return 'On-Site';
    }
    return workPreference.charAt(0).toUpperCase() + workPreference.slice(1);
  };

  const checkCompensationType = (item) => {
    switch (item) {
      case 'Per day':
        return 'day';
      case 'Per month':
        return 'month';
      default:
        return 'week';
    }
  };

  const getJobApplicants = async () => {
    const query =
      trackingStatus === 'all'
        ? '?page=1&limit=10'
        : `?status=${trackingStatus}&page=${currentPage}&limit=10`;

    try {
      const res = await dispatch(fetchRecruiterApplications(query));
      const {
        payload: {
          data: {
            data: { jobApplications },
          },
        },
      } = res || {};

      setApplications(jobApplications?.data || []);
      setPaginationData({
        count: jobApplications?.count,
        page: jobApplications?.page,
      });
      setApplicationCount(jobApplications?.count || 0);
    } catch (error) {
      console.error('Error fetching job applicants:', error);
    }
  };

  useEffect(() => {
    getJobApplicants();
  }, [currentPage]);

  const handleCloseDetailDialog = async () => {
    await getJobApplicants();
    setOpenDetailDialog(false);
  };

  useEffect(() => {
    getJobApplicants();
  }, [trackingStatus]);

  const changeStatus = async (status, applicationId) => {
    const data = {
      id: applicationId,
      dataToSend: {
        jobApplicationStatus: status,
      },
    };
    await dispatch(updateRecruiterApplicationStatus(data));
    setApplications((prevApplications) =>
      prevApplications.map((application) =>
        application._id === applicationId
          ? { ...application, jobApplicationStatus: status }
          : application,
      ),
    );
  };

  const exportApplicants = async (appliedApplicant) => {
    try {
      const query = `?status=${appliedApplicant}`;
      const res = await dispatch(fetchExportApplicants(query));
      const {
        payload: {
          data: { data },
        },
      } = res || {};
      if (data) {
        const link = document.createElement('a');
        link.href = data;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('No file URL found in the response data.');
      }
    } catch (error) {
      console.error('Error exporting applicants:', error);
    }
  };

  const durationCheck = (item) => {
    const isPlural = activeJobData?.availabilityPeriod > 1;
    switch (item) {
      case 'daily':
        return isPlural ? 'Days' : 'Day';
      case 'weekly':
        return isPlural ? 'Weeks' : 'Week';
      default:
        return isPlural ? 'Months' : 'Month';
    }
  };

  return (
    <StyledJobApplication>
      <div className="page-wrap">
        <div className="sec-1">
          <div className="header-1">Your Active Job</div>
          <div className="job-application-card">
            <div className="active-job-head">
              <div className="user">
                <div className="dp">
                  <CircleAvatar
                    src={activeJobData?.companyLogo}
                    name={activeJobData?.companyName}
                  />
                </div>
                <div className="dp-domain">
                  <div className="user-work">{activeJobData?.jobRole}</div>
                  <div className="user-name">{activeJobData?.companyName}</div>
                </div>
              </div>
              <div className="active-job">
                <div className="date-posted">Date Posted</div>
                <div className="date">{formatDate(activeJobData?.createdAt)}</div>
              </div>
            </div>
            <div className="job-skills">
              {activeJobData?.skills?.map((item) => (
                <div className="user-application">{item}</div>
              ))}
            </div>
            <div className="user-info">
              <div className="user-informations">
                <div className="location">
                  <div className="information-icons">
                    <SmallLocationIcon />
                  </div>
                  <div className="information-text">
                    {`${activeJobData?.jobCity}, ${activeJobData?.jobState} `}
                    <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>
                    {capitalizeWorkPreference(activeJobData?.locationType)}
                  </div>
                </div>
                <div className="user-charge">
                  <div className="information-icons">
                    <SmallCurrecyIcon />
                  </div>
                  <div className="information-text">
                    {`₹ ${activeJobData?.compensationRate}
                  /${checkCompensationType(activeJobData?.compensationType)}`}
                  </div>
                </div>
                <div className="user-availability">
                  <div className="information-icons">
                    <SmallCalenderIcon />
                  </div>
                  <div className="information-text">
                    {activeJobData?.availabilityPeriod} {durationCheck(activeJobData?.availableFor)}
                  </div>
                </div>
              </div>
              <div
                className="view-details-btn"
                onClick={() => navigate(`/job-detail?job-id=${activeJobData?._id}`)}>
                <PrimaryButton>View Job Details</PrimaryButton>
              </div>
            </div>
            <div className="mobile-date-view">
              <div
                className="view-details-btn-mobile"
                onClick={() => navigate(`/job-detail?job-id=${activeJobData?._id}`)}>
                <PrimaryButton>View Job Details</PrimaryButton>
              </div>
              <div className="active-job-mobile">
                <div className="date-posted">Date Posted</div>
                <div className="date">{formatDate(activeJobData?.createdAt)}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="sec-2">
          <div className="mobile-view">
            <div className="header-2">Total Applicants : {applicationCount}</div>
            <div className="export-btn-mobile" onClick={() => exportApplicants(trackingStatus)}>
              <BorderButton className="btn-2">
                <ExportIcon />
                Export List
              </BorderButton>
            </div>
          </div>
          <div className="filter">
            <div className="filter-radio-wrap">
              <StyledRadioGroup
                options={jobTrackingOptions}
                value={trackingStatus}
                onChange={(e) => handleRadioChange(e)}
              />
            </div>
            <div className="btn" onClick={() => exportApplicants(trackingStatus)}>
              <BorderButton className="btn-2">
                <ExportIcon />
                Export List
              </BorderButton>
            </div>
          </div>
          <div className="table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="no-border t-header">Applicant Name</TableCell>
                  <TableCell className="no-border t-header">Date Applied</TableCell>
                  <TableCell className="no-border t-header">Status</TableCell>
                  <TableCell className="no-border t-header" />
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.length > 0 ? (
                  applications?.map((applicant) => (
                    <TableRow key={applicant?._id}>
                      <TableCell className="no-border t-body">
                        {applicant?.expertDetails?.firstName} {applicant?.expertDetails?.lastName}
                      </TableCell>
                      <TableCell className="no-border t-body">
                        {formatDate(applicant?.createdAt)}
                      </TableCell>
                      <TableCell className="no-border t-body">
                        <ColorDropdown
                          selectedColor={applicant?.jobApplicationStatus}
                          onColorChange={(status) => changeStatus(status, applicant?._id)}
                        />
                      </TableCell>
                      <TableCell className="no-border t-body">
                        <div
                          className="underline-link"
                          onClick={() => {
                            setSelectedDetail(applicant);
                            navigate(`/applicant-detail?id=${applicant?.expertDetails?._id}`);
                          }}
                          style={{ cursor: 'pointer', width: 'fit-content' }}>
                          View Profile
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <div style={{ margin: '40px', textAlign: 'left' }}>No Apllicants</div>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="mobile-table">
            {applications.length > 0
              ? applications?.map((applicant) => (
                  <div className="each-applicant">
                    <div className="mobile-app-name">
                      <div className="mobile-header">Applicant Name</div>
                      <div className="mobile-body">
                        {applicant?.expertDetails?.firstName} {applicant?.expertDetails?.lastName}
                      </div>
                    </div>
                    <div className="mobile-date">
                      <div className="mobile-header">Date Applied</div>
                      <div className="mobile-body">{formatDate(applicant?.createdAt)}</div>
                    </div>
                    <ColorDropdown
                      selectedColor={applicant?.jobApplicationStatus}
                      onColorChange={(status) => changeStatus(status, applicant?._id)}
                    />
                    <div
                      className="mobile-view-details"
                      onClick={() => {
                        setSelectedDetail(applicant);
                        navigate('/applicant/details', { state: { applicant } });
                      }}>
                      View Details
                    </div>
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
      <ActiveJobDetails
        openDialog={openDialog}
        closeDialog={handleCloseDialog}
        jobDetails={activeJobData}
      />
      <ApplicationDetailPage
        openDialog={openDetailDialog}
        closeDialog={handleCloseDetailDialog}
        jobDetails={selectedDetail}
        applications={applications}
      />
      {applications?.length !== 0 ? (
        <StyledPaginationBox>
          <div className="jobs-pagination-box">
            <div className="left-wrap">
              <TablePagination
                component="div"
                count={paginationData?.count}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
              />
            </div>
            <div className="right-wrap">
              <Pagination
                variant="outlined"
                shape="rounded"
                size="medium"
                page={currentPage}
                count={Math.ceil(paginationData?.count / rowsPerPage)}
                onChange={(event, newPage) => {
                  handleChangePage(event, newPage);
                }}
              />
            </div>
          </div>
        </StyledPaginationBox>
      ) : (
        ''
      )}
    </StyledJobApplication>
  );
};

export default PrivateLayout(TrackJobApplication);
