/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */

import React from 'react';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import PrivateLayout from '../../../layout/PrivateLayout';
import StyledSubscription from './StyledSubscription';
import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import { cancelSubscription } from '../../../store/reducers/fractionTalent/apiThunk';
import { checkPlanType } from '../../../helper';
import { selectorAuthentication } from '../../../store/reducers/authentication';

const RecruiterSubscription = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData: { data: { subscriptionDetails = {} } = {} } = {} }
  = useSelector(selectorAuthentication);

  const cancelPlanHandler = async () => {
    const { subscriptionId } = subscriptionDetails;
    if (subscriptionId) {
      await dispatch(cancelSubscription(subscriptionId)).then((val) => val);
    }
  };
  return (

    <StyledSubscription>
      <div className="main-container">
        <div className="subscard-wrap">
          <div className="subscard-wrap-top">
            <div className="heading">For accessing the database of Fractional Professionals and posting a job, each job post will remain valid for 90 days.</div>
            <div className="subtitle">You are a one person company and this is your marketing  investment. </div>
          </div>
          <div className="divider" />
          <div className="plan">
            <div className="plan-heading">Subscription plan</div>
            <div className="plan-cards">
              <div className="plan-card">
                <div className="select-plan">
                  <div className="plan-duration">{checkPlanType(subscriptionDetails?.planId?.item?.name)} Plan</div>
                </div>
                <div className="plan-amount">
                  {subscriptionDetails?.planId?.item?.name === 'Yearly' ? `₹ ${subscriptionDetails?.amount / 100}/Year` : subscriptionDetails?.planId?.item?.name === 'Monthly' ? `₹ ${subscriptionDetails?.amount / 100}/Month` : 'Free Trial'}
                </div>
              </div>
              {subscriptionDetails?.status ?
                <div className="plan-card-date">
                  <div>
                    <div className="plan-duration">Start Date</div>
                    <div className="plan-date">
                      {subscriptionDetails?.freeTrial ?
                        moment(subscriptionDetails?.createdAt).format('ll') :
                        moment(subscriptionDetails?.currentStart).format('ll') }
                    </div>
                  </div>
                  <div className="ver-divider" />
                  <div>
                    <div className="plan-duration">End Date</div>
                    <div className="plan-date">
                      {subscriptionDetails?.freeTrial ?
                        moment(subscriptionDetails?.startAt).format('ll') :
                        moment(subscriptionDetails?.currentEnd).format('ll') }
                    </div>
                  </div>
                </div>
                : null}
            </div>
            <div className="btns">
              <SecondaryButton
                sx={{
                  border: '1px solid #ff3c3c',
                  color: '#DA3333',
                  '&:hover': {
                    color: '#DA3333',
                  },
                  maxWidth: '200px',
                }}
                onClick={() => cancelPlanHandler()}>
                {subscriptionDetails?.status !== 'active' ? 'Cancel Trial' : 'Cancel Subscription'}
              </SecondaryButton>
              {subscriptionDetails ? null : <PrimaryButton disabled>Choose Plan</PrimaryButton>}
            </div>
          </div>
        </div>
        <div className="faq-wrap">
          <div className="title">FAQs</div>
          <div className="accordion-wrap">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#0D141C' }} />}
                aria-controls="panel1-content"
                id="panel1-header"
          >
                <Typography component="span">Charges and Validity !!</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Rs 5000 for accessing the data base of Fractional Professionals and Posting a Job.
                Each Job Post is Valid for 90 days. There is no recurring fee or monthly payout.
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
          >
                <Typography component="span">How is this platform able to maintain quality ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Our platform charges Fractional Professionals a monthly subscription
                to keep an active Profile.This ensures the quality of Professionals on the platform.
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel2-header"
          >
                <Typography component="span">Do you help us fill the position?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                We help you fill the position by putting your requirement in front of right
                fractional professionals. If we do not have the right talent, then we post
                it on our social media handles for extended reach.
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel2-header"
          >
                <Typography component="span">Who signs the job contract ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                The contract job is between you and the fractional professional.
                We only help in matching the requirement.
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </StyledSubscription>

  );
};

export default PrivateLayout(RecruiterSubscription);
