import { theme } from '../../theme';

const { palette: { primary: { main } } = {} } = theme;

const PostJobIcon = ({ color = main }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.37526 2.10678L3.35859 5.38178C1.75026 6.43178 1.75026 8.78178 3.35859 9.83178L8.37526 13.1068C9.27526 13.6984 10.7586 13.6984 11.6586 13.1068L16.6503 9.83178C18.2503 8.78178 18.2503 6.44011 16.6503 5.39011L11.6586 2.11511C10.7586 1.51511 9.27526 1.51511 8.37526 2.10678Z"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.69193 10.8984L4.68359 14.8068C4.68359 15.8651 5.50026 16.9984 6.50026 17.3318L9.15859 18.2151C9.61693 18.3651 10.3753 18.3651 10.8419 18.2151L13.5003 17.3318C14.5003 16.9984 15.3169 15.8651 15.3169 14.8068V10.9401"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.833 12.5V7.5"
      stroke={color}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PostJobIcon;
