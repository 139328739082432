import * as React from 'react';
import { StyledCreate } from '../landing/StyledCreate';
import Availability from './Availability';
import BasicInfo from './Basic';
import Location from './location';
import Subscription from './Subscription';

export default function CreateLanding({ stepNumber = 0, setActiveStep }) {
  const handleRenderSection = () => {
    switch (stepNumber) {
      case 0:
        return <BasicInfo stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 1:
        return <Location stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 2:
        return <Availability stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 3:
        return <Subscription stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      default:
        return '';
    }
  };

  return (
    <StyledCreate>
      <div className="page-content-wrap">
        <div className="content-wrap">{handleRenderSection()}</div>
      </div>
    </StyledCreate>
  );
}
