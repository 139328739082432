import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '../../../../components/TextField';
import UploadIcon from '../../../../components/icons/UploadIcon';
import { BorderButton } from '../../../../components/Button';
import RecruiterPostJobButtons from '../../../../components/RecruiterPostJobButtons';
import StyledBasic from './StyledBasic';
import { validationPostaJobBasic } from '../../../../utils/validations/authValidation';
import { ToastNotifyError } from '../../../../components/Toast/ToastNotify';
import DeleteIcon from '../../../../components/icons/DeleteIcon';
import { setRecruiterBasic } from '../../../../store/reducers/recruiterPostJob';
import SelectBox from '../../../../components/SelectBox';

const options = [
  { title: 'Startup', value: 'startup' },
  { title: 'Other', value: 'multinational' },
];

const Basic = ({ stepNumber = 0, setActiveStep }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [extension, setExtension] = useState(null);
  const fileInputRef = useRef(null);

  const previousData = useSelector((state) => state?.recruiterPostJob?.previousJobDta?.data);
  const recruiterBasic = useSelector((state) => state.recruiterPostJob?.recruiterBasic);

  const submitHandler = async (values) => {
    const {
      contactDetails,
      linkedInProfileLink,
      companyName,
      companyAddress,
      designation,
      companyWebsite,
      companyType,
    } = values;

    const postData = {
      countryCode: '+91',
      phoneNumber: contactDetails,
      linkedinProfile: linkedInProfileLink,
      companyName,
      companyAddress,
      designation,
      companyWebsite,
      companyType,
      imageFile: file,
      fileExtention: extension,
    };
    dispatch(setRecruiterBasic(postData));
  };

  const formik = useFormik({
    initialValues: {
      contactDetails: '',
      linkedInProfileLink: '',
      companyName: '',
      companyAddress: '',
      designation: '',
      companyWebsite: '',
      companyType: '',
    },
    validationSchema: validationPostaJobBasic,
    validateOnMount: true,
    onSubmit: submitHandler,
  });

  const {
    handleBlur,
    values: {
      contactDetails,
      linkedInProfileLink,
      companyName,
      companyAddress,
      designation,
      companyWebsite,
      companyType,
    } = {},

    touched: {
      contactDetails: tucContactDetails,
      linkedInProfileLink: tucLinkedInProfileLink = false,
      companyName: tucCompanyName = false,
      companyAddress: tucCompanyAddress = false,
      designation: tucDesignation = false,
      companyWebsite: tucCompanysWebsite = false,
      companyType: tucCompanysType = false,
    } = {},
    errors: {
      contactDetails: errContactDetails,
      linkedInProfileLink: errLinkedInProfileLink,
      companyName: errCompanyName,
      companyAddress: errCompanyAddress,
      designation: errDesignation,
      companyWebsite: errCompanysWebsite,
      companyType: errCompanysType,
    } = {},
  } = formik;

  const handleChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  const validateFile = (selectedFile) => {
    if (selectedFile) {
      const validTypes = ['image/jpeg', 'image/png'];
      const maxSize = 2 * 1024 * 1024;

      if (!validTypes.includes(selectedFile.type)) {
        ToastNotifyError('File format not supported. Please upload a JPEG or PNG file.');
        setFile(null);
      } else if (selectedFile.size > maxSize) {
        ToastNotifyError('File size should be less than 2 MB.');
        setFile(null);
      } else {
        setFile(selectedFile);
      }
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setExtension(event?.target?.files[0]?.name?.split('.')?.pop());
    validateFile(selectedFile);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (Object.keys(recruiterBasic).length > 0) {
      formik.setValues({
        contactDetails: recruiterBasic?.phoneNumber,
        linkedInProfileLink: recruiterBasic?.linkedinProfile,
        companyName: recruiterBasic?.companyName,
        companyAddress: recruiterBasic?.companyAddress,
        designation: recruiterBasic?.designation,
        companyWebsite: recruiterBasic?.companyWebsite,
        companyType: recruiterBasic?.companyType,
      });
      setFile(recruiterBasic?.imageFile);
    } else if (previousData) {
      formik.setValues({
        contactDetails: previousData?.phoneNumber,
        linkedInProfileLink: previousData?.linkedinProfile,
        companyName: previousData?.companyName,
        companyAddress: previousData?.companyAddress,
        designation: previousData?.designation,
        companyWebsite: previousData?.companyWebsite,
        companyType: previousData?.companyType,
      });
      setFile(previousData?.companyLogo);
    }
  }, [recruiterBasic]);

  return (
    <StyledBasic>
      <div className="page-wrap">
        <div className="page-header">Basic</div>
        <div className="content-wrap">
          <div className="left-basic-wrap">
            <Grid
              container
              columnSpacing={2.5}
              rowSpacing={2}
              className="input-fields-wrap"
              alignItems="end">
              <Grid item md={6} xs={12}>
                <Box>
                  <TextField
                    placeholder="Enter 10 digit number"
                    name="contactDetails"
                    type="tel"
                    label="Contact Details"
                    important
                    fullWidth
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value)) {
                        handleChange(e);
                      }
                    }}
                    onBlur={handleBlur}
                    value={contactDetails}
                    error={tucContactDetails && !!errContactDetails}
                    helperText={tucContactDetails && errContactDetails}
                    startIcon={<div className="contryCode-adornment">+91</div>}
                    sx={{
                      '& .MuiInputBase-input': {
                        padding: '13px 13px 13px 62px !important',
                      },
                      '& .MuiInputAdornment-root': {
                        marginRight: '0px !important',
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  placeholder="e.g. linkedin.com/in/johndoe"
                  name="linkedInProfileLink"
                  type="text"
                  important
                  label="LinkedIn Profile Url"
                  showTooltip
                  tooltip="Add your linkedin profile url"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={linkedInProfileLink}
                  error={tucLinkedInProfileLink && !!errLinkedInProfileLink}
                  helperText={tucLinkedInProfileLink && errLinkedInProfileLink}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  placeholder="Enter company name"
                  name="companyName"
                  type="text"
                  label="Company Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={companyName}
                  error={tucCompanyName && !!errCompanyName}
                  helperText={tucCompanyName && errCompanyName}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  placeholder="Enter company address"
                  name="companyAddress"
                  type="text"
                  label="Company Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={companyAddress}
                  error={tucCompanyAddress && !!errCompanyAddress}
                  helperText={tucCompanyAddress && errCompanyAddress}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  placeholder="Enter your designation"
                  name="designation"
                  type="text"
                  label="Your Designation in Company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={designation}
                  error={tucDesignation && !!errDesignation}
                  helperText={tucDesignation && errDesignation}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  placeholder="Type or paste website URL"
                  name="companyWebsite"
                  type="text"
                  label="Company's Website"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={companyWebsite}
                  error={tucCompanysWebsite && !!errCompanysWebsite}
                  helperText={tucCompanysWebsite && errCompanysWebsite}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="company-type">
                  <SelectBox
                    placeholderValue="Enter startup or multinational"
                    name="companyType"
                    type="text"
                    label="Company's Type"
                    options={options}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    selectValue={companyType}
                    error={tucCompanysType && !!errCompanysType}
                    helperText={tucCompanysType && errCompanysType}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="right-basic-wrap">
            <div className="upload-wrap">
              <UploadIcon />
              <div className="right-basic-header">Upload Company’s Logo</div>
              <div className="right-basic-body">
                Choose a file or drag & drop here. It should be in JPEG or PNG upto 2 MB
              </div>
              <BorderButton onClick={handleButtonClick}>Browse file</BorderButton>
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".jpeg, .jpg, .png"
              />
            </div>
            {file && (
              <div className="upload-file">
                <div className="file-name">Company’s Logo</div>
                <div className="delete-icon" onClick={() => setFile(null)}>
                  <DeleteIcon />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <RecruiterPostJobButtons
        showBackButton={false}
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={() => submitHandler(formik.values)}
        disableNext={!formik.isValid}
      />
    </StyledBasic>
  );
};

export default Basic;
