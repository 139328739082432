/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthorization } from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const fetchJobProfile = createAsyncThunk(
  'fractionTalent/jobprofile',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.JOB_PROFILE, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const createJobProfile = createAsyncThunk(
  'expertProfile/createJobProfile',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.CREATE_JOB_PROFILE, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

// const createRecruiterJob = createAsyncThunk(
//   'recruiterProfile/createJob',
//   async (data, { dispatch, getState }) => {
//     const API = getAuthorization(getState);
//     try {
//       dispatch(updateLoading(true));
//       const response = await API.post(API_PATH.CREATE_RECRUITER_JOB, data);
//       dispatch(updateLoading(false));
//       return response?.data;
//     } catch (err) {
//       return err;
//     }
//   },
// );

const uploadResume = createAsyncThunk(
  'expertProfile/uploadResume',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    const API_WITHOUT_HEADER = getAuthorization(getState, { isAuthHeader: false });
    try {
      dispatch(updateLoading(true));
      const { extension, uploadFile } = data;
      const s3Data = {
        extension,
      };
      let response;
      if (extension) {
        response = await API.post(API_PATH.GET_S3_URL, s3Data);
        const { data: { data: { url } = {} } = {} } = response;
        await API_WITHOUT_HEADER.put(url, uploadFile);
      }
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchDropdownProfile = createAsyncThunk(
  'fractionTalent/dropdownProfile',
  async (data, { getState }) => {
    const { field, search } = data;
    const API = getAuthorization(getState);

    try {
      const response = await API.get(
        `${API_PATH.SEARCH_DROPDOWN_EXPERT}${field}?name=${search || ''}`,
      );

      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchJobOverview = createAsyncThunk('fractionTalent/jobProfiles', async (_, { getState }) => {
  const API = getAuthorization(getState);
  try {
    const response = await API.get(`${API_PATH.EXPERT_JOB_OVERVIEW}`);
    return response?.data;
  } catch (err) {
    return err;
  }
});

const fetchApplyJob = createAsyncThunk(
  'expertProfile/fetchApplyJob',
  async (jobId, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.APPLY_JOB}${jobId}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchSaveJob = createAsyncThunk(
  'expertProfile/fetchSaveJob',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(API_PATH.SAVE_JOB, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

const fetchSearchingFilters = createAsyncThunk(
  'expertProfile/fetchSearchingFilters',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.SEARCHING_FILTERS, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchSuggestedJobs = createAsyncThunk(
  'expertProfile/fetchSuggestedJobs',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(`${API_PATH.SUGGESTED_JOBS}?${data}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchSavedJobs = createAsyncThunk(
  'expertProfile/fetchSavedJobs',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(`${API_PATH.EXPERT_SAVED_JOBS}?${data}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchTrackingJobs = createAsyncThunk(
  'expertProfile/fetchTrackingJobs',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(`${API_PATH.TRACKING_JOBS}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchJobDetails = createAsyncThunk(
  'expertProfile/fetchTrackingJobs',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(`${API_PATH.GET_RECRUITER_JOB_DETAILS}${data}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchSubscriptionPlans = createAsyncThunk(
  'expertProfile/fetchSubscriptionPlans',
  async (_, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(`${API_PATH.SUBSCRIPTION_PLANS}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const cretaeSubscription = createAsyncThunk(
  'expertProfile/cretaeSubscription',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.CREATE_SUBSCRIPTION}`, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const cancelSubscription = createAsyncThunk(
  'expertProfile/cancelSubscription',
  async (id, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.post(`${API_PATH.CANCEL_SUBSCRIPTION}${id}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);

export {
  fetchJobProfile,
  createJobProfile,
  uploadResume,
  fetchDropdownProfile,
  fetchJobOverview,
  fetchApplyJob,
  fetchSaveJob,
  fetchSearchingFilters,
  fetchSuggestedJobs,
  fetchSavedJobs,
  fetchTrackingJobs,
  fetchJobDetails,
  fetchSubscriptionPlans,
  cretaeSubscription,
  cancelSubscription,
};
