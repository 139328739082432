/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import StyledDashboard from './StyledRecruiterDashboard';
import noDashboardBG from '../../../assets/svg/noDashboardBG.svg';
import { PrimaryButton } from '../../../components/Button';
import PrivateLayout from '../../../layout/PrivateLayout';
import { ROUTE_PATH } from '../../../constants/route';
import { getRecruiterDashboardProfile } from '../../../store/reducers/recruiterProfile/apiThunk';
import CircleAvatar from '../../../components/CircleAvatar';
import SmallCalenderIcon from '../../../components/icons/SmallCalenderIcon';
import SmallCurrecyIcon from '../../../components/icons/SmallCurrecyIcon';
import RightArrowIcon from '../../../components/icons/RightArrowIcon';
import CircleCloseIcon from '../../../components/icons/CircleCloseIcon';
import SmallEducation from '../../../components/icons/SmallEducation';
import SmallGlobeIcon from '../../../components/icons/SmallGlobeIcon';
import SmallLocationIcon from '../../../components/icons/SmallLocationIcon';
import { getRecruiterJob } from '../../../store/reducers/recruiterPostJob/apiThunk';
import SuggestedCandidateProfile from '../SuggestedCandidates/SuggestedCandidateProfile';
import RedWarningIcon from '../../../components/icons/RedWarningIcon';
import ArrowDownIcon from '../../../components/icons/ArrowDownIcon';

const RecruiterDashboard = () => {
  const [activeJobData, setActiveJobData] = useState(null);
  const [suggestionCandidates, setSuggestionCandidates] = useState([]);
  const [visibleCandidates, setVisibleCandidates] = useState([]);
  const [removedCandidates, setRemovedCandidates] = useState([]);
  const [isJob, setIsJob] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDetail, SetSelectedDetail] = useState(null);
  const { POSTAJOB, RECRUITER_SUGGESTED_CANDIDATES, RECRUITER_TRACK_APPLICATION } = ROUTE_PATH;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleHeight = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    dispatch(getRecruiterJob())
      .then((res) => {
        setIsJob(!!res?.payload?.data);
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
      });
  }, []);

  useEffect(() => {
    if (isJob) {
      dispatch(getRecruiterDashboardProfile())
        .then((res) => {
          if (res?.payload?.status) {
            setActiveJobData(res?.payload?.data?.data?.job);
            setSuggestionCandidates(res?.payload?.data?.data?.suggestedJobs);
          }
        })
        .catch((error) => {
          console.error('Error fetching profile:', error);
        });
    }
  }, [isJob]);

  useEffect(() => {
    if (suggestionCandidates?.length > 0) {
      const filteredCandidates = suggestionCandidates.filter(
        (candidate) => !removedCandidates.includes(candidate._id),
      );
      setVisibleCandidates(filteredCandidates.slice(0, 2));
    }
  }, [suggestionCandidates, removedCandidates]);

  const removeCandidate = (id) => {
    setRemovedCandidates((prev) => [...prev, id]);
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const durationCheck = (item) => {
    const isPlural = activeJobData?.availabilityPeriod > 1;
    switch (item) {
      case 'daily':
        return isPlural ? 'Days' : 'Day';
      case 'weekly':
        return isPlural ? 'Weeks' : 'Week';
      default:
        return isPlural ? 'Months' : 'Month';
    }
  };
  const durationCheckSuggestion = (item, suggNo) => {
    const isPlural = suggNo > 1;
    switch (item) {
      case 'daily':
        return isPlural ? 'Days' : 'Day';
      case 'weekly':
        return isPlural ? 'Weeks' : 'Week';
      default:
        return isPlural ? 'Months' : 'Month';
    }
  };

  const checkCompensationType = (item) => {
    switch (item) {
      case 'Per day':
        return 'day';
      case 'Per month':
        return 'month';
      default:
        return 'week';
    }
  };

  const capitalizeWorkPreference = (workPreference) => {
    if (!workPreference) return '';
    if (workPreference === 'onSite') {
      return 'On-Site';
    }
    return workPreference.charAt(0).toUpperCase() + workPreference.slice(1);
  };

  const handleSelectedDetails = (item) => {
    SetSelectedDetail(item);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const renderDasboardData = () => (
    <div className="page-wrap">
      <div className="page-stats-wrap">
        <div className="left-section-wrap">
          <div className="active-job-wrap no-data">
            <div className="content-wrap">
              <div className="card-1">
                <div className="card-heading">Track Active Job Applications</div>
              </div>
              {activeJobData?.jobStatus === 'expired' ? (
                <div className="page-warning">
                  <RedWarningIcon />
                  <div className="warning-text">
                    The validity of your current job has expired. Please edit the current job to
                    make it visible to experts.
                  </div>
                </div>
              ) : (
                ''
              )}
              {activeJobData ? (
                <div className="job-application-card">
                  <div className="active-job-head">
                    <div className="user">
                      <div className="dp">
                        <CircleAvatar
                          src={activeJobData?.companyLogo}
                          name={activeJobData?.companyName}
                        />
                      </div>
                      <div className="dp-domain">
                        <div className="user-work">{activeJobData?.jobRole}</div>
                        <div className="user-name">{activeJobData?.companyName}</div>
                      </div>
                    </div>
                    <div className="active-job">
                      <div className="date-posted">Date Posted</div>
                      <div className="date">{formatDate(activeJobData?.updatedAt)}</div>
                    </div>
                  </div>
                  <div className="job-skills">
                    {activeJobData?.skills?.map((item) => (
                      <div className="user-application">{item}</div>
                    ))}
                  </div>
                  <div className="user-info">
                    <div className="user-informations">
                      <div className="location">
                        <SmallCalenderIcon />
                        <div className="information-text">
                          {`${activeJobData?.jobCity}, ${activeJobData?.jobState} `}
                          <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>
                          {capitalizeWorkPreference(activeJobData?.locationType)}
                        </div>
                      </div>
                      <div className="user-charge">
                        <SmallCurrecyIcon />
                        <div className="information-text">{`₹ ${activeJobData?.compensationRate}/${checkCompensationType(activeJobData?.compensationType)}`}</div>
                      </div>
                      <div className="user-availability">
                        <SmallCalenderIcon />
                        <div className="information-text">
                          {activeJobData?.availabilityPeriod}{' '}
                          {durationCheck(activeJobData?.availableFor)}
                        </div>
                      </div>
                    </div>
                    <div
                      className="view-details-btn"
                      onClick={() => navigate(RECRUITER_TRACK_APPLICATION)}>
                      <PrimaryButton>View Applicants</PrimaryButton>
                    </div>
                  </div>
                  <div className="mobile-date-view">
                    <div
                      className="view-details-btn-mobile"
                      onClick={() => navigate(RECRUITER_TRACK_APPLICATION)}>
                      <PrimaryButton>View Applicants</PrimaryButton>
                    </div>
                    <div className="active-job-mobile">
                      <div className="date-posted">Date Posted</div>
                      <div className="date">{formatDate(activeJobData?.updatedAt)}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-body">
                  No active jobs at the moment! Post a job to create a new job.
                </div>
              )}
            </div>
          </div>

          <div className="suggested-candidates-wrap no-data-s">
            {!suggestionCandidates?.length > 0 && (
              <img className="bg-image" src={noDashboardBG} alt="" />
            )}
            <div className="content-wrap">
              <div className="card-2">
                <div className="card-heading">Suggested Candidates</div>
                <div
                  className="seeAll-btn"
                  onClick={() => navigate(RECRUITER_SUGGESTED_CANDIDATES)}>
                  <div className="btn-text">See All</div>
                  <RightArrowIcon />
                </div>
              </div>
              <div className="suggestion-cards">
                {visibleCandidates?.length > 0 ? (
                  visibleCandidates.map((suggestionCandidate) => (
                    <div className="suggested-candidates-card">
                      <div className="card-head">
                        <div className="user">
                          <div className="dp">
                            <CircleAvatar
                              src={suggestionCandidate?.expertData?.profileImagePath}
                              name={suggestionCandidate?.expertData?.firstName}
                            />
                          </div>
                          <div className="dp-domain">
                            <div className="user-work">
                              {suggestionCandidate?.expertData?.firstName}{' '}
                              {suggestionCandidate?.expertData?.lastName}
                            </div>
                          </div>
                        </div>
                        <div
                          className="cross-icon"
                          onClick={() => removeCandidate(suggestionCandidate?._id)}>
                          <CircleCloseIcon />
                        </div>
                      </div>
                      <div className="job-skills-2">
                        {suggestionCandidate?.skills?.map((item) => (
                          <div className="user-application-2">{item}</div>
                        ))}
                      </div>
                      <div className="user-info">
                        <div className="user-informations">
                          <div className="design">
                            <SmallGlobeIcon />
                            <div className="information-text">{suggestionCandidate?.jobRole}</div>
                          </div>
                          <div className="education">
                            <SmallEducation />
                            <div className="information-text">{suggestionCandidate?.domain}</div>
                          </div>
                          <div className="location">
                            <SmallLocationIcon />
                            <div className="information-text">
                              {`${suggestionCandidate?.city}, ${suggestionCandidate?.state}`}{' '}
                              <span style={{ color: '#757575', lineHeight: '8px' }}>•</span>{' '}
                              {capitalizeWorkPreference(suggestionCandidate?.workPreference)}
                            </div>
                          </div>
                          <div className="user-charge">
                            <SmallCurrecyIcon />
                            <div className="information-text">{`₹ ${suggestionCandidate?.compensation}/${checkCompensationType(suggestionCandidate?.compensationPeriod)}`}</div>
                          </div>
                          <div className="user-availability">
                            <SmallCalenderIcon />
                            <div className="information-text">
                              {suggestionCandidate?.availability}{' '}
                              {durationCheckSuggestion(
                                suggestionCandidate?.availabilityType,
                                suggestionCandidate?.availability,
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="suggestion-view-details-btn"
                          onClick={() => {
                            setOpenDialog(true);
                            handleSelectedDetails(suggestionCandidate);
                          }}>
                          View Details
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card-body-suggested">
                    No suggestion yet ! Post a job to get suggested candidates
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="right-section-wrap">
          <div className="post-btn">
            <PrimaryButton disabled={isJob}>Post A Job</PrimaryButton>
          </div>
          <div className={`subs-wrap ${isExpanded ? 'expanded' : ''}`} onClick={toggleHeight}>
            <div className="subs-card-wrap">
              <div className="subs-heading">My Subscription</div>
              <div className="subs-arrow" style={{ rotate: isExpanded ? '180deg' : '' }}>
                <ArrowDownIcon />
              </div>
            </div>
            <div className="subs-sub-heading">You are currently subscribed to</div>
            <div className="subs-card">
              <div className="select-plan">
                <div className="plan-duration">Half-Yearly Plan</div>
              </div>
              <div className="plan-amount">
                Rs 1199 <span>For 6 months</span>
              </div>
              <PrimaryButton onClick={() => navigate('/recruiter-subscription')}>View Details</PrimaryButton>
            </div>
            <div className="subs-date">
              <div className="subs-date-heading">Subscription Start Date:</div>
              <div className="subs-date-date">August 23, 2024</div>
            </div>
            <div className="subs-date">
              <div className="subs-date-heading">Subscription End Date:</div>
              <div className="subs-date-date">August 23, 2024</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <StyledDashboard>
      {isJob ? (
        renderDasboardData()
      ) : (
        <div className="noData-wrap">
          <img src={noDashboardBG} alt="noData" />
          <div className="heading">Nothing to show! start by posting a job</div>
          <PrimaryButton
            sx={{
              width: '160px',
              '@media screen and (max-width: 767px)': {
                width: '100px',
              },
            }}
            onClick={() => navigate(POSTAJOB)}>
            Post A Job
          </PrimaryButton>
        </div>
      )}
      <SuggestedCandidateProfile
        openDialog={openDialog}
        closeDialog={handleCloseDialog}
        jobDetails={selectedDetail}
        dashboard
      />
    </StyledDashboard>
  );
};
export default PrivateLayout(RecruiterDashboard);
