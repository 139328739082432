/* eslint-disable arrow-body-style */
import React from 'react';

const SmallGlobeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99967 14.6654C11.6816 14.6654 14.6663 11.6806 14.6663 7.9987C14.6663 4.3168 11.6816 1.33203 7.99967 1.33203C4.31778 1.33203 1.33301 4.3168 1.33301 7.9987C1.33301 11.6806 4.31778 14.6654 7.99967 14.6654Z"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33372 2H6.00039C4.70039 5.89333 4.70039 10.1067 6.00039 14H5.33372"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 2C11.3 5.89333 11.3 10.1067 10 14"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.6667V10C5.89333 11.3 10.1067 11.3 14 10V10.6667"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 5.99844C5.89333 4.69844 10.1067 4.69844 14 5.99844"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SmallGlobeIcon;
