/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
// import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import StyledJobDetail from './StyledJobDetails';
import AutoComplete from '../../../../components/AutoComplete';
import TextField from '../../../../components/TextField';
import { validationPostaJobDetails } from '../../../../utils/validations/authValidation';
import { fetchDropdownProfile } from '../../../../store/reducers/fractionTalent/apiThunk';
import MultiselectAutoComplete from '../../../../components/MultiselectAutoComplete/MultiselectAutoComplete';
import RecruiterPostJobButtons from '../../../../components/RecruiterPostJobButtons';
import { setRecruiterJobDetails } from '../../../../store/reducers/recruiterPostJob';
import increase from '../../../../assets/svg/increase.svg';
import decresae from '../../../../assets/svg/decrease.svg';
import ExpandMoreIcon from '../../../../components/icons/ExpandMoreIcon';

const JobDetails = ({ stepNumber = 1, setActiveStep }) => {
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [searchField, setSearchField] = useState('industry');
  const searchTimeoutRef = useRef(null);
  const dispatch = useDispatch();
  const recruiterJobDetails = useSelector((state) => state.recruiterPostJob.recruiterJobDetails);
  const previousData = useSelector((state) => state?.recruiterPostJob?.previousJobDta?.data);

  const handleSearch = async () => {
    const query = {
      field: searchField,
      search,
    };
    const response = await dispatch(fetchDropdownProfile(query));
    const { payload: { data = [] } = {} } = response;
    setDropDownOptions(data);
  };

  // useEffect(() => {
  //   setDropDownOptions([]);
  // }, [searchField]);

  useEffect(() => {
    const skillsoption = async () => {
      const query = {
        field: 'skill',
        search,
      };
      const response = await dispatch(fetchDropdownProfile(query));
      const { payload: { data = [] } = {} } = response;
      setSkillOptions(data);
    };
    skillsoption();
  }, []);

  const submitHandler = async (values) => {
    const {
      jobRole,
      skills,
      domain,
      industry,
      experienceYears,
      experienceDescription,
      linkJobPortal,
    } = values;

    const postData = {
      jobRole,
      skills,
      domain,
      industry,
      jobExperience: experienceYears,
      jobDescription: experienceDescription,
      portalLink: linkJobPortal,
    };
    dispatch(setRecruiterJobDetails(postData));
  };

  useEffect(() => {
    // Clear any previous timeout to prevent multiple calls
    clearTimeout(searchTimeoutRef.current);

    // Set a new timeout to delay the search
    searchTimeoutRef.current = setTimeout(() => {
      handleSearch(searchField, search);
    }, 500); // Adjust delay as needed

    // Cleanup on unmount to avoid memory leaks
    return () => clearTimeout(searchTimeoutRef.current);
  }, [searchField, search]);

  const formik = useFormik({
    initialValues: {
      jobRole: '',
      skills: [],
      domain: '',
      industry: '',
      experienceYears: '0',
      experienceDescription: '',
      linkJobPortal: '',
    },
    validationSchema: validationPostaJobDetails,
  });

  const {
    handleBlur,
    values: {
      jobRole,
      skills,
      domain,
      industry,
      experienceYears,
      experienceDescription,
      linkJobPortal,
    } = {},

    touched: {
      jobRole: tucJobRole,
      skills: tucSkills = false,
      domain: tucDomain = false,
      industry: tucIndustry = false,
      experienceYears: tucExperienceYears = false,
      linkJobPortal: tucLinkJobPortal = false,
      experienceDescription: tucExperienceDescription = false,
    } = {},
    errors: {
      jobRole: errJobRole,
      skills: errSkills,
      domain: errDomain,
      industry: errIndustry,
      experienceYears: errExperienceYears,
      linkJobPortal: errLinkJobPortal,
      experienceDescription: errExperienceDescription,
    } = {},
  } = formik;

  useEffect(() => {
    if (Object.keys(recruiterJobDetails).length > 0) {
      formik.setValues({
        jobRole: recruiterJobDetails?.jobRole,
        skills: recruiterJobDetails?.skills,
        domain: recruiterJobDetails?.domain,
        industry: recruiterJobDetails?.industry,
        experienceYears: recruiterJobDetails?.jobExperience,
        experienceDescription: recruiterJobDetails?.jobDescription,
        linkJobPortal: recruiterJobDetails?.portalLink,
      });
    } else if (previousData) {
      formik.setValues({
        jobRole: previousData?.jobRole,
        skills: previousData?.skills,
        domain: previousData?.domain,
        industry: previousData?.industry,
        experienceYears: previousData?.jobExperience,
        experienceDescription: previousData?.jobDescription,
        linkJobPortal: previousData?.portalLink,
      });
    }
  }, [recruiterJobDetails]);

  const handleChange = (e, name) => {
    // setDisableSaveBtn(false);
    formik.setFieldValue(name, e.target.value);
  };

  const customHandleChange = (e, name) => {
    // setDisableSaveBtn(false);
    if (Number.isInteger(e.target.value)) {
      const changedValue = e.target.innerText;
      formik.setFieldValue(name, changedValue);
    } else {
      formik.setFieldValue(name, e.target.value);
    }
  };

  const handleDescrease = () => {
    formik.setFieldTouched('experienceYears', true);
    const currentExperience = parseInt(experienceYears) || 0;
    formik.setFieldValue('experienceYears', currentExperience - 1);
  };

  const handleIncrease = () => {
    formik.setFieldTouched('experienceYears', true);
    const currentExperience = parseInt(experienceYears) || 0;
    formik.setFieldValue('experienceYears', currentExperience + 1);
  };

  const RenderExp = () => (
    <div className="exp-buttons">
      <img alt="" src={decresae} onClick={handleDescrease} />
      <img alt="" src={increase} onClick={handleIncrease} />
    </div>
  );

  return (
    <StyledJobDetail>
      <div className="page-wrap">
        <div className="page-header">Job Details</div>
        <div className="content-wrap">
          <div className="first-section">
            <Grid
              container
              columnSpacing={2.5}
              rowSpacing={2}
              className="input-fields-wrap"
              alignItems="start">
              <Grid item md={6} xs={12}>
                <AutoComplete
                  name="jobRole"
                  type="text"
                  label="Job Role"
                  placeholderValue="Select Role"
                  important
                  showTooltip={false}
                  onFocus={() => setSearchField('job-role')}
                  optionsValue={dropDownOptions?.map((obj) => obj?.name) || []}
                  changeAutoComplete={(e) => {
                    setSearchField('job-role');
                    setSearch(e.target.value);
                    customHandleChange(e, 'jobRole');
                  }}
                  onBlur={handleBlur}
                  value={jobRole}
                  error={tucJobRole && !!errJobRole}
                  helperText={tucJobRole && errJobRole}
                  icon={<ExpandMoreIcon />}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AutoComplete
                  name="industry"
                  type="text"
                  label="Industry"
                  placeholderValue="Select Industry"
                  important
                  showTooltip={false}
                  onFocus={() => setSearchField('industry')}
                  optionsValue={dropDownOptions?.map((obj) => obj?.name) || []}
                  changeAutoComplete={(e) => {
                    setSearchField('industry');
                    setSearch(e.target.value);
                    customHandleChange(e, 'industry');
                  }}
                  onBlur={handleBlur}
                  value={industry}
                  error={tucIndustry && !!errIndustry}
                  helperText={tucIndustry && errIndustry}
                  icon={<ExpandMoreIcon />}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AutoComplete
                  name="domain"
                  type="text"
                  label="Domain"
                  important
                  placeholderValue="Select Domain"
                  showTooltip={false}
                  onFocus={() => setSearchField('domain')}
                  optionsValue={dropDownOptions?.map((obj) => obj.name) || []}
                  changeAutoComplete={(e) => {
                    setSearchField('domain');
                    setSearch(e.target.value);
                    customHandleChange(e, 'domain');
                  }}
                  onBlur={handleBlur}
                  value={domain}
                  error={tucDomain && !!errDomain}
                  helperText={tucDomain && errDomain}
                  icon={<ExpandMoreIcon />}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  name="experienceYears"
                  type="tel"
                  label="Year(s) Of Experience"
                  important
                  showTooltip={false}
                  onChange={(e) => {
                    if (/^\d*$/.test(e.target.value)) {
                      customHandleChange(e, 'experienceYears');
                    }
                  }}
                  onBlur={handleBlur}
                  value={experienceYears}
                  error={tucExperienceYears && !!errExperienceYears}
                  helperText={tucExperienceYears && errExperienceYears}
                  icon={<RenderExp />}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  name="linkJobPortal"
                  type="text"
                  label="Link To Job On Portal"
                  placeholderValue="Paste portal link if any"
                  showTooltip={false}
                  onChange={(e) => customHandleChange(e, 'linkJobPortal')}
                  onBlur={handleBlur}
                  value={linkJobPortal}
                  error={tucLinkJobPortal && !!errLinkJobPortal}
                  helperText={tucLinkJobPortal && errLinkJobPortal}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <MultiselectAutoComplete
                  name="skills"
                  type="text"
                  label="Skills"
                  important
                  placeholder="Enter Skills (e.g. Business Analyst)"
                  optionsValue={skillOptions?.map((obj) => obj?.name) || []}
                  value={skills}
                  onBlur={handleBlur}
                  onChange={(newSkill) => {
                    formik.setFieldValue('skills', newSkill);
                  }}
                  selectedValues={skills}
                  onInputChange={(value) => setSearch(value)}
                  error={tucSkills && !!errSkills}
                  helperText={tucSkills && errSkills}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  placeholderValue="Write more about your job"
                  name="experienceDescription"
                  type="text"
                  multiline
                  minRows={2}
                  maxRows={25}
                  label="Job description"
                  important
                  onChange={(e) => handleChange(e, 'experienceDescription')}
                  onBlur={handleBlur}
                  value={experienceDescription}
                  error={tucExperienceDescription && !!errExperienceDescription}
                  helperText={tucExperienceDescription && errExperienceDescription}
                  icon={`${experienceDescription?.length ? experienceDescription.length : 0}/3500`}
                  wordCount
                  inputProps={{ maxLength: 3501 }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <RecruiterPostJobButtons
        showBackButton
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={() => submitHandler(formik.values)}
        disableNext={!formik.isValid}
      />
    </StyledJobDetail>
  );
};

export default JobDetails;
