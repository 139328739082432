import { Radio } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useRazorpay from 'react-razorpay';
import { resetPostJob } from '../../../../store/reducers/recruiterPostJob';
import { ROUTE_PATH } from '../../../../constants/route';
import { ToastNotifySuccess } from '../../../../components/Toast/ToastNotify';
import {
  createRecruiterJob,
  getRecruiterJob,
} from '../../../../store/reducers/recruiterPostJob/apiThunk';
import RecruiterPostJobButtons from '../../../../components/RecruiterPostJobButtons';
import StyledSubscription from './StyledSubscription';
import { cretaeSubscription, fetchSubscriptionPlans } from '../../../../store/reducers/fractionTalent/apiThunk';
import { fetchGetRecruiterProfile } from '../../../../store/reducers/authentication/apiThunk';

const { POSTAJOB } = ROUTE_PATH;

function Subscription({ stepNumber = 4, setActiveStep }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const [finalData, setFinalData] = useState({});
  const [planList, setPlanList] = useState([]);
  const [planId, setPlanId] = useState(null);
  const data = useSelector((state) => state?.recruiterPostJob);

  const getSubscriptionPlans = async () => {
    const response = await dispatch(fetchSubscriptionPlans()).then((val) => val);
    const { payload: { data: { recruiterPlans = [] } } = {} } = response;
    setPlanList(recruiterPlans);
  };

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  useEffect(() => {
    if (data) {
      const mergedData = {
        ...data.recruiterBasic,
        ...data.recruiterJobDetails,
        ...data.recruiterLocation,
        ...data.recruiterAvailability,
      };
      setFinalData(mergedData);
    }
  }, [data]);

  const plans = [
    {
      plan: 'Plan',
      Amount: 5000,
      planDuration: '/ 3 month',
      offer: '🎉 First Month Free',
    },
  ];

  const submitHandler = async () => {
    const response = await dispatch(createRecruiterJob(finalData));
    if (response?.payload?.status) {
      dispatch(resetPostJob());
      ToastNotifySuccess('Job Posted Successfully');
      const { _id: jobId } = response.payload.data;
      localStorage.setItem('jobId', jobId);
      await dispatch(getRecruiterJob());
      navigate(POSTAJOB);
    }
  };

  const razorPayHandler = async (subscriptionId) => {
    if (subscriptionId) {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        currency: 'INR',
        name: 'Super Connects',
        subscription_id: subscriptionId,

        handler(response) {
          if (response.razorpay_payment_id) {
            dispatch(fetchGetRecruiterProfile());
            submitHandler();
            ToastNotifySuccess('Subscription purchased successfully');
          }
        },
        theme: {
          color: '#1A1E22',
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on('payment.failed', (response) => {
        if (response.error.reason === 'payment_failed') {
          ToastNotifySuccess('Payment_failed');
        }
      });

      rzp1.open();
    }
  };

  const selectPlanHandler = async () => {
    if (planId) {
      const postData = { planId };
      const response = await dispatch(cretaeSubscription(postData)).then((val) => val);
      const { payload: { data: { subscriptionId = '' } } = {} } = response;
      if (subscriptionId) {
        await razorPayHandler(subscriptionId);
        setPlanId(null);
      }
    }
  };

  return (
    <StyledSubscription>
      <div className="main-container">
        <div className="subscard-wrap">
          <div className="subscard-wrap-top">
            <div className="heading">For accessing the database of Fractional Professionals and posting a job, each job post will remain valid for 90 days.</div>
          </div>
          <div className="divider" />
          <div className="plan">
            <div className="plan-heading">Subscription plan</div>
            <div className="plan-cards">
              {plans?.map((plan) => (
                <div className="plan-card">
                  <div className="select-plan">
                    <div className="plan-duration">{plan?.plan}</div>
                    <Radio color="primary" className="plan-radio" onClick={() => setPlanId(planId ? null : planList[0]?.id)} />
                  </div>
                  <div className="plan-amount">
                    ₹ {plan?.Amount}<span>{plan?.planDuration}</span>
                  </div>
                  <div className="offer-strip">{plan?.offer}</div>
                </div>
              ))}
              <div className="btns">
                <RecruiterPostJobButtons
                  pay
                  showBackButton
                  disableNext={!planId}
                  stepNumber={stepNumber}
                  setActiveStep={setActiveStep}
                  handleSubmit={() => selectPlanHandler()}
            />
              </div>
            </div>
          </div>
        </div>
        <div className="faq-wrap">
          <div className="title">FAQs</div>
          <div className="accordion-wrap">
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#0D141C' }} />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">Charges and Validity !!</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Rs 5000 for accessing the data base of Fractional Professionals and Posting a Job.
                Each Job Post is Valid for 90 days. There is no recurring fee or monthly payout.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography component="span">How is this platform able to maintain quality ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Our platform charges Fractional Professionals a monthly subscription
                to keep an active Profile.This ensures the quality of Professionals on the platform.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel2-header"
              >
                <Typography component="span">Do you help us fill the position?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                We help you fill the position by putting your requirement in front of right
                fractional professionals. If we do not have the right talent, then we post
                it on our social media handles for extended reach.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel2-header"
              >
                <Typography component="span">Who signs the job contract ?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                The contract job is between you and the fractional professional.
                We only help in matching the requirement.
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </StyledSubscription>
  );
}

export default Subscription;
