// /* eslint-disable arrow-body-style */
// import React, { useEffect, useState } from 'react';
// import { Grid } from '@mui/material';
// import { useDispatch } from 'react-redux';
// import { useNavigate, useParams } from 'react-router-dom';
// import StyledSummaryPage from './StyledSummaryPage';
// import PrivateLayout from '../../../layout/PrivateLayout';
// import { getRecruiterJobDetails } from '../../../store/reducers/recruiterPostJob/apiThunk';
// import { ROUTE_PATH } from '../../../constants/route';
// import { BorderButton, PrimaryButton } from '../../../components/Button';
// import PhotoIcon from '../../../components/icons/PhotoIcon';

// const { POSTAJOB } = ROUTE_PATH;

// const SummaryPage = () => {
//   const { jobId } = useParams();
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [data, setData] = useState();
//   const [isExpanded, setIsExpanded] = useState(false);

//   const getData = async (id) => {
//     const response = await dispatch(getRecruiterJobDetails(id));
//     if (response?.payload) {
//       setData(response?.payload?.data);
//     }
//   };

//   useEffect(() => {
//     getData(jobId);
//   }, []);

//   const durationCheck = (item) => {
//     const isPlural = data?.availabilityPeriod > 1;
//     switch (item) {
//       case 'daily':
//         return isPlural ? 'Days' : 'Day';
//       case 'weekly':
//         return isPlural ? 'Weeks' : 'Week';
//       default:
//         return isPlural ? 'Months' : 'Month';
//     }
//   };
//   const toggleDescription = () => {
//     setIsExpanded((prev) => !prev);
//   };

//   const downloadLogo = async (url) => {
//     try {
//       const response = await fetch(url);
//       const blob = await response.blob();
//       const downloadUrl = URL.createObjectURL(blob);
//       const link = document.createElement('a');
//       link.href = downloadUrl;
//       link.download = 'company_logo.png';
//       link.click();
//       URL.revokeObjectURL(downloadUrl);
//     } catch (error) {
//       console.error('Failed to download the logo:', error);
//     }
//   };

//   const capitalizeWorkPreference = (workPreference) => {
//     if (!workPreference) return '';
//     if (workPreference === 'onSite') {
//       return 'On-Site';
//     }
//     return workPreference.charAt(0).toUpperCase() + workPreference.slice(1);
//   };

//   return (
//     <StyledSummaryPage>
//       <div className="summary-wrap">
//         <div className="summary-sections">
//           <div className="left-section">
//             <div className="left-heading">Job Details Summary</div>
//             <Grid
//               container
//               columnSpacing={1.5}
//               rowSpacing={3}
//               className="summay-details-wrap"
//               alignItems="start">
//               <Grid item md={4} xs={12}>
//                 <div className="each-head">Company Name</div>
//                 <div className="each-body">{data?.companyName}</div>
//               </Grid>
//               <Grid item md={4} xs={12}>
//                 <div className="each-head">Company's Type</div>
//                 <div className="each-body">
//                   {data?.companyType
//                     ? data.companyType.charAt(0).toUpperCase() + data.companyType.slice(1)
//                     : ''}
//                 </div>
//               </Grid>
//               <Grid item md={4} xs={12}>
//                 <div className="each-head">Industry</div>
//                 <div className="each-body">{data?.industry}</div>
//               </Grid>
//               <Grid item md={4} xs={12}>
//                 <div className="each-head">Job Role</div>
//                 <div className="each-body">{data?.jobRole}</div>
//               </Grid>
//               <Grid item md={4} xs={12}>
//                 <div className="each-head">Domain</div>
//                 <div className="each-body">{data?.domain}</div>
//               </Grid>
//               <Grid item md={4} xs={12}>
//                 <div className="each-head">Years of Experience Required</div>
//                 <div className="each-body">{data?.jobExperience}</div>
//               </Grid>
//               <Grid item md={12} xs={12}>
//                 <div className="details-heading">Company’s Website</div>
//                 <div className="link">
//                   {' '}
//                   <a rel="noreferrer" target="_blank" href={data?.companyWebsite}>
//                     {data?.companyWebsite}
//                   </a>
//                 </div>
//               </Grid>
//               <Grid item md={12} xs={12}>
//                 <div className="details-heading">Link To Job On Portal</div>
//                 <div className="link">
//                   <a rel="noreferrer" target="_blank" href={data?.linkedinProfile}>
//                     {data?.linkedinProfile}
//                   </a>
//                 </div>
//               </Grid>
//               <Grid item md={12} xs={12}>
//                 <div className="details-heading">Skills</div>
//                 <div className="details-skills">
//                   {data?.skills?.map((item) => {
//                     return <div className="each-skill">{item}</div>;
//                   })}
//                 </div>
//               </Grid>
//               <Grid item md={12} xs={12}>
//                 <div className="details-heading">Job Description</div>
//                 <div className="details-description">
//                   {isExpanded || !data?.jobDescription
//                     ? data?.jobDescription
//                     : data?.jobDescription.length > 100
//                       ? `${data?.jobDescription.slice(0, 100)}...`
//                       : data?.jobDescription}
//                   {data?.jobDescription?.length > 100 && (
//                     <span onClick={toggleDescription}
// style={{ color: 'blue', cursor: 'pointer' }}>
//                       {isExpanded ? '' : ' See more'}
//                     </span>
//                   )}
//                 </div>
//               </Grid>
//               {data?.companyLogo && (
//                 <div
//                   className="upload-file"
//                   // prettier-ignore
//                   onClick={() =>
// downloadLogo(`${process.env.REACT_APP_IMAGE_URL}/${data?.companyLogo}`)}>
//                   <PhotoIcon />
//                   <div className="file-name">Company's Logo</div>
//                 </div>
//               )}
//             </Grid>
//           </div>
//           <div className="right-section">
//             <div className="personal-information">
//               <div className="p-heading">Personal Information</div>
//               <div className="each-head">Your Designation</div>
//               <div className="each-body" style={{ marginBottom: '10px' }}>
//                 {data?.designation}
//               </div>
//               <div className="each-head">Contact</div>
//               <div className="each-body">{data?.phoneNumber}</div>
//             </div>
//             <div className="charges">
//               <div className="c-heading">Charges & Preference</div>
//               <div className="charge">{`₹ ${data?.compensationRate}`}</div>
//               <div className="charge-duration" style={{ marginBottom: '10px' }}>
//                 {data?.compensationType}
//               </div>
//               <div className="each-head">Job Type</div>
//               <div className="each-body" style={{ marginBottom: '10px' }}>
//                 {capitalizeWorkPreference(data?.locationType)}
//               </div>
//               <div className="each-head">Job Location</div>
//               <div className="each-body">{data?.jobCity}</div>
//             </div>
//             <div className="availability">
//               <div className="a-heading">Availability</div>
//               <div className="each-head">Available</div>
//               <div className="each-body" style={{ marginBottom: '10px' }}>
//                 {durationCheck(data?.availableFor)}
//               </div>
//               <div className="each-head">{`Active ${durationCheck(data?.availableFor)}`}</div>
//               <div className="each-body">{data?.availabilityPeriod}</div>
//             </div>
//           </div>
//         </div>
//         <div className="summary-btn">
//           <div className="btn-back">
//             <BorderButton onClick={() => navigate(POSTAJOB)}>Back</BorderButton>
//           </div>
//           <div className="btn-post" onClick={() => navigate(POSTAJOB)}>
//             <PrimaryButton>Post Job</PrimaryButton>
//           </div>
//         </div>
//       </div>
//     </StyledSummaryPage>
//   );
// };

// export default PrivateLayout(SummaryPage);

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import Grid from '@mui/material/Grid';
import moment from 'moment-timezone';
// import { selectorAuthentication } from '../../../store/reducers/authentication';
import { fetchApplyJob, fetchJobDetails } from '../../../store/reducers/fractionTalent/apiThunk';
import StyledJobDetailsPage from '../../jobs/jobDetails/StyledJobDetailsPage';
import { checkCompensationType, durationCheck, stringAvatar } from '../../../helper';
import { PrimaryButton } from '../../../components/Button';
import backArrow from '../../../assets/svg/jobdetail/jobdetail-back-arrow.svg';
import backArrowMobile from '../../../assets/svg/jobdetail/arrow-left.svg';
import share from '../../../assets/svg/share-circle.svg';
// import save from '../../../assets/svg/save-circle.svg';
// import saved from '../../../assets/svg/savedBlue.svg';
import arrowIcon from '../../../assets/svg/jobdetail/jobdetail-arrow.svg';
import industryIcon from '../../../assets/svg/jobdetail/jobdetail-industry.svg';
import domainIcon from '../../../assets/svg/jobdetail/jobdetail-domain.svg';
import experienceIcon from '../../../assets/svg/jobdetail/jobdetail-experience.svg';
import locationIcon from '../../../assets/svg/jobdetail/jobdetail-location.svg';
import coinIcon from '../../../assets/svg/jobdetail/jobdetail-coin.svg';
import infoIcon from '../../../assets/svg/jobdetail/jobdetail-Info.svg';
import calendarIcon from '../../../assets/svg/jobdetail/jobdetail-calendar.svg';
import tickIcon from '../../../assets/svg/jobdetail/jobdetail-tickcircle.svg';
import TickCircle from '../../../components/icons/TickCircle';
import companyIcon from '../../../assets/svg/jobdetail/jobdetail-company.svg';
import locationSmallIcon from '../../../assets/svg/jobdetail/jobdetail-location-small.svg';
import smsIcon from '../../../assets/svg/jobdetail/jobdetail-sms.svg';
import userIcon from '../../../assets/svg/jobdetail/jobdetail-user.svg';
import websiteIcon from '../../../assets/svg/jobdetail/jobdetail-website.svg';
import LinkdinIcon from '../../../assets/svg/jobdetail/jobdetail-linkdin.svg';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import CustomTooltip from '../../../components/Tooltip/Tooltip';
import PrivateLayout from '../../../layout/PrivateLayout';
import JobShareModal from '../../jobs/jobsOverview/JobShareModal';
import StyledJobsOverview from '../../jobs/jobsOverview/StyledJobsOverview';

const SummaryPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [jobId, setJobId] = useState(null);
  const [shareId, setShareId] = useState(null);
  const [openDialogShare, setOpenDialogShare] = useState(false);
  const [jobDetails, setJobDetails] = useState({});

  const handleCloseShareDialog = () => {
    setOpenDialogShare(false);
  };
  const handleOpenShareDialog = () => {
    setShareId(jobId);
    setOpenDialogShare(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Get the query parameter values
    if (location.search) {
      setJobId(params.get('job-id'));
    }
  }, [location]);

  const getJobDetails = async () => {
    try {
      const query = `${jobId}`;
      const response = await dispatch(fetchJobDetails(query));

      const {
        payload: { data = {}, status = false },
      } = response;

      if (status) {
        setJobDetails(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getJobDetails();
  }, [jobId]);

  // const handleSaveJob = async (item, isAlreadySaved) => {
  //   const { _id: id } = item;
  //   const data = {
  //     jobId: id,
  //     save: isAlreadySaved,
  //   };
  //   const response = await dispatch(fetchSaveJob(data));
  //   const { payload: { status = false } = {} } = response;
  //   if (status) {
  //     ToastNotifySuccess('Job status (saved/unsaved) updated successfully.');
  //     await getJobDetails();
  //   }
  // };

  const handleJobApply = async () => {
    const response = await dispatch(fetchApplyJob(jobId));
    const { payload: { status = false } = {} } = response;
    if (status) {
      ToastNotifySuccess('You have applied to the job succesfully!');
      await getJobDetails();
    }
  };

  const capitalizeWorkPreference = (workPreference) => {
    if (!workPreference) return '';
    if (workPreference === 'onSite') {
      return 'On-Site';
    }
    return workPreference.charAt(0).toUpperCase() + workPreference.slice(1);
  };

  const JobDetaisInfo = ({ src, heading, detail }) => (
    <div>
      <div className="info-wrap">
        <img className="info-icon" src={src} alt="icon" />
        <div>
          <div className="info-heading">
            {heading}
          </div>
          <div className="info-detail">
            {detail}
          </div>
        </div>
      </div>
      <div className="info-wrap-mobile">
        <div className="info-heading">
          <img className="info-icon" src={src} alt="icon" />
          <span>
            {heading}
          </span>
        </div>
        <div className="info-detail">
          {detail}
        </div>
      </div>
    </div>
  );

  const JobInfoCard = ({ heading, content }) => (
    <div className="info-card-wrap">
      <div className="info-heading">{heading}</div>
      <div>{content}</div>
    </div>
  );

  const handleBack = () => {
    navigate(-1);
  };

  if (!jobDetails) return;

  return (
    <StyledJobDetailsPage>
      <div className="page-wrap">
        <div className="page-title-mobile-wrap">
          <div className="content">
            <div className="left"> <img alt="arrow" src={backArrowMobile} onClick={() => handleBack()} /> <span>Job Details</span></div>
            <div className="right">
              <img className="icon-image" src={share} alt="" onClick={() => handleOpenShareDialog()} />
              {/* <img
                className="icon-image"
                src={jobDetails?.isJobSaved ? saved : save}
                alt=""
                onClick={() => (jobDetails?.isJobSaved ?
                  handleSaveJob(jobDetails, false)
                  : handleSaveJob(jobDetails, true))}
                  /> */}
            </div>
          </div>
        </div>
        <div className="page-title"><img alt="arrow" src={backArrow} onClick={() => handleBack()} /> <span>Job Details</span></div>
        <div className="job-details-wrap">
          <div className="top-space" />
          <div className="job-details-content-wrap">
            <div className="job-header">
              <div className="left">
                <div>
                  {jobDetails?.companyLogo ?
                    <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${jobDetails?.companyLogo}`} alt="" />
                    : <Avatar {...stringAvatar(jobDetails?.companyName)} />}
                </div>
                <div className="commpany-info">
                  <div className="job-title">{jobDetails?.jobRole}
                    {jobDetails?.portalLink ?
                      <CustomTooltip title="Check the job posting on company’s portal" maxWidth="148px">
                        <img src={arrowIcon} alt="" onClick={() => window.open(jobDetails.portalLink, '_blank', 'rel=noopener noreferrer')} />
                      </CustomTooltip>
                      : null}
                  </div>
                  <div className="job-industry"> <img src={industryIcon} alt="" /> {jobDetails?.companyName}</div>
                </div>
              </div>
              <div className="right">
                <div>
                  <div className="date-title">Date Posted</div>
                  <div className="date-desc">{moment(jobDetails.createdAt).format('DD MMMM YYYY')}</div>
                </div>
                <img className="icon-image" src={share} alt="" onClick={() => handleOpenShareDialog()} />
                {/* <img
                  className="icon-image"
                  src={jobDetails?.isJobSaved ? saved : save}
                  alt=""
                  onClick={() => (jobDetails?.isJobSaved ?
                    handleSaveJob(jobDetails, false)
                    : handleSaveJob(jobDetails, true))}
                  /> */}
                {/* <PrimaryButton
                  disabled={jobDetails?.isApplied}
                  onClick={handleJobApply}
                  startIcon={<TickCircle />}
                  sx={{
                    width: {
                      xs: '134px',
                      sm: '134px',
                    },
                    '&:disabled': {
                      backgroundColor: '#00AF31',
                    } }}>
                  {jobDetails?.isApplied ? 'Applied' : 'Apply Job' }
                </PrimaryButton> */}
              </div>
            </div>
            <div className="job-header-mobile">
              <div className="left">
                <div>
                  {jobDetails?.companyLogo ?
                    <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${jobDetails?.companyLogo}`} alt="" />
                    : <Avatar {...stringAvatar(jobDetails?.companyName)} />}
                </div>
                <div className="commpany-info">
                  <div className="job-title">{jobDetails?.jobRole}
                    {!jobDetails?.portalLink ?
                      <CustomTooltip title="Check the job posting on company’s portal" maxWidth="148px">
                        <img src={arrowIcon} alt="" onClick={() => window.open(jobDetails.portalLink, '_blank', 'rel=noopener noreferrer')} />
                      </CustomTooltip>
                      : null}
                  </div>
                  <div className="job-industry"> <img src={industryIcon} alt="" /> {jobDetails?.companyName}</div>
                  <div className="date-title">Date Posted :<span className="date-desc">{moment(jobDetails.createdAt).format('DD MMMM YYYY')}</span></div>
                </div>
              </div>
            </div>
            <Grid container columnSpacing={2}>
              <Grid item md={8.5} xs={12}>
                <div className="section-title">Job Details</div>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={experienceIcon} heading="Experience Required" detail={`${jobDetails?.jobExperience} year(s)`} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={domainIcon} heading="Domain" detail={jobDetails?.domain} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={industryIcon} heading="Industry" detail={jobDetails?.industry} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={locationIcon} heading="Job Location" detail={`${jobDetails?.jobCity}, ${jobDetails?.jobState}`} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={industryIcon} heading="Job Type" detail={capitalizeWorkPreference(jobDetails?.locationType)} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={locationIcon} heading="Preferred Job Location" detail={jobDetails?.preferredJobLocation} />
                  </Grid>
                  <div className="divider" />
                  <Grid item xs={12}>
                    <div className="about-wrap">
                      <div className="about-heading">Job Description</div>
                      <div className="about-detail">{jobDetails?.jobDescription}</div>
                    </div>
                  </Grid>
                  {/* {!jobDetails?.isApplied ?
                    <Grid mt={2} pb={4}>
                      <PrimaryButton
                        disabled={jobDetails.isApplied}
                        startIcon={<TickCircle />}
                        sx={{
                          width: {
                            xs: '134px',
                            sm: '134px',
                          },
                        }}>
                        Apply Job
                      </PrimaryButton>
                    </Grid> : null} */}
                </Grid>
              </Grid>
              <Grid item md={3.5} xs={12} mt={4}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <JobInfoCard
                      heading="Charges"
                      content={
                        <div className="charges-wrap">
                          <div className="price">
                            <img className="info-icon" src={coinIcon} alt="icon" /> <span>₹{jobDetails?.compensationRate}</span>
                          </div>
                          <div className="fees"> Fees per {`${checkCompensationType(jobDetails?.compensationType)}`}</div>
                          <div className="negotiable"> <img className="info-icon" src={infoIcon} alt="icon" /> <span>This price is </span><span className="blue-text">{`${jobDetails?.isNegotiable ? 'Negotiable' : 'Non-Negotiable'}!`}</span></div>
                        </div>
                    } />
                  </Grid>
                  <Grid item xs={12}>
                    <JobInfoCard
                      heading="Availability"
                      content={
                        <div className="availability-wrap">
                          <div className="availability-heading">
                            <img className="info-icon" src={tickIcon} alt="icon" /> <span>Available for</span>
                          </div>
                          <div className="availability-detail">
                            {durationCheck(jobDetails?.availableFor, jobDetails?.availabilityPeriod)}
                          </div>
                          <div className="mt-16">
                            <div className="availability-heading">
                              <img className="info-icon" src={calendarIcon} alt="icon" /> <span>Active Days in a Month</span>
                            </div>
                            <div className="availability-detail">
                              {jobDetails?.availabilityPeriod}
                            </div>
                          </div>
                        </div>
                    } />
                  </Grid>
                  <Grid item xs={12}>
                    <JobInfoCard
                      heading="About Company"
                      content={
                        <div className="company-wrap">
                          <div>
                            <div className="company-heading">
                              <img className="info-icon" src={userIcon} alt="icon" /> <span>Designation</span>
                            </div>
                            <div className="company-detail">
                              {jobDetails?.designation}
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={smsIcon} alt="icon" /> <span>Contact Details</span>
                            </div>
                            <div className="company-detail contact">
                              {jobDetails?.phoneNumber}
                              <a
                                className="job-links"
                                href={jobDetails?.linkedinProfile}
                                target="_blank"
                                rel="noreferrer">
                                <img className="linkdin-icon" src={LinkdinIcon} alt="icon" />
                              </a>
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={locationSmallIcon} alt="icon" /> <span>Company Address</span>
                            </div>
                            <div className="company-detail">
                              {jobDetails?.companyAddress}
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={companyIcon} alt="icon" /> <span>Company Type</span>
                            </div>
                            <div className="company-detail">
                              {jobDetails?.companyType}
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={websiteIcon} alt="icon" /> <span>Website</span>
                            </div>
                            <div className="company-detail">
                              <a
                                className="job-links"
                                href={jobDetails?.companyWebsite}
                                target="_blank"
                                rel="noreferrer">
                                {jobDetails?.companyWebsite}
                              </a>
                            </div>
                          </div>
                        </div>
                    } />
                  </Grid>
                  <Grid item xs={12}>
                    <JobInfoCard
                      heading="Skills Required"
                      content={
                        <div className="skill-wrap">
                          {jobDetails?.skills?.map((skill) => (
                            <span key={skill} className="chips">
                              {skill}
                            </span>
                          ))}
                        </div>
                    } />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="action-buttons">
          <PrimaryButton
            disabled={jobDetails?.isApplied}
            onClick={handleJobApply}
            startIcon={jobDetails?.isApplied ? <TickCircle /> : null}
            sx={{
              '&:disabled': {
                backgroundColor: '#00AF31',
              } }}>
            {jobDetails?.isApplied ? 'Applied' : 'Apply Job' }
          </PrimaryButton>
        </div>
      </div>
      <StyledJobsOverview>
        <JobShareModal
          openDialog={openDialogShare}
          closeDialog={handleCloseShareDialog}
          id={shareId}
        />
      </StyledJobsOverview>
    </StyledJobDetailsPage>
  );
};

export default PrivateLayout(SummaryPage);
