import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthorization } from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const getRecruiterDashboardProfile = createAsyncThunk(
  'recruiterProfile/dashboard',
  async (_, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.GET_RECRUITER_DASHBOARD_PROFILE);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const getRecruiterJobStatus = createAsyncThunk(
  'recruiterJobProfile/status',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    const { id, postData } = data;
    try {
      dispatch(updateLoading(true));
      const response = await API.patch(API_PATH.RECRUITER_CHANGE_JOB_STATUS + id, postData);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const getContactRecruiter = createAsyncThunk(
  'recruiterJobProfile/contact',
  async (id, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.RECRUITER_CONTACT + id);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const getDownloadResume = createAsyncThunk(
  'recruiterJobProfile/resume',
  async (id, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.RECRUITER_DETAILS_RESUME + id);
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  },
);

export {
  getRecruiterDashboardProfile,
  getRecruiterJobStatus,
  getContactRecruiter,
  getDownloadResume,
};
