import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { usePlacesWidget } from 'react-google-autocomplete';
import LandingButtons from '../../../components/LandingButtons';
import StyledLocation from './StyledLocation';
import AutoComplete from '../../../components/AutoComplete';
import StyledRadioGroup from '../../../components/Common/AccountsDetailsModal/StyledRadioGroup';
import { createJobProfile, fetchJobProfile } from '../../../store/reducers/fractionTalent/apiThunk';
import { selectorFractionTalent } from '../../../store/reducers/fractionTalent';
import { validationSchemaLocation } from '../../../utils/validations/authValidation';
import LocationAutocomplete from '../../../components/LocationAutocomplete';

function Location({ stepNumber = 0, setActiveStep }) {
  const dispatch = useDispatch();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [disableState, setDisableState] = useState(true);
  const workPreferenceOptions = [
    { value: 'remote', label: 'Remote' },
    { value: 'onSite', label: 'On-site' },
    { value: 'hybrid', label: 'Hybrid' },
  ];
  const { fractionTalent: { data: talentProfile } = {} } = useSelector(selectorFractionTalent);

  const submitHandler = async (values) => {
    const { city, state, workPreference, preferredJobLocation } = values;
    const data = {
      formStep: 2,
      city,
      state,
      workPreference,
      preferredJobLocation,
    };
    await dispatch(createJobProfile(data));
    dispatch(fetchJobProfile());
    setDisableSaveBtn(true);
  };

  const formik = useFormik({
    initialValues: {
      city: '',
      state: '',
      workPreference: talentProfile?.workPreference || 'remote',
      preferredJobLocation: '',
    },
    validationSchema: validationSchemaLocation,
    onSubmit: submitHandler,
    enableReinitialize: true,
  });

  const {
    handleBlur,
    values: {
      city,
      state,
      workPreference,
      preferredJobLocation } = {},

    touched: {
      city: tucCity,
      state: tucState,
      preferredJobLocation: tucPreferredJobLocation,
    } = {},
    errors: {
      city: errCity,
      state: errState,
      preferredJobLocation: errPreferredJobLocation } = {},
  } = formik;

  const customHandleChange = (e, name) => {
    setDisableSaveBtn(false);
    if (Number.isInteger(e.target.value)) {
      const changedValue = e.target.innerText;
      formik.setFieldValue(name, changedValue);
    } else {
      formik.setFieldValue(name, e.target.value);
    }
  };

  useEffect(() => {
    if (talentProfile) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        city: talentProfile.city || '',
        state: talentProfile.state || '',
        workPreference: talentProfile.workPreference || 'remote',
        preferredJobLocation: talentProfile.preferredJobLocation || '',
      }));
    }
  }, [talentProfile]);

  const handleRadioChange = (newValue) => {
    formik.setFieldValue('workPreference', newValue);
  };

  useEffect(() => {
    if (workPreference === 'remote') {
      formik.setFieldValue('preferredJobLocation', '');
      delete formik.errors.preferredJobLocation;
      formik.setErrors({ ...formik.errors });
    }
  }, [workPreference]);

  const { ref: cityRef } = usePlacesWidget({
    apiKey: 'AIzaSyBfndC_Qxf9tApzas17Jrmpttahz1fMdFY',
    onPlaceSelected: (place) => {
      const selectedCity = place?.address_components?.find((comp) => comp.types.includes('locality'))?.long_name || '';
      const selectedState = place?.address_components?.find((comp) => comp.types.includes('administrative_area_level_1'))?.long_name || '';
      // Update form fields
      formik.setFieldValue('city', selectedCity);
      formik.setFieldValue('state', selectedState);
      setDisableState(true);
    },
  });

  const { ref: perfRef } = usePlacesWidget({
    apiKey: 'AIzaSyBfndC_Qxf9tApzas17Jrmpttahz1fMdFY',
    onPlaceSelected: (place) => {
      const selectedCity = place?.address_components?.find((comp) => comp.types.includes('locality'))?.long_name || '';
      formik.setFieldValue('preferredJobLocation', selectedCity);
    },
  });

  return (
    <StyledLocation>
      <div className="page-wrap">
        <div className="header-wrap">Location Preferences</div>
        <div className="sub-header-wrap">Current Location</div>
        <div className="content-wrap">
          <Grid
            container
            columnSpacing={2}
            rowSpacing={0}
          >
            <Grid item md={4.5} xs={12}>
              <LocationAutocomplete
                ref={cityRef}
                name="city"
                type="text"
                label="City"
                important
                onChange={(e) => { customHandleChange(e, 'city'); setDisableState(false); }}
                onBlur={handleBlur}
                value={city}
                defaultValue={city}
                error={tucCity && !!errCity}
                helperText={tucCity && errCity}
              />
            </Grid>
            <Grid item md={4.5} xs={12}>
              <AutoComplete
                disabled={disableState}
                name="state"
                type="text"
                label="State"
                important
                changeAutoComplete={(e) => customHandleChange(e, 'state')}
                onBlur={handleBlur}
                value={state}
                error={tucState && !!errState}
                helperText={tucState && errState}
              />
            </Grid>
            <Grid item md={7} lg={4.5} xs={12} style={{ textAlign: 'left' }}>
              <div className="input-Label_text label-space">Job type*</div>
              <StyledRadioGroup
                options={workPreferenceOptions}
                value={workPreference}
                onChange={(e) => handleRadioChange(e)}
              />
            </Grid>
            {/* {workPreference !== 'remote' ? */}
            <Grid item md={4.5} xs={12}>
              <LocationAutocomplete
                disabled={workPreference === 'remote'}
                showTooltip={workPreference === 'remote'}
                tooltip="Job Location is required for hybrid & On-site"
                ref={perfRef}
                name="preferredJobLocation"
                type="text"
                label="Job Location"
                important={workPreference !== 'remote'}
                onChange={(e) => customHandleChange(e, 'preferredJobLocation')}
                onBlur={handleBlur}
                value={preferredJobLocation}
                defaultValue={preferredJobLocation}
                error={tucPreferredJobLocation && !!errPreferredJobLocation}
                helperText={tucPreferredJobLocation && errPreferredJobLocation}
                  />
            </Grid>
            {/* : null} */}
          </Grid>
        </div>
      </div>
      <LandingButtons
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={() => submitHandler(formik.values)}
        disableNext={workPreference === 'remote' ? !(city && state) : !(city && state && preferredJobLocation)}
        disableSave={disableSaveBtn}
        showNextButton
        showBackButton
      />
    </StyledLocation>
  );
}

export default Location;
