/* eslint-disable arrow-body-style */
import React from 'react';

const SubscriptionNavbarIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.22656 11.9422C7.22656 13.0172 8.05156 13.8839 9.07656 13.8839H11.1682C12.0599 13.8839 12.7849 13.1255 12.7849 12.1922C12.7849 11.1755 12.3432 10.8172 11.6849 10.5839L8.32656 9.41719C7.66823 9.18385 7.22656 8.82552 7.22656 7.80885C7.22656 6.87552 7.95156 6.11719 8.84323 6.11719H10.9349C11.9599 6.11719 12.7849 6.98385 12.7849 8.05885"
        stroke="#757575"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5V15"
        stroke="#757575"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5003 18.3346H7.50033C3.33366 18.3346 1.66699 16.668 1.66699 12.5013V7.5013C1.66699 3.33464 3.33366 1.66797 7.50033 1.66797H12.5003C16.667 1.66797 18.3337 3.33464 18.3337 7.5013V12.5013C18.3337 16.668 16.667 18.3346 12.5003 18.3346Z"
        stroke="#757575"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SubscriptionNavbarIcon;
