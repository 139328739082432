import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';

const StyledCalendar = styled(
  'div',
  Dialog,
)(
  ({ theme }) => `
    padding: 40px;
    @media screen and (max-width: 768px){
      padding: 30px;
    }
    @media screen and (max-width: 480px){
      padding: 20px;
    }
    color: ${theme.palette.customColor.primaryBlack};
    .heading {
      color: ${theme.palette.customColor.primaryBlack};
      font-weight: 600;
      line-height: 36px;
      font-size: 24px;
      text-align: left;
    }
    .subtitle {
      color: ${theme.palette.customColor.secondaryGrey};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }
    .rbc-time-view .rbc-time-content .rbc-day-slot .rbc-events-container .rbc-event{
      color: #415DF3;
      background: #1d1d1d;
      font-size: 12.987px;
      font-weight: 700;
      border-left: 1px solid #505050;
      border-bottom: 1px solid #505050;
      border-right: none;
      border-top: none;
    }
    .MuiButtonBase-root{
          padding:12px 10px;
          border-radius:12px;
          font-family: Plus Jakarta Sans;
          font-size: 18px;
          font-weight: 500;
          line-height: 22.68px;
          text-align: left;
        }
  `,
);

export default StyledCalendar;
