import { styled } from '@mui/material/styles';

const StyledDashboard = styled('div')(
  ({ theme }) => `
  .page-wrap {
    padding: 40px;
    width: 100%;

    @media screen and (max-width: 768px) {
      padding: 20px;
    }

    @media screen and (max-width: 481px) {
      padding: 0px;
    }

    .page-stats-wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
      }

      .no-data {
        min-height: 220px;
      }

      .no-data-s {
        min-height: 320px;
      }

      .left-section-wrap {
        width: 75%;

        @media screen and (max-width: 1200px) {
          width: 100%;
        }

        .active-job-wrap {
          background-color: white;
          border-radius: 12px;
          position: relative;
          overflow: hidden;

          .bg-image {
            position: absolute;
            rotate: -36deg;
            bottom: -54px;
            right: -65px;
          }

          .content-wrap {
            .page-warning {
              margin: 5px 20px;
              display: flex;
              gap: 10px;
              align-items: center;
              border: 1px solid ${theme.palette.customColor.stateError};
              border-radius: 10px;
              background-color: ${theme.palette.customColor.primaryPink};
              padding: 5px 10px;
              .warning-text{
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Plus Jakarta Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
           .job-application-card {
              border: 1px solid #E2E4E7;
              border-radius: 12px;
              margin: 20px;
              padding: 20px;
              .active-job-head {
              width: 100%;
              display: flex;
              justify-content: space-between;
                .user {
                display: flex;
                gap: 10px;
                align-items: center;
                .dp-domain{
                .user-work {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Sora";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
                }
                .user-name {
                color: ${theme.palette.customColor.secondaryGrey};
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
                }
                }
                }
                .active-job {
                @media screen and (max-width: 1200px) {
                display: none;
                }
                .date-posted {
                color: ${theme.palette.customColor.grey_7};
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
                }
                .date {
                color: ${theme.palette.customColor.primaryBlack};
                margin-top: 5px;
                font-family: "Plus Jakarta Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-align: left;
                }
                }
              }
                .job-skills{
                  margin-top: 40px;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 10px;
                .user-application{
                width: auto;
                background-color: ${theme.palette.customColor.grey_5};
                padding: 5px 10px;
                border-radius: 12px;
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Plus Jakarta Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                }
}
                .user-info {
                margin-top: 15px;
                display: flex;
                justify-content: space-between;
                align-items: end;
                  .view-details-btn {
                    @media screen and (max-width: 1200px) {
                      display: none;
                    }
                  }
                .location {
                display: flex;
                gap: 10px;
                align-items: center;
                }
                .user-charge{
                display: flex;
                gap: 10px;
                align-items: center;
                }
                .user-availability {
                display: flex;
                gap: 10px;
                align-items: center;
                }
                }
            }
                .mobile-date-view {
                  .active-job-mobile {
                    .date-posted {
                      font-family: "Plus Jakarta Sans";
                      color: ${theme.palette.customColor.grey_7};
                      font-style: normal;
                      font-weight: 600;
                      font-size: 14px;
                      text-align: left;
                      line-height: normal;
                    }
                    .date {
                      color: ${theme.palette.customColor.primaryBlack};
                      margin-top: 5px;
                      font-family: "Plus Jakarta Sans";
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      text-align: left;
                    }
                  }
                    margin-top: 10px;
                    display: none;
                    @media screen and (max-width: 1200px) {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    }
                  }  

            .card-1 {
              padding: 20px;
              .card-heading {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Plus Jakarta Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
              }

              .card-body {
                margin-top: 30px;
                color: ${theme.palette.customColor.secondaryGrey};
                font-family: "Plus Jakarta Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-align: center;
              }
            }
          }
        }

        .suggested-candidates-wrap {
          margin-top: 30px;
          background-color: white;
          border-radius: 12px;
          position: relative;
          overflow: hidden;

          .bg-image {
            position: absolute;
            rotate: -36deg;
            bottom: -54px;
            right: -65px;
          }

          .content-wrap {
            .card-2 {
              padding: 20px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .seeAll-btn{
              display: flex;
              gap: 10px;
              align-items: center;
              cursor: pointer;


              .btn-text{
                cursor: pointer;
               color: ${theme.palette.customColor.primaryBlack};
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
              }
              }
}
              .suggestion-cards{
              display: flex;
              gap: 10px;
                   
              @media screen and (max-width: 1200px) {
                flex-direction: column-reverse;
                width: 94%;
              }
}
              .suggested-candidates-card{
              border: 1px solid #E2E4E7;
              border-radius: 12px;
              margin: 10px;
              padding: 20px;
              width: 100%;
              .card-head {
              display: flex;
              justify-content: space-between;
              align-items: center;
              }
               .user {
                display: flex;
                gap: 10px;
                align-items: center;
                .dp-domain{
                .user-work {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Sora";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
                }
                .user-name {
                color: ${theme.palette.customColor.secondaryGrey};
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
                }
                }
                }
                
              }
                .cross-icon{
                cursor: pointer;
                }
                
              .job-skills-2 {
                  height: 38px;
                overflow-y: auto;
              margin-top: 30px;
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              align-items: center;
              .user-application-2{
              width: auto;
                background-color: ${theme.palette.customColor.grey_5};
                padding: 5px 10px;
                border-radius: 12px;
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Plus Jakarta Sans";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
              }
              }
                 .user-info {
                    margin-top: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: end;
                .location {
                display: flex;
                gap: 10px;
                align-items: center;
                }
                .user-charge{
                display: flex;
                gap: 10px;
                align-items: center;
                }
                .design{
                display: flex;
                gap: 10px;
                align-items: center;
                }
                .education{
                display: flex;
                gap: 10px;
                align-items: center;
                }
                .user-availability {
                display: flex;
                gap: 10px;
                align-items: center;
                }
                .suggestion-view-details-btn{
                color: ${theme.palette.primary.main};
                text-decoration: underline;
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                cursor: pointer;
                }
                }
              .card-heading {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Plus Jakarta Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
              }

              .card-body-suggested {
              width: 100%;
                margin-top: 90px;
                display: flex;
                justify-content: center;
                color: ${theme.palette.customColor.secondaryGrey};
                font-family: "Plus Jakarta Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-align: center;
              }
                
          }
        }
      }

      .right-section-wrap {
        width: 25%;
        padding: 0px 20px;

         @media screen and (max-width: 1200px) {
          width: 100%;
          padding: 0px;
          .post-btn {
            margin: 10px 20px 0px;
          }
        }

        .subs-wrap {
          margin-top: 20px;
          background-color: white;
          border-radius: 12px;
          padding: 20px;
          transition: height 0.3s ease;
          overflow: hidden;
        }
          @media screen and (max-width: 1200px) {
           .subs-wrap {
              margin: 20px 0px;
              height: 59px;
              padding-top: 15px;
            }
           }
           .subs-wrap.expanded {
             height: auto !important;
            }
          .subs-card-wrap {
            .subs-heading {
              color: ${theme.palette.customColor.primaryBlack};
              font-family: "Plus Jakarta Sans";
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-align: left;
            }
            .subs-arrow {
              display: none;
            }
            @media screen and (max-width: 1200px) {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .subs-arrow {
                display: block;
              }  
            }
          }

          .subs-sub-heading {
            margin-top: 10px;
            color: ${theme.palette.customColor.secondaryGrey};
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: left;
          }

          .subs-card {
            margin-top: 10px;
            border: 1px solid ${theme.palette.customColor.grey_6};
            padding: 10px;
            border-radius: 12px;

            .select-plan {
              width: 120px;

              .plan-duration {
                border: 0px solid #415DF333;
                background-color: #415DF333;
                border-radius: 12px;
                color: ${theme.palette.primary.main};
                font-family: "Plus Jakarta Sans";
                font-size: 12px;
                font-weight: 600;
                padding: 4px 8px;
              }
            }

            .plan-amount {
              text-align: left;
              padding: 10px 0px;
              color: ${theme.palette.customColor.primaryBlack};
              font-family: "Sora";
              font-size: 24px;
              font-weight: 700;

              span {
                color: ${theme.palette.customColor.secondaryGrey};
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-weight: 400;
              }
            }
          }

          .subs-date {
            background-color: ${theme.palette.customColor.grey_5};
            border-radius: 12px;
            padding: 20px;
            margin: 15px 0px;

            .subs-date-heading {
              color: ${theme.palette.customColor.secondaryGrey};
              font-family: "Plus Jakarta Sans";
              font-size: 14px;
              font-weight: 600;
              text-align: left;
            }

            .subs-date-date {
              color: ${theme.palette.customColor.primaryBlack};
              font-family: "Plus Jakarta Sans";
              font-size: 16px;
              font-weight: 500;
              text-align: left;
            }
          }
        }
      }
    }

    .page-table-wrap {
      border-radius: 10px;
      background: ${theme.palette.secondary.main};
      display: flex;
      margin: 10px 0px;
      flex-direction: column;

      @media screen and (max-width: 480px) {
        width: 93%;
        margin: auto;
        border-radius: 12px;
        margin-bottom: 20px;
      }

      .title {
        color: ${theme.palette.customColor.primaryBlack};
        font-family: "Plus Jakarta Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px;

        @media screen and (max-width: 480px) {
          font-size: 17px;
          padding: 16px;
        }

        .seeAll {
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          cursor: pointer;
          column-gap: 10px;
        }
      }

      .table-wrap {
        padding: 0px 20px;

        @media screen and (max-width: 480px) {
          width: 100%;
          padding: 0px 16px;
          padding-bottom: 20px;
        }

        .noSessionsHeading {
          color: ${theme.palette.customColor.secondaryGrey};
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-top: 24px;
          margin-bottom: 36px;

          @media screen and (max-width: 480px) {
            font-size: 18px;
          }
        }
      }
    }
  }

  .noData-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 50vh;
    background-color: white;
    margin: 27px;
    border-radius: 12px;
    min-height: 80vh;

    .heading {
      color: ${theme.palette.customColor.secondaryGrey};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 25px;
      margin-top: 20px;

      @media screen and (max-width: 481px) {
        font-size: 18px;
        padding: 0px 20px;
      }
    }
}
`,
);

export default StyledDashboard;
