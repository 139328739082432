import { styled } from '@mui/material/styles';

const StyledSuggestedCandidateProfile = styled('div')(
  ({ theme }) => `
  .sc-profile-wrap{
    padding:44px;
    color:#1C1C1C;
    .sc-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sc-user {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .sc-btns {
        display: flex;
        gap: 20px;
        align-items: center;
      }  
    }
    .sc-about {
      border-radius: 12px;
      padding: 20px;
      box-shadow: 0px 0px 23px 0px #0000000F;
      margin-top: 20px;

      .sc-about-head {
        color: ${theme.palette.customColor.primaryBlack};
        font-family: "Plus Jakarta Sans";
        font-size: 20px;
        font-weight: 600;
        text-align: left;
      }
      .about-des {
        margin: 10px 0px;
        color: ${theme.palette.customColor.primaryBlack};
        font-family: "Plus Jakarta Sans";
        font-size: 16px;
        font-weight: 500;
        text-align: left;
      }
      .roles {
        margin-top: 20px;
        display: flex;
        gap: 100px;
        align-items: center;
        flex-wrap: wrap;
        .about-heads {
          color: #757575;
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-weight: 600;
          text-align: left;
        }
        .about-body {
          margin-top: 2px;
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-weight: 500;
          text-align: left;
        }  
      }

      .skills-head {
        margin-top: 20px;
        color: #757575;
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-weight: 600;
        text-align: left;
      }
      .skills {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        
        .user-application-2 {
          width: auto;
          background-color: #415DF314;
          padding: 5px 10px;
          border-radius: 12px;
          color: ${theme.palette.primary.main};
          font-family: "Plus Jakarta Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
        }
      }
      .recume-wrap {
        margin-top: 20px;
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 10px;
        padding: 10px 20px;
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;
      }      
    }
    .sc-social {
      border-radius: 12px;
      padding: 20px;
      box-shadow: 0px 0px 23px 0px #0000000F;
      margin-top: 20px;

      .social-head {
        color: ${theme.palette.customColor.primaryBlack};
        font-family: "Plus Jakarta Sans";
        font-size: 20px;
        font-weight: 600;
        text-align: left;
      }
      .social-links {
        margin: 20px 0px 0px;
        .social-link-head {
          color: #757575;
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-weight: 600;
          text-align: left;
        }
        .social-link-body {
          color: #415DF3;
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          text-decoration: underline;
          cursor: pointer;
        }  
      }
    }
      
    .sc-charge-available {
      margin-top: 20px;
      width: 100%;
      display: flex;
      gap: 20px;
      align-items: center;

      .sc-charge{
        width: 100%;
        border-radius: 12px;
        padding: 20px;
        box-shadow: 0px 0px 23px 0px #0000000F;

        .charge-head {
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 20px;
          font-weight: 600;
          text-align: left;
        }
        .charge-body-wrap {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .charge {
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Sora";
            font-size: 20px;
            font-weight: 700;
            text-align: left;
          }
          .charge-duration {
            color: #525252;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-weight: 400;
            text-align: left;
          }
            
          .chare-location-head {
            color: #757575;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-weight: 600;
            text-align: left;
          }
          .chare-location-body {
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-weight: 500;
            text-align: left;
          }
        }  
        
      }
      .sc-available {
        width: 100%;
        border-radius: 12px;
        padding: 20px;
        box-shadow: 0px 0px 23px 0px #0000000F;

        .available-head {
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 20px;
          font-weight: 600;
          text-align: left;
        }
        .available-wrap {
          margin-top: 10px;
          display: flex;
          gap: 230px;
          align-items: center;

          .available-location-head {
            color: #757575;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-weight: 600;
            text-align: left;
          }
          .available-location-body {
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-weight: 500;
            text-align: left;
          }
        }  
      }  
    }
    
  }
  `,
);

export default StyledSuggestedCandidateProfile;
