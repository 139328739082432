import { styled } from '@mui/material/styles';

const StyledAvailability = styled('div')(
  ({ theme }) => `
  .page-wrap{
    background: ${theme.palette.customColor.primaryWhite};
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .header-wrap{
      background: ${theme.palette.customColor.primaryWhite};
      font-weight: 600;
      font-size: 20px;
      color: ${theme.palette.customColor.primaryBlack};
      text-align: left;
      padding: 24px 24px 24px 30px;
      @media screen and (max-width: 590px){
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.16px;
        text-align: left;
      }
    }
    .content-wrap{
      padding: 0px 24px 24px 24px;
      display: flex;
      justify-content: space-between;
      color: ${theme.palette.customColor.primaryBlack};
      @media screen and (max-width: 590px){
        padding: 0px 24px 24px 24px;   
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 160px;
      }
      .radio-group-container {
       @media screen and (min-width: 900px) and (max-width: 1440px) {
          flex-direction: column;
        }
      }
      .availability-type {
        @media screen and (min-width: 900px) and (max-width: 1440px) {
          margin-top: 20px;
        }
      }
      .input-Label_text {
        font-family: "Plus Jakarta Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: ${theme.palette.customColor.secondaryGrey};
        text-align: left;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
      } 
    }
    .selectdayHeading{
      color: #525252;
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align:left;
    } 
    .weekButtons{
      border: 0.5px solid #415DF3;
      padding: 15px 26px;
      box-shadow: none;
      border-radius: 10px;
      cursor: pointer;
      color: #1C1C1C;
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      @media screen and (max-width: 600px){
        margin-right: 10px;
        margin-bottom: 15px;
        padding: 8px 16px;
        font-size: 12px;
      }
  
    },

  .toogleSwitch {
    height: 90px;
    align-items: center;
    @media screen and (max-width: 800px){
      height: fit-content;
    }
  }  
  .toogle-label {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    text-align: left;
    @media screen and (max-width: 1024px){
      font-size: 12px;
    }
  },
  .form-heading{
    margin-top:-16px;
    padding: 0px 24px 24px 24px;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    text-align: left;
    color:#1C1C1C

  }
  .price_currency{
    .MuiInputBase-root{
      width: fit-content;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
    .MuiInputBase-input{
      width: 77px;
      box-sizing: border-box;
      height: 48px;
      padding: 13px 24px !important;
    }
    }
  }
  .price_amt{
    .MuiInputBase-input{
      outline: 0 !important;
      box-sizing: border-box;
      height: 48px;
      padding: 13px 16px !important;
    }
  }
  .rate-adornment{
    background: #F4F4F4;
    padding: 13.5px 16px !important;
    margin-right: -16px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.16px;
    text-align: left;
    color:#1C1C1C

  }
  .MuiToggleButtonGroup-root {
    @media screen and (max-width: 900px){
      margin-bottom:16px;
    }
    @media screen and (max-width: 768px){
      justify-content: flex-start;
      margin-bottom:16px;
      flex-wrap:wrap;
    }
  }
  .MuiToggleButtonGroup-grouped {
    @media screen and (max-width: 768px){
    width: calc(50% - 16px);
    }
  }
}`,
);

export default StyledAvailability;
