import { styled } from '@mui/material/styles';

const StyledJobDetail = styled('div')(
  ({ theme }) => `
    .page-wrap {
      width: 100%;
      background: ${theme.palette.customColor.primaryWhite};
      border-radius: 12px;
      overflow: hidden;

      .exp-buttons{
        display:inline-flex;
        gap:8px;
        padding:0 16px;
        img{
          cursor:pointer;
        }
      }

      .page-header {
        font-weight: 600;
        font-size: 20px;
        color: ${theme.palette.customColor.primaryBlack};
        text-align: left;
        padding: 20px 0px 0px 20px;
      }

      .content-wrap {
        padding: 20px;
          .first-section {
          width: 80%;
          @media screen and (max-width: 800px) {
            width: 100%;
          }
          
          }
        }
      }
    }
  `,
);

export default StyledJobDetail;
