import React from 'react';
import { MenuItem, FormControl, Select, styled } from '@mui/material';

const ColorDropdown = ({
  onColorChange,
  selectedColor = 'applied',
  width = '230px',
  MobileWidth = '60%',
}) => {
  const colorMap = {
    applied: '#ECEFFE',
    underReview: '#FCF6E5',
    interview: '#FCF0E5',
    rejected: '#FFE3E3',
    accepted: '#E5F7EA',
  };

  const handleColorChange = (event) => {
    const newColor = event.target.value;
    if (onColorChange) {
      onColorChange(newColor);
    }
  };

  const StyledSelect = styled(Select)(() => ({
    backgroundColor: colorMap[selectedColor],
    color:
      selectedColor === 'applied'
        ? '#415DF3'
        : selectedColor === 'underReview'
          ? '#E1A800'
          : selectedColor === 'interviewScheduled'
            ? '#E16C00'
            : selectedColor === 'rejected'
              ? '#DA3333'
              : selectedColor === 'accepted'
                ? '#00AF31'
                : 'black',
    borderRadius: '10px',
    padding: '10px 20px',
    fontWeight: 'bold',
    border: '1px solid #E2E4E7',
    height: '48px',
    width: { width },
    '& .MuiSelect-icon': {
      color: 'black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  }));

  return (
    <div style={{ width: '100%' }}>
      <FormControl
        fullWidth
        sx={{
          width: {
            width: '100%',
            '@media (max-width: 700px)': {
              width: '75%',
            },
            '@media (max-width: 1200px)': {
              width: MobileWidth,
            },
          },
        }}>
        <StyledSelect value={selectedColor} onChange={handleColorChange} disableUnderline>
          <MenuItem
            value="applied"
            sx={{
              backgroundColor: colorMap.applied,
              color: '#415DF3',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}>
            APPLIED
          </MenuItem>
          <MenuItem
            value="underReview"
            sx={{
              backgroundColor: colorMap.underReview,
              color: '#E1A800',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}>
            UNDER REVIEW
          </MenuItem>
          <MenuItem
            value="accepted"
            sx={{
              backgroundColor: colorMap.accepted,
              color: '#00AF31',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}>
            ACCEPTED
          </MenuItem>
          <MenuItem
            value="interviewScheduled"
            sx={{
              backgroundColor: colorMap.interview,
              color: '#E16C00',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}>
            INTERVIEW SCHEDULED
          </MenuItem>
          <MenuItem
            value="rejected"
            sx={{
              backgroundColor: colorMap.rejected,
              color: '#DA3333',
              '&:hover': {
                backgroundColor: '#D3D3D3',
              },
            }}>
            REJECTED
          </MenuItem>
        </StyledSelect>
      </FormControl>
    </div>
  );
};

export default ColorDropdown;
