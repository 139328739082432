import React from 'react';
import Location from './Location';
import Availability from './Availability';
import Subscription from './Subscription';
import Basic from './Basic';
import JobDetails from './JobDetails';
import { StyledCreate } from './StyledCreate';

export default function CreatePostJob({ stepNumber = 0, setActiveStep }) {
  const handleRenderSection = () => {
    switch (stepNumber) {
      case 0:
        return <Basic stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 1:
        return <JobDetails stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 2:
        return <Location stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 3:
        return <Availability stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      case 4:
        return <Subscription stepNumber={stepNumber} setActiveStep={setActiveStep} />;
      default:
        return '';
    }
  };

  return (
    <StyledCreate>
      <div className="page-content-wrap">
        <div className="content-wrap">{handleRenderSection()}</div>
      </div>
    </StyledCreate>
  );
}
