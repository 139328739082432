import { styled } from '@mui/material/styles';
import applicantDetailFrame from '../../../assets/images/ApplicantDetailFrame.png';
// import myServiceFrame from '../../../assets/svg/myServicesBG.svg';

const StyledApplicantDetails = styled('div')(
  ({ theme }) => `
  .content-wrap {
    padding: 30px 50px;
    .mobile-buttons{
      display: none;
    }
    @media screen and (max-width: 900px) {
      padding: 20px 0px;
      .mobile-buttons {
        padding: 20px 10px;
        align-items: center;
        display: flex;
        background: white;
        position: fixed;
        width: 100%;
        z-index: 99;
        bottom: 0px;
        justify-content: space-between;
        .mobile-btn {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
    
  .back-btn {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    cursor:pointer;
  }
  .back-btn-text {
    color: ${theme.palette.customColor.primaryBlack};
    font-style: normal;
    font-weight: 600;
    font-family: "Plus Jakarta Sans";
    font-size: 24px;
  }
  .detail-card {
    position: relative;
    margin-top: 20px;
    width: 100%;
    border: 0px solid;
    border-radius: 24px;
    background: white;
    min-height: 40vh;
    .img-back {
      width: 100%;
      height: 63px;
      background-image: url(${applicantDetailFrame});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 26px 26px 0px 0px;
    }
  } 
  .main-card {
    .header {
      margin: 10px 20px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
      .left-header {
        margin-top: 38px;
        display: flex;
        align-items: baseline;
        position: relative;
        @media screen and (max-width: 1200px) {
          margin-top: 5px;
        }
        .img {
          position: relative;
          .img-wrap {
            border: 0px solid white;
            background: white;
            width: 200px;
            height: 200px;
            border-radius: 50%;
            position: absolute;
            top: -115px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 1200px) {
              width: 110px;
              height: 110px;
              top: -70px;
            }
          }
        }
        .applicant {
            margin-left: 220px;
             @media screen and (max-width: 1200px) {
              margin-left: 130px;
            }
            .applicant-name {
              color: ${theme.palette.customColor.primaryBlack};
              font-family: "Sora";
              font-size: 28px;
              font-style: normal;
              text-align: left;
              font-weight: 700;
              @media screen and (max-width: 1200px) {
                font-size: 20px;
              }
            }
            .applicant-role {
             display: flex;
             gap: 10px;
             aligin-items: center;
             color: #415DF3;
             font-weight: 600;
             font-size: 18px;
             font-style: normal;
             text-align: left;
             font-family: "Plus Jakarta Sans";
             @media screen and (max-width: 1200px) {
                font-size: 14px;
              }
            
            }
          }    
      }
      .right-header {
        display: flex;
        gap: 20px;
        align-items: center;
        .sc-dropdown {
           @media screen and (max-width: 1200px) {
             width: 100%;
             margin-top: 30px;
           }
        }
        .insta {
          height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background: white;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            width: 26px;
        }
            .insta a {
            justify-content: center;
            align-items: center;
            display: flex;
        }
            @media screen and (max-width: 1200px) {
          .insta {
            display: none;
          }
        }
        .linkdin {
            height: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background: white;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            width: 26px;
        }

        .linkdin a {
            justify-content: center;
            align-items: center;
            display: flex;
        }

        @media screen and (max-width: 1200px) {
          .linkdin {
            display: none;
          }
        }
        .contact-btn {
          @media screen and (max-width: 1200px) {
            display: none;
          }
                
        }  
      }
    }
    .main-body-card {
      margin: 80px 20px 20px;
      display: flex;
      align-items: start;
        @media screen and (max-width: 1200px) {
        flex-direction: column;
        margin: 20px 20px 20px;
        }
    }
    .subs-cards {
      width: 25%;
      @media screen and (max-width: 1200px) {
          width: 100%;
        }
      .card-1 {
        border-radius : 20px;
        background-color: #F8F4F0;
        padding: 20px;
        .card-head {
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 20px;
          font-style: normal;
          text-align: left;
          font-weight: 600;
        }
      }
      .resume-card {
        margin-top: 20px;
        border-radius: 20px;
        background: white;
        padding: 20px;
        .resume {
            display: flex;
            gap: 10px;
            align-items: self-start;
            margin-bottom: 20px;
          .resume-main {
            text-align: left;
            .resume-topic {
                font-family: "Plus Jakarta Sans";
                font-size: 16px;
                font-style: normal;
                text-align: left;
                font-weight: 600;
                color: ${theme.palette.customColor.primaryBlack};
            }
            .resume-body {
                color: #757575;
                font-family: "Plus Jakarta Sans";
                font-size: 12px;
                font-style: normal;
                text-align: left;
                font-weight: 500;
            }    

          }
        }

      }  
      .price {
        display: flex;
        gap: 10px;
        align-items: baseline;
        margin-top: 20px;
        .price-card {
          text-align: left;
          .price-text {
            color: ${theme.palette.customColor.primaryBlack};
            font-size: 20px;
            font-style: normal;
            text-align: left;
            font-weight: 700;
            font-family: "Sora";
          }
          .price-duration {
            color: #525252;
            font-size: 14px;
            font-style: normal;
            text-align: left;
            font-weight: 500;
            font-family: "Plus Jakarta Sans";
          }
        }
      }
      .nego {
        margin: 20px 0px;
        display: flex;
        gap: 10px;
        align-items: center;
        .nego-text {
            font-style: normal;
            text-align: left;
            font-weight: 600;
            font-family: "Plus Jakarta Sans";
            font-size: 12px;
            color: #525252;
            span {
              color: #415DF3;
            }
        }
      }
      .subs {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        align-items: self-start;
        .subs-main {
        text-align: left;
        .subs-topic {
            font-style: normal;
            color: #757575;
            text-align: left;
            font-weight: 600;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
        }
         .subs-body {
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            text-align: left;
            font-weight: 500;
            color: ${theme.palette.customColor.primaryBlack};
         }   
        }
      }      
    }   
    .main-body {
       width: 75%;
       @media screen and (max-width: 1200px) {
          width: 100%;
        }
       .my-services {
          margin-bottom: 20px;
          position: relative;
          overflow: hidden;
          border: 1px solid #CDD2F0;
          background: linear-gradient(to right, #BDC8FF00, #BDC8FF33);
          border-radius: 10px;
          width: 80%;
          height: 50px;
          display: flex;
          justify-content: space-between;
          padding: 0px 20px;
          align-items: center;
          @media screen and (max-width: 1200px) {
            width: 100%;   
          }
          .image-btn {
            position: absolute;
            right: 0px;
          }
          .group-service {
            position: relative;
            .image-text {
              position: absolute;
              top: -8px;
              left: -12px;
            }
            .image-arrow {
              position: absolute;
              top: -17px;
              right: -55px;
            }  
          }  
          .service-text {
            font-weight: 700;
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            text-align: left;
            color: #415DF3;
          }
       }
      .body-header {
        color: ${theme.palette.customColor.primaryBlack};
        font-weight: 600;
        font-family: "Plus Jakarta Sans";
        font-size: 20px;
        font-style: normal;
        text-align: left;
        margin-bottom: 20px;
      }
      .body-topics {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        @media screen and (max-width: 1200px) {
          flex-direction: column;
          width: 100%;
        }
        .each-topic {
          flex: 1 1 45%; 
          display: flex;
          gap: 20px;
          align-items: center;
          @media screen and (max-width: 1200px) {
            width: 100%;
          }
          .topic-name {
            @media screen and (max-width: 1200px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
          }
          .topic-head {
            color: #757575;
            font-weight: 600;
            font-size: 14px;
            font-style: normal;
            text-align: left;
            font-family: "Plus Jakarta Sans";
            @media screen and (max-width: 1200px) {
              font-size: 12px;
            }
          }
          .topic-body {
            color: ${theme.palette.customColor.primaryBlack};
            font-weight: 600;
            font-size: 18px;
            font-style: normal;
            text-align: left;
            font-family: "Sora";
             @media screen and (max-width: 1200px) {
              font-size: 12px;
             }
          }  
        }
      }
      .skills-head {
        margin-top: 30px;
        color: #757575;
        font-size: 14px;
        font-style: normal;
        text-align: left;
        font-family: "Plus Jakarta Sans";  
        font-weight: 600;
        }       
      }
      .skills {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .user-application-2 {
            width: auto;
            padding: 5px 10px;
            border-radius: 12px;
            background-color: rgba(65, 93, 243, 0.2);
            color: ${theme.palette.primary.main};
            font-family: "Plus Jakarta Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }  
      .about-me {
        margin-top: 30px;
        color: #757575;
        font-size: 14px;
        font-style: normal;
        text-align: left;
        font-family: "Plus Jakarta Sans";  
        font-weight: 600;
      }
      .about-desc {
        padding-bottom: 30px;
        padding-right: 10px;
        color: #1C1C1C;
        font-size: 16px;
        font-style: normal;
        text-align: left;
        font-family: "Plus Jakarta Sans";  
        font-weight: 500;
        white-space: pre-wrap;
      }
  }   
  `,
);

export default StyledApplicantDetails;
