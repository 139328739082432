/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-one-expression-per-line */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { PrimaryButton, SecondaryButton } from '../../../components/Button';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
import TextField from '../../../components/TextField';
import linkedin from '../../../assets/svg/socialBlue/linkedinBlue.svg';
import twitter from '../../../assets/svg/socialBlue/twitterBlue.svg';
import youtube from '../../../assets/svg/socialBlue/youtubeBlue.svg';
import whatsapp from '../../../assets/svg/socialBlue/whatsappBlue.svg';
import telegram from '../../../assets/svg/socialBlue/telegramBlue.svg';
import substack from '../../../assets/svg/socialBlue/substackBlue.svg';
import speakerTag from '../../../assets/svg/speakerTag.svg';
import hiArrow from '../../../assets/svg/hiArrow.svg';
import hiWing from '../../../assets/svg/hiWing.svg';
import StyledPreviewPage from './StyledPreviewPage';
import { fetchLandingPageDataByUserName } from '../../../store/reducers/expertProfile/apiThunk';
import { CONSTANTS } from '../../../constants/constants';
import { ROUTE_PATH } from '../../../constants/route';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import PreviewLayout from '../Layout';
import BasicModal from '../../../components/Modal/BasicModal';
import { validationSpeaker } from '../../../utils/validations/authValidation';
import { fetchBookSpeaker } from '../../../seekerStore/reducers/seekerProfile/apiThunk';
import HelloText from '../../../assets/images/helloText.png';

const Preview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const {
    SOCIALLINKS: { YOUTUBE, TWITTER, WHATSAPP, TELEGRAM, SUBSTACK, LINKEDIN },
  } = CONSTANTS;
  const {
    userLandingData: {
      data: {
        expert: {
          firstName = '',
          lastName = '',
          profileImagePath = '',
          primaryEmail = '',
          username = '',
          _id,
        } = {},
        bio: fetchedBio = '',
        whoAmI: fetchedWhoAmI = '',
        socialMedia = [],
        expertServices = [],
        isAvailableForPublicSpeaking = false,
      } = {},
    } = {},
  } = useSelector(selectorExpertProfile);
  const [bookAsSpeaker, setBookAsSpeaker] = useState(false);

  const submitHandler = async (values, { resetForm }) => {
    const { email, message } = values;

    const data = {
      seekerEmail: email,
      message,
    };

    const dataRequest = {
      header: Intl.DateTimeFormat().resolvedOptions().timeZone,
      item: data,
      id: _id,
    };

    const response = await dispatch(fetchBookSpeaker(dataRequest));
    const { payload: { status = false, message: mess } = {} } = response;
    if (status) {
      ToastNotifySuccess(mess);
      setBookAsSpeaker(false);
      resetForm();
    }
    await dispatch(fetchLandingPageDataByUserName(params));
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    validationSchema: validationSpeaker,
    onSubmit: submitHandler,
  });

  const {
    handleSubmit,
    values: { email, message: seekersMessage } = {},
    touched: { email: tucEmail = false, message: tucSeekersMessage = false } = {},
    errors: { email: errEmail, message: errSeekersMessage } = {},
  } = formik;

  const landingData = async (item) => {
    const response = await dispatch(fetchLandingPageDataByUserName(item));
    const { payload: { status = false, data, message } = {} } = response;
    if (status) {
      sessionStorage.setItem('isVisited', true);
      sessionStorage.setItem('expertEmail', data?.expert?.primaryEmail);
      sessionStorage.setItem('expertUserName', item?.username);
      sessionStorage.setItem('seekerVerified', false);
      sessionStorage.setItem('seekerUnit', data?.expertServices[0]?.unit);
    } else {
      navigate(-1);
      ToastNotifyError(message);
    }
  };
  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      landingData(params);
    } else {
      sessionStorage.setItem('isVisited', true);
      sessionStorage.setItem('expertEmail', primaryEmail);
      sessionStorage.setItem('expertUserName', username);
      sessionStorage.setItem('seekerVerified', false);
      sessionStorage.setItem('seekerUnit', expertServices[0]?.unit);
    }
  }, []);

  const getIcon = (type) => {
    switch (type) {
      case TWITTER:
        return twitter;
      case YOUTUBE:
        return youtube;
      case SUBSTACK:
        return substack;
      case WHATSAPP:
        return whatsapp;
      case TELEGRAM:
        return telegram;
      case LINKEDIN:
        return linkedin;
      default:
        return '';
    }
  };

  const handleBookNowNavigation = () => {
    if (Object.keys(params).length === 0) {
      navigate(ROUTE_PATH.PREVIEW_SERVICES);
    } else {
      navigate(`${ROUTE_PATH.PREVIEW_SERVICES}/${params.username}`);
    }
  };

  const handleEmailChange = (event, name) => {
    formik.setFieldValue(name, event.target.value.trim());
  };

  const handleMessageChange = (event, name) => {
    formik.setFieldValue(name, event.target.value);
  };

  const speakerModalContent = () => (
    <form onSubmit={handleSubmit}>
      <div className="content_wrap">
        <TextField
          name="email"
          label="Email"
          type="text"
          important
          placeholderValue="Enter your email"
          value={email}
          onChange={(e) => handleEmailChange(e, 'email')}
          error={tucEmail && !!errEmail}
          helperText={tucEmail && errEmail}
        />
        <TextField
          name="message"
          label="Message for expert"
          type="text"
          placeholderValue="Write message for expert"
          value={seekersMessage}
          onChange={(e) => handleMessageChange(e, 'message')}
          rows={2}
          multiline
          maxLength={250}
          className="message_content"
          error={tucSeekersMessage && !!errSeekersMessage}
          helperText={tucSeekersMessage && errSeekersMessage}
        />
      </div>
      <div className="footer-wrap">
        <PrimaryButton
          type="submit"
          sx={{
            width: '100%',
            // '@media screen and (max-width: 0px)': {
            //   width: 'auto',
            // },
          }}>
          Submit
        </PrimaryButton>
      </div>
    </form>
  );

  return (
    <StyledPreviewPage>
      <Box
        className="content-wrap"
        // sx={{ transform: { xs: 'scale(1)', md: 'scale(0.65)', lg: 'scale(1)' } }}
      >
        <div className="name_container">
          <div style={{ position: 'relative', display: 'flex' }}>
            <div className="name">
              <img src={HelloText} alt="" />
            </div>
            <div className="hi_img_arrow">
              <img src={hiArrow} alt="" />
            </div>
            <div className="wing_svg">
              <img src={hiWing} alt="" />
            </div>
          </div>
        </div>
        <div className="user_details_wrap">
          <div className="profile-pic-wrap">
            <div style={{ position: 'relative' }}>
              <img
                src={`${profileImagePath ? `${process.env.REACT_APP_IMAGE_URL}/${profileImagePath}` : ''}`}
                className="profile-pic"
                alt=""
              />
              {isAvailableForPublicSpeaking && (
                <div className="profile_speaker_tag">
                  <img src={speakerTag} alt="" />
                </div>
              )}
            </div>
          </div>

          <div className="user_full_name">{`${firstName} ${lastName}`}</div>
          <div className="expertise">{fetchedWhoAmI}</div>
          <div className="bio">{fetchedBio}</div>
          <div className="socialIcons">
            <Box mt={2} mb={2}>
              {socialMedia.map(({ mediaType = '', mediaProfileUrl = '' }) => (
                <a href={mediaProfileUrl} target="_blank" rel="noreferrer">
                  <img src={getIcon(mediaType)} className="socialIconsImages" alt="" />
                </a>
              ))}
            </Box>
          </div>

          <div className="button_wrap_user">
            {/* {isAvailableForPublicSpeaking && (
              <SecondaryButton
                sx={{
                  maxWidth: '250px',
                  border: '1px solid #415DF3',
                  '&.Mui-disabled': {
                    pointerEvents: 'auto !important',
                    cursor: 'not-allowed !important',
                  },
                }}
                disabled={Object.keys(params)?.length === 0}
                onClick={() => setBookAsSpeaker(true)}>
                Book as a Speaker
              </SecondaryButton>
            )} */}
            {Object.keys(params)?.length ?
              <SecondaryButton
                sx={{
                  maxWidth: '250px',
                  border: '1px solid #415DF3',
                  '&.Mui-disabled': {
                    pointerEvents: 'auto !important',
                    cursor: 'not-allowed !important',
                  },
                }}
                onClick={() => {
                  window.open(
                    `/expert-details?id=${_id}`,
                    '_blank', // This specifies the new tab
                  );
                }}>
                Book as a Fractional Expert
              </SecondaryButton> : null}

            <PrimaryButton
              sx={{
                maxWidth: '250px',
                borderRadius: '10px',
                boxShadow: '0px 3px 16px 0px rgba(0, 0, 0, 0.10)',
                height: 'fit-content',
                fontSize: '16px',
                fontWeight: '500 !important',
                padding: '12px 10px',
                alignItems: 'center',
                '&.Mui-disabled': {
                  pointerEvents: 'auto !important',
                  cursor: 'not-allowed !important',
                },
              }}
              disabled={Object.keys(params)?.length === 0}
              onClick={() => handleBookNowNavigation()}>
              Book Now
            </PrimaryButton>
          </div>
        </div>
      </Box>
      <BasicModal
        openDialog={bookAsSpeaker}
        dialogContent={speakerModalContent()}
        noHeader={false}
        heading={
          <Stack className="head" spacing={1.5}>
            <div className="heading_pop_social">Book as a Speaker</div>
          </Stack>
        }
        dialogCss="speaker_content"
        closeButVisible
        closeDialog={() => setBookAsSpeaker(false)}
      />
    </StyledPreviewPage>
  );
};

export default PreviewLayout(Preview);
