/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import IconButton from '@mui/material/IconButton';
import logo from '../../assets/svg/logo.svg';
import StyledSignupPage from './StyledSignupPage';
import TextField from '../../components/TextField';
import SelectBox from '../../components/SelectBox';
import AutoComplete from '../../components/AutoComplete';
import { PrimaryButton } from '../../components/Button';
import OtpModal from '../../components/OtpModal';
import changeProfile from '../../assets/svg/changeProfile.svg';
import profilePic from '../../assets/svg/profilePic.svg';
import '../../styles/_global.scss';
import {
  fetchSignUp,
  fetchGetProfile,
  fetchUsername,
  fetchLogout,
  fetchSendOtp,
  fetchCountrieslists,
  fetchRecruiterSignUp,
  fetchRecruiterUsername,
  fetchGetRecruiterProfile,
} from '../../store/reducers/authentication/apiThunk';
import { ROUTE_PATH } from '../../constants/route';
import {
  validationSignUpSchema,
  validateFileType,
  validateFileSize,
} from '../../utils/validations/authValidation';
import { selectorAuthentication } from '../../store/reducers/authentication';
import { CONSTANTS, REGEX } from '../../constants/constants';
import LogoutIcon from '../../components/icons/LogoutIcon';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import messages from '../../constants/message';
import loginBg from '../../assets/images/login-bg.png';
import CustomTooltip from '../../components/Tooltip/Tooltip';

const { MSG_FILE_FORMATE, MSG_FILE_SIZE } = messages;

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const { TERMS_AND_CONDITIONS, PRIVACY_POLICY, DASHBOARD, LOGIN, RECRUITER_DASHBOARD } = ROUTE_PATH;

const {
  LOGIN_TYPE: { GOOGLE },
} = CONSTANTS;
const { EMAIL_PATTERN } = REGEX;

function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    auth: {
      data: {
        firstName: userFistName,
        lastName: userLastName,
        email: userEmail = '',
        type: signUpType,
      } = {},
    } = {},
    signUp = {},
    recruiterSignUp = {},
    otp = {},
  } = useSelector(selectorAuthentication);
  const [file, setFile] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [extension, setExtension] = useState('');
  const [checkBoxValue, setCheckBoxValue] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMsgName, setErrorMsgName] = useState('');
  const [usernameOptions, setUsernameOptions] = useState([]);
  const [isEmailExists, setIsEmailExists] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [emailValue, setEmailValue] = useState(userEmail);
  const [otpModal, setOtpModal] = useState(false);
  const [countryLists, setCountryLists] = useState([]);
  const [loading, setIsloading] = useState(false);
  const isRecruiter = localStorage.getItem('recruiter');

  const fetchCountriesLists = async () => {
    const response = await dispatch(fetchCountrieslists()).then((val) => val);
    const { payload: { data = [] } = {} } = response;
    const filteredData = data?.map(({ nicename }) => ({
      title: nicename,
      value: nicename,
    }));
    setCountryLists(filteredData);
  };

  useEffect(() => {
    fetchCountriesLists();
  }, []);

  useEffect(() => {
    if (userEmail) {
      setIsEmailVerified(true);
      setIsEmailExists(true);
    }
  }, [userEmail]);

  useEffect(() => {
    const { status = false } = otp;
    if (status && emailValue) {
      setOtpModal(true);
    }
  }, [otp]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (searchTerm) {
        const data = {
          username: searchTerm,
        };
        setIsloading(true);
        let response;
        if (isRecruiter) {
          response = await dispatch(fetchRecruiterUsername(data));
        } else {
          response = await dispatch(fetchUsername(data));
        }
        const {
          payload: { data: { isAvailable, suggestedUsername } = {}, message },
        } = response;
        setIsloading(false);
        if (!isAvailable) {
          setUsernameOptions(suggestedUsername);
          setErrorMsgName(message);
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  function handleImage(e) {
    if (e?.target?.files[0]) {
      setUploadFile(e?.target?.files[0]);
      setFile(URL?.createObjectURL(e?.target?.files[0]));
    }
  }

  const addImage = (event) => {
    setExtension(event?.target?.files[0]?.name?.split('.')?.pop());
    handleImage(event);
  };

  useEffect(() => {
    const { status = false } = signUp;
    const { status: status2 = false } = recruiterSignUp;

    if (isRecruiter && status2) {
      dispatch(fetchGetRecruiterProfile());
      navigate(RECRUITER_DASHBOARD);
    } else if (status) {
      dispatch(fetchGetProfile());
      navigate(DASHBOARD);
    }
  }, [signUp, recruiterSignUp]);

  const SignupUser = (updatedData) => {
    if (isRecruiter) {
      dispatch(fetchRecruiterSignUp(updatedData));
    } else {
      dispatch(fetchSignUp(updatedData));
    }
  };

  const submitHandler = async (values) => {
    const { firstName, lastName, email, userName, country } = values;
    const updatedData = {
      firstName,
      lastName,
      primaryEmail: email,
      secondaryEmail: signUpType === GOOGLE ? email : email,
      username: userName,
      country,
      fileExtension: extension,
      uploadPicture: uploadFile,
    };

    if (extension && !validateFileType(extension)) {
      ToastNotifyError(MSG_FILE_FORMATE);
    } else if (uploadFile && validateFileSize(uploadFile)) {
      ToastNotifyError(MSG_FILE_SIZE);
      // } else if (!extension) {
      // ToastNotifyError(MSG_FILE_REQUIRED);
    } else {
      SignupUser(updatedData);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: userFistName,
      lastName: userLastName,
      email: emailValue,
      userName: '',
      country: '',
    },
    validationSchema: validationSignUpSchema,
    onSubmit: submitHandler,
  });

  const {
    handleSubmit,
    values: { firstName, lastName, email, userName, country } = {},
    touched: {
      firstName: tucFirstName = false,
      lastName: tucLastName = false,
      email: tucEmail = false,
      userName: tucUserName = false,
      country: tucCountry = false,
    } = {},
    errors: {
      firstName: errFirstName,
      lastName: errLastName,
      email: errEmail,
      userName: errUserName,
      country: errCountry,
    } = {},
  } = formik;

  const handleChange = (e, name) => {
    if (['email'].includes(name)) {
      formik.setFieldValue(name, e?.target?.value?.trim());
    } else {
      formik.setFieldValue(name, e?.target?.value);
      if (['firstName', 'lastName'].includes(name)) {
        const convertedString =
          (e?.target?.value).charAt(0).toUpperCase() + (e?.target?.value).slice(1);
        formik.setFieldValue(name, convertedString);
      }
    }
  };

  const handleUserNameChange = (event, name) => {
    setErrorMsgName('');
    if (Number.isInteger(event?.target?.value)) {
      const changedValue = event?.target?.innerText;
      setSearchTerm(changedValue);
      formik.setFieldValue(name, changedValue);
    } else {
      const changedValue = event?.target?.value?.replace(/[ ]+/g, '');
      setSearchTerm(changedValue);
      formik.setFieldValue(name, changedValue);
    }
  };

  const handleLogOut = () => {
    dispatch(fetchLogout()).then(() => {
      navigate(LOGIN);
    });
  };

  const handleCheckBoxChange = () => {
    setCheckBoxValue(!checkBoxValue);
  };

  const handleOtpModal = () => {
    if (emailValue && EMAIL_PATTERN.test(emailValue)) {
      const sendOtpRequest = {
        primaryEmail: emailValue,
        type: 2,
      };
      dispatch(fetchSendOtp(sendOtpRequest));
    }
  };

  const renderEmailError = () => {
    if (!isEmailExists) {
      return (
        <div
          className={
            emailValue && EMAIL_PATTERN.test(emailValue)
              ? 'verifyEmail'
              : 'verifyEmail disableVerifyEmail'
          }>
          <span onClick={() => handleOtpModal()}>Please verify email</span>
        </div>
      );
    }
    return '';
  };

  const handleEmailChange = (event, name) => {
    setEmailValue(event?.target?.value);
    formik.setFieldValue(name, event?.target?.value?.trim());
  };

  return (
    <StyledSignupPage>
      <div className="page-wrap">
        <div className="page-left-wrap">
          <img src={loginBg} alt="" srcSet="" className="loginBg" />
        </div>
        <div className="page-right-wrap">
          <div className="page-right-content">
            <form onSubmit={handleSubmit}>
              <div className="logo-wrap">
                <img src={logo} alt="Superconnects Logo" className="logoImage" />
              </div>
              <div className="heading-wrap">
                <div className="title">Set-up your profile</div>
                <div className="subheading">{`${isRecruiter ? 'Create your recruiter’s profile' : 'Create your expert’s profile'}`}</div>
              </div>
              <div className="profile-section-wrap">
                <div className="profilepic-wrap">
                  <img src={file || profilePic} alt="" className="profileImage" />
                  <img src={changeProfile} alt="" className="changeProfile" />
                  <div>
                    <input
                      type="file"
                      id="file-input"
                      accept="image/png, image/jpeg"
                      className="fileUpload"
                      onChange={(e) => addImage(e)}
                    />
                  </div>
                </div>
                <div className="name-wrap">
                  <div className="">
                    <TextField
                      id=""
                      name="firstName"
                      type="text"
                      label="First Name"
                      important
                      onChange={(e) => handleChange(e, 'firstName')}
                      value={firstName}
                      error={tucFirstName && !!errFirstName}
                      helperText={tucFirstName && errFirstName}
                    />
                  </div>
                  <div className="">
                    <TextField
                      id=""
                      label="Last Name"
                      important
                      name="lastName"
                      type="text"
                      onChange={(e) => handleChange(e, 'lastName')}
                      value={lastName}
                      error={tucLastName && !!errLastName}
                      helperText={tucLastName && errLastName}
                    />
                  </div>
                </div>
              </div>
              <div className="">
                <TextField
                  disabled={isEmailExists}
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => handleEmailChange(e, 'email')}
                  value={email}
                  error={tucEmail && !!errEmail}
                  helperText={tucEmail && errEmail}
                  id=""
                  label="Email"
                  important
                  icon={isEmailExists ? <CheckCircleOutlineIcon /> : ''}
                />
                {renderEmailError()}
              </div>
              <div className="username-outer-wrap">
                <div className="username-wrap " style={{ flexGrow: 1 }}>
                  <AutoComplete
                    name="userName"
                    type="text"
                    label="Username"
                    important
                    placeholderValue="Select username"
                    optionsValue={usernameOptions}
                    changeAutoComplete={(e) => handleUserNameChange(e, 'userName')}
                    value={userName}
                    error={tucUserName && !!errUserName}
                    helperText={tucUserName && errUserName}
                    icon={<ExpandMoreOutlinedIcon />}
                    showTooltip
                    startIcon="superconnects.co/"
                    startIconClasses="startIconClass"
                    tooltip="This will be unique & will be used as the sharable link of your landing page. For example, superconnects.co/username."
                  />
                  <div className="errorMsg">{errorMsgName}</div>
                </div>
                <div className="loader-box">{loading && <CircularProgress size={32} />}</div>
              </div>
              <div className="country-wrap">
                <SelectBox
                  label="Country"
                  important
                  name="country"
                  options={countryLists}
                  onChange={(event) => handleChange(event, 'country')}
                  placeholderValue="Select a country"
                  selectValue={country}
                  error={tucCountry && !!errCountry}
                  helperText={tucCountry && errCountry}
                  errorMsg={tucCountry && errCountry}
                  showTooltip
                  tooltip="For price of the services which you’ll offer, INR will be used as the currency if you choose India, while USD will be used if you choose any other country."
                />
                <div className="countryNote">
                  Note : We’re available in the countries appearing in the drop-down here
                </div>
              </div>
              <div className="termsCondition-wrap ">
                <Checkbox
                  className="checkbox"
                  {...label}
                  value={checkBoxValue}
                  onChange={() => handleCheckBoxChange()}
                />
                {'By registering, I agree to all '}
                <Link to={TERMS_AND_CONDITIONS} target="_blank" className="linkColor">
                  Terms & Conditions
                </Link>
                {' and '}
                <Link to={PRIVACY_POLICY} target="_blank" className="linkColor">
                  Privacy Policy.
                </Link>
              </div>
              <div className="m-bottom-15">
                <PrimaryButton type="submit" disabled={checkBoxValue || !isEmailVerified}>
                  Sign Up
                </PrimaryButton>
              </div>
            </form>
          </div>
          <div className="logout-wrap">
            <CustomTooltip title="Log Out">
              <IconButton onClick={() => handleLogOut()}>
                <LogoutIcon />
              </IconButton>
            </CustomTooltip>
          </div>
        </div>
        {otpModal ? (
          <OtpModal
            email={emailValue}
            setOtpModal={setOtpModal}
            setIsEmailVerified={setIsEmailVerified}
          />
        ) : (
          ''
        )}
      </div>
    </StyledSignupPage>
  );
}

export default Signup;
