/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import AutoComplete from '../../../../components/AutoComplete';
import StyledAvailability from '../../../fractionTalent/Availability/StyledAvailability';
import StyledRadioGroup from '../../../../components/Common/AccountsDetailsModal/StyledRadioGroup';
import CustomTooltip from '../../../../components/Tooltip/Tooltip';
import ToogleSwitch from '../../../../components/ToogleSwitch/ToogleSwitch';
import { validationSchemaAvailability } from '../../../../utils/validations/authValidation';
import { setRecruiterAvailability } from '../../../../store/reducers/recruiterPostJob';
import RecruiterPostJobButtons from '../../../../components/RecruiterPostJobButtons';
import TextField from '../../../../components/TextField';
import ExpandMoreIcon from '../../../../components/icons/ExpandMoreIcon';

const chargeTypes = ['Per day', 'Per week', 'Per month'];

const workPreferenceOptions = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];

// const currencies = [
//   'INR',
//   'USD',
//   'EUR',
//   'JPY',
//   'GBP',
//   'AUD',
//   'CAD',
//   'CHF',
//   'CNY',
//   'RUB',
//   'ZAR',
//   'BRL',
//   'MXN',
//   'KRW',
//   'SGD',
//   'NZD',
//   'TRY',
//   'SAR',
//   'ARS',
// ];

const Availability = ({ stepNumber = 3, setActiveStep }) => {
  const dispatch = useDispatch();
  // const [selectedCurrency, setSelectedCurrency] = useState('INR');
  const recruiterAvailability = useSelector(
    (state) => state.recruiterPostJob.recruiterAvailability,
  );
  const previousData = useSelector((state) => state?.recruiterPostJob?.previousJobDta?.data);

  // const handleCurrencyChange = (currency) => {
  //   setSelectedCurrency(currency);
  // };

  const submitHandler = async (values) => {
    const { compensation, isNegotiable, compensationPeriod, availabilityType, availability } =
      values;
    const data = {
      compensationRate: compensation,
      isRateNegotiable: isNegotiable,
      compensationType: compensationPeriod,
      availableFor: availabilityType,
      availabilityPeriod: availability,
    };
    await dispatch(setRecruiterAvailability(data));
  };

  const formik = useFormik({
    initialValues: {
      compensation: '',
      isNegotiable: false,
      compensationPeriod: 'Per day',
      availabilityType: 'daily',
      availability: 0,
    },
    validationSchema: validationSchemaAvailability,
    onSubmit: submitHandler,
    validateOnChange: true,
  });

  const {
    handleBlur,
    values: { compensation, isNegotiable, compensationPeriod, availabilityType, availability } = {},

    touched: {
      compensation: tucCompensation = false,
      isNegotiable: tucIsNegotiable = false,
      compensationPeriod: tucCompensationPeriod = false,
      availabilityType: tucAvailabilityType = false,
      availability: tucAvailability = false,
    } = {},
    errors: {
      compensation: errCompensation,
      isNegotiable: errIsNegotiable,
      compensationPeriod: errCompensationPeriod,
      availability: errAvailability,
      availabilityType: errAvailabilityType,
    } = {},
  } = formik;

  useEffect(() => {
    if (Object.keys(recruiterAvailability).length > 0) {
      formik.setValues({
        compensation: recruiterAvailability?.compensationRate || '',
        isNegotiable: recruiterAvailability?.isRateNegotiable || false,
        compensationPeriod: recruiterAvailability?.compensationType || 'Per day',
        availabilityType: recruiterAvailability?.availableFor || 'daily',
        availability: recruiterAvailability?.availabilityPeriod || 0,
      });
    } else if (previousData) {
      formik.setValues({
        compensation: previousData?.compensationRate || '',
        isNegotiable: previousData?.isRateNegotiable || false,
        compensationPeriod: previousData?.compensationType || 'Per day',
        availabilityType: previousData?.availableFor || 'daily',
        availability: previousData?.availabilityPeriod || 0,
      });
    }
  }, [recruiterAvailability]);

  const handleRadioChange = (newValue) => {
    formik.setFieldValue('availabilityType', newValue);
  };

  const getTimeUnit = (preferenceValue) => {
    switch (preferenceValue) {
      case 'daily':
        return 'day';
      case 'weekly':
        return 'week';
      case 'monthly':
        return 'month';
      default:
        return null;
    }
  };
  const getTitle = (preferenceValue) => {
    switch (preferenceValue) {
      case 'daily':
        return 'days in a month';
      case 'weekly':
        return 'weeks in a month';
      case 'monthly':
        return 'months in a year';
      default:
        return null;
    }
  };

  const availableOptions = (preferenceValue) => {
    const maxLimit = preferenceValue === 'daily' ? 31 : preferenceValue === 'monthly' ? 12 : 4; // e.g., 30 days or 12 weeks/months
    return Array.from({ length: maxLimit }, (_, i) => ({
      label: `${i + 1}`,
      value: (i + 1).toString(),
    }));
  };

  const customHandleChange = (e, name) => {
    if (Number.isInteger(e.target.value)) {
      const changedValue = e.target.innerText;
      formik.setFieldValue(name, changedValue);
    } else {
      formik.setFieldValue(name, e.target.value);
    }
  };

  const handlePriceChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      // Allows only digits
      formik.setFieldValue('compensation', value);
    } else {
      formik.setFieldError('compensation', 'Please enter a valid integer value.');
      formik.setFieldTouched('compensation', true);
    }
  };
  return (
    <StyledAvailability>
      <div className="page-wrap">
        <div className="header-wrap">Information</div>
        <div className="content-wrap">
          <Grid container columnSpacing={2.5} rowSpacing={0} className="radio-group-container">
            <Grid item md={4.5} xs={12}>
              <div
                className="title-tooltip"
                style={{ display: 'flex', gap: '0px', alignItems: 'center' }}>
                <div className="input-Label_text label-space">Expert's Availability Required*</div>
                <div>
                  <CustomTooltip title="Mark the Candidate's availability for your Job by selecting days, weeks, or months, then specify a number (e.g., 10 days or 2 weeks or 1 month) to set your schedule. You can later modify it with Candidate as per need." />
                </div>
              </div>
              <StyledRadioGroup
                options={workPreferenceOptions}
                value={availabilityType}
                error={tucAvailabilityType && !!errAvailabilityType}
                helperText={tucAvailabilityType && errAvailabilityType}
                onChange={(e) => handleRadioChange(e)}
              />
            </Grid>
            <Grid item md={4.5} xs={12} className="availability-type">
              <AutoComplete
                name="availability"
                type="text"
                label={`Select the number of ${getTitle(availabilityType)} required for the job`}
                classes="whoAmI"
                important
                placeholderValue={`Number of ${getTimeUnit(availabilityType)}`}
                showTooltip={false}
                optionsValue={availableOptions(availabilityType)}
                changeAutoComplete={(e) => customHandleChange(e, 'availability')}
                onBlur={handleBlur}
                value={availability}
                error={tucAvailability && !!errAvailability}
                helperText={tucAvailability && errAvailability}
                icon={<ExpandMoreIcon />}
              />
            </Grid>
          </Grid>
        </div>
        <div className="form-heading">Compensation</div>
        <div className="content-wrap">
          <Grid container columnSpacing={2.5} rowSpacing={0} alignItems="start">
            <Grid item md={2.5} xs={12}>
              <AutoComplete
                name="compensationPeriod"
                type="text"
                label="Select type"
                classes="whoAmI"
                important
                placeholderValue="Charge per day"
                showTooltip={false}
                optionsValue={chargeTypes}
                changeAutoComplete={(e) => customHandleChange(e, 'compensationPeriod')}
                onBlur={handleBlur}
                value={compensationPeriod}
                error={tucCompensationPeriod && !!errCompensationPeriod}
                helperText={tucCompensationPeriod && errCompensationPeriod}
                icon={<ExpandMoreIcon />}
              />
            </Grid>
            <Grid item xs={12} sm={5} alignItems="flex-start">
              <Box>
                <TextField
                  className="price_amt"
                  placeholder=" 00"
                  name="compensation"
                  label="Rate as per availability"
                  important
                  onChange={(e) => handlePriceChange(e)}
                  onBlur={handleBlur}
                  value={compensation}
                  error={tucCompensation && !!errCompensation}
                  helperText={tucCompensation && errCompensation}
                  startIcon={<div className="rate-adornment">INR</div>}
                  sx={{
                    '& .price_amt .MuiInputBase-input': {
                      padding: '13px 28px !important',
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Stack direction="row" className="toogleSwitch" spacing={1}>
                <ToogleSwitch
                  defaultChecked={false}
                  inputProps={{ 'aria-label': 'ant design' }}
                  checked={isNegotiable}
                  error={tucIsNegotiable && !!errIsNegotiable}
                  helperText={tucIsNegotiable && errIsNegotiable}
                  onChange={(e) => formik.setFieldValue('isNegotiable', e.target.checked)}
                />
                <div className="toogle-label">This pricing is negotiable</div>
                <CustomTooltip
                  title="Make your price negotiable to allow people negotiate the charges."
                  placement="bottom"
                />
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>

      <RecruiterPostJobButtons
        showBackButton
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={() => submitHandler(formik.values)}
        disableNext={!formik.isValid}
      />
    </StyledAvailability>
  );
};

export default Availability;
