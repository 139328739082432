import * as React from 'react';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import { makeStyles } from '@material-ui/core';
// import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { InputAdornment } from '@mui/material';
import StyledTextField from '../TextField/StyledTextField';
import { ToastNotifyError } from '../Toast/ToastNotify';
import ExpandMoreIcon from '../icons/ExpandMoreIcon';

const StyledTag = styled(({ label, onDelete, ...other }) => (
  <div {...other}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  background-color: #415df314;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: #415df3;
  border-radius: 40px;
  overflow: hidden;
  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & svg {
    font-size: 14px;
    margin-top: 2px;
    cursor: pointer;
  }
`;

const Listbox = styled('ul')`
  width: 100%;
  margin: -16px 0 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  & li {
    padding: 5px 12px;
    display: flex;
    & span {
      flex-grow: 1;
      text-align: left;
      line-height: 36px;
    }
  }
  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;
  }
  & li.${autocompleteClasses.focused} {
    background-color: #f4f4f4;
    cursor: pointer;
  }
`;
const SelctedOptions = styled('div')`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
export default function MultiselectAutoComplete(props) {
  const {
    placeholder = '',
    label,
    important,
    optionsValue,
    onChange,
    onInputChange,
    onFocus,
    selectedValues = [],
  } = props;

  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    multiple: true,
    options: optionsValue,
    value: selectedValues,
    getOptionLabel: (option) => option,
    onChange: (event, newValue) => {
      if (onChange) {
        onChange(newValue);
      }
    },
    onInputChange: (event, newInputValue) => {
      setInputValue(newInputValue);
      if (onInputChange) {
        onInputChange(newInputValue);
      }
    },
    open, // Use the open state here
    onOpen: () => setOpen(true), // Set open to true when dropdown is opened
    onClose: () => setOpen(false),
  });

  React.useEffect(() => {
    if (focused && onFocus) {
      onFocus();
    }
  }, [focused, onFocus]);

  // Function to add the current input value to the value array
  const addCurrentValue = () => {
    // e.preventDefault();
    const trimmedInput = inputValue.trim(); // Trim whitespace from input
    const inputValueLower = trimmedInput.toLowerCase();

    // Check for duplicates case-insensitively and with trimmed input
    const isDuplicate = selectedValues.some((val) => val.toLowerCase() === inputValueLower);

    if (trimmedInput && !isDuplicate) {
      const newValue = [...selectedValues, trimmedInput];
      if (onChange) onChange(newValue);
      setInputValue(''); // Clear the input
    } else {
      ToastNotifyError('Duplicate Skills are not allowed');
    }

    setOpen(false); // Close the dropdown manually
  };

  // Function to remove a tag from the value array
  const handleDelete = (tagToDelete) => {
    const newValue = selectedValues.filter((tag) => tag !== tagToDelete);
    if (onChange) onChange(newValue);
  };

  return (
    <>
      <div {...getRootProps()} className="input-Label_text label-space">
        <StyledTextField className={focused ? 'focused' : ''}>
          <div className="input-Label_text label-space">
            {label}
            {important ? <span className="">*</span> : ''}
          </div>
          <TextField
            fullWidth
            focused={false}
            autoFocus={false}
            className="custom-field"
            size="small"
            variant="standard"
            margin="normal"
            placeholder={placeholder}
            {...getInputProps()}
            InputProps={{
              autoComplete: 'off',
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end" style={{ position: 'absolute', right: '10px' }}>
                  {inputValue && (
                    <span
                      onClick={addCurrentValue}
                      style={{
                        cursor: 'pointer',
                        marginRight: '8px',
                        fontSize: '14px',
                        padding: '4px 8px',
                        border: '1px solid #415DF3',
                        borderRadius: '18px',
                        fontWeight: 500,
                        color: '#1c1c1c',
                      }}>
                      Add Skill
                    </span>
                  )}
                  <ExpandMoreIcon style={{ color: '#A6A6A6' }} />
                </InputAdornment>
              ),
            }}
          />
        </StyledTextField>
      </div>

      {groupedOptions.length > 0 && (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((option, index) => {
            const { key, ...optionProps } = getOptionProps({ option, index });
            return (
              <li key={key} {...optionProps}>
                <span style={{ color: 'black' }}>{option}</span>
              </li>
            );
          })}
        </Listbox>
      )}

      <SelctedOptions>
        {selectedValues?.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <StyledTag
              key={key}
              {...tagProps}
              label={option}
              onDelete={() => handleDelete(option)}
            />
          );
        })}
      </SelctedOptions>
    </>
  );
}
