import { styled } from '@mui/material/styles';

const StyledLocation = styled('div')(
  ({ theme }) => `
  .page-wrap{
    background: ${theme.palette.customColor.primaryWhite};
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .header-wrap{
      background: ${theme.palette.customColor.primaryWhite};
      font-weight: 600;
      font-size: 20px;
      color: ${theme.palette.customColor.primaryBlack};
      text-align: left;
      padding: 24px 24px 24px 30px;
      @media screen and (max-width: 590px){
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.16px;
        text-align: left;
      }
    }
    .sub-header-wrap{
        font-family: Plus Jakarta Sans;
        font-size: 18px;
        font-weight: 600;
        line-height: 22.68px;
        text-align: left;
        color: ${theme.palette.customColor.primaryBlack};
        padding: 0px 24px 16px;
        @media screen and (max-width: 590px){
          font-size: 14px;
          font-weight: 500;
          line-height: 17.64px;
        }
    }
    .content-wrap{
      padding: 0px 24px 24px 24px;
      display: flex;
      justify-content: space-between;
      color: ${theme.palette.customColor.primaryBlack};
      @media screen and (max-width: 1100px){
        padding: 0px 24px 24px 24px;   
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 160px;
      }
      .input-Label_text {
        font-family: "Plus Jakarta Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: ${theme.palette.customColor.secondaryGrey};
        text-align: left;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
      }
    }

  .MuiToggleButtonGroup-root {
    @media screen and (max-width: 900px){
      margin-bottom:16px;
    }
    @media screen and (max-width: 768px){
      justify-content: flex-start;
      flex-wrap:wrap;
    }
  }
  .MuiToggleButtonGroup-grouped {
    @media screen and (max-width: 590px){
    width: calc(50% - 16px);
    }
  }
}`,
);

export default StyledLocation;
