/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CssBaseline from '@mui/material/CssBaseline';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ExpandLess } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import logo from '../../assets/svg/logo.svg';
import avatar from '../../assets/svg/avatar.svg';
import StyledPrivateLayout, {
  StyledHeaderMenu,
  StyledHeaderMenuItem,
  AppBar,
  Main,
  StyledDrawer,
} from './StyledPrivateLayout';
import StarIcon from '../../components/icons/StarIcon';
import PersonPinIcon from '../../components/icons/PersonPinIcon';
import CalendarIcon from '../../components/icons/CalendarIcon';
import NoteIcon from '../../components/icons/NoteIcon';
import ContactIcon from '../../components/icons/ContactIcon';
import ArrowDownIcon from '../../components/icons/ArrowDownIcon';
import { ROUTE_PATH } from '../../constants/route';
import UserIcon from '../../components/icons/UserIcon';
import LogoutIcon from '../../components/icons/LogoutIcon';

import ProfileModal from '../../components/Modal/Profile';
import TextField from '../../components/TextField';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import profilePic from '../../assets/svg/profilePic.svg';
import {
  validationEditProfileSchema,
  validateFileType,
  validateFileSize,
} from '../../utils/validations/authValidation';
import { selectorAuthentication, logout } from '../../store/reducers/authentication';
import { resetExpertProfile } from '../../store/reducers/expertProfile';
import {
  fetchEditProfile,
  fetchGetProfile,
  fetchGetRecruiterProfile,
  fetchLogout,
  fetchRecruiterEditProfile,
  fetchRecruiterLogout,
} from '../../store/reducers/authentication/apiThunk';
import changeProfile from '../../assets/svg/changeProfile.svg';
import Modal from '../../components/Modal';
import message from '../../constants/message';
import { ToastNotifyError } from '../../components/Toast/ToastNotify';
import ContactFooter from '../../components/ContactFooter';
import AccountIcon from '../../components/icons/AccountIcon';
import AccountsDetailsModal from '../../components/Common/AccountsDetailsModal';
import FeedbackIcon from '../../components/icons/FeedbackIcon';
import FeedbackIconGray from '../../components/icons/FeedbackIconGray';
import LockIcon from '../../components/icons/LockIcon';
import TalentIcon from '../../components/icons/TalentIcon';
import PostJobIcon from '../../components/icons/PostJobIcon';
import { resetPostJob } from '../../store/reducers/recruiterPostJob';
import TrackJobNavbarIcon from '../../components/icons/TrackJobNavbarIcon';
import SubscriptionNavbarIcon from '../../components/icons/SubscriptionNavbarIcon';
import SuggestedCandidateNavbarIcon from '../../components/icons/SuggestedCandidateNavbarIcon';
import crossIcon from '../../assets/svg/crossIcon3.svg';
import { selectorFractionTalent } from '../../store/reducers/fractionTalent';
import { getRecruiterJob } from '../../store/reducers/recruiterPostJob/apiThunk';

const { MSG_FILE_FORMATE, MSG_FILE_SIZE } = message;

const {
  DASHBOARD,
  // LOGIN,
  TERMS_AND_CONDITIONS,
  LANDING_PAGE,
  CALENDAR,
  FEEDBACKS,
  FRACTION_TALENT,
  RECRUITER_DASHBOARD,
  LANDING,
  JOB_OVERVIEW,
  APPLY_JOB,
  APPLIED_JOB,
  SUBSCRIPTION,
  SAVED_JOB,
  MY_JOB_PROFILE,
  POSTAJOB,
  RECRUITER_SUGGESTED_CANDIDATES,
  RECRUITER_TRACK_APPLICATION,
  RECRUITER_SUBSCRIPTION,
} = ROUTE_PATH;

const listItems = [
  {
    title: 'Dashboard',
    icon: 'dashboard',
    url: DASHBOARD,
  },
  {
    title: 'Landing Page',
    icon: 'landing',
    url: LANDING_PAGE,
  },
  {
    title: 'Calendar',
    icon: 'calendar',
    url: CALENDAR,
  },
  {
    title: 'Feedbacks',
    icon: 'feedbacks',
    url: FEEDBACKS,
  },
];
const recruiterListItems = [
  { title: 'Dashboard', icon: 'dashboard', url: RECRUITER_DASHBOARD },
  { title: 'Post A Job', icon: 'postjob', url: POSTAJOB },
  {
    title: 'Suggested Candidates',
    icon: 'suggestedCandidiate',
    url: RECRUITER_SUGGESTED_CANDIDATES,
  },
  { title: 'Track Job Applications', icon: 'trackJob', url: RECRUITER_TRACK_APPLICATION },
  { title: 'Subscription', icon: 'subscription', url: RECRUITER_SUBSCRIPTION },
];
const subListItems = [
  {
    title: 'My Job Profile',
    url: MY_JOB_PROFILE,
  },
  {
    title: 'Jobs Overview',
    url: JOB_OVERVIEW,
  },
  {
    title: 'Suggested Jobs',
    url: APPLY_JOB,
  },
  {
    title: 'Track Applied Jobs',
    url: APPLIED_JOB,
  },
  {
    title: 'Subscription',
    url: SUBSCRIPTION,
  },
  {
    title: 'Saved Jobs',
    url: SAVED_JOB,
  },
];
const navTitles = [
  ...listItems,
  ...subListItems,
  {
    title: 'Job Profile',
    url: FRACTION_TALENT,
  },
];

const PrivateLayout =
  (Component, isFooterRequired = true) =>
  () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const {
      userData: {
        data: {
          firstName: userFistName = '',
          lastName: userLastName = '',
          primaryEmail: userEmail = '',
          username: userUsername = 'username',
          profileImagePath: userProfileImagePath,
        } = {},
      } = {},
    } = useSelector(selectorAuthentication);

    const { fractionTalent: { data: { jobRole = '' } = {} } = {} } =
      useSelector(selectorFractionTalent);

    const profilePicRef = useRef(null);
    const [openProfileDialog, setOpenProfileDialog] = useState(false);
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const [menuEle, setMenuEle] = useState(null);
    const [file, setFile] = useState(null);
    const [uploadFile, setUploadFile] = useState();
    const [extension, setExtension] = useState('');
    const [mobileOpen, setMobileOpen] = useState(true);
    const [openAccountModal, setOpenAccountModal] = useState(false);
    const isRecruiter = localStorage.getItem('recruiter');
    const [fractionTalentOpen, setFractionTalentOpen] = useState(false);
    const [disableFractionTalent, setDisableFractionTalent] = useState(true);
    const [isJob, setIsJob] = useState(false);

    const open = Boolean(menuEle);
    const { pathname } = location;

    useEffect(() => {
      if (!desktop) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    }, [desktop]);

    useEffect(() => {
      // open fraction talent menu list
      if (pathname.includes('fraction-talent')) {
        setFractionTalentOpen(true);
      }
    }, [pathname]);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const handleCloseProfileDialoag = () => {
      setOpenProfileDialog(false);
    };

    const handleOpenProfileDialoag = () => {
      setOpenProfileDialog(true);
    };

    const handleCloseLogoutDialoag = () => {
      setOpenLogoutModal(false);
    };

    const handleOpenLogoutDialoag = () => {
      setOpenLogoutModal(true);
    };

    const handleOpenAccountDialoag = () => {
      setOpenAccountModal(true);
    };

    const handleCloseAccountDialoag = () => {
      setOpenAccountModal(false);
    };

    const handleLogoutUser = async () => {
      if (isRecruiter) {
        await dispatch(resetPostJob());
        await dispatch(fetchRecruiterLogout());
      } else {
        await dispatch(fetchLogout());
        await dispatch(logout());
        await dispatch(resetExpertProfile());
      }
      await localStorage.clear();
      navigate(LANDING);
    };

    const handleGetNavTitle = () => {
      const selectedItem = isRecruiter
        ? recruiterListItems.filter((item) => item.url === pathname)
        : navTitles.filter((item) => item.url === pathname);

      if (selectedItem.length === 0) {
        const dashboardPath = pathname.slice(0, pathname.lastIndexOf('/'));
        const filteredData = navTitles.filter((item) => item.url === dashboardPath);
        return filteredData.length > 0 ? filteredData[0].title : '';
      }
      return selectedItem[0].title;
    };

    const handleClick = (event) => {
      setMenuEle(event.currentTarget);
    };
    const handleClickTalent = () => {
      if (jobRole) {
        if (pathname.includes('fraction-talent')) {
          setFractionTalentOpen(!fractionTalentOpen);
        } else {
          navigate(MY_JOB_PROFILE);
        }
      }
    };

    const handleClose = (type = '') => {
      setMenuEle(null);
      switch (type) {
        case 'profile':
          handleOpenProfileDialoag(true);
          break;
        case 'logout':
          handleOpenLogoutDialoag();
          break;
        default:
          break;
      }
    };

    useEffect(() => {
      dispatch(getRecruiterJob())
        .then((res) => {
          setIsJob(!!res?.payload?.data);
        })
        .catch((error) => {
          console.error('Error fetching profile:', error);
        });
    }, []);

    const submitHandler = async (values) => {
      const { firstName, lastName } = values;
      const updatedData = {
        firstName,
        lastName,
        profileImagePath: userProfileImagePath || '',
        uploadFile,
        extension,
      };

      if (extension && !validateFileType(extension)) {
        ToastNotifyError(MSG_FILE_FORMATE);
      } else if (uploadFile && validateFileSize(uploadFile)) {
        ToastNotifyError(MSG_FILE_SIZE);
      } else {
        const response = isRecruiter
          ? await dispatch(fetchRecruiterEditProfile(updatedData)).then((val) => val)
          : await dispatch(fetchEditProfile(updatedData)).then((val) => val);

        const { payload: { status } = {} } = response;
        if (status) {
          if (isRecruiter) {
            dispatch(fetchGetRecruiterProfile());
          } else {
            dispatch(fetchGetProfile());
          }
          setOpenProfileDialog(false);
        }
      }
    };

    const formik = useFormik({
      initialValues: {
        firstName: userFistName,
        lastName: userLastName,
        email: userEmail,
        userName: userUsername,
      },
      validationSchema: validationEditProfileSchema,
      onSubmit: submitHandler,
    });
    const {
      handleSubmit,
      values: { firstName, lastName, email, userName } = {},
      touched: {
        firstName: tucFirstName = false,
        lastName: tucLastName = false,
        email: tucEmail = false,
        userName: tucUserName = false,
      } = {},
      errors: {
        firstName: errFirstName,
        lastName: errLastName,
        email: errEmail,
        userName: errUserName,
      } = {},
    } = formik;

    useEffect(() => {
      const { setFieldValue } = formik;
      setFieldValue('firstName', userFistName);
      setFieldValue('lastName', userLastName);
      setFieldValue('email', userEmail);
      setFieldValue('userName', userUsername);
    }, [openProfileDialog]);

    useEffect(() => {
      if (jobRole) {
        setDisableFractionTalent(false);
      } else {
        setDisableFractionTalent(true);
      }
    }, [jobRole]);

    const handleChange = (e, name) => {
      if (['email'].includes(name)) {
        formik.setFieldValue(name, e.target.value.trim());
      } else {
        formik.setFieldValue(name, e.target.value);
        if (['firstName', 'lastName'].includes(name)) {
          const convertedString = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
          formik.setFieldValue(name, convertedString);
        }
      }
    };

    const getColor = (path) => {
      if (pathname === path) {
        return theme.palette.primary.main;
      }
      return theme.palette.customColor.grey_7;
    };

    const handleGetIcon = (type) => {
      switch (type) {
        case 'dashboard':
          return <StarIcon color={getColor(DASHBOARD)} />;
        case 'landing':
          return <PersonPinIcon color={getColor(LANDING_PAGE)} />;
        case 'calendar':
          return <CalendarIcon color={getColor(CALENDAR)} />;
        case 'terms':
          return <NoteIcon color={getColor(TERMS_AND_CONDITIONS)} />;
        case 'feedbacks':
          return pathname === FEEDBACKS ? <FeedbackIcon /> : <FeedbackIconGray />;
        case 'contact':
          return <ContactIcon color={getColor()} />;
        case 'postjob':
          return <PostJobIcon color={getColor()} />;
        case 'trackJob':
          return <TrackJobNavbarIcon color={getColor()} />;
        case 'subscription':
          return <SubscriptionNavbarIcon color={getColor()} />;
        case 'suggestedCandidiate':
          return <SuggestedCandidateNavbarIcon color={getColor()} />;
        default:
          break;
      }
      return '';
    };

    function handleImage(e) {
      setUploadFile(e.target.files[0]);
      setFile(URL.createObjectURL(e.target.files[0]));
    }

    const addImage = (event) => {
      setExtension(event.target.files[0].name.split('.').pop());
      handleImage(event);
    };
    const handleJobProfile = () => {
      navigate(FRACTION_TALENT);
    };

    const handleSubMenuTalent = (e) => {
      e.stopPropagation();
    };

    const profileModalContent = () => (
      <form onSubmit={handleSubmit}>
        <div className="profileFormWrap">
          <div className="wrapper-1">
            <div className="profilePicWrap">
              <img
                src={
                  file ||
                  `${
                    userProfileImagePath
                      ? `${process.env.REACT_APP_IMAGE_URL}/${userProfileImagePath}`
                      : profilePic
                  }`
                }
                alt=""
                className="profileImage"
              />
              <img
                src={changeProfile}
                alt=""
                className="changeProfile"
                onClick={() => profilePicRef.current.click()}
              />
              <input
                ref={profilePicRef}
                type="file"
                accept="image/png, image/jpeg"
                className="fileUpload"
                onChange={(e) => addImage(e)}
              />
            </div>
            <div className="splitInput">
              <TextField
                name="firstName"
                type="text"
                label="First Name"
                important
                onChange={(e) => handleChange(e, 'firstName')}
                value={firstName}
                error={tucFirstName && !!errFirstName}
                helperText={tucFirstName && errFirstName}
              />
              <TextField
                name="lastName"
                type="text"
                label="Last Name"
                important
                onChange={(e) => handleChange(e, 'lastName')}
                value={lastName}
                error={tucLastName && !!errLastName}
                helperText={tucLastName && errLastName}
              />
            </div>
          </div>
          <div>
            <TextField
              name="email"
              type="email"
              disabled
              onChange={(e) => handleChange(e, 'email')}
              value={email}
              error={tucEmail && !!errEmail}
              helperText={tucEmail && errEmail}
              label="Email"
              important
            />
          </div>
          <div>
            <TextField
              name="userName"
              type="text"
              disabled
              onChange={(e) => handleChange(e, 'userName')}
              value={userName}
              error={tucUserName && !!errUserName}
              helperText={tucUserName && errUserName}
              label="Username"
              important
            />
          </div>
          <div>
            <PrimaryButton
              type="submit"
              sx={{
                marginTop: '15px',
              }}>
              Save
            </PrimaryButton>
          </div>
        </div>
      </form>
    );

    const logoutModalContent = () => (
      <>
        <div>Are you sure you want to Logout?</div>
        <div className="logout-button-wrap">
          <SecondaryButton border onClick={() => handleLogoutUser()}>
            Yes
          </SecondaryButton>
          <PrimaryButton onClick={() => handleCloseLogoutDialoag()}>No</PrimaryButton>
        </div>
      </>
    );

    return (
      <StyledPrivateLayout>
        <ProfileModal
          openDialog={openProfileDialog}
          closeDialog={handleCloseProfileDialoag}
          dialogContent={profileModalContent()}
          noHeader={false}
          heading="Profile"
          dialogCss="profileDialodWrap"
        />
        <Modal
          openDialog={openLogoutModal}
          closeDialog={handleCloseLogoutDialoag}
          dialogContent={logoutModalContent()}
          noHeader={false}
          heading="Logout"
          dialogCss="logoutDialogWrap"
        />
        <CssBaseline />

        <AppBar position="fixed" open={mobileOpen}>
          <Toolbar className="main-header">
            <div style={{ display: 'flex' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  color: 'white',
                  mr: 2,
                  display: { sm: `${mobileOpen ? 'none' : ''}` },
                }}>
                <MenuIcon />
              </IconButton>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 700,
                  fontSize: '24px',
                }}
                variant="h6"
                noWrap
                component="div">
                {handleGetNavTitle()}
              </Typography>
            </div>
            <div className="profile-wrap">
              <Avatar
                alt="Remy Sharp"
                src={
                  userProfileImagePath
                    ? `${process.env.REACT_APP_IMAGE_URL}/${userProfileImagePath}`
                    : avatar
                }
              />
              <Typography className="title" noWrap component="div">
                {userFistName}
              </Typography>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                  minWidth: 'auto',
                }}>
                <ArrowDownIcon />
              </Button>
              <StyledHeaderMenu
                className="header-menu"
                anchorEl={menuEle}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <StyledHeaderMenuItem onClick={() => handleClose('profile')}>
                  <UserIcon width={25} height={25} />
                  My Profile
                </StyledHeaderMenuItem>
                <StyledHeaderMenuItem onClick={() => handleClose('logout')}>
                  <LogoutIcon width={25} height={25} />
                  Logout
                </StyledHeaderMenuItem>
              </StyledHeaderMenu>
            </div>
          </Toolbar>
        </AppBar>
        {isRecruiter ? (
          <>
            {!desktop && mobileOpen && (
              <div
                className="cross-navbar"
                style={{ position: 'absolute', zIndex: '999999', right: '80px', top: '90px' }}
                onClick={handleDrawerToggle}>
                <img src={crossIcon} alt="cross" width="35px" />
              </div>
            )}
            <StyledDrawer
              open={mobileOpen}
              // onClose={handleDrawerToggle}
              variant={`${desktop ? 'permanent' : 'temporary'}`}
              anchor="left"
              sx={{
                '& .MuiDrawer-paper': {
                  width: desktop ? '250px' : '300px',
                },
              }}>
              <Toolbar sx={{ marginTop: desktop ? '0px' : '80px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={logo} alt="" />
                </Box>
              </Toolbar>
              <List className="link-wrap">
                {recruiterListItems.map(({ title, icon, url }) => {
                  const isDisabled =
                    !isJob &&
                    (title === 'Suggested Candidates' || title === 'Track Job Applications');

                  const handleClickLink = (event) => {
                    if (isDisabled) {
                      event.preventDefault();
                    }
                  };
                  return (
                    <div key={title} className="listDiv">
                      <Link to={url} onClick={handleClickLink} key={url}>
                        <ListItem
                          className={`${isDisabled ? 'disabledItem' : ''} ${
                            (url === pathname ||
                              url === pathname.slice(0, pathname.lastIndexOf('/'))) &&
                            !isDisabled
                              ? 'active'
                              : ''
                          }`}
                          key={title}
                          disablePadding>
                          <ListItemButton disabled={isDisabled}>
                            <ListItemIcon>{handleGetIcon(icon)}</ListItemIcon>
                            <ListItemText primary={title} />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    </div>
                  );
                })}
              </List>
            </StyledDrawer>
          </>
        ) : (
          <StyledDrawer
            open={mobileOpen}
            onClose={handleDrawerToggle}
            variant={`${desktop ? 'permanent' : 'temporary'}`}
            anchor="left">
            <Toolbar>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo} alt="" />
              </Box>
              <IconButton
                className="drawerIcon"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="end"
                sx={{
                  color: 'white',
                  padding: '0px',
                  ...(open && { display: 'none' }),
                }}>
                {mobileOpen ? (
                  <img
                    src={crossIcon}
                    alt="cross"
                    width="28px"
                    height="28px"
                    style={{ paddingLeft: '8px' }}
                  />
                ) : (
                  ''
                )}
              </IconButton>
            </Toolbar>
            <List className="link-wrap">
              {listItems.map(({ title, icon, url }) => (
                <div key={title} className="listDiv">
                  <Link to={url} key={url}>
                    <ListItem
                      className={
                        url === pathname || url === pathname.slice(0, pathname.lastIndexOf('/'))
                          ? 'active'
                          : ''
                      }
                      key={title}
                      disablePadding>
                      <ListItemButton>
                        <ListItemIcon>{handleGetIcon(icon)}</ListItemIcon>
                        <ListItemText primary={title} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </div>
              ))}
              <div className="listDiv" onClick={() => handleOpenAccountDialoag()}>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <AccountIcon width={20} height={20} />
                    </ListItemIcon>
                    <ListItemText primary="Bank Account Details" />
                  </ListItemButton>
                </ListItem>
              </div>
              <div className="listDiv">
                <ListItem
                  disablePadding
                  disabled={disableFractionTalent}
                  className={pathname.includes(FRACTION_TALENT) ? 'active' : ''}
                  onClick={() => handleClickTalent()}>
                  <ListItemButton>
                    <ListItemIcon>
                      <TalentIcon
                        width={20}
                        height={20}
                        color={
                          pathname.includes(FRACTION_TALENT)
                            ? theme.palette.primary.main
                            : theme.palette.customColor.grey_7
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Fractional Expert" />
                    <ListItemIcon style={{ marginRight: ' -8px' }}>
                      {disableFractionTalent ? (
                        <LockIcon width={20} height={20} />
                      ) : fractionTalentOpen ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                <Collapse in={fractionTalentOpen} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    sx={{ ml: 4, mt: 1, borderLeft: '1px solid #E2E4E7' }}>
                    {subListItems.map(({ title, url }) => (
                      <div key={title} onClick={handleSubMenuTalent}>
                        <Link to={url} key={url}>
                          <ListItem disablePadding sx={{ my: 1 }}>
                            <ListItemButton
                              sx={{ ml: 1 }}
                              className={
                                url === pathname ? 'fraction-sub-list active' : 'fraction-sub-list'
                              }>
                              <ListItemText primary={title} />
                            </ListItemButton>
                          </ListItem>
                        </Link>
                      </div>
                    ))}
                  </List>
                </Collapse>
              </div>
            </List>
            <div className="fraction-talent-poster">
              <div className="top-heading">
                Hey <span className="blue">Expert!</span> 👀
              </div>
              <div className="title">Looking for fractional jobs? </div>
              <div className="sub-title">
                You can now apply for fractional jobs tailored to your expertise.{' '}
              </div>
              <PrimaryButton onClick={handleJobProfile}>Create Job Profile</PrimaryButton>
            </div>
          </StyledDrawer>
        )}

        <Main component="mainWrap" open={mobileOpen}>
          <Component />
          {isFooterRequired ? <ContactFooter /> : null}
        </Main>

        <AccountsDetailsModal
          openDialog={openAccountModal}
          closeDialog={handleCloseAccountDialoag}
        />
      </StyledPrivateLayout>
    );
  };

export default PrivateLayout;
