/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import { Box, Grid } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../../assets/svg/logo.svg';
import StyledLayout from './StyledLayout';
import { ROUTE_PATH } from '../../../constants/route';
import { selectorExpertProfile } from '../../../store/reducers/expertProfile';
import starBg from '../../../assets/svg/startBg.svg';
// import { fetchLandingPageDataByUserName } from '../../../store/reducers/expertProfile/apiThunk';

const PreviewLayout = (Component) => () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const [tabValue, setTabValue] = useState();

  // const fetchUserData = async () => {
  //   await dispatch(fetchLandingPageDataByUserName(params));
  // };

  // useEffect(() => { fetchUserData(); }, []);

  const { userLandingData: { data: { expert: { username = '' } = {} } = {} } = {} } =
    useSelector(selectorExpertProfile);

  const getTabValue = (value) => {
    if (Object.keys(params).length === 0) {
      switch (value) {
        case ROUTE_PATH.PREVIEW_ABOUT:
          setTabValue(1);
          break;
        case ROUTE_PATH.PREVIEW_SERVICES:
          setTabValue(2);
          break;
        case ROUTE_PATH.PREVIEW_LINKS:
          setTabValue(3);
          break;
        case ROUTE_PATH.EXPERT_FEEDBACK:
          setTabValue(4);
          break;
        default:
          setTabValue(0);
      }
    } else {
      switch (value) {
        case `${ROUTE_PATH.PREVIEW_ABOUT}/${params.username}`:
          setTabValue(1);
          break;
        case `${ROUTE_PATH.PREVIEW_SERVICES}/${params.username}`:
          setTabValue(2);
          break;
        case `${ROUTE_PATH.PREVIEW_LINKS}/${params.username}`:
          setTabValue(3);
          break;
        case `${ROUTE_PATH.EXPERT_FEEDBACK}/${params.username}`:
          setTabValue(4);
          break;
        default:
          setTabValue(0);
      }
    }
  };

  useEffect(() => {
    getTabValue(location.pathname);
  }, []);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    if (Object.keys(params).length === 0) {
      switch (newValue) {
        case 1:
          navigate(ROUTE_PATH.PREVIEW_ABOUT);
          break;
        case 2:
          navigate(ROUTE_PATH.PREVIEW_SERVICES);
          break;
        case 3:
          navigate(ROUTE_PATH.PREVIEW_LINKS);
          break;
        case 4:
          navigate(ROUTE_PATH.EXPERT_FEEDBACK);
          break;
        default:
          if (Object.keys(params).length !== 0) {
            navigate(`/${username}/`);
          } else {
            navigate(ROUTE_PATH.PREVIEW_PAGE);
          }
      }
    } else {
      switch (newValue) {
        case 1:
          navigate(`${ROUTE_PATH.PREVIEW_ABOUT}/${params.username}`);
          break;
        case 2:
          navigate(`${ROUTE_PATH.PREVIEW_SERVICES}/${params.username}`);
          break;
        case 3:
          navigate(`${ROUTE_PATH.PREVIEW_LINKS}/${params.username}`);
          break;
        case 4:
          navigate(`${ROUTE_PATH.EXPERT_FEEDBACK}/${params.username}`);
          break;
        default:
          if (Object.keys(params).length !== 0) {
            navigate(`/${params.username}/`);
          } else {
            navigate(ROUTE_PATH.PREVIEW_PAGE);
          }
      }
    }
  };

  const stepperDots = () => (
    <div className="dots-wrap">
      <MobileStepper variant="dots" steps={5} position="static" activeStep={tabValue} />
    </div>
  );
  return (
    <StyledLayout>
      <div className="preview-page-wrap">
        <Grid container className="next_layer_wrap">
          <Grid xs={3} sm={5} item className="left_image_wrap">
            <img src={starBg} alt="" />
          </Grid>
          <Grid xs={9} sm={7} item className="right_image_wrap" />
        </Grid>
        <div className="preview-content-wrap">
          <Box position="relative" className="header-wrap">
            <div>
              <img src={logo} alt="" className="logoImage" />
            </div>
            {Object.keys(params).length === 0 ? (
              <div className="closePreview-btn-wrap">
                <Box className="closePreview-btn" onClick={() => navigate('/landing')}>
                  <CloseIcon />
                  <div className="webCloseText">Close Preview</div>
                </Box>
              </div>
            ) : (
              ''
            )}
          </Box>

          <div className="component-wrap">
            <Box
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%">
              <Box
                width={{ xs: 'calc(100% - 32px)', sm: 'calc(100% - 64px)', md: '80%' }}
                overflowY="auto"
                maxWidth="lg">
                <Component />
              </Box>
              <Box
                position="absolute"
                top="calc(50% - 64px)"
                right="24px"
                display={{ xs: 'none', md: 'block' }}>
                {stepperDots()}
              </Box>
            </Box>
          </div>
          <Box className="footer-wrap">
            <div className="footer_nav_items">
              {['Home', 'About', 'Services', 'Posts', 'Feedback'].map((item, index) => (
                <div
                  className="tabs_inactive"
                  key={`tabs-${item}`}
                  onClick={() => handleTabChange(index)}>
                  <div className={`${tabValue === index ? 'active_tab' : ''}`}>{item}</div>
                  <div className={`${tabValue === index ? 'active_tab_border' : ''}`} />
                </div>
              ))}
            </div>
          </Box>
        </div>
      </div>
    </StyledLayout>
  );
};

export default PreviewLayout;
