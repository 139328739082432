import { styled } from '@mui/material/styles';

const StyledTracksJobs = styled('div')(
  ({ theme }) => `
  .page-wrap {
    margin:20px;
    min-height:calc(100dvh - 120px);
    background: ${theme.palette.secondary.main};
    border-radius:12px;
    padding:20px; 
    @media screen and (max-width: 900px){
     margin:0px;
     padding:0px;
    }
    @media only screen and (min-width: 901px) and (max-width: 1099px)  {
      margin:20px 10px;
      max-width:780px;
   }
    .section-title{
      font-family: Plus Jakarta Sans;
      font-size: 20px;
      font-weight: 600;
      line-height: 25.2px;
      text-align: left;
      color:#1C1C1C;
      margin-bottom:8px;
      @media screen and (max-width: 900px){
        display:none;
      }
    }
    
    .filter-radio-wrap{
      margin-top:20px;
      margin-bottom:20px;
      text-align:left;
      overflow-x:scroll;
      .MuiToggleButtonGroup-root{
        flex-wrap:nowrap;
        .MuiToggleButtonGroup-grouped{
          white-space: nowrap;
        }
      }
      ::-webkit-scrollbar{
        display:none;
      }
      @media screen and (max-width: 900px){
        padding: 8px 0px 16px 16px;
        margin:0px;
        background:#F8F4F0;
      }
    }
    .section-title-mob{
      display:none;
      @media screen and (max-width: 900px){
        display:block;
        margin:16px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.16px;
        text-align:left;
        margin-bottom:0px;
      }
    }
    .job-card-list-wrap{ 
      .job-card-wrap{
        padding:12px;
        border-radius:10px;
        background: ${theme.palette.secondary.main};
        border:1px solid #E2E4E7;
        height:100%;
        .card-top{
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-bottom:16px;
            .right{
                display: flex;
                align-items: start;
                max-width:calc(100% - 124px);
                gap:8px;
                .brand-image{
                    width: 40px;
                    height: 40px;
                    gap: 0px;
                    border-radius: 50%;
                    @media screen and (max-width: 900px){
                      min-width:40px;
                      max-width:40px;
                    }
                }
                .job-title{
                    font-family: Sora;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20.16px;
                    text-align: left;
                    color: ${theme.palette.customColor.primaryBlack};
                }
                .job-postedby{
                    font-family: Plus Jakarta Sans;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17.64px;
                    text-align: left;
                    color: #525252;
                }
                .skill-match{
                    font-family: Plus Jakarta Sans;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15.12px;
                    text-align: left;
                    color:#00AF31;
                }
            }
            .left {
                display:inline-flex;
                align-items:center;
                gap:10px;
                img{
                  cursor:pointer;
                }
                .jobstatus-chip {
                  padding:4px 8px;
                  font-family: Plus Jakarta Sans;
                  font-size: 10px;
                  font-weight: 700;
                  line-height: 12.6px;
                  letter-spacing: 0.08em;
                  text-align: center;
                  border-radius:40px;
                  text-transform:uppercase;
                }
                /* Specific styles for each status */

                .applied {
                  border: 1px solid #415DF3;
                  color: #415DF3;
                  background: #415DF31A;
                }

                .accepted {
                  border: 1px solid #00AF31;
                  color: #00AF31;
                   background: #00AF311A;
                  ;
                }

                .interviewScheduled {
                  border: 1px solid #E16C00;
                  color: #E16C00;
                  background:#E16C001A;
                }

                .underReview {
                  border: 1px solid #E1A800;
                  color: #E1A800;
                  background: #E1A8001A;
                }

                .closed {
                  border: 1px solid #1C1C1C;
                  color: #1C1C1C;
                  background: #1C1C1C1A;
                }

                .rejected {
                  border: 1px solid #DA3333;
                  color: #DA3333;
                  background: #FFE3E3;
                }
            }
        }
        .skill-chips-box{
            marging-top:16px;
            margin-bottom:12px;
            display:flex;
            align-items:center;
            gap:4px;
            overflow-x:scroll;
            max-width:790px;
            &::-webkit-scrollbar {
              height: 4px;
            }
            .chips{
                background:#F4F4F4;
                padding:4px 8px;
                font-family: Plus Jakarta Sans;
                font-size: 12px;
                font-weight: 500;
                line-height: 15.12px;
                text-align: left;
                border-radius:40px;
                white-space: nowrap;
                margin-bottom: 8px;
            }
        }
        .card-bottom {
          .job-info-content{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          }
          .icon-text-box-wrap{
            display: flex;
            flex-direction:column;
            gap:8px;
              .icon-text-box{
                display: inline-flex;
                align-items: center;
                gap:4px;
              }
          }
          .sub-text{
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-weight: 500;
            line-height: 17.64px;
            text-align: left;
          }
          .view-details{
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-weight: 600;
            line-height: 17.64px;
            text-align: left;
            text-decoration:underline;
            color:#415DF3;
            cursor:pointer;
            min-width:84px;
          }
          .date-text{
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-weight: 500;
            line-height: 17.64px;
            text-align: left;
            color:#415DF3;
            margin-top:12px;
          }
        }
      }
    }
    .no-result-box{
      display:flex;
      align-items:center;
      justify-content:center;
      padding-top:10vh;
    } 
  }
`,
);

export default StyledTracksJobs;

export const StyledPaginationBox = styled('div')(
  ({ theme }) => `
  .jobs-pagination-box {
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:0px 20px;
    .left-wrap{
      .MuiTablePagination-displayedRows{
          color: ${theme.palette.customColor.primaryBlack};
          font-size: 16px;        
      }
      .MuiTablePagination-actions{
         display:none;
      }
    }
    .right-wrap{
      .MuiPaginationItem-root{
        background:#ffffff;
        color: ${theme.palette.customColor.primaryBlack};
        border:1px solid #E2E4E7;
        border-radius:10px;
        height:32px;
        &.Mui-selected{
          background: ${theme.palette.primary.main};
          color:#ffffff;
         
        }
      }
    }
  }
`,
);
