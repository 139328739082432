/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { Avatar, Dialog } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import share from '../../../assets/svg/share-circle.svg';
import close from '../../../assets/svg/gray-close-circle.svg';
import { PrimaryButton } from '../../../components/Button';
import coin from '../../../assets/svg/coin-jobs.svg';
import locationPoint from '../../../assets/svg/location-jobs.svg';
import StyledJobsOverview, {
  StyledJobsDetailsModal,
} from '../../jobs/jobsOverview/StyledJobsOverview';
import { checkCompensationType, stringAvatar } from '../../../helper';
import { ROUTE_PATH } from '../../../constants/route';
import JobShareModal from '../../jobs/jobsOverview/JobShareModal';

const ActiveJobDetails = ({ openDialog, closeDialog, jobDetails }) => {
  const [openDialogShare, setOpenDialogShare] = useState();
  const [shareId, setShareId] = useState();
  const JobdetailsModalContent = () => {
    const navigate = useNavigate();
    // const location = useLocation();

    const durationCheckSuggestion = (item, suggNo) => {
      const isPlural = suggNo > 1;
      switch (item) {
        case 'daily':
          return isPlural ? 'Days' : 'Day';
        case 'weekly':
          return isPlural ? 'Weeks' : 'Week';
        default:
          return isPlural ? 'Months' : 'Month';
      }
    };
    const { POSTAJOB } = ROUTE_PATH;

    const handleOpenShareDialog = (item) => {
      const jobId = item?._id;
      setShareId(jobId);
      setOpenDialogShare(true);
    };
    return (
      <StyledJobsDetailsModal>
        <div className="jobdetails-modalContent-wrap">
          <div className="job-card-wrap">
            <div className="card-header card-top">
              <div className="right">
                <div className="title">Job Details</div>
              </div>
              <div className="left">
                <div className="icons-btns">
                  <img
                    className="brand-image"
                    src={share}
                    alt=""
                    onClick={() => handleOpenShareDialog(jobDetails)}
                  />
                  <img className="brand-image" src={close} alt="" onClick={closeDialog} />
                </div>
              </div>
            </div>
            <div className="card-top">
              <div className="right">
                {jobDetails?.companyLogo ? (
                  <img
                    className="brand-image"
                    src={`${process.env.REACT_APP_IMAGE_URL}/${jobDetails?.companyLogo}`}
                    alt=""
                  />
                ) : (
                  <Avatar {...stringAvatar(jobDetails?.companyName)} />
                )}

                <div>
                  <div className="job-title">{jobDetails?.jobRole}</div>
                  <div className="job-postedby">{jobDetails?.companyName}</div>
                </div>
              </div>
              <div className="left">
                <div>
                  <PrimaryButton onClick={() => navigate(POSTAJOB)} sx={{ width: '450px' }}>
                    Edit Job
                  </PrimaryButton>
                </div>
              </div>
            </div>
            <div className="job-content-wrap">
              <Grid container>
                <Grid item md={7} xs={12}>
                  <div>
                    <div className="skills-heading">Skills required</div>
                    <div className="skill-chips-box">
                      {jobDetails?.skills?.map((skill) => (
                        <span key={skill} className="chips">
                          {skill}
                        </span>
                      ))}
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Company/Job location</div>
                    <div className="icon-text-box">
                      <img alt="" src={locationPoint} />
                      <div className="sub-text">
                        {jobDetails?.jobCity}, {jobDetails?.jobState}{' '}
                        <span style={{ color: '#757575', lineHeight: '8px' }}> • </span>{' '}
                        <span>{jobDetails?.locationType}</span>
                      </div>
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Compensation</div>
                    <div className="icon-text-box">
                      <img alt="" src={coin} />
                      <div className="sub-text">
                        ₹ {jobDetails?.compensationRate}/
                        {`${checkCompensationType(jobDetails?.compensationType)}`}
                      </div>
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Job time</div>
                    <div className="skill-chips-box">
                      {jobDetails?.availabilityPeriod}{' '}
                      {durationCheckSuggestion(
                        jobDetails?.availableFor,
                        jobDetails?.availabilityPeriod,
                      )}
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Domain</div>
                    <div className="sub-text">{jobDetails?.domain}</div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Company website</div>
                    <div className="desc">
                      <a
                        className="job-links"
                        href={jobDetails?.companyWebsite}
                        target="_blank"
                        rel="noreferrer">
                        {jobDetails?.companyWebsite}
                      </a>
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Check the job posting on company’s portal</div>
                    <div className="doc-link">
                      <a
                        className="job-links"
                        href={jobDetails?.portalLink}
                        target="_blank"
                        rel="noreferrer">
                        {jobDetails?.portalLink}
                      </a>
                    </div>
                    <div className="divider" />
                  </div>
                  <div>
                    <div className="skills-heading">Job Description</div>
                    {jobDetails?.jobDescription}
                  </div>
                </Grid>
                <Grid item md={1} xs={0} />
                <Grid item md={4} xs={12}>
                  <div className="more-details-wrap">
                    <div className="heading">More Details</div>
                    <div className="content">
                      <div>
                        <div className="title">Designation</div>
                        <div className="desc">{jobDetails?.designation}</div>
                      </div>
                      <div>
                        <div className="title">Contact</div>
                        <div className="desc">{jobDetails?.phoneNumber}</div>
                      </div>
                      <div>
                        <div className="title">Company Address</div>
                        <div className="desc">{jobDetails?.companyAddress}</div>
                      </div>
                      <div>
                        <div className="title">Company Type</div>
                        <div className="desc">{jobDetails?.companyType}</div>
                      </div>
                      <div>
                        <div className="title">LinkedIn profile</div>
                        <div className="desc">
                          <a
                            className="job-links"
                            href={jobDetails?.linkedinProfile}
                            target="_blank"
                            rel="noreferrer">
                            {jobDetails?.linkedinProfile}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </StyledJobsDetailsModal>
    );
  };

  const handleCloseShareDialog = () => {
    setOpenDialogShare(false);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={closeDialog}
        sx={{
          marginTop: '64px',
          '& .MuiDialog-paper': {
            borderTopLeftRadius: '64px',
            borderTopRightRadius: '64px',
          },
        }}>
        {JobdetailsModalContent()}
      </Dialog>
      <StyledJobsOverview>
        <JobShareModal
          openDialog={openDialogShare}
          closeDialog={handleCloseShareDialog}
          id={shareId}
        />
      </StyledJobsOverview>
    </>
  );
};

export default ActiveJobDetails;
