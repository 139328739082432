import { styled } from '@mui/material/styles';

const StyledJobApplication = styled('div')(
  ({ theme }) => `
    .page-wrap {
      padding: 15px;
      background: white;
      margin: 40px 40px;
      border-radius: 12px;

      @media screen and (max-width: 1200px) {
        margin: 20px 0px;
        border-radius: 0px;
      }
    }

    .sec-1 {
      .header-1 {
        color: ${theme.palette.customColor.primaryBlack};
        font-family: "Plus Jakarta Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
      }

      .job-application-card {
        margin-top: 20px;
        border: 1px solid #E2E4E7;
        border-radius: 12px;
        padding: 20px;

        .active-job-head {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .user {
            display: flex;
            gap: 10px;
            align-items: center;

            .dp-domain {
              .user-work {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Sora";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
              }

              .user-name {
                color: ${theme.palette.customColor.secondaryGrey};
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
              }
            }
          }

          .active-job {

              @media screen and (max-width: 1200px) {
                display: none;
              }
            .date-posted {
              color: ${theme.palette.customColor.grey_7};
              font-family: "Plus Jakarta Sans";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-align: left;
            }

            .date {
              color: ${theme.palette.customColor.primaryBlack};
              margin-top: 5px;
              font-family: "Plus Jakarta Sans";
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-align: left;
            }
          }
        }

        .job-skills {
          margin-top: 40px;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          .user-application {
            width: auto;
            background-color: ${theme.palette.customColor.grey_5};
            padding: 5px 10px;
            border-radius: 12px;
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
          }
        }

        .user-info {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          align-items: end;

          .location {
            display: flex;
            gap: 10px;
            align-items: center;
          }

          .user-charge {
            display: flex;
            gap: 10px;
            align-items: center;
          }

          .user-availability {
            display: flex;
            gap: 10px;
            align-items: center;
          }
        }
        .view-details-btn {
          @media screen and (max-width: 1200px) {
            display: none;
            }
        }
        
                .mobile-date-view {
                  .active-job-mobile {
                    .date-posted {
                      font-family: "Plus Jakarta Sans";
                      color: ${theme.palette.customColor.grey_7};
                      font-style: normal;
                      font-weight: 600;
                      font-size: 14px;
                      text-align: left;
                      line-height: normal;
                    }
                    .date {
                      color: ${theme.palette.customColor.primaryBlack};
                      margin-top: 5px;
                      font-family: "Plus Jakarta Sans";
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      text-align: left;
                    }
                  }
                    margin-top: 10px;
                    display: none;
                    @media screen and (max-width: 1200px) {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    }
                  }      
      }
    }
    .sec-2 {
      margin-top: 20px;
      .mobile-view {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-2 {
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-align: left;

          @media screen and (max-width: 1200px) {
            font-size: 16px;
          }
        }
        .export-btn-mobile {
          display: none;
          @media screen and (max-width: 1200px) {
            display: block; 
          }
        }  
      }
      .filter {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .filter-radio-wrap {
          @media screen and (max-width: 1200px) {
            width: 250%;
            overflow: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;

            .filter-radio-wrap::-webkit-scrollbar {
              display: none;
            }
          }  
        }
        .btn {
          width: 150px;
          .btn-2 {
            display: flex;
            gap: 10px;
            @media screen and (max-width: 1200px) {
            display: none; 
          }
          }
        }
      }
        
      .table {
        border: 1px solid #E2E4E7;
        margin-top: 20px;
        border-radius: 12px;
         @media screen and (max-width: 1200px) {
            display: none; 
          }
        .no-border {
          border: none;
        }
        .t-header {
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .t-body {
          color: ${theme.palette.customColor.primaryBlack};
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        .underline-link {
          text-decoration: underline;
          color: #0D2BC8;
        }    
      }

      .each-applicant {
        border: 1px solid #E2E4E7;
        padding: 10px 10px;
        border-radius: 12px;
        margin: 20px 0px;
        display: none;
         @media screen and (max-width: 1200px) {
            display: block; 
          }
        .mobile-header {
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
          }
          .mobile-body {
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
          }
        .mobile-app-name {
          display: flex;
          gap: 30px;
          margin: 20px 20px;
          justify-content: center;
        }
        .mobile-date {
          display: flex;
          gap: 30px;
          margin: 20px 20px;
          justify-content: center;
          } 
          .mobile-view-details {
            cursor: pointer;
            text-decoration: underline;
            color: #0D2BC8;
            margin: 10px 0px;
        }   
      }
    }  
  `,
);

export default StyledJobApplication;
