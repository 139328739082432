import { createSlice } from '@reduxjs/toolkit';
import {
  createRecruiterJob,
  fetchExportApplicants,
  fetchRecruiterApplications,
  fetchRecruiterSearchingFilters,
  getRecruiterJob,
  getRecruiterJobDetails,
  updateRecruiterApplicationStatus,
} from './apiThunk';

const initialData = {
  recruiterBasic: {},
  recruiterJobDetails: {},
  recruiterLocation: {},
  recruiterAvailability: {},
  loading: false,
  error: null,
  jobData: {},
  recruiterFilterData: {},
  previousJobDta: {},
};

const postJobSlice = createSlice({
  name: 'RecruiterPostJob',
  initialState: { ...initialData },
  reducers: {
    resetPostJob: (state) => {
      state.recruiterBasic = {};
      state.recruiterJobDetails = {};
      state.recruiterLocation = {};
      state.recruiterAvailability = {};
    },
    setRecruiterBasic: (state, action) => {
      state.recruiterBasic = action.payload;
    },
    setRecruiterJobDetails: (state, action) => {
      state.recruiterJobDetails = action.payload;
    },
    setRecruiterLocation: (state, action) => {
      state.recruiterLocation = action.payload;
    },
    setRecruiterAvailability: (state, action) => {
      state.recruiterAvailability = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRecruiterJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createRecruiterJob.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createRecruiterJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to create job';
      })
      .addCase(getRecruiterJobDetails.fulfilled, (state, action) => {
        state.jobData = action.payload;
      })
      .addCase(getRecruiterJob.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.previousJobDta = action.payload;
      })
      .addCase(fetchRecruiterSearchingFilters.fulfilled, (state, action) => {
        state.recruiterFilterData = action.payload;
      })
      .addCase(fetchRecruiterApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRecruiterApplications.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateRecruiterApplicationStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateRecruiterApplicationStatus.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchExportApplicants.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExportApplicants.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      });
  },
});

const {
  resetPostJob,
  setRecruiterBasic,
  setRecruiterJobDetails,
  setRecruiterLocation,
  setRecruiterAvailability,
} = postJobSlice.actions;

const selectorPostJob = (state) => state.RecruiterPostJob;

export {
  resetPostJob,
  setRecruiterBasic,
  setRecruiterJobDetails,
  setRecruiterLocation,
  setRecruiterAvailability,
  selectorPostJob,
};

export default postJobSlice.reducer;
