import { createSlice } from '@reduxjs/toolkit';
import { ToastNotifyError, ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import {
  fetchLandingSuggestions,
  fetchLandingPageData,
  fetchLandingPage,
  fetchDeleteService,
  fetchUpdateService,
  linkToCalendar,
  fetchLandingPageDataByUserName,
  fetchConnectAccountLink,
  fetchExpertSession,
  fetchSessionDetail,
  fetchExpertSessionCancel,
  fetchDashboardStatistics,
  fetchDashboardSessions,
  fetchCreateIssue,
  fetchBankDetails,
} from './apiThunk';

const initialData = {
  suggestions: {},
  userLandingData: {},
  calendarData: {},
  seekerPageData: {},
  dashboardStatistics: {},
  bankDetails: {},
  temp: '',
};
const expertProfileSlice = createSlice({
  name: 'expertProfile',
  initialState: { ...initialData },
  reducers: {
    resetExpertProfile: () => initialData,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLandingSuggestions.fulfilled, (state, action) => {
      state.suggestions = action.payload;
    });
    builder.addCase(fetchLandingSuggestions.rejected, (state, action) => {
      state.suggestions = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchLandingPage.fulfilled, () => {
      // ToastNotifySuccess(action.payload.message);
    });
    builder.addCase(fetchLandingPage.rejected, () => {
      // ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchLandingPageData.fulfilled, (state, action) => {
      console.log(action.payload, 'action.payload');
      state.userLandingData = action.payload;
    });
    builder.addCase(fetchLandingPageData.rejected, (state, action) => {
      state.userLandingData = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchLandingPageDataByUserName.fulfilled, (state, action) => {
      state.userLandingData = action.payload;
    });
    builder.addCase(fetchLandingPageDataByUserName.rejected, (state, action) => {
      state.userLandingData = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchUpdateService.fulfilled, (state, action) => {
      ToastNotifySuccess(action.payload.message);
    });
    builder.addCase(fetchUpdateService.rejected, (state, action) => {
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchDeleteService.fulfilled, (state, action) => {
      ToastNotifySuccess(action.payload.message);
    });
    builder.addCase(fetchDeleteService.rejected, (state, action) => {
      ToastNotifyError(action.error.message);
    });
    builder.addCase(linkToCalendar.fulfilled, () => {
      // ToastNotifySuccess(action.payload.message);
    });
    builder.addCase(linkToCalendar.rejected, (state, action) => {
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchConnectAccountLink.fulfilled, () => {
      // ToastNotifySuccess(action.payload.message);
    });
    builder.addCase(fetchConnectAccountLink.rejected, (state, action) => {
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchExpertSession.fulfilled, (state, action) => {
      state.calendarData = action.payload;
    });
    builder.addCase(fetchExpertSession.rejected, (state, action) => {
      state.calendarData = action.payload;
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchSessionDetail.fulfilled, () => {
      // ToastNotifySuccess(action.payload.message);
    });
    builder.addCase(fetchSessionDetail.rejected, (state, action) => {
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchExpertSessionCancel.fulfilled, (state, action) => {
      ToastNotifySuccess(action?.payload?.message);
    });
    builder.addCase(fetchExpertSessionCancel.rejected, (action) => {
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchDashboardStatistics.fulfilled, (state, action) => {
      state.dashboardStatistics = action.payload;
    });
    builder.addCase(fetchDashboardStatistics.rejected, () => {});
    builder.addCase(fetchDashboardSessions.fulfilled, () => {});
    builder.addCase(fetchDashboardSessions.rejected, (action) => {
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchCreateIssue.fulfilled, (state, action) => {
      ToastNotifySuccess(action.payload.message);
    });
    builder.addCase(fetchCreateIssue.rejected, (action) => {
      ToastNotifyError(action.error.message);
    });
    builder.addCase(fetchBankDetails.fulfilled, (state, action) => {
      state.bankDetails = action.payload;
    });
  },
});

const { reducer, actions } = expertProfileSlice;
const selectorExpertProfile = (state) => state.expertProfile;
const { resetExpertProfile } = actions;
export { resetExpertProfile, selectorExpertProfile };

export default reducer;
