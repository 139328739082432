/* eslint-disable arrow-body-style */
import React from 'react';

const SmallEducation = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.70001 1.6862L2.68668 4.3062C1.40001 5.1462 1.40001 7.0262 2.68668 7.8662L6.70001 10.4862C7.42001 10.9595 8.60668 10.9595 9.32668 10.4862L13.32 7.8662C14.6 7.0262 14.6 5.15287 13.32 4.31287L9.32668 1.69287C8.60668 1.21287 7.42001 1.21287 6.70001 1.6862Z"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75374 8.71875L3.74707 11.8454C3.74707 12.6921 4.4004 13.5987 5.2004 13.8654L7.32707 14.5721C7.69374 14.6921 8.3004 14.6921 8.67374 14.5721L10.8004 13.8654C11.6004 13.5987 12.2537 12.6921 12.2537 11.8454V8.75208"
        stroke="#415DF3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.2666 10V6" stroke="#415DF3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SmallEducation;
