import { styled } from '@mui/material/styles';

const StyledSuggestedCandidates = styled('div')(
  ({ theme }) => `
  .page-wrap{
    margin:20px;
    @media screen and (max-width: 1200px) {
        margin: 0px;
      }
    .filter-search textfeild{
      background: white;
    }
    .filter-search {
      margin: 10px 0px 20px;
     @media screen and (max-width: 1200px) {
        margin: 10px 20px 20px;
      }
    }
    .filter-icon {
      position: fixed;
      z-index: 1000;
      bottom: 5px;
      right: 10px;
      display: none;
      @media screen and (max-width: 900px) {
        display: block;
    }
    }
    .suggested-jobs-wrap{
      background: ${theme.palette.secondary.main};
      border-radius:12px;
      padding:20px; 

      @media screen and (max-width: 1200px) {
       border-radius: 0px;
      }

      .job-card-list-wrap{  
        min-height:calc(100dvh - 150px);
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
       }
        .suggested-candidates-card {
          border: 1px solid #E2E4E7;
          border-radius: 12px;
          margin: 20px 0px 0px;
          padding: 20px;
          height: fit-content;
          width: 47%;
          @media screen and (max-width: 650px) {
            width: 100%;
          }
          

    .card-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .user {
        display: flex;
        gap: 10px;
        align-items: center;

        .dp-domain {
        text-align: left;
            .user-work {
                color: ${theme.palette.customColor.primaryBlack};
                font-family: "Sora";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: left;
            }

            .user-name {
                color: ${theme.palette.customColor.secondaryGrey};
                font-family: "Plus Jakarta Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
            }
        }
    }

    .cross-icon {
        cursor: pointer;
    }

    .job-skills-2 {
        height: 38px;
        overflow-y: auto;
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;

        .user-application-2 {
            width: auto;
            background-color: ${theme.palette.customColor.grey_5};
            padding: 5px 10px;
            border-radius: 12px;
            color: ${theme.palette.customColor.primaryBlack};
            font-family: "Plus Jakarta Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
        }
    }

    .user-info {
        margin-top: 30px;
        // display: flex;
        // justify-content: space-between;
        // align-items: end;

        .location {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .user-charge {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .design {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .education {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .user-availability {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .suggestion-view-details-btn {
            color: ${theme.palette.primary.main};
            text-decoration: underline;
            font-family: "Plus Jakarta Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            cursor: pointer;
            text-align: right;
            margin-top: -22px;

        }
    }

    .card-heading {
        color: ${theme.palette.customColor.primaryBlack};
        font-family: "Plus Jakarta Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
    }

    .card-body-suggested {
        width: 100%;
        margin-top: 90px;
        display: flex;
        justify-content: center;
        color: ${theme.palette.customColor.secondaryGrey};
        font-family: "Plus Jakarta Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
    }
}
      } 
    }
  }
  .section-title{
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 25.2px;
    text-align: left;
    color:#1C1C1C;
    margin-bottom:8px;
  }
  .filters-wrap{
    background: ${theme.palette.secondary.main}; 
    border-radius:12px;
    padding:20px;
    @media screen and (max-width: 900px) {
    display: none;
    }
    .section-content{
      text-align:left;
      .section-heading{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.16px;
        text-align: left;
        color:#1C1C1C;
        text-transform:capitalize;
        margin:12px 0px;
      }
      .MuiListItemButton-root{
        padding:0px;
      }
      .MuiListItemIcon-root{
        min-width:44px;
      }
      .MuiListItemText-primary {
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.64px;
        text-align: left;
        color: #1C1C1C;
      }
      .more-text{
        display:inline-flex;
        align-items:center;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.64px;
        text-align: left;
        color:#415DF3;
        margin-bottom:20px;
        cursor:pointer;
    }
    .show-more-box {
      border: 1px solid white;
      height: 300px;
      background: white;
      position: absolute;
      width: 800px;
      z-index: 1000;
      border-radius: 12px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      overflow: hidden;
      .show-more-content{
        margin: 15px 30px;
        .show-more-header {
          display: flex;
          jistify-content: space-between;
          .show-more-search {
            width: 50%;
          }
        }
        .show-more-checkbox {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin: -30px 0px;
          max-height: 200px;
          overflow-y: auto;

          .MuiListItemButton-root {
            flex: 1 0 25%;
            min-width: 120px;
          }
        }  
      }
    }    
  }
  .filter-buttons{
    margin-top:20px;
    display: flex;
    gap: 12px;
    align-items:center;
    flex-wrap: wrap;
    .clear-btn{
      font-family: Plus Jakarta Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.64px;
      text-align: center;
      width:106px;
      padding:12px;
      gap: 10px;
      border-radius: 8px;
      opacity: 0px;
      cursor:pointer;
      display:flex;
      align-item:center;
      justify-content:center;
      &:hover{
        background:#c1c1c1;
      }
    }
  }
  .MuiButtonBase-root{
    height:34px !important;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    text-align: left;
    padding:0px !important;
  }
}
 
`,
);

export default StyledSuggestedCandidates;
