/* eslint-disable import/prefer-default-export */
import { styled } from '@mui/material/styles';

const StyledLandingButton = styled('div')(
  () => `
  .buttons-wrap{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
    @media screen and (max-width: 480px){
      padding: 0 20px;
    }
    .left-btn{
      width: 200px;
      @media screen and (max-width: 768px){
        width: 140px;
      }
      @media screen and (max-width: 480px){
        width: 22vw;
      }
    }
    .right-btn{
      width: 400px;
      display: flex;
      justify-content: end;
      margin-left: auto;
      @media screen and (max-width: 768px){
        width: auto;
      }
      .footer-btn{
        width: 190px;
        @media screen and (max-width: 768px){
          width: 140px;
        }
        @media screen and (max-width: 480px){
            width: fit-content;
          .MuiButtonBase-root{
            padding: 14px 12px;
            width: fit-content;
          }
        }
      }
      .footer-save{
        margin-left: 10px;
      }
    }
  }
    .pay-buttons-wrap {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      margin: 40px 10px;

      .left-btn{
      width: 200px;
      @media screen and (max-width: 768px){
        width: 140px;
      }
      @media screen and (max-width: 480px){
        width: 22vw;
      }
      .right-btn{
      width: 200px;
      display: flex;
      justify-content: end;
      margin-left: auto;
      @media screen and (max-width: 768px){
        width: auto;
      }
      .footer-btn{
        width: 200px;
        @media screen and (max-width: 768px){
          width: 140px;
        }
        @media screen and (max-width: 480px){
            width: fit-content;
          .MuiButtonBase-root{
            padding: 14px 12px;
            width: fit-content;
          }
        }
      }
      .footer-save{
        margin-left: 10px;
      }
    }
      }
  `,
);

export default StyledLandingButton;
