import { styled } from '@mui/material/styles';

const StyledBasic = styled('div')(
  ({ theme }) => `
    .page-wrap {
      width: 100%;
      background: ${theme.palette.customColor.primaryWhite};
      border-radius: 12px;
      overflow: hidden;

      .page-header {
        font-weight: 600;
        font-size: 20px;
        color: ${theme.palette.customColor.primaryBlack};
        text-align: left;
        padding: 20px 0px 0px 20px;
      }

      .content-wrap {
        display: flex;
        justify-content: space-around;
        padding: 20px;
        
        @media screen and (max-width: 900px){
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .left-basic-wrap {
          width: 70%;
          @media screen and (max-width: 900px){
           width: 100%;
          }

          .contryCode-adornment {
          position: absolute;
            background: #F4F4F4;
            padding: 13.5px 16px !important;
            // margin-right: -16px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            font-family: Plus Jakarta Sans;
            font-size: 16px;
            font-weight: 500;
            line-height: 20.16px;
            text-align: left;
            color: #1C1C1C;
          }
        }

        .right-basic-wrap {
          width: 20%;
          @media screen and (max-width: 900px){
           width: 80%;
          }

          .upload-file {
            margin-top: 20px;
            border: 1px solid ${theme.palette.primary.main};
            border-radius: 12px;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .file-name {
              font-size: 14px;
              font-family: Plus Jakarta Sans;
              font-weight: 400;
              text-align: left;
              color: #525252;
            }

            .delete-icon {
              cursor: pointer;
            }
          }

          .upload-wrap {
            border: 1.5px dashed ${theme.palette.primary.main};
            border-radius: 12px;
            padding: 30px 30px;
            margin-top: 40px;

            .right-basic-header {
              font-weight: 600;
              font-size: 14px;
              color: ${theme.palette.customColor.primaryBlack};
              padding: 10px 0px 5px 0px;
            }

            .right-basic-body {
              font-weight: 400;
              font-size: 14px;
              color: ${theme.palette.customColor.secondaryGrey};
              padding: 0px 0px 10px;
            }
          }
        }
      }
    }
  `,
);

export default StyledBasic;
