/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Grid from '@mui/material/Grid';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import LandingButtons from '../../../components/LandingButtons';
import StyledBasic from './StyledBasic';
import TextField from '../../../components/TextField';
import AutoComplete from '../../../components/AutoComplete';
import { SecondaryButton } from '../../../components/Button';
import FileUploadIcon from '../../../components/icons/FileUploadIcon';
import {
  createJobProfile,
  fetchDropdownProfile,
  fetchJobProfile,
  uploadResume,
} from '../../../store/reducers/fractionTalent/apiThunk';
import { selectorFractionTalent } from '../../../store/reducers/fractionTalent';
import {
  validateFileSize,
  validationJobInfoSchema,
} from '../../../utils/validations/authValidation';
import trash from '../../../assets/svg/trash.svg';
import repeat from '../../../assets/svg/repeat.svg';
import { ToastNotifyError } from '../../../components/Toast/ToastNotify';
import messages from '../../../constants/message';
import MultiselectAutoComplete from '../../../components/MultiselectAutoComplete/MultiselectAutoComplete';
import increase from '../../../assets/svg/increase.svg';
import decresae from '../../../assets/svg/decrease.svg';
import CustomTooltip from '../../../components/Tooltip/Tooltip';

function BasicInfo({ stepNumber = 0, setActiveStep }) {
  const dispatch = useDispatch();
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  // const [resumeFile, setResumeFile] = useState(null);
  const { MSG_PDFFILE_FORMATE, MSG_PDFFILE_SIZE } = messages;
  const [search, setSearch] = useState('');
  const [searchField, setSearchField] = useState('job-role');
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const searchTimeoutRef = useRef(null);
  const resumeInput = useRef(null);

  const { fractionTalent: { data: talentProfile } = {} } = useSelector(selectorFractionTalent);

  const submitHandler = async (values) => {
    const {
      jobRole,
      skills,
      domain,
      industry,
      socialLink,
      linkedInLink,
      experienceYears,
      resumeUrl,
      experienceDescription,
    } = values;

    const postData = {
      formStep: 1,
      jobRole,
      skills,
      domain,
      industry,
      socialLink,
      linkedInLink,
      experienceYears,
      resumeUrl,
      experienceDescription,
    };
    await dispatch(createJobProfile(postData));
    dispatch(fetchJobProfile());
    setDisableSaveBtn(true);
  };

  const formik = useFormik({
    initialValues: {
      jobRole: '',
      skills: [],
      domain: '',
      socialLink: '',
      linkedInLink: '',
      industry: '',
      experienceYears: 0,
      resumeUrl: '',
      experienceDescription: '',
    },
    validationSchema: validationJobInfoSchema,
    onSubmit: submitHandler,
  });

  const {
    handleBlur,
    values: {
      jobRole,
      skills,
      domain,
      industry,
      socialLink,
      linkedInLink,
      experienceYears,
      resumeUrl,
      experienceDescription,
    } = {},

    touched: {
      jobRole: tucJobRole,
      skills: tucSkills = false,
      domain: tucDomain = false,
      industry: tucIndustry = false,
      linkedInLink: tucLinkedInLink = false,
      socialLink: tucSocialLink = false,
      experienceYears: tucExperienceYears = false,
      resumeUrl: tucResumeUrl = false,
      experienceDescription: tucExperienceDescription = false,
    } = {},
    errors: {
      jobRole: errJobRole,
      skills: errSkills,
      domain: errDomain,
      industry: errIndustry,
      linkedInLink: errLinkedInLink,
      socialLink: errSocialLink,
      experienceYears: errExperienceYears,
      resumeUrl: errResumeUrl,
      experienceDescription: errExperienceDescription,
    } = {},
  } = formik;

  const handleChange = (e, name) => {
    setDisableSaveBtn(false);
    formik.setFieldValue(name, e.target.value);
  };

  const customHandleChange = (e, name) => {
    setDisableSaveBtn(false);
    if (Number.isInteger(e.target.value)) {
      const changedValue = e.target.innerText;
      formik.setFieldValue(name, changedValue);
    } else {
      formik.setFieldValue(name, e.target.value);
    }
  };

  useEffect(() => {
    formik.setFieldValue('jobRole', talentProfile?.jobRole);
    formik.setFieldValue('industry', talentProfile?.industry);
    formik.setFieldValue('skills', talentProfile?.skills);
    formik.setFieldValue('domain', talentProfile?.domain);
    formik.setFieldValue('socialLink', talentProfile?.socialLink);
    formik.setFieldValue('linkedInLink', talentProfile?.linkedInLink);
    formik.setFieldValue('experienceYears', parseInt(talentProfile?.experienceYears));
    formik.setFieldValue('resumeUrl', talentProfile?.resumeUrl);
    formik.setFieldValue('experienceDescription', talentProfile?.experienceDescription);
  }, [talentProfile]);

  const handleSearch = async () => {
    const query = {
      field: searchField,
      ...(search !== undefined && search !== null && { search }),
    };
    const response = await dispatch(fetchDropdownProfile(query));
    const { payload: { data = [] } = {} } = response;
    setDropDownOptions(data);
  };

  useEffect(() => {
    // Clear any previous timeout to prevent multiple calls
    clearTimeout(searchTimeoutRef.current);

    // Set a new timeout to delay the search
    searchTimeoutRef.current = setTimeout(() => {
      handleSearch(searchField, search);
    }, 500); // Adjust delay as needed

    // Cleanup on unmount to avoid memory leaks
    return () => clearTimeout(searchTimeoutRef.current);
  }, [searchField, search]);

  const handleResumeUpload = async (e) => {
    // setResumeFile(URL.createObjectURL(e.target.files[0]));
    const updatedData = {
      uploadFile: e.target.files[0],
      extension: e.target.files[0].name.split('.').pop(),
    };
    if (updatedData.extension && !['pdf', 'doc', 'docx'].includes(updatedData.extension)) {
      ToastNotifyError(MSG_PDFFILE_FORMATE);
    } else if (updatedData.uploadFile && validateFileSize(updatedData.uploadFile)) {
      ToastNotifyError(MSG_PDFFILE_SIZE);
    } else {
      const response = await dispatch(uploadResume(updatedData)).then((val) => val);
      const { payload: { data: { path = '' } } = {} } = response;
      formik.setFieldValue('resumeUrl', path);
    }
  };

  const handleResumeDelete = () => {
    formik.setFieldValue('resumeUrl', '');
  };

  useEffect(() => {
    setDropDownOptions([]);
  }, [searchField]);

  const handleDecrease = () => {
    formik.setFieldTouched('experienceYears', true);
    const currentExperience = parseInt(experienceYears) || 0; // Default to 0 if null or undefined
    formik.setFieldValue('experienceYears', currentExperience - 1);
  };

  const handleIncrease = () => {
    formik.setFieldTouched('experienceYears', true);
    const currentExperience = parseInt(experienceYears) || 0; // Default to 0 if null or undefined
    formik.setFieldValue('experienceYears', currentExperience + 1);
  };

  const RenderExp = () => (
    <div className="exp-buttons">
      <img alt="" src={decresae} onClick={handleDecrease} />
      <img alt="" src={increase} onClick={handleIncrease} />
    </div>
  );

  const ResumeUpload = () => {
    useEffect(() => {}, [resumeUrl, errResumeUrl]);
    return (
      <Grid item md={4} xs={12} sx={{ textAlign: 'left' }}>
        <div className="input-Label_text label-space">
          Upload Resume <CustomTooltip title="Upload your resume in (.pdf, .docx, .doc) format." />
        </div>
        <div className="upload-button-wrap">
          <SecondaryButton
            startIcon={<FileUploadIcon />}
            onClick={() => resumeInput.current.click()}
            sx={{
              border: `1px solid ${tucResumeUrl && errResumeUrl ? '#DA3333' : '#415DF3'}`,
              width: '183px',
            }}>
            {resumeUrl ? 'Myresume' : 'Upload resume'}
          </SecondaryButton>

          {resumeUrl && (
            <>
              <div className="delete-btn red" onClick={handleResumeDelete}>
                <span>
                  <img src={trash} alt="Delete icon" />
                </span>
                <span>Delete</span>
              </div>
              <div className="delete-btn blue" onClick={() => resumeInput.current.click()}>
                <span>
                  <img src={repeat} alt="Replace icon" />
                </span>
                <span>Replace</span>
              </div>
            </>
          )}

          <input
            type="file"
            ref={resumeInput}
            id="resumeUrl"
            accept=".pdf, .docx, .doc"
            className="fileUpload"
            onBlur={handleBlur}
            onChange={(e) => handleResumeUpload(e)}
          />

          {/* {!resumeUrl && errResumeUrl && <div className="resume-error">{errResumeUrl}</div>} */}
        </div>
      </Grid>
    );
  };

  return (
    <StyledBasic>
      <div className="page-wrap">
        <div className="header-wrap">Basic details</div>
        <div className="content-wrap">
          <Grid
            container
            columnSpacing={2.5}
            rowSpacing={2}
            className="input-fields-wrap"
            alignItems="start">
            <Grid item md={4.5} xs={12}>
              <AutoComplete
                name="jobRole"
                type="text"
                label="Job Role"
                classes="whoAmI"
                important
                showTooltip={false}
                placeholderValue="Select Role"
                onFocus={() => setSearchField('job-role')}
                optionsValue={dropDownOptions?.map((obj) => obj?.name) || []}
                changeAutoComplete={(e) => {
                  setSearchField('job-role');
                  setSearch(e.target.value);
                  customHandleChange(e, 'jobRole');
                }}
                onBlur={handleBlur}
                value={jobRole}
                error={tucJobRole && !!errJobRole}
                helperText={tucJobRole && errJobRole}
                icon={<ExpandMoreOutlinedIcon />}
              />
            </Grid>
            <Grid item md={4.5} xs={12}>
              <AutoComplete
                name="industry"
                type="text"
                label="Industry"
                classes="whoAmI"
                important
                showTooltip={false}
                placeholderValue="Select Industry"
                onFocus={() => setSearchField('industry')}
                optionsValue={dropDownOptions?.map((obj) => obj?.name) || []}
                changeAutoComplete={(e) => {
                  setSearchField('industry');
                  setSearch(e.target.value);
                  customHandleChange(e, 'industry');
                }}
                onBlur={handleBlur}
                value={industry}
                error={tucIndustry && !!errIndustry}
                helperText={tucIndustry && errIndustry}
                icon={<ExpandMoreOutlinedIcon />}
              />
            </Grid>
            <Grid item md={4.5} xs={12}>
              <AutoComplete
                name="domain"
                type="text"
                label="Domain"
                classes="whoAmI"
                important
                showTooltip={false}
                placeholderValue="Select Domain"
                onFocus={() => setSearchField('domain')}
                optionsValue={dropDownOptions?.map((obj) => obj.name) || []}
                changeAutoComplete={(e) => {
                  setSearchField('domain');
                  setSearch(e.target.value);
                  customHandleChange(e, 'domain');
                }}
                onBlur={handleBlur}
                value={domain}
                error={tucDomain && !!errDomain}
                helperText={tucDomain && errDomain}
                icon={<ExpandMoreOutlinedIcon />}
              />
            </Grid>
            <Grid item md={4.5} xs={12}>
              <TextField
                name="experienceYears"
                type="number"
                label="Year(s) Of Experience"
                classes="whoAmI"
                important
                showTooltip={false}
                placeholderValue="Years of experience"
                onChange={(e) => customHandleChange(e, 'experienceYears')}
                onBlur={handleBlur}
                value={experienceYears}
                error={tucExperienceYears && !!errExperienceYears}
                helperText={tucExperienceYears && errExperienceYears}
                icon={<RenderExp />}
              />
            </Grid>

            <Grid item md={4.5} xs={11} mt={1}>
              <TextField
                placeholder="e.g. linkedin.com/in/johndoe"
                name="linkedInLink"
                type="text"
                label="LinkedIn Profile link"
                onChange={(e) => handleChange(e, 'linkedInLink')}
                onBlur={handleBlur}
                value={linkedInLink}
                error={tucLinkedInLink && !!errLinkedInLink}
                helperText={tucLinkedInLink && errLinkedInLink}
              />
            </Grid>
            <Grid item md={4.5} xs={11}>
              <TextField
                placeholder="e.g. dribbble.com/johndoe"
                name="socialLink"
                type="text"
                // important
                label="Social Profile Link"
                showTooltip
                tooltip="Add any social media profile link (twitter/github/dribbble/portfolio)"
                onChange={(e) => handleChange(e, 'socialLink')}
                onBlur={handleBlur}
                value={socialLink}
                error={tucSocialLink && !!errSocialLink}
                helperText={tucSocialLink && errSocialLink}
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <MultiselectAutoComplete
                name="skills"
                type="text"
                label="Skills"
                classes="whoAmI"
                important
                placeholder="Enter Skills (e.g. Business Analyst)"
                optionsValue={dropDownOptions?.map((obj) => obj?.name) || []}
                value={skills}
                onBlur={handleBlur}
                onChange={(newSkill) => formik.setFieldValue('skills', newSkill)}
                selectedValues={skills}
                onInputChange={(value) => setSearch(value)}
                onFocus={() => setSearchField('skill')}
                error={tucSkills && !!errSkills}
                helperText={tucSkills && errSkills}
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                placeholder="Write more about yourself"
                name="experienceDescription"
                type="text"
                classes="aboutMe"
                multiline
                rows={2}
                label="Write more about you & your experience"
                important
                onChange={(e) => handleChange(e, 'experienceDescription')}
                onBlur={handleBlur}
                value={experienceDescription}
                error={tucExperienceDescription && !!errExperienceDescription}
                helperText={tucExperienceDescription && errExperienceDescription}
                icon={`${experienceDescription?.length ? experienceDescription.length : 0}/3500`}
                wordCount
                inputProps={{ maxLength: 3500 }}
              />
            </Grid>
            <ResumeUpload />
          </Grid>
        </div>
      </div>

      <LandingButtons
        showBackButton={false}
        stepNumber={stepNumber}
        setActiveStep={setActiveStep}
        handleSubmit={() => submitHandler(formik.values)}
        disableNext={!formik.isValid}
        disableSave={!formik.isValid || disableSaveBtn}
      />
    </StyledBasic>
  );
}

export default BasicInfo;
