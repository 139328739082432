/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import { Checkbox, Dialog, Grid, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { PrimaryButton } from '../../../components/Button';
import StyledFilterMobile from './StyledFilterMobile';
import TextFeild from '../../../components/TextField';

const Experience = [
  { name: '0', value: 0 },
  { name: '1-5 Years', value: 1 },
  { name: 'Between 5 to 8 years', value: 2 },
  { name: 'Between 8 to 12 years', value: 3 },
  { name: 'More than 12 years', value: 4 },
];
const JobPreference = [
  { name: 'On-site', value: 'onSite' },
  { name: 'Remote', value: 'remote' },
  { name: 'Hybrid', value: 'hybrid' },
];
const Availibility = [
  { name: 'Daily', value: 'daily' },
  { name: 'Weekly', value: 'weekly' },
  { name: 'Monthly', value: 'monthly' },
];

const FilterMobile = ({ filters, closeFilterDialog, onApplyFilters, openFilterDialog = false }) => {
  const [checkedDomains, setCheckedDomains] = useState([]);
  const [checkedIndustries, setCheckedIndustries] = useState([]);
  const [checkedExperience, setCheckedExperience] = useState([]);
  const [checkedJobPreference, setCheckedJobPreference] = useState([]);
  const [checkedAvailability, setCheckedAvailability] = useState([]);
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('domain');
  const [checkedJobRole, setCheckedJobRole] = useState([]);
  const [locationInput, setLocationInput] = useState('');
  const [locations, setLocations] = useState([]);

  const handleToggle = (category, value) => {
    const updateChecked = (checkedState, setCheckedState) => {
      const currentIndex = checkedState.indexOf(value);
      const newChecked = [...checkedState];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setCheckedState(newChecked);
    };

    switch (category) {
      case 'domain':
        updateChecked(checkedDomains, setCheckedDomains);
        break;
      case 'industry':
        updateChecked(checkedIndustries, setCheckedIndustries);
        break;
      case 'experience':
        updateChecked(checkedExperience, setCheckedExperience);
        break;
      case 'jobPreference':
        updateChecked(checkedJobPreference, setCheckedJobPreference);
        break;
      case 'availability':
        updateChecked(checkedAvailability, setCheckedAvailability);
        break;
      case 'location':
        updateChecked(checkedLocations, setCheckedLocations);
        break;
      case 'jobRole':
        updateChecked(checkedLocations, setCheckedJobRole);
        break;
      default:
        break;
    }
  };

  const getListForSelectedTopic = () => {
    switch (selectedTopic) {
      case 'domain':
        return filters?.domainList || [];
      case 'industry':
        return filters?.industryList || [];
      case 'experience':
        return Experience || [];
      case 'job Preference':
        return JobPreference || [];
      case 'availability':
        return Availibility || [];
      case 'jobRole':
        return filters?.jobRoleList || [];
      default:
        return filters?.domainList || [];
    }
  };

  const handleClearFilters = () => {
    setCheckedDomains([]);
    setCheckedIndustries([]);
    setCheckedExperience([]);
    setCheckedJobPreference([]);
    setCheckedAvailability([]);
    setCheckedLocations([]);
    setLocations([]);
    setCheckedJobRole([]);
    setLocationInput('');
  };

  const handleApplyFilters = () => {
    const selectedFilters = {
      domains: checkedDomains,
      industries: checkedIndustries,
      experience: checkedExperience,
      jobPreferences: checkedJobPreference,
      availability: checkedAvailability,
      locations: checkedLocations,
      jobRole: checkedJobRole,
    };
    onApplyFilters(selectedFilters);
    closeFilterDialog();
    handleClearFilters();
  };

  const handleAddLocation = () => {
    if (locationInput.trim()) {
      const newLocation = locationInput.trim();
      setLocations((prevLocations) => [...prevLocations, newLocation]);
      setCheckedLocations((prevChecked) => [...prevChecked, newLocation]);
      setLocationInput('');
    }
  };
  const FilterModal = () => (
    <StyledFilterMobile>
      <div className="component-wrap">
        <div className="wrap-header">
          <div className="header">Filter</div>
          <div className="close" onClick={() => closeFilterDialog()}>
            Close
          </div>
        </div>
        <Grid container spacing={1} className="filter-container">
          <Grid item xs={4} className="filter-topics">
            {[
              'domain',
              'experience',
              'job Role',
              'industry',
              'job Preference',
              'availability',
              'location',
            ].map((topic) => (
              <div
                key={topic}
                className={`topic ${selectedTopic === topic ? 'selected' : ''}`}
                onClick={() => setSelectedTopic(topic)}
                style={{
                  color: selectedTopic === topic ? 'blue' : 'inherit',
                  cursor: 'pointer',
                }}>
                {topic.charAt(0).toUpperCase() + topic.slice(1)}
              </div>
            ))}
          </Grid>
          <Grid item xs={7.5} className="filter-options">
            <div className="filter-options-header">{`Filter By ${selectedTopic}`}</div>
            {selectedTopic !== 'location' ? (
              getListForSelectedTopic().map((item) => (
                <ListItemButton
                  sx={{ maxHeight: '70px' }}
                  key={item._id}
                  role="listitem"
                  onClick={() => handleToggle(selectedTopic, item.name)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={
                        (selectedTopic === 'domain' && checkedDomains.includes(item.name)) ||
                        (selectedTopic === 'industry' && checkedIndustries.includes(item.name)) ||
                        (selectedTopic === 'experience' && checkedExperience.includes(item.name)) ||
                        (selectedTopic === 'job Preference' &&
                          checkedJobPreference.includes(item.name)) ||
                        (selectedTopic === 'availability' &&
                          checkedAvailability.includes(item.name)) ||
                        (selectedTopic === 'location' && checkedLocations.includes(item.name)) ||
                        (selectedTopic === 'job Role' && checkedJobRole.includes(item.name))
                      }
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={` ${item?.name}`} />
                </ListItemButton>
              ))
            ) : (
              <div>
                <div className="section-heading">Location</div>
                <TextFeild
                  value={locationInput}
                  onChange={(e) => setLocationInput(e.target.value)}
                  onKeyDown={(e) => e.key === 'Enter' && handleAddLocation()}
                  fullWidth
                  size="small"
                  placeholderValue="Search"
                  sx={{
                    marginBottom: '10px !importatnt',
                    minHeight: '0px !important',
                    width: '90%',
                  }}
                />
                {locations.map((location, index) => (
                  <ListItemButton
                    key={index}
                    role="listitem"
                    onClick={() => handleToggle('location', location)}>
                    <ListItemIcon>
                      <Checkbox
                        checked={checkedLocations.includes(location)}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={location} />
                  </ListItemButton>
                ))}
              </div>
            )}
          </Grid>
        </Grid>
        <div className="filter-buttons">
          <span className="clear-btn" onClick={() => handleClearFilters()}>
            Clear
          </span>
          <PrimaryButton sx={{ width: '106px' }} onClick={() => handleApplyFilters()}>
            Apply Filter
          </PrimaryButton>
        </div>
      </div>
    </StyledFilterMobile>
  );
  return (
    <Dialog
      fullScreen
      open={openFilterDialog}
      onClose={closeFilterDialog}
      sx={{ overflow: 'hidden' }}>
      {FilterModal()}
    </Dialog>
  );
};

export default FilterMobile;
