const durationCheck = (availableFor, availability = 1) => {
  const isPlural = availability > 1;
  switch (availableFor) {
    case 'daily':
      return isPlural ? 'Days' : 'Day';
    case 'weekly':
      return isPlural ? 'Weeks' : 'Week';
    default:
      return isPlural ? 'Months' : 'Month';
  }
};
const durationCheckSuggestion = (item, suggNo) => {
  const isPlural = suggNo > 1;
  switch (item) {
    case 'daily':
      return isPlural ? 'Days' : 'Day';
    case 'weekly':
      return isPlural ? 'Weeks' : 'Week';
    default:
      return isPlural ? 'Months' : 'Month';
  }
};

const checkCompensationType = (item) => {
  switch (item) {
    case 'Per day':
      return 'day';
    case 'Per month':
      return 'month';
    default:
      return 'week';
  }
};

function stringAvatar(name = 'A') {
  return {
    children: `${name.split(' ')[0][0]}`,
  };
}

const checkPlanType = (item) => {
  switch (item) {
    case 'Yearly':
      return 'Annual';
    case 'Monthly':
      return 'Monthly';
    default:
      return 'Starter';
  }
};

export {
  checkCompensationType,
  durationCheckSuggestion,
  durationCheck,
  stringAvatar,
  checkPlanType,
};
