const messages = {
  MSG_VALID_EMAIL: 'Please enter a valid email',
  MSG_VALID_USERNAME: 'Please enter a valid username',
  MSG_VALID_FIRSTNAME: 'Please enter a valid first name',
  MSG_VALID_LASTNAME: 'Please enter a valid last name',
  MSG_EMAIL_REQ: 'Email is required',
  MSG_USERNAME_REQ: 'Username is required',
  MSG_EMAIL_LENGTH: 'Email should not be of more than 100 characters',
  MSG_USERNAME_LENGTH: 'UserName should not be of more than 20 characters',
  MSG_FIRSTNAME_LENGTH: 'FirstName should not be of more than 50 characters',
  MSG_LASTNAME_LENGTH: 'LastName should not be of more than 50 characters',
  MSG_REQUIRED_PASSWORD: 'Password is required',
  MSG_CURRENT_PASSWORD: 'Current Password is required',
  MSG_NEW_PASSWORD: 'New Password is required',
  MSG_CONFIRM_PASSWORD: 'Confirm Password is required',
  MSG_PASSWORD_MATCH: 'Passwords must match',
  MSG_VALID_PASSWORD:
    'A valid password should contain atleast 8 characters, 1 small letter, 1 capital letter, 1 number, 1 special character',
  MSG_EMAIL_NOT_EXIST: 'Email not exists',
  MSG_OTP_NOT_MATCH: 'OTP does not matched',
  MSG_EMAIL_VERIFIED: 'Email Verified Successfully',
  MSG_OTP_VERIFIED: 'OTP Verified Successfully',
  MSG_OTP_RESEND: 'OTP Resent Successfully',
  MSG_PASSWORD_RESET: 'Password Changed Successfully',
  MSG_INVALID_EMAIL_PASS: 'Invalid Email or Password',
  MSG_FIRSTNAME_REQ: 'First Name is required',
  MSG_LASTNAME_REQ: 'Last Name is required',
  MSG_OTP_SENT: 'OTP Sent Successfully',
  MSG_EMAIL_EXIST: 'Email already exists',
  MSG_LENGTH: 'Message should not be of more than 256 characters',
  MSG_REQ: 'Message is required',
  MSG_FILE_REQ: 'File is required',
  MSG_FILE_FORMATE: 'Only png, jpeg, jpg, webp fomates allowed',
  MSG_FILE_SIZE: 'Image size not allowed more than 5 MB',
  MSG_FILE_REQUIRED: 'Profile picture required',
  MSG_MESSAGE_REQ: 'This field is required',
  MSG_MESSAGE_LENGTH: 'Max. character limit is 200',
  MSG_PDFFILE_FORMATE: 'Only .pdf & word file is allowed',
  MSG_PDFFILE_SIZE: 'file size not allowed more than 5 MB',
};
export default messages;
