import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MuiAppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import fractionTalent from '../../assets/svg/fractionTalent.svg';

const drawerWidth = 240;

const StyledPrivateLayout = styled('div')(
  ({ theme }) => `
    display: flex;
    @media screen and (max-width: 899px){
      flex-direction: column;
    } 
    .MuiAppBar-root{
        position: fixed;
        padding-right: 0px !important;
        background-color: ${theme.palette.secondary.main};
        color: ${theme.palette.customColor.primaryBlack};
        box-shadow: none;
        @media screen and (max-width: 1025px){
          width: calc(100% - 218px);
        }
        @media screen and (max-width: 899px){
          width: 100%;
        }
        .MuiTypography-root{
          font-family: "Plus Jakarta Sans";
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          @media screen and (max-width: 480px){
            font-size: 18px;
          }  
        }
        .profile-wrap{
          .MuiTypography-root{
            font-size: 16px;
            font-weight: 500;
          }
        }
        .main-header{
            display: flex;
            justify-content: space-between;
            .profile-wrap{
                display: flex;
                align-items: center;
                .MuiAvatar-root{
                    margin-right: 10px;
                    @media screen and (max-width: 480px){
                      margin-right: 0px;
                    }
                }
                .title{
                    max-width: 150px;
                    @media screen and (max-width: 480px){
                      display: none;
                    }
                }
            }
        }
    }

    .MuiDrawer-root{
      @media screen and (max-width: 1025px){
        width: 218px;
      }
        .MuiDrawer-paper{
            background-color: ${theme.palette.secondary.main};
            color: ${theme.palette.customColor.secondaryGrey};
            border: 0px solid transparent;
            @media screen and (max-width: 1025px){
              width: 218px;
            }
            .MuiToolbar-root{
                img{
                    width: 100%;
                }
            }
                
            .link-wrap{
                padding-left: 10px;
                padding-right: 10px;
                .listDiv{
                      margin-top: 12px;
                      .MuiListItemButton-root{
                        border-radius: 10px;
                        padding-top: 12px;
                        padding-bottom: 12px;
                      }
                }
                .MuiListItemIcon-root{
                    color: ${theme.palette.customColor.blackLight};
                    margin-right: 10px;
                    min-width: unset;
                  }
                .MuiTypography-root{
                  font-family: Sora;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                .active{
                    border-radius: 10px;
                    background: ${theme.palette.customColor.blue4};
                    box-shadow: ${theme.palette.boxShadow.sixth};
                    .MuiTypography-root{
                      font-weight: 600;
                    }
                }
                .active .MuiListItemIcon-root, .active .MuiListItemText-root{
                    color: ${theme.palette.primary.main};
                }
                a {
                    text-decoration: none;
                    color: unset;
                }
            }
        }
    }
    mainWrap {
      position: relative;
        flex-grow: 1;
        min-height: 100vh;
        background: ${theme.palette.customColor.primaryOrange};
        padding-top: 64px;
    }

    .header-menu{
        background-color: red;
    }
    .MuiIconButton-root{
      @media screen and (max-width: 768px){
        color: ${theme.palette.customColor.primaryBlack};
      }
      @media screen and (max-width: 480px){
        margin-right: 0px;
      }
    }

  .fraction-talent-poster{
    margin:0 auto;
    width:218px;
    height:248px;
    background-image: url(${fractionTalent});
    padding:16px;
    .top-heading{
      font-family: Sora;
      font-size: 20px;
      font-weight: 700;
      line-height: 25.2px;
      text-align: center;
      margin-bottom:20px;
      color: ${theme.palette.customColor.primaryBlack};
      .blue{
        color: ${theme.palette.primary.main};
      }
    }
    .title{
      font-family: Sora;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.64px;
      text-align: center;
    }
    .sub-title{
      margin-top:4px;
      margin-bottom:32px;
      font-family: Plus Jakarta Sans;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: ${theme.palette.customColor.para};
    },
    .MuiButtonBase-root{
      min-height:34px;
      font-family: Plus Jakarta Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
    }
  } 
  .fraction-sub-list {
    height:34px;
    font-family: Sora;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    text-align: left;
  }
  `,
);

const StyledDrawer = styled(Drawer)(
  ({ theme }) => `
    width: ${drawerWidth}px;
    flexShrink: 0;
    .MuiDrawer-paper {
      width: ${drawerWidth}px;
      box-sizing: border-box;
    }
    .MuiBackdrop-root{
      display: none;
    }
    .drawerIcon{
      display: none;
    }
    .MuiDrawer-paper{
        background-color: ${theme.palette.secondary.main};
        color: ${theme.palette.customColor.primaryBlack};
        border: 0px solid transparent;
        .MuiToolbar-root{
            img{
                width: 100%;
            }
        }
        .link-wrap{
            padding-left: 10px;
            padding-right: 10px;
            .MuiListItemIcon-root{
                color: ${theme.palette.customColor.blackLight};
                @media screen and (max-width: 1025px){
                  min-width: 40px;
                }
            }
            .active{
                background: ${theme.palette.customColor.blue4};
                border-radius: 10px;
                box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.05);
            }
            .active .MuiListItemIcon-root, .active .MuiListItemText-root{
                color: ${theme.palette.primary.main};
            }
            a {
                text-decoration: none;
                color: unset;
            }
        }
    }
    ${theme.breakpoints.down('md')} {
      .MuiBackdrop-root{
        display: block;
      }
      .drawerIcon{
        display: block;
        color: ${theme.palette.customColor.primaryBlack}
      }
    }
    .fraction-talent-poster{
      margin:0 auto;
      width:218px;
      height:248px;
      background-image: url(${fractionTalent});
      padding:16px;
      .top-heading{
        font-family: Sora;
        font-size: 20px;
        font-weight: 700;
        line-height: 25.2px;
        text-align: center;
        margin-bottom:20px;
        color: ${theme.palette.customColor.primaryBlack};
        .blue{
          color: ${theme.palette.primary.main};
        }
      }
      .title{
        font-family: Sora;
        font-size: 14px;
        font-weight: 600;
        line-height: 17.64px;
        text-align: center;
      }
      .sub-title{
        margin-top:4px;
        margin-bottom:32px;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: ${theme.palette.customColor.para};
      },
      .MuiButtonBase-root{
        min-height:34px;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  `,
);

const StyledHeaderMenu = styled(Menu)(
  ({ theme }) => `
  .MuiPaper-root{
    background-color: ${theme.palette.secondary.main};
    border-radius: 12px;
    box-shadow: 0px 10px 23px 0px #00000008;
  }

  .MuiList-root{
    padding: 0px;
  }

  .MuiButtonBase-root{
    padding: 10px;
    color: ${theme.palette.customColor.primaryBlack};
    gap: 8px;
  }
  .MuiButtonBase-root{
    border-bottom: 1px solid rgba(242, 242, 242, 0.05);
  }
  .MuiButtonBase-root:last-child{
    border-bottom: 1px solid transparent;
    color: ${theme.palette.customColor.stateError}; 

  }
`,
);

const StyledHeaderMenuItem = styled(MenuItem)(
  ({ theme }) => `
  color: '${theme.palette.customColor.primaryWhite}';
  .MuiMenuItem-root{
    color: ${theme.palette.customColor.primaryWhite}; 
  }

  
`,
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  marginLeft: `${drawerWidth}px`,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${theme.breakpoints.down('md') ? 0 : drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.down('md')]: {
    left: '0px',
    width: '100%',
    marginLeft: '0px',
  },
}));

const Main = styled('mainWrap', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: '0px',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: '0px',
    }),
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
    },
  }),
);

export { StyledHeaderMenu, StyledHeaderMenuItem, AppBar, Main, StyledDrawer };

export default StyledPrivateLayout;
