import { styled } from '@mui/material/styles';

const StyledBasic = styled('div')(
  ({ theme }) => `
  .page-wrap{
    background: ${theme.palette.customColor.primaryWhite};
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    .header-wrap{
      background: ${theme.palette.customColor.primaryWhite};
      font-weight: 600;
      font-size: 20px;
      color: ${theme.palette.customColor.primaryBlack};
      text-align: left;
      padding: 24px 24px 24px 30px;
      @media screen and (max-width: 590px){
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.16px;
        text-align: left;
      }
    }
    .content-wrap{
      padding: 0px 24px 24px 24px;
      display: flex;
      justify-content: space-between;
      color: ${theme.palette.customColor.primaryBlack};
      @media screen and (max-width: 590px){
        padding: 0px 24px 24px 24px;   
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 160px;
      }
      .link_strip_wrap{
        padding: 20px;
        background-color:#F4F4F4;
        border: 1px solid #E2E4E7;
        border-radius:12px;
        display:flex;
        width:100%;
        justify-content:space-between;
        gap:12px;
        align-items:center;
        .link_strip_sub{
          font-size: 18px;
          font-weight: 600;
          line-height: 22.68px;
          text-align: left;
          color:#1C1C1C;
        }
        .link_strip_desc{
          max-width:520px;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          color:#525252;
        }

        .MuiButtonBase-root{
          padding:18px 22px;
          border-radius:12px;
          font-family: Plus Jakarta Sans;
          font-size: 18px;
          font-weight: 500;
          line-height: 22.68px;
          text-align: left;

        }
        
         @media screen and (max-width: 786px){
          flex-direction:column;
          .btn_stripe_wrap{
          width:100%;
        }
         }

      }
      .upload-button-wrap{
        text-align: left;
        display:inline-block;
        align-items:center;
        width: 331px;
        .delete-btn {
          display:inline-flex;
          flex-direction:column;
          justify-content:center;
          font-family: Plus Jakarta Sans;
          font-size: 16px;
          font-weight: 600;
          line-height: 20.16px;
          text-align: left;
          text-decoration:underline;
          cursor:pointer;
          margin-left:16px;
          span{
            text-align:center;
          }
        }
        .red{
          color:#DA3333;
        }
        .blue{
          color:#415DF3
        }
      }
    }
    .connect-icon{
      padding-left: 5px;
    }
    .fileUpload{
      display: none;
    }
    .resume-error{
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #ff3c3c !important;
    }
}
.exp-buttons{
  display:inline-flex;
  gap:8px;
  padding:0 16px;
  img{
    cursor:pointer;
  }
}
.input-Label_text {
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${theme.palette.customColor.secondaryGrey};
  text-align: left;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
}
`,
);

export default StyledBasic;
