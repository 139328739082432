export default function LockIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill="#F4F4F4" />
      <g clipPath="url(#clip0_5669_11184)">
        <path d="M7.33325 16.668C7.33325 14.7824 7.33325 13.8395 7.91904 13.2538C8.50482 12.668 9.44763 12.668 11.3333 12.668H16.6666C18.5522 12.668 19.495 12.668 20.0808 13.2538C20.6666 13.8395 20.6666 14.7824 20.6666 16.668C20.6666 18.5536 20.6666 19.4964 20.0808 20.0822C19.495 20.668 18.5522 20.668 16.6666 20.668H11.3333C9.44763 20.668 8.50482 20.668 7.91904 20.0822C7.33325 19.4964 7.33325 18.5536 7.33325 16.668Z" stroke="#1C1C1C" />
        <path d="M10 12.6654V11.332C10 9.12289 11.7909 7.33203 14 7.33203C16.2091 7.33203 18 9.12289 18 11.332V12.6654" stroke="#1C1C1C" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_5669_11184">
          <rect width="16" height="16" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
}
