/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import StyledSuggestedCandidateProfile from './StyledSuggestedCandidateProfile';
import { PrimaryButton } from '../../../components/Button';
import CircleAvatar from '../../../components/CircleAvatar';
// import ColorDropdown from '../../../components/ColorDropdown';
// import { getRecruiterJobStatus } from '../../../store/reducers/recruiterProfile/apiThunk';
import PdfIcon from '../../../components/icons/PdfIcon';
import BigCrossIcon from '../../../components/icons/BigCrossIcon';
import {
  getContactRecruiter,
  getDownloadResume,
  // getDownloadResume,
} from '../../../store/reducers/recruiterProfile/apiThunk';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';

const SuggestedCandidateProfile = ({ openDialog, closeDialog, jobDetails, dashboard = false }) => {
  // const [selectedColor, setSelectedColor] = useState('applied');
  const dispatch = useDispatch();
  // const [downloadLink, setDownloadLink] = useState('');

  // const handleColorChange = (color) => {
  //   setSelectedColor(color);
  // };

  // useEffect(() => {
  //   if (selectedColor) {
  //     const changeStatus = async () => {
  //       const postStatus = (item) => {
  //         switch (item) {
  //           case 'applied':
  //             return 'applied';
  //           case 'interview':
  //             return 'interviewScheduled';
  //           case 'underReview':
  //             return 'underReview';
  //           case 'rejected':
  //             return 'rejected';
  //           default:
  //             return 'accepted';
  //         }
  //       };

  //       const data = {
  //         id: `/${jobDetails?.expertJobApplication[0]?._id}`,
  //         postData: {
  //           jobApplicationStatus: postStatus(selectedColor),
  //         },
  //       };
  //       await dispatch(getRecruiterJobStatus(data));
  //     };

  //     changeStatus();
  //   }
  // }, [selectedColor, jobDetails?._id]);

  const contactUser = async () => {
    const id = dashboard ? jobDetails?.expertData?._id : jobDetails?.expertDetails?._id;
    const res = await dispatch(getContactRecruiter(id));
    if (res?.payload?.status) {
      ToastNotifySuccess('Email has been sent to the user');
    }
  };

  const capitalizeWorkPreference = (workPreference) => {
    if (!workPreference) return '';
    if (workPreference === 'onSite') {
      return 'On-Site';
    }
    return workPreference.charAt(0).toUpperCase() + workPreference.slice(1);
  };

  const DownloadResumePdf = async () => {
    const id = jobDetails?.expertDetails?._id;
    if (!id) {
      console.error('Expert ID is missing.');
      return;
    }
    try {
      const res = await dispatch(getDownloadResume(id));

      if (res?.payload?.data?.status) {
        const fileUrl = res?.payload?.data?.data;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = `${dashboard ? jobDetails?.expertData?.firstName : jobDetails?.expertDetails?.firstName}'s Resume`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error while downloading resume:', error);
    }
  };

  const durationCheck = (itemCount, item) => {
    const isPlural = itemCount > 1;
    switch (item) {
      case 'daily':
        return isPlural ? 'Days' : 'Day';
      case 'weekly':
        return isPlural ? 'Weeks' : 'Week';
      default:
        return isPlural ? 'Months' : 'Month';
    }
  };

  const JobdetailsModalContent = () => (
    <StyledSuggestedCandidateProfile>
      <div className="sc-profile-wrap">
        <div className="sc-head">
          <div className="sc-user">
            <CircleAvatar
              src={
                dashboard
                  ? jobDetails?.expertData?.profileImagePath
                  : jobDetails?.expertDetails?.profileImagePath
              }
              name={
                dashboard ? jobDetails?.expertData?.firstName : jobDetails?.expertDetails?.firstName
              }
            />
            <div className="sc-user-name" style={{ fontWeight: '600' }}>
              {dashboard
                ? `${jobDetails?.expertData?.firstName} ${jobDetails?.expertData?.lastName}`
                : `${jobDetails?.expertDetails?.firstName} ${jobDetails?.expertDetails?.lastName}`}
              <div style={{ fontSize: '13px', fontWeight: '400' }}>{jobDetails?.jobRole}</div>
            </div>
          </div>

          <div className="sc-btns">
            {/* {jobDetails?.expertJobApplication?.length > 0 && (
              <div className="sc-dropdown">
                <ColorDropdown onColorChange={handleColorChange} />
              </div>
            )} */}
            <PrimaryButton sx={{ width: '230px' }} onClick={() => contactUser()}>
              Contact
            </PrimaryButton>
            <div className="cross-btn" onClick={closeDialog} style={{ cursor: 'pointer' }}>
              <BigCrossIcon />
            </div>
          </div>
        </div>
        <div className="sc-about">
          <div className="sc-about-head">About</div>
          <div className="about-des">{jobDetails?.experienceDescription}</div>
          <div className="roles">
            <div className="job-role">
              <div className="about-heads">Job Role</div>
              <div className="about-body">{jobDetails?.jobRole}</div>
            </div>
            <div className="industry">
              <div className="about-heads">Industry</div>
              <div className="about-body">{jobDetails?.industry}</div>
            </div>
            <div className="domain">
              <div className="about-heads">Domian</div>
              <div className="about-body">{jobDetails?.domain}</div>
            </div>
            <div className="experience">
              <div className="about-heads">Year(s) of Experience</div>
              <div className="about-body">{jobDetails?.experienceYears}</div>
            </div>
            <div className="location">
              <div className="about-heads">Current location</div>
              <div className="about-body">
                {jobDetails?.city}, {jobDetails?.state}
              </div>
            </div>
          </div>
          <div className="skills-head">Skills</div>
          <div className="skills">
            {jobDetails?.skills?.map((item) => (
              <div className="user-application-2">{item}</div>
            ))}
          </div>
          <div className="recume-wrap" onClick={() => DownloadResumePdf()}>
            <PdfIcon />
            <div>{`${dashboard ? jobDetails?.expertData?.firstName : jobDetails?.expertDetails?.firstName}'s Resume`}</div>
          </div>
        </div>
        <div className="sc-social">
          <div className="social-head">Social</div>
          <div className="social-links">
            <div className="social-link-head">Linkedin Profile</div>
            <div className="social-link-body">
              <a
                className="job-links"
                href={jobDetails?.linkedInLink}
                target="_blank"
                rel="noreferrer">
                {jobDetails?.linkedInLink}{' '}
              </a>
            </div>
          </div>
          <div className="social-links">
            <div className="social-link-head">Social Media Link</div>
            <div className="social-link-body">
              <a
                className="job-links"
                href={jobDetails?.socialLink}
                target="_blank"
                rel="noreferrer">
                {jobDetails?.socialLink}{' '}
              </a>
            </div>
          </div>
        </div>
        <div className="sc-charge-available">
          <div className="sc-charge">
            <div className="charge-head">Charges & Preference</div>
            <div className="charge-body-wrap">
              <div className="change-value">
                <div className="charge">₹ {jobDetails?.compensation}</div>
                <div className="charge-duration">{jobDetails?.compensationPeriod}</div>
              </div>
              <div className="change-locations">
                <div className="chare-location-head">Work Preference</div>
                <div className="charge-location-body">
                  {capitalizeWorkPreference(jobDetails?.workPreference)}
                </div>
              </div>
              <div className="change-locations">
                <div className="chare-location-head">Preferred Job Location</div>
                <div className="charge-location-body">
                  {jobDetails?.preferredJobLocation ? jobDetails?.preferredJobLocation : 'NA'}
                </div>
              </div>
            </div>
          </div>
          <div className="sc-available">
            <div className="available-head">Availability</div>
            <div className="available-wrap">
              <div className="available-locations">
                <div className="available-location-head">Available</div>
                <div className="available-location-body">
                  {' '}
                  {jobDetails?.availabilityType
                    ? jobDetails.availabilityType.charAt(0).toUpperCase() +
                      jobDetails.availabilityType.slice(1)
                    : ''}
                </div>
              </div>
              <div className="available-locations">
                <div className="available-location-head">
                  Active {durationCheck(jobDetails?.availability, jobDetails?.availabilityType)}
                </div>
                <div className="available-location-body">{jobDetails?.availability}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledSuggestedCandidateProfile>
  );

  return (
    <Dialog
      fullScreen
      open={openDialog}
      onClose={closeDialog}
      sx={{
        marginTop: '64px',
        '& .MuiDialog-paper': {
          borderTopLeftRadius: '64px',
          borderTopRightRadius: '64px',
        },
      }}>
      {JobdetailsModalContent()}
    </Dialog>
  );
};

export default SuggestedCandidateProfile;
