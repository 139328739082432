import { styled } from '@mui/material/styles';

const StyledFilterMobile = styled('div')(
  ({ theme }) => `
    .component-wrap {
      background-color: #F8F4F0;
      height: 100vh;
      .wrap-header {
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        .header {
          color: ${theme.palette.primary.black};
          font-family: Plus Jakarta Sans;
          font-size: 18px;
          font-weight: 700;
        }
        .close {
          color: #415DF3;
          font-family: Plus Jakarta Sans;
          font-size: 18px;
          font-weight: 700;
          cursor: pointer;
        }  
      }
      .filter-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        gap: 10px;
        height: 80vh;
        
        .filter-topics {
          background: white;
          display: flex;
          flex-direction: column;
          gap: 50px;
          padding: 20px 0px 20px 20px;
          height: 100%;
          .topic {
            color: #050505;
            font-family: Plus Jakarta Sans;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
          } 
        } 
        .filter-options {
          background: white;
          display: flex;
          flex-direction: column;
          padding: 20px 0px 20px 20px;
          height: 100%;
          overflow-y: auto;

          .filter-options-header {
            color: #050505;
            font-family: Sora;
            font-size: 14px;
            font-weight: 700;
            text-transform: capitalize;
          }
        }  
      }
      .filter-buttons {
        margin-top: 20px;
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px; 
      }   
    }
  .filters-wrap{
    background: ${theme.palette.secondary.main}; 
    border-radius:12px;
    padding:20px;
    @media screen and (max-width: 900px) {
    display: none;
    }
    .section-content{
      text-align:left;
      .section-heading{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.16px;
        text-align: left;
        color:#1C1C1C;
        text-transform:capitalize;
        margin:12px 0px;
      }
      .MuiListItemButton-root{
        padding:0px;
      }
      .MuiListItemIcon-root{
        min-width:44px;
      }
      .MuiListItemText-primary {
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.64px;
        text-align: left;
        color: #1C1C1C;
      }
      .more-text{
        display:inline-flex;
        align-items:center;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.64px;
        text-align: left;
        color:#415DF3;
        margin-bottom:20px;
        cursor:pointer;
    }
  }`,
);

export default StyledFilterMobile;
