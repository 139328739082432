/* eslint-disable radix */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable func-names */
/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
import { REGEX, CONSTANTS } from '../../constants/constants';
import validationMessages from '../../constants/message';

const {
  ALLOWED_EXTENSION,
  MB_1,
  SOCIALLINKS: { YOUTUBE, TWITTER, WHATSAPP, TELEGRAM, SUBSTACK, LINKEDIN },
} = CONSTANTS;

const {
  MSG_VALID_EMAIL,
  MSG_EMAIL_REQ,
  MSG_REQUIRED_PASSWORD,
  MSG_EMAIL_LENGTH,
  MSG_FIRSTNAME_REQ,
  MSG_LASTNAME_REQ,
  MSG_VALID_USERNAME,
  MSG_USERNAME_REQ,
  MSG_USERNAME_LENGTH,
  MSG_FIRSTNAME_LENGTH,
  MSG_LASTNAME_LENGTH,
  MSG_VALID_FIRSTNAME,
  MSG_VALID_LASTNAME,
  MSG_MESSAGE_REQ,
  MSG_MESSAGE_LENGTH,
} = validationMessages;

const { EMAIL_PATTERN, USERNAME_PATTERN, FIRSTNAME_PATTERN, URL_PATTERN } = REGEX;
const validationSchema = yup.object({
  email: yup
    .string()
    .required(MSG_EMAIL_REQ)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH),
  password: yup.string().required(MSG_REQUIRED_PASSWORD),
});

const validationEmailSchema = yup.object({
  email: yup
    .string()
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH)
    .required(MSG_EMAIL_REQ),
});

const validationSignUpSchema = yup.object({
  email: yup
    .string()
    .required(MSG_EMAIL_REQ)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(254, MSG_EMAIL_LENGTH),
  firstName: yup
    .string()
    .required(MSG_FIRSTNAME_REQ)
    .matches(FIRSTNAME_PATTERN, MSG_VALID_FIRSTNAME)
    .max(50, MSG_FIRSTNAME_LENGTH),
  lastName: yup
    .string()
    .required(MSG_LASTNAME_REQ)
    .matches(FIRSTNAME_PATTERN, MSG_VALID_LASTNAME)
    .max(50, MSG_LASTNAME_LENGTH),
  userName: yup
    .string()
    .required(MSG_USERNAME_REQ)
    .matches(USERNAME_PATTERN, MSG_VALID_USERNAME)
    .max(20, MSG_USERNAME_LENGTH),
  country: yup.string().required('Country is required'),
});

const validationSpeaker = yup.object({
  email: yup
    .string()
    .required(MSG_EMAIL_REQ)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(100, MSG_EMAIL_LENGTH),
  message: yup.string().required(MSG_MESSAGE_REQ).max(200, MSG_MESSAGE_LENGTH),
});

const validationExpertContact = yup.object({
  contactEmail: yup
    .string()
    .required(MSG_EMAIL_REQ)
    .matches(EMAIL_PATTERN, MSG_VALID_EMAIL)
    .max(100, MSG_EMAIL_LENGTH),
  message: yup.string().required(MSG_MESSAGE_REQ).max(200, MSG_MESSAGE_LENGTH),
});

const validationEditProfileSchema = yup.object({
  firstName: yup
    .string()
    .required(MSG_FIRSTNAME_REQ)
    .matches(FIRSTNAME_PATTERN, MSG_VALID_FIRSTNAME)
    .max(50, MSG_FIRSTNAME_LENGTH),
  lastName: yup
    .string()
    .required(MSG_LASTNAME_REQ)
    .matches(FIRSTNAME_PATTERN, MSG_VALID_LASTNAME)
    .max(50, MSG_LASTNAME_LENGTH),
});

const validationAddServiceSchema = yup.object({
  serviceName: yup.string().required('This field is required'),
  price: yup
    .string()
    .matches(/^\d{1,5}$/, 'Price should not exceed 5 digits') // Allows up to 5 digits
    .test('is-valid-price', 'Price should be more than Rs 50', (value) => {
      const numericValue = Number(value);
      return numericValue === 0 || numericValue >= 50;
    })
    .required('This field is required'), // Field is required

  duration: yup
    .number()
    .min(30, 'Duration should be minimum 30 minutes')
    .typeError('Please enter a valid number')
    .required('This field is required'),
  description: yup
    .string()
    .required('This field is required')
    .max(250, 'Description length should not be more than 250 characters'),
});

const validationPersonalInfoSchema = yup.object({
  whoAmI: yup.string().required('This field is required'),
  expertise: yup.string().required('This field is required'),
  bio: yup
    .string()
    .required('This field is required')
    .min(100, 'Description length should be minimum 100 characters')
    .max(350, 'Description length should not be more than 350 characters'),
  aboutMe: yup
    .string()
    .required('This field is required')
    .min(500, 'Minimum characters required: 500')
    .max(3500, 'About me length should not be more than 3500 characters'),
});

const validateFileType = (extension) => {
  if (ALLOWED_EXTENSION.includes(extension.toLowerCase())) {
    return true;
  }
  return false;
};

const validateFileSize = (uploadFile) => {
  const { size = 0 } = uploadFile;
  if (size > MB_1) {
    return true;
  }
  return false;
};

const YOUTUBE_URL_PATTERN = /^https?:\/\/(?:www\.)?.*youtube.*$/;
const TWITTER_X_URL_PATTERN = /^https?:\/\/(?:twitter\.com|x\.com)\/.+/;
const WHATSAPP_URL_PATTERN = /^https?:\/\/chat\.whatsapp\.com\/.+/;
const TELEGRAM_URL_PATTERN = /^https?:\/\/telegram\.me\/.+/;
const SUBSTACK_URL_PATTERN = /^https?:\/\/(?:[A-Za-z0-9_-]+\.)?substack\.com\/.+/;
const LINKEDIN_URL_PATTERN = /^https?:\/\/(?:www\.)?linkedin\.com\/.+/;

const validationSocialLinkSchema = yup.object({
  socialLinks: yup.array().of(
    yup.object().shape({
      mediaType: yup.string().required('This field is required'),
      mediaProfileUrl: yup
        .string()
        .when('mediaType', (mediaType, schema) => {
          if (mediaType.includes(YOUTUBE.toString())) {
            return schema
              .required('Please enter YouTube URL')
              .matches(YOUTUBE_URL_PATTERN, 'Please enter a valid YouTube URL');
          }
          if (mediaType.includes(TWITTER.toString())) {
            return schema
              .required('Please enter Twitter or X URL')
              .matches(TWITTER_X_URL_PATTERN, 'Please enter a valid Twitter or X URL');
          }
          if (mediaType.includes(WHATSAPP.toString())) {
            return schema
              .required('Please enter WhatsApp URL')
              .matches(WHATSAPP_URL_PATTERN, 'Please enter a valid WhatsApp URL');
          }
          if (mediaType.includes(TELEGRAM.toString())) {
            return schema
              .required('Please enter Telegram URL')
              .matches(TELEGRAM_URL_PATTERN, 'Please enter a valid Telegram URL');
          }
          if (mediaType.includes(SUBSTACK.toString())) {
            return schema
              .required('Please enter Substack URL')
              .matches(SUBSTACK_URL_PATTERN, 'Please enter a valid Substack URL');
          }
          if (mediaType.includes(LINKEDIN.toString())) {
            return schema
              .required('Please enter LinkedIn URL')
              .matches(LINKEDIN_URL_PATTERN, 'Please enter a valid LinkedIn URL');
          }
          return schema.required('Please enter URL');
        })
        .required('This field is required')
        .matches(URL_PATTERN, 'Please enter a valid URL'),
    }),
  ),
});

const URLVALIDATION_PATTERN = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

const validationLinksUrl = yup.object({
  popupUrlsData: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .max(50, 'Title length should not be more than 50 characters')
        .required('This field is required'),
      url: yup
        .string()
        .matches(URLVALIDATION_PATTERN, 'Please enter a valid url')
        .required('This field is required'),
    }),
  ),
});

const validationBooksCourcesSchema = yup.object({
  courses: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required('This field is required')
        .max(100, 'Name length should not be more than 100 characters'),
      url: yup
        .string()
        .required('This field is required')
        .matches(URL_PATTERN, 'Please enter valid url'),
    }),
  ),
  books: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required('This field is required')
        .max(100, 'Name length should not be more than 100 characters'),
      url: yup
        .string()
        .required('This field is required')
        .matches(URL_PATTERN, 'Please enter valid url'),
    }),
  ),
});

const validationAvailabilitySlots = yup.object({
  slotLists: yup.array().of(
    yup
      .object()
      .shape({
        timeLists: yup.array().of(
          yup.object().shape({
            startTime: yup
              .object()
              .shape({
                hours: yup.number(),
                minutes: yup.number(),
              })
              .test(
                'start-time-less-than-end-time',
                'Start time must be less than end time and have a minimum difference of 30 minutes',
                function (value) {
                  const { endTime } = this.parent;
                  const startHours = value.hours * 60 + value.minutes;
                  const endHours = endTime.hours * 60 + endTime.minutes;
                  const minimumDifference = 30;
                  return endHours > startHours && endHours - startHours >= minimumDifference;
                },
              ),
            endTime: yup.object().shape({
              hours: yup.number(),
              minutes: yup.number(),
            }),
          }),
        ),
      })
      .test(
        'unique-start-end-time',
        'You have already entered availability for this period',
        function () {
          const errors = [];
          const slotLists = this.parent;

          for (let i = 0; i < slotLists.length; i++) {
            const currentTimeLists = slotLists[i].timeLists;

            for (let j = 0; j < currentTimeLists.length; j++) {
              const currentSlot = currentTimeLists[j];
              const currentTime = `${currentSlot?.startTime?.hours}:${currentSlot?.startTime?.minutes}-${currentSlot?.endTime?.hours}:${currentSlot?.endTime?.minutes}`;

              for (let k = j + 1; j < currentTimeLists.length; j++) {
                const otherSlot = currentTimeLists[k];
                const otherTime = `${otherSlot?.startTime?.hours}:${otherSlot?.startTime?.minutes}-${otherSlot?.endTime?.hours}:${otherSlot?.endTime?.minutes}`;

                if (currentTime === otherTime) {
                  // Add error message with the index of the duplicate slot
                  errors.push(
                    this.createError({
                      message: 'You have already entered availability for this period',
                      path: `slotLists[${i}].timeLists[${k}]`,
                      type: 'unique-start-end-time',
                    }),
                  );
                }
              }
            }
          }
          if (errors.length > 0) {
            throw new yup.ValidationError(errors);
          }
          return true;
        },
      )
      .test('no-overlapping-slots', 'Time slots cannot overlap with each other', function () {
        const errors = [];
        const slotLists = this.parent;

        for (let i = 0; i < slotLists.length; i++) {
          const currentTimeLists = slotLists[i].timeLists;

          for (let j = 0; j < currentTimeLists.length; j++) {
            const currentSlot = currentTimeLists[j];
            const currentStart =
              currentSlot?.startTime?.hours * 60 + currentSlot?.startTime?.minutes;
            const currentEnd = currentSlot?.endTime?.hours * 60 + currentSlot?.endTime?.minutes;

            for (let k = j + 1; k < currentTimeLists.length; k++) {
              const otherSlot = currentTimeLists[k];
              const otherStart = otherSlot?.startTime?.hours * 60 + otherSlot?.startTime?.minutes;
              const otherEnd = otherSlot?.endTime?.hours * 60 + otherSlot?.endTime?.minutes;

              if (
                (otherStart >= currentStart && otherStart < currentEnd) ||
                (otherEnd > currentStart && otherEnd <= currentEnd) ||
                (otherStart <= currentStart && otherEnd >= currentEnd)
              ) {
                // Add error message with the index of overlapping slot
                errors.push(
                  this.createError({
                    message: 'Time slots cannot overlap with each other',
                    path: `slotLists[${i}].timeLists[${k}]`,
                    type: 'no-overlapping-slots',
                  }),
                );
                break;
              }
            }
          }
        }

        if (errors.length > 0) {
          throw new yup.ValidationError(errors);
        }

        return true;
      }),
  ),
});

const validationMailDescription = yup.object({
  bio: yup
    .string()
    .required('Description field is required')
    .max(500, 'Description length should not be more than 500 characters'),
});

const validationSchemaBankDetails = yup.object().shape({
  country: yup.string().required('Country is required'),
  accountType: yup.string().required('Account Type is required'),
  accountHolderName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'Account Holder Name should only contain alphabets and spaces')
    .required('Account Holder Name is required'),

  ifscCode: yup
    .string()
    .matches(
      /^[A-Za-z0-9]+$/,
      'IFSC Code should only contain alphabets and numbers without any special characters',
    )
    .matches(/[0-9]/, 'Invalid IFSC Code, must contain at least one numeric value')
    .length(11, 'Please enter 11-digit alpha-numeric code')
    .required('IFSC Code is required'),

  accountNumber: yup
    .string()
    .transform((value) => value.replace(/[^0-9]/g, ''))
    .test(
      'is-numeric',
      'Account Number should only contain numbers without any special characters',
      (value) => /^[0-9]*$/.test(value),
    )
    .min(9, 'Minimum Account Number length should be 9 characters')
    .max(18, 'Maximum Account Number length should be 18 characters')
    .required('Account Number is required'),
});
const validationSchemaAddFeedback = yup.object().shape({
  name: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'Name should only contain alphabets and spaces')
    .required('This field is required'),
  link: yup
    .string()
    .matches(
      REGEX.SOCIALMEDIALINKS,
      'Invalid social media profile URL. Allowed platforms: LinkedIn, Twitter, GitHub, Dribbble',
    )
    .required('This field is required'),
  message: yup
    .string()
    .required('This field is required')
    .test('word-count', 'Feedback Message must be at most 100 words', (value) => {
      if (!value) return true; // If no message, pass validation for required to handle
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount <= 100;
    }),
});

const validationPostaJobBasic = yup.object({
  contactDetails: yup
    .string()
    .required('Contact details is required')
    .length(10, 'Contact details must be exactly 10 digits')
    .matches(/^\d+$/, 'Contact details must only contain digits'),
  linkedInProfileLink: yup
    .string()
    .matches(/^https:\/\/(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+\/?$/, 'Invalid LinkedIn URL')
    .required('LinkedIn profile URL is required'),
  companyWebsite: yup
    .string()
    .matches(
      /^(https?:\/\/)(www\.)?[a-zA-Z0-9-]+\.(com|org|net|gov|in|edu|io|co|info|biz|co\.uk|us|ca)(\/[^\s]*)?$/,
      'Please enter a valid website with a proper domain extension (e.g., .com, .org, .net)',
    ),
});
const validationPostaJobDetails = yup.object({
  jobRole: yup.string().required('Job role is required'),
  experienceYears: yup
    .number()
    .typeError('Experience years must be a number')
    .min(0, 'Experience years cannot be negative')
    .max(50, 'Experience years cannot exceed 50')
    .required('Experience years are required'),
  industry: yup.string().required('Industry is required'),
  companysWebsite: yup.string().url('Please enter a valid website'),
  experienceDescription: yup
    .string()
    .required('Job description is required')
    .max(3500, 'Description should not exceed 3500 characters'),
  skills: yup
    .array()
    .of(yup.string().required('Each skill is required'))
    .min(1, 'At least one skill is required')
    .required('Skills are required'),
  linkJobPortal: yup
    .string()
    .matches(
      /^(https?:\/\/)(www\.)?[a-zA-Z0-9-]+\.(com|org|net|gov|in|edu|io|co|info|biz|co\.uk|us|ca)(\/[^\s]*)?$/,
      'Please enter a valid website with a proper domain extension (e.g., .com, .org, .net)',
    ),
  domain: yup.string().required('Domain is Required'),
});

const validationJobInfoSchema = yup.object({
  jobRole: yup.string().required('Job role is required'),

  skills: yup
    .array()
    .of(yup.string().required('Each skill is required'))
    .min(1, 'At least one skill is required')
    .required('Skills are required'),

  domain: yup.string().required('Domain is required'),

  socialLink: yup
    .string()
    .nullable()
    .notRequired()
    .matches(
      /(https?:\/\/)?(www\.)?(twitter|github|dribbble|x)\.com\/[a-zA-Z0-9_]+/,
      'Social link must be a valid Twitter, X, GitHub, or Dribbble URL',
    ),

  linkedInLink: yup
    .string()
    .nullable()
    .notRequired()
    .matches(
      /(https?:\/\/)?(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9_-]+/,
      'Invalid LinkedIn URL',
    ),

  industry: yup.string().required('Industry is required'),

  experienceYears: yup
    .number()
    .typeError('Experience years must be a number')
    .min(0, 'Experience years cannot be negative')
    .max(50, 'Experience years cannot exceed 50')
    .required('Experience years are required'),

  resumeUrl: yup.string().required('Upload resume to continue to Next'),

  experienceDescription: yup
    .string()
    .required('Experience description is required')
    .max(3500, 'Description should not exceed 3500 characters'),
});

const validationSchemaLocation = yup.object({
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  workPreference: yup
    .string()
    .oneOf(['remote', 'onSite', 'hybrid'], 'Invalid work preference')
    .required('Work preference is required'),
  preferredJobLocation: yup
    .string()
    .when('workPreference', (workPreference, schema) =>
      workPreference !== 'remote'
        ? schema.required('Preferred job location is required')
        : schema.optional(),
    ),
});
const validationSchemaRecruiterLocation = yup.object({
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  workPreference: yup
    .string()
    .oneOf(['remote', 'onSite', 'hybrid'], 'Invalid work preference')
    .required('Work preference is required'),
  preferredJobLocation: yup
    .string()
    .when('workPreference', (workPreference, schema) =>
      workPreference !== 'remote'
        ? schema.required('Preferred job location is required')
        : schema.optional(),
    ),
});

const validationSchemaAvailability = yup.object({
  compensation: yup
    .number()
    .required('Compensation is required')
    .positive('Compensation must be a positive number')
    .max(99999999, 'Price can range between 0 to 99999999.'),
  isNegotiable: yup.boolean().optional(),
  compensationPeriod: yup
    .string()
    .oneOf(['Per day', 'Per week', 'Per month'], 'Invalid compensation period')
    .required('Compensation period is required'),
  availabilityType: yup.string().required('Availability type is required'),
  availability: yup
    .number()
    .required('Availability is required')
    .min(1, 'Must be at least 1')
    .test('max-value', 'Value exceeds the maximum allowed', function (value) {
      const { availabilityType } = this.parent;
      const maxValues = { daily: 31, weekly: 4, monthly: 12 };
      const maxValue = maxValues[availabilityType];
      if (!value) return true;
      return value <= maxValue;
    }),
});

export {
  validationSchema,
  validationEmailSchema,
  validationSignUpSchema,
  validationEditProfileSchema,
  validationAddServiceSchema,
  validateFileType,
  validateFileSize,
  validationSocialLinkSchema,
  validationPersonalInfoSchema,
  validationBooksCourcesSchema,
  validationAvailabilitySlots,
  validationMailDescription,
  validationLinksUrl,
  validationSpeaker,
  validationSchemaBankDetails,
  validationSchemaAddFeedback,
  validationJobInfoSchema,
  validationSchemaLocation,
  validationSchemaAvailability,
  validationPostaJobBasic,
  validationPostaJobDetails,
  validationSchemaRecruiterLocation,
  validationExpertContact,
};
