import { TextField } from '@mui/material';
import React, { forwardRef } from 'react';
import StyledTextField from '../TextField/StyledTextField';
import '../../styles/_global.scss';
import CustomTooltip from '../Tooltip/Tooltip';

const LocationAutocomplete = forwardRef(
  ({ name, label, value, onChange, onBlur, important, showTooltip = false,
    tooltip = '', ...props }, ref) => (
      <StyledTextField>
        <div className="input-Label_text label-space" style={{ lineHeight: '21px', marginBottom: '3px' }}>
          {label}
          {important ? <span className="">*</span> : ''}
          {showTooltip ? <CustomTooltip title={tooltip} placement="top" /> : ''}
        </div>
        <TextField
          inputRef={ref}
          fullWidth
          color="secondary"
          focused={false}
          autoFocus={false}
          className="custom-field"
          size="small"
          variant="standard"
          margin="normal"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          InputProps={{
            disableUnderline: true,
          }}
          {...props}
    />
      </StyledTextField>
  ),
);
export default LocationAutocomplete;
