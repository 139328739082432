/* eslint-disable arrow-body-style */
import React from 'react';

const SearchIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.66536 13.9987C11.1632 13.9987 13.9987 11.1632 13.9987 7.66536C13.9987 4.16756 11.1632 1.33203 7.66536 1.33203C4.16756 1.33203 1.33203 4.16756 1.33203 7.66536C1.33203 11.1632 4.16756 13.9987 7.66536 13.9987Z"
        stroke="#525252"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6654 14.6654L13.332 13.332"
        stroke="#525252"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
