import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ children }) {
  const auth = localStorage.getItem('auth');
  const isRecruiter = localStorage.getItem('recruiter');
  const location = useLocation();

  if (!auth) {
    return <Navigate to="/" />;
  }
  if (isRecruiter) {
    const allowedPaths = [
      '/recruiter-dashboard',
      '/suggested-candidates',
      '/recruiter-subscription',
      '/track-job-applications',
      '/postjob',
      '/summary',
      '/applicant/details',
    ];

    const isDynamicSummaryPath = location.pathname.startsWith('/summary/');
    if (!allowedPaths.includes(location.pathname) && !isDynamicSummaryPath) {
      return <Navigate to="/" />;
    }
  }

  return children;
}

export default PrivateRoute;
