import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthorization } from '../../../services/api';
import { API_PATH } from '../../../constants/route';
import { updateLoading } from '../loader';

const createRecruiterJob = createAsyncThunk(
  'recruiterProfile/createJob',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    const API_WITHOUT_HEADER = getAuthorization(getState, { isAuthHeader: false });
    try {
      dispatch(updateLoading(true));
      let response;
      if (data?.imageFile?.name) {
        const { fileExtention, imageFile, ...resData } = data;
        const s3Data = {
          extension: fileExtention,
        };
        let companyLogo = '';
        if (fileExtention) {
          const signedUrlRes = await API.post(API_PATH.GET_S3_URL, s3Data);
          const { data: { data: { path, url } = {} } = {} } = signedUrlRes;
          companyLogo = path;

          await API_WITHOUT_HEADER.put(url, imageFile);
        }
        const postJobData = {
          ...resData,
          companyLogo,
        };
        delete postJobData.fileExtension;
        delete postJobData.imageFile;
        response = await API.post(API_PATH.CREATE_RECRUITER_JOB, postJobData);
      } else {
        delete data.fileExtention;
        delete data.imageFile;
        response = await API.post(API_PATH.CREATE_RECRUITER_JOB, data);
      }
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const getRecruiterJobDetails = createAsyncThunk(
  'recruiterProfile/getJob',
  async (id, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(`${API_PATH.GET_RECRUITER_JOB_DETAILS}${id}`);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const getRecruiterJob = createAsyncThunk(
  'recruiterProfile/Job',
  async (_, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.GET_RECRUITER_JOB);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchRecruiterSearchingFilters = createAsyncThunk(
  'recruiter/fetchSearchingFilters',
  async (data, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.SEARCHING_FILTERS, data);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchRecruiterSearchCandidates = createAsyncThunk(
  'recruiter/fetchSearchCandidates',
  async (querry, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.SEARCH_RECRUITER_CANDIDATES + querry);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchRecruiterApplications = createAsyncThunk(
  'recruiter/fetchJobApplications',
  async (querry, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.RECRUITER_JOB_APPLICATIONS + querry);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const updateRecruiterApplicationStatus = createAsyncThunk(
  'recruiter/updateJobApplicationsStatus',
  async (data, { dispatch, getState }) => {
    const { id, dataToSend } = data;
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.patch(
        API_PATH.RECRUITER_UPDATE_APPLICATION_STATUS + id,
        dataToSend,
      );
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const fetchExportApplicants = createAsyncThunk(
  'recruiter/exportjobApplicants',
  async (query, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.RECRUITER_EXPORT_APPLICANTS + query);
      dispatch(updateLoading(false));
      return response?.data;
    } catch (err) {
      return err;
    }
  },
);
const getExpertDetails = createAsyncThunk(
  'recruiter/expertdetails',
  async (id, { dispatch, getState }) => {
    const API = getAuthorization(getState);
    try {
      dispatch(updateLoading(true));
      const response = await API.get(API_PATH.EXPERT_DETAILS + id);
      dispatch(updateLoading(false));
      return response;
    } catch (err) {
      return err;
    }
  },
);

export {
  createRecruiterJob,
  getRecruiterJobDetails,
  getRecruiterJob,
  fetchRecruiterSearchingFilters,
  fetchRecruiterSearchCandidates,
  fetchRecruiterApplications,
  updateRecruiterApplicationStatus,
  fetchExportApplicants,
  getExpertDetails,
};
