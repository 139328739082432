/* eslint-disable arrow-body-style */
import React from 'react';

const SmallLocationIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99992 8.95297C9.14867 8.95297 10.0799 8.02172 10.0799 6.87297C10.0799 5.72422 9.14867 4.79297 7.99992 4.79297C6.85117 4.79297 5.91992 5.72422 5.91992 6.87297C5.91992 8.02172 6.85117 8.95297 7.99992 8.95297Z"
        stroke="#415DF3"
      />
      <path
        d="M2.41281 5.6587C3.72615 -0.114634 12.2795 -0.107967 13.5861 5.66537C14.3528 9.05203 12.2461 11.9187 10.3995 13.692C9.05948 14.9854 6.93948 14.9854 5.59281 13.692C3.75281 11.9187 1.64615 9.04537 2.41281 5.6587Z"
        stroke="#415DF3"
      />
    </svg>
  );
};

export default SmallLocationIcon;
