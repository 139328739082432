/* eslint-disable arrow-body-style */
/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BackArrowIcon from '../../../components/icons/BackArrowIcon';
import StyledApplicantDetails from './StyledApplicantDetail';
import linkdinIcon from '../../../assets/svg/linkdinIcon.svg';
import ColorDropdown from '../../../components/ColorDropdown';
import { BorderButton, PrimaryButton } from '../../../components/Button';
import experienceIcon from '../../../assets/svg/experinceIcon.svg';
import locationIcon from '../../../assets/svg/locationIcon.svg';
import industryIcon from '../../../assets/svg/industryIcon.svg';
import domainIcon from '../../../assets/svg/domainIcon.svg';
import priceIcon from '../../../assets/svg/priceCoinIcon.svg';
import infoIcon from '../../../assets/svg/infoIcon.svg';
import CircleAvatar from '../../../components/CircleAvatar';
import educationIcon from '../../../assets/svg/educationIcon.svg';
import workIcon from '../../../assets/svg/workIcon.svg';
import smallLocationIcon from '../../../assets/svg/smallLocationIcon.svg';
import circleIcon from '../../../assets/svg/circleTickIcon.svg';
import resumeIcon from '../../../assets/svg/resumeIcon.svg';
import smallCalenderIcon from '../../../assets/svg/smallCalenderIcon.svg';
import {
  updateRecruiterApplicationStatus,
  getExpertDetails,
} from '../../../store/reducers/recruiterPostJob/apiThunk';
import {
  getContactRecruiter,
  getDownloadResume,
} from '../../../store/reducers/recruiterProfile/apiThunk';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import bgLines from '../../../assets/svg/bg-lines.svg';
import bgArrow from '../../../assets/svg/bg-arrow.svg';
import bgText from '../../../assets/svg/bg-text-icon.svg';
import twitterIcon from '../../../assets/svg/twitterIcon.svg';
import dribbleIcon from '../../../assets/svg/dribbbleIcon.svg';
import githubIcon from '../../../assets/svg/githubIcon.svg';
import portfolioIcon from '../../../assets/svg/portfolioIcon.svg';
import { checkCompensationType, durationCheck } from '../../../helper';
import Modal from '../../../components/Modal';
import arrow from '../../../assets/svg/arrow.svg';

const ApplicantDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [expertId, setExpertId] = useState(null);
  const [applicant, setApplicant] = useState();
  const [applicationStatus, setApplicationStatus] = useState(applicant?.jobApplicationStatus);
  const [openModal, setOpenModal] = useState(false);
  const { scandidate } = location.state || {};
  const auth = localStorage.getItem('auth');
  const isRecruiter = localStorage.getItem('recruiter');

  const handleCloseDialoag = () => {
    setOpenModal(false);
  };

  const modalContent = () => (
    <>
      <div className="dialog-title" style={{ fontWeight: '600' }}>
        Please login/signup as a Recruiter to view the profile.
      </div>
      <div className="google-calendar-button-wrap">
        <PrimaryButton
          sx={{ width: '150px' }}
          onClick={() => {
            localStorage.setItem('redirectUrl', `/applicant-detail${window.location.search}`);
            localStorage.setItem('recruiter', 'true');
            navigate(auth ? '/dashboard' : '/login');
          }}>
          Continue
          <img src={arrow} alt="" srcSet="" className="arrow-icon" />
        </PrimaryButton>
      </div>
    </>
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Get the query parameter values
    if (location.search) {
      setExpertId(params.get('id'));
    }
  }, [location]);

  const fetchExpertDetails = async () => {
    const response = await dispatch(getExpertDetails(expertId));
    const { payload: { data: { data = {} }, status } = {} } = response;
    if (status) {
      setApplicant(data);
    }
  };

  useEffect(() => {
    if (!auth || (auth && isRecruiter === null)) {
      setOpenModal(true);
    } else if (expertId) {
      fetchExpertDetails();
      localStorage.removeItem('redirectUrl');
    }
  }, [expertId]);

  useEffect(() => {
    setApplicationStatus(applicant?.jobApplicationStatus);
  }, [applicant]);

  const changeStatus = async (RecentStatus, applicationId) => {
    const data = {
      id: applicationId,
      dataToSend: {
        jobApplicationStatus: RecentStatus,
      },
    };
    const response = await dispatch(updateRecruiterApplicationStatus(data));
    const { payload: { data: { status, data: { jobApplicationStatus } = {} } = {} } = {} } =
      response;

    if (status) {
      setApplicationStatus(jobApplicationStatus);
    }
  };

  const contactUser = async () => {
    const id = applicant?.expert?._id;
    const res = await dispatch(getContactRecruiter(id));
    if (res?.payload?.status) {
      ToastNotifySuccess('Email has been sent to the user');
    }
  };

  const capitalizeWorkPreference = (workPreference) => {
    if (!workPreference) return '';
    if (workPreference === 'onSite') {
      return 'On-Site';
    }
    return workPreference.charAt(0).toUpperCase() + workPreference.slice(1);
  };

  const DownloadResumePdf = async () => {
    const id = applicant?.expert?._id;
    if (!id) {
      console.error('Expert ID is missing.');
      return;
    }

    try {
      const res = await dispatch(getDownloadResume(id));
      if (res?.payload?.data?.status) {
        const fileUrl = res?.payload?.data?.data;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = `${applicant?.expert?.firstName}'s Resume`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error while downloading resume:', error);
    }
  };

  const getIconSrc = (url) => {
    if (!url) {
      return portfolioIcon;
    }
    const formattedUrl = url.startsWith('http') ? url : `https://${url}`;

    try {
      const domain = new URL(formattedUrl).hostname.toLowerCase();

      if (domain.includes('twitter.com')) {
        return twitterIcon;
      }

      if (domain.includes('dribbble.com')) {
        return dribbleIcon;
      }

      if (domain.includes('github.com')) {
        return githubIcon;
      }

      return portfolioIcon;
    } catch (error) {
      console.error('Invalid URL:', url);
      return portfolioIcon;
    }
  };

  if (!auth || (auth && isRecruiter === null)) {
    return (
      <Modal
        openDialog={openModal}
        closeDialog={handleCloseDialoag}
        dialogContent={modalContent()}
        noHeader
        dialogCss="logoutDialogWrap"
      />
    );
  }

  const checkCompensationType2 = (item) => {
    switch (item) {
      case 'Per day':
        return 'Month';
      case 'Per month':
        return 'Year';
      default:
        return 'Month';
    }
  };

  const durationCheckSuggestion = (item, suggNo) => {
    const isPlural = suggNo > 1;
    switch (item) {
      case 'daily':
        return isPlural ? 'Days' : 'Day';
      case 'weekly':
        return isPlural ? 'Weeks' : 'Week';
      default:
        return isPlural ? 'Months' : 'Month';
    }
  };

  return (
    <StyledApplicantDetails>
      <div className="content-wrap">
        <div style={{ textAlign: 'left' }}>
          <span className="back-btn" onClick={() => navigate(-1)}>
            <BackArrowIcon />
            <span className="back-btn-text">Profile</span>
          </span>
        </div>
        <div className="mobile-buttons">
          <div className="mobile-btn">
            <div className="linkdin">
              <a href={applicant?.linkedInLink} target="_blank" rel="noopener noreferrer">
                <img src={linkdinIcon} alt="LinkedIn" />
              </a>
            </div>
            <div className="insta">
              <a href={applicant?.socialLink} target="_blank" rel="noopener noreferrer">
                <img src={getIconSrc(applicant?.socialLink)} alt="socialLink" />
              </a>
            </div>
          </div>
          <PrimaryButton
            sx={{ width: '180px' }}
            className="contact-btn"
            onClick={() => contactUser()}>
            Contact
          </PrimaryButton>
        </div>

        <div className="detail-card">
          <div className="img-back" />
          <div className="main-card">
            <div className="header">
              <div className="left-header">
                <div className="img">
                  <div className="img-wrap">
                    <CircleAvatar
                      src={applicant?.expert?.profileImagePath}
                      size="180px"
                      MobileSize="100px"
                    />
                  </div>
                </div>
                <div className="applicant">
                  <div className="applicant-name">
                    {applicant?.expert?.firstName} {applicant?.expert?.lastName}
                  </div>
                  <div className="applicant-role">
                    <img src={educationIcon} alt="" />
                    {applicant?.jobRole}
                  </div>
                </div>
              </div>
              <div className="right-header">
                {applicant?.linkedInLink ?
                  <div className="linkdin">
                    <a href={applicant?.linkedInLink} target="_blank" rel="noopener noreferrer">
                      <img src={linkdinIcon} alt="LinkedIn" />
                    </a>
                  </div> : null}
                {applicant?.socialLink ?
                  <div className="insta">
                    <a href={applicant?.socialLink} target="_blank" rel="noopener noreferrer">
                      <img src={getIconSrc(applicant?.socialLink)} alt="socialLink" />
                    </a>
                  </div> : null}
                {!scandidate && (
                  <div className="sc-dropdown">
                    <ColorDropdown
                      selectedColor={applicationStatus}
                      onColorChange={(status) => changeStatus(status, applicant?._id)}
                      MobileWidth="100%"
                    />
                  </div>
                )}
                <PrimaryButton
                  sx={{ width: '180px' }}
                  className="contact-btn"
                  onClick={() => contactUser()}>
                  Contact
                </PrimaryButton>
              </div>
            </div>
            <div className="main-body-card">
              <div className="main-body">
                <div className="my-services">
                  <div className="image-btn">
                    <img src={bgLines} alt="" />
                  </div>
                  <div className="group-service">
                    <div className="image-text">
                      <img src={bgText} alt="" />
                    </div>
                    <div className="service-text">My Services</div>
                    <div className="image-arrow">
                      <img src={bgArrow} alt="" />
                    </div>
                  </div>
                  <div>
                    <BorderButton
                      style={{ width: '130px' }}
                      minHeight="35px"
                      onClick={() => {
                        setTimeout(() => {
                          window.open(
                            `${process.env.REACT_APP_URL}/services/${applicant?.expert?.username}`,
                            '_blank',
                          );
                        }, 0);
                      }}>
                      Book a Call
                    </BorderButton>
                  </div>
                </div>
                <div className="body-header">Basic Details</div>
                <div className="body-topics">
                  <div className="each-topic">
                    <img src={experienceIcon} alt="" />
                    <div className="topic-name">
                      <div className="topic-head">Experience</div>
                      <div className="topic-body">{`${applicant?.experienceYears} ${applicant?.experienceYears > 1 ? 'Year (s)' : 'Year'}`}</div>
                    </div>
                  </div>
                  <div className="each-topic">
                    <img src={locationIcon} alt="" />
                    <div className="topic-name">
                      <div className="topic-head">Current Location</div>
                      <div className="topic-body">
                        {`${applicant?.city}, 
                      ${applicant?.state}`}
                      </div>
                    </div>
                  </div>
                  <div className="each-topic">
                    <img src={industryIcon} alt="" />
                    <div className="topic-name">
                      <div className="topic-head">Industry</div>
                      <div className="topic-body">{applicant?.industry}</div>
                    </div>
                  </div>
                  <div className="each-topic">
                    <img src={domainIcon} alt="" />
                    <div className="topic-name">
                      <div className="topic-head">Domain</div>
                      <div className="topic-body">{applicant?.domain}</div>
                    </div>
                  </div>
                </div>
                <div className="skills-head">Skills</div>
                <div className="skills">
                  {applicant?.skills?.map((item) => (
                    <div className="user-application-2" key={item?.id}>
                      {item}
                    </div>
                  ))}
                </div>
                <div className="about-me">About Me</div>
                <div className="about-desc">{applicant?.experienceDescription}</div>
              </div>
              <div className="subs-cards">
                <div className="card-1">
                  <div className="card-head">Charges & Preferences</div>
                  <div className="price">
                    <img src={priceIcon} alt="" />
                    <div className="price-card">
                      <div className="price-text">₹{applicant?.compensation}</div>
                      <div className="price-duration">{`Fees per ${checkCompensationType(applicant?.compensationType)}`}</div>
                    </div>
                  </div>
                  <div className="nego">
                    <img src={infoIcon} alt="" />
                    <div className="nego-text">
                      This price is{' '}
                      <span>{` ${applicant?.isNegotiable ? 'Negotiable' : 'Non-Negotiable'}!`}</span>
                    </div>
                  </div>
                  <hr />
                  <div className="subs">
                    <img src={workIcon} alt="" />
                    <div className="subs-main">
                      <div className="subs-topic">Work Preference</div>
                      <div className="subs-body">
                        {capitalizeWorkPreference(applicant?.workPreference)}
                      </div>
                    </div>
                  </div>
                  <div className="subs">
                    <img src={smallLocationIcon} alt="" />
                    <div className="subs-main">
                      <div className="subs-topic">Preferred Job Location</div>
                      <div className="subs-body">{applicant?.preferredJobLocation}</div>
                    </div>
                  </div>
                </div>
                <div className="card-1" style={{ marginTop: '20px' }}>
                  <div className="card-head">Availability</div>
                  <div className="subs">
                    <img src={circleIcon} alt="" />
                    <div className="subs-main">
                      <div className="subs-topic">Available For</div>
                      <div className="subs-body">
                        {durationCheck(
                          applicant?.availabilityType,
                          applicant?.availability,
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="subs">
                    <img src={smallCalenderIcon} alt="" />
                    <div className="subs-main">
                      <div className="subs-topic">
                        {`Active ${durationCheckSuggestion(applicant?.availabilityType, applicant?.availability)} in a ${checkCompensationType2(applicant?.compensationPeriod)}`}
                      </div>
                      <div className="subs-body">{applicant?.availability}</div>
                    </div>
                  </div>
                </div>
                <div className="card-1" style={{ margin: '20px 0px' }}>
                  <div className="card-head">My Resume</div>
                  <div className="resume-card">
                    <div className="resume">
                      <img src={resumeIcon} alt="" />
                      <div className="resume-main">
                        <div className="resume-topic">
                          {`${applicant?.expert?.firstName}'s Resume`}
                        </div>
                        <div className="resume-body">DOC, 188KB</div>
                      </div>
                    </div>
                    <div onClick={() => DownloadResumePdf()}>
                      <BorderButton>View resume</BorderButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledApplicantDetails>
  );
};

export default ApplicantDetails;
