import { styled } from '@mui/material/styles';

const StyledFractionTalent = styled('div')(
  ({ theme }) => `
  padding: 32px 40px;
  color: ${theme.palette.customColor.primaryWhite};
  @media screen and (max-width: 480px){
    padding: 0px;
  }

  .page-header-wrap{
    margin: 0px;
    @media screen and (max-width: 480px){
      padding: 16px;
      flex-direction: column;
      width: 100%;
    }
    .MuiGrid-root{
      padding: 0px;
    }
    .previewButton-wrap{
      @media screen and (max-width: 600px){
        margin: auto;
        margin-left: 0px;
        margin-top: 16px;
        display: inline-table;
        height:34px;
      }
    }
  }

  .MuiStepLabel-label{
    color: ${theme.palette.customColor.grey_7};
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media screen and (max-width: 480px){
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
      font-size: 12px;

    }
  }

  .MuiStepLabel-label.Mui-active {
    color: ${theme.palette.primary.main} !important;
  }
  .MuiStepLabel-label, .Mui-completed {
  }
  .Mui-completed{
    color: #1c1c1c !important;
  }

  .MuiStep-root{
    padding: 0px;
    @media screen and (max-width: 480px){
      margin-bottom: 20px;
    }
    .MuiStepLabel-root{
      align-items: start;
      @media screen and (max-width: 480px){
      }
      .MuiStepLabel-labelContainer{
        text-align: left;
        @media screen and (max-width: 480px){
        }
      }
    }
  }
  .MuiStepConnector-root {
    left: calc(-90% + 30px);
    right: calc(90% + 32px);
    border-radius: 20px;
    overflow: hidden;    
    @media screen and (max-width: 480px){
      left: -4.2rem;
      right: 2rem;
      width: 3.125rem;
    }
    @media screen and (max-width: 430px){
      left: -3.6rem;
      width: 3rem;
    }
    @media screen and (max-width: 400px){
      left: -3.2rem;
      width: 2.8rem;
    }
    .MuiStepConnector-line{
      @media screen and (max-width: 480px){
        display: block;
      }
    }
  }

  .stepperWrap {
    text-align: left;
    .title{
      color: ${theme.palette.customColor.primaryBlack};
      font-family: "Plus Jakarta Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 12px;
      @media screen and (max-width: 480px){
        margin-bottom: 35px;
      }
      @media screen and (max-width: 1200px){
        font-size: 23px;
      }
      @media screen and (max-width: 1150px){
        font-size: 21px;
      }
      @media screen and (max-width: 1150px){
        font-size: 18px;
      }
    }
    .MuiStack-root{
      width: 66%;
      min-width: 635px;
      @media screen and (max-width: 768px){
        width: 100%;
        min-width: unset;
        overflow-x: auto;
      }
      @media screen and (max-width: 480px){
        min-width: unset;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .mobileHrLine{
      display: none;
      @media screen and (max-width: 480px){
        display: block;
        width: 60px;
        height: 3px;
        background: white;
        border-radius: 40px;
        margin: 0px 10px;    
      }
    }
    .MuiStepper-root{
      .MuiStepLabel-root {
        .Mui-completed{
          div{
            border:0;
            background: transparent;
            img{
              width: 40px;
              height: 40px;
            }
          }
        }
      }
      @media screen and (max-width: 600px){
        .MuiStepLabel-root {
        .Mui-completed{
          div{
            border:0;
            img{
              width: 28px;
              height: 28px;
            }
          }
        }
      }
      }
    }
  }
  `,
);

export default StyledFractionTalent;
