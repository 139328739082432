/* eslint-disable arrow-body-style */
import React from 'react';

const ExportIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.82031 6.50141L12.3803 3.94141L14.9403 6.50141"
        stroke="#1C1C1C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3809 14.1817V4.01172"
        stroke="#1C1C1C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 12C4.5 16.42 7.5 20 12.5 20C17.5 20 20.5 16.42 20.5 12"
        stroke="#1C1C1C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExportIcon;
