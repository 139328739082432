import React from 'react';

const UploadIcon = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.1402 16.3164C36.7402 16.8847 39.4352 20.2764 39.4352 27.7014V27.9397C39.4352 36.1347 36.1535 39.4164 27.9585 39.4164H16.0235C7.82854 39.4164 4.54688 36.1347 4.54688 27.9397V27.7014C4.54688 20.3314 7.20521 16.9397 13.6952 16.3347"
      stroke="#415DF3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 27.5001V6.63672"
      stroke="#415DF3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.1408 10.7237L21.9991 4.58203L15.8574 10.7237"
      stroke="#415DF3"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UploadIcon;
