import { styled } from '@mui/material/styles';

const StyledApplyJobs = styled('div')(
  ({ theme }) => `
  .page-wrap{
    margin:20px;
    @media screen and (max-width: 900px){
      margin:8px 0px 0px;
    }
    .suggested-content-wrap{
      @media only screen and (min-width: 921px) and (max-width: 1099px)  {
        max-width:784px;
      }
    }
    .web-filters{
      @media screen and (max-width: 900px){
        display:none;
      }
    }
    .suggested-jobs-wrap{
      background: ${theme.palette.secondary.main};
      border-radius:12px;
      padding:20px; 
      min-height:calc(100dvh - 120px);
      @media screen and (max-width: 900px){
        min-height: calc(100dvh - 78px);
      }
      .job-card-list-wrap{ 
        @media screen and (max-width: 900px){
          padding:20px 0px 0px;
        }
        .job-card-wrap{
          padding:12px;
          border-radius:10px;
          background: ${theme.palette.secondary.main};
          border:1px solid #E2E4E7;
          height:100%;
          .card-top{
              display: flex;
              justify-content: space-between;
              align-items: start;
              margin-bottom:16px;
              .right{
                  display: flex;
                  align-items: start;
                  max-width:calc(100% - 124px);
                  gap:8px;
                  .brand-image{
                      width: 40px;
                      height: 40px;
                      gap: 0px;
                      border-radius: 50%;
                      @media screen and (max-width: 900px){
                        min-width:40px;
                        max-width:40px;
                      }
                  }
                  .job-title{
                      font-family: Sora;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 20.16px;
                      text-align: left;
                      color: ${theme.palette.customColor.primaryBlack};
                  }
                  .job-postedby{
                      font-family: Plus Jakarta Sans;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 17.64px;
                      text-align: left;
                      color: #525252;
                  }
                  .skill-match{
                      font-family: Plus Jakarta Sans;
                      font-size: 12px;
                      font-weight: 500;
                      line-height: 15.12px;
                      text-align: left;
                      color:#00AF31;
                  }
              }
              .left {
                  display:inline-flex;
                  align-items:center;
                  gap:10px;
                  img{
                    cursor:pointer;
                  }
                  .applied-chip{
                    border:1px solid #415DF3;
                    color:#415DF3;
                    background:#415DF31A;
                    padding:4px 8px;
                    font-family: Plus Jakarta Sans;
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 12.6px;
                    letter-spacing: 0.08em;
                    text-align: center;
                    border-radius:40px;
                    text-transform:uppercase;
                  }
              }
          }
          .skill-chips-box{
              marging-top:16px;
              margin-bottom:12px;
              display:flex;
              align-items:center;
              gap:4px;
              overflow-x:scroll;
              max-width:790px;
              &::-webkit-scrollbar {
                height: 4px;
              }
              .chips{
                  background:#F4F4F4;
                  padding:4px 8px;
                  font-family: Plus Jakarta Sans;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 15.12px;
                  text-align: left;
                  border-radius:40px;
                  white-space: nowrap;
                  margin-bottom: 8px;
              }
          }
          .card-bottom {
            .job-info-content{
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
            }
            .icon-text-box-wrap{
              display: flex;
              flex-direction:column;
              gap:8px;
                .icon-text-box{
                  display: inline-flex;
                  align-items: center;
                  gap:4px;
                }
            }
            .sub-text{
              font-family: Plus Jakarta Sans;
              font-size: 14px;
              font-weight: 500;
              line-height: 17.64px;
              text-align: left;
            }
            .view-details{
              font-family: Plus Jakarta Sans;
              font-size: 14px;
              font-weight: 600;
              line-height: 17.64px;
              text-align: left;
              text-decoration:underline;
              color:#415DF3;
              cursor:pointer;
              min-width:84px;
            }
            .date-text{
              font-family: Plus Jakarta Sans;
              font-size: 14px;
              font-weight: 500;
              line-height: 17.64px;
              text-align: left;
              color:#415DF3;
              margin-top:12px;
            }
          }
        }
      } 
    }
    .show-more-box {
      border: 1px solid white;
      height: 300px;
      background: white;
      position: absolute;
      width: 800px;
      z-index: 1000;
      border-radius: 12px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      overflow: hidden;
      .show-more-content{
        margin: 15px 30px;
        .show-more-header {
          display: flex;
          jistify-content: space-between;
          .show-more-search {
            width: 50%;
          }
        }
        .show-more-checkbox {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin: -30px 0px;
          max-height: 200px;
          overflow-y: auto;

          .MuiListItemButton-root {
            flex: 1 0 25%;
            min-width: 120px;
          }
        }  
      }
    } 
  }
  .section-title{
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 600;
    line-height: 25.2px;
    text-align: left;
    color:#1C1C1C;
    margin-bottom:8px;
  }
  .filters-wrap{
    background: ${theme.palette.secondary.main}; 
    border-radius:12px;
    padding:20px;
    .section-content{
      text-align:left;
      .section-heading{
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 20.16px;
        text-align: left;
        color:#1C1C1C;
        text-transform:capitalize;
        margin:12px 0px;
      }
      .MuiListItemButton-root{
        padding:0px;
      }
      .MuiListItemIcon-root{
        min-width:44px;
      }
      .MuiListItemText-primary {
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.64px;
        text-align: left;
        color: #1C1C1C;
      }
      .more-text{
        display:inline-flex;
        align-items:center;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.64px;
        text-align: left;
        color:#415DF3;
        margin-bottom:20px;
        cursor:pointer;
    }
  }
  .filter-buttons{
    margin-top:20px;
    display:flex;
    gap:12px;
    align-items:center;
    .clear-btn{
      font-family: Plus Jakarta Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 17.64px;
      text-align: center;
      width:106px;
      padding:12px;
      gap: 10px;
      border-radius: 8px;
      opacity: 0px;
      cursor:pointer;
      display:flex;
      align-item:center;
      justify-content:center;
      &:hover{
        background:${theme.palette.action.hover};
      }
    }
  }
  .MuiButtonBase-root{
    height:34px !important;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    text-align: left;
    padding:0px !important;
  },
  
}
.mobile-filter-btn {
  display:none;
  position: sticky;
  left: 8px;
  bottom: 140px;
  text-align:right;
  height: 0px;
  @media screen and (max-width: 900px){
    display:block;
  }
 
}
.exp-buttons{
  display:inline-flex;
  gap:4px;
  padding:0 4px;
  img{
    cursor:pointer;
  }
}
.exper{
  .MuiFormControl-root{
    min-height:40px;
    .MuiInput-input {
      padding-right : 0px;
    }
  }
}
`,
);

export default StyledApplyJobs;

export const StyledFilterPopup = styled('div')(
  ({ theme }) => `
.mobile-filter-popup-content-wrap{
  background:#F8F4F0;
  height: 100vh;
  .filter-headers{
    background:#FFFFFF;
    padding:16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    .close-btn{
      curser:pointer;
      color:#415DF3;
    }
  }
  .filter-content-wrap{
    margin-top:8px;
    .content-box {
      background:#FFFFFF;
      height:calc(100vh - 128px);
      overflow-y:scroll;
      .section-heading{
        padding:14px 16px;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.8px;
        text-align: left;
        color:#1C1C1C;
        text-transform: capitalize
      }
      .MuiListItemButton-root{
        padding-top:0px;
        padding-bottom:0px;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;
        color:#050505;
      }
      .filter-suggestions-heading{
        padding:14px 12px 16px 12px;
        font-family: Sora;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        text-align: left;
        color:#050505;
        text-transform:uppercase;
      }
    }
  }
  .action-buttons{
    position:sticky;
    bottom:0px;
    box-shadow: -2px 0px 4px 0px #00000040;
    background:#FFFFFF;
    padding:19px 20px;
    .filter-buttons{
      display:flex;
      gap:12px;
      align-items:center;
      justify-content: space-between;
      .clear-btn{
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 17.64px;
        text-align: center;
        width:106px;
        padding:12px;
        gap: 10px;
        border-radius: 8px;
        opacity: 0px;
        cursor:pointer;
        &:hover{
          background:${theme.palette.action.hover};
        }
      }
      .MuiButtonBase-root{
        height:34px !important;
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        padding:0px !important;
      }
    }
  }
}
`,
);
