/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import Grid from '@mui/material/Grid';
import moment from 'moment-timezone';
import { selectorAuthentication } from '../../../store/reducers/authentication';
import { fetchApplyJob, fetchJobDetails, fetchSaveJob } from '../../../store/reducers/fractionTalent/apiThunk';
import StyledJobDetailsPage from './StyledJobDetailsPage';
import { checkCompensationType, durationCheck, stringAvatar } from '../../../helper';
import { PrimaryButton } from '../../../components/Button';
import backArrow from '../../../assets/svg/jobdetail/jobdetail-back-arrow.svg';
import backArrowMobile from '../../../assets/svg/jobdetail/arrow-left.svg';
import share from '../../../assets/svg/share-circle.svg';
import save from '../../../assets/svg/save-circle.svg';
import saved from '../../../assets/svg/savedBlue.svg';
import arrowIcon from '../../../assets/svg/jobdetail/jobdetail-arrow.svg';
import industryIcon from '../../../assets/svg/jobdetail/jobdetail-industry.svg';
import domainIcon from '../../../assets/svg/jobdetail/jobdetail-domain.svg';
import experienceIcon from '../../../assets/svg/jobdetail/jobdetail-experience.svg';
import locationIcon from '../../../assets/svg/jobdetail/jobdetail-location.svg';
import coinIcon from '../../../assets/svg/jobdetail/jobdetail-coin.svg';
import infoIcon from '../../../assets/svg/jobdetail/jobdetail-Info.svg';
import calendarIcon from '../../../assets/svg/jobdetail/jobdetail-calendar.svg';
import tickIcon from '../../../assets/svg/jobdetail/jobdetail-tickcircle.svg';
import TickCircle from '../../../components/icons/TickCircle';
import companyIcon from '../../../assets/svg/jobdetail/jobdetail-company.svg';
import locationSmallIcon from '../../../assets/svg/jobdetail/jobdetail-location-small.svg';
import smsIcon from '../../../assets/svg/jobdetail/jobdetail-sms.svg';
import userIcon from '../../../assets/svg/jobdetail/jobdetail-user.svg';
import websiteIcon from '../../../assets/svg/jobdetail/jobdetail-website.svg';
import LinkdinIcon from '../../../assets/svg/jobdetail/jobdetail-linkdin.svg';
import { ToastNotifySuccess } from '../../../components/Toast/ToastNotify';
import CustomTooltip from '../../../components/Tooltip/Tooltip';
import JobShareModal from '../jobsOverview/JobShareModal';
import StyledJobsOverview from '../jobsOverview/StyledJobsOverview';
import Modal from '../../../components/Modal';
import arrow from '../../../assets/svg/arrow.svg';

const Jobdetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [jobId, setJobId] = useState(null);
  const [shareId, setShareId] = useState(null);
  const [openDialogShare, setOpenDialogShare] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const { userData: { data: { _id: expertId } = {} } = {} } = useSelector(selectorAuthentication);
  const auth = localStorage.getItem('auth');
  const isRecruiter = localStorage.getItem('recruiter');

  const handleCloseDialoag = () => {
    setOpenModal(false);
  };

  const modalContent = () => (
    <>
      <div className="dialog-title" style={{ fontWeight: '600' }}>Please login/signup as an Expert to view the job details.</div>
      <div className="google-calendar-button-wrap">
        <PrimaryButton
          sx={{ width: '150px' }}
          onClick={() => { localStorage.setItem('redirectUrl', `/job-detail${window.location.search}`); navigate('/login'); }}>
          Continue
          <img src={arrow} alt="" srcSet="" className="arrow-icon" />
        </PrimaryButton>
      </div>
    </>
  );

  const handleCloseShareDialog = () => {
    setOpenDialogShare(false);
  };
  const handleOpenShareDialog = () => {
    setShareId(jobId);
    setOpenDialogShare(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Get the query parameter values
    if (location.search) {
      setJobId(params.get('job-id'));
    }
  }, [location]);

  const getJobDetails = async () => {
    try {
      const query = `${jobId}?expertId=${expertId}`;
      const response = await dispatch(fetchJobDetails(query));
      const {
        payload: { data = {}, status = false },
      } = response;
      if (status) {
        setJobDetails(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!auth || (auth && isRecruiter)) {
      setOpenModal(true);
    } else if (jobId) {
      getJobDetails();
      localStorage.removeItem('redirectUrl');
    }
  }, [jobId]);

  const handleSaveJob = async (item, isAlreadySaved) => {
    const { _id: id } = item;
    const data = {
      jobId: id,
      save: isAlreadySaved,
    };
    const response = await dispatch(fetchSaveJob(data));
    const { payload: { status = false } = {} } = response;
    if (status) {
      ToastNotifySuccess('Job status (saved/unsaved) updated successfully.');
      await getJobDetails();
    }
  };

  const handleJobApply = async () => {
    const response = await dispatch(fetchApplyJob(jobId));
    const { payload: { status = false } = {} } = response;
    if (status) {
      ToastNotifySuccess('You have applied to the job succesfully!');
      await getJobDetails();
    }
  };

  const capitalizeWorkPreference = (workPreference) => {
    if (!workPreference) return '';
    if (workPreference === 'onSite') {
      return 'On-Site';
    }
    return workPreference.charAt(0).toUpperCase() + workPreference.slice(1);
  };

  const JobDetaisInfo = ({ src, heading, detail }) => (
    <div>
      <div className="info-wrap">
        <img className="info-icon" src={src} alt="icon" />
        <div>
          <div className="info-heading">
            {heading}
          </div>
          <div className="info-detail">
            {detail}
          </div>
        </div>
      </div>
      <div className="info-wrap-mobile">
        <div className="info-heading">
          <img className="info-icon" src={src} alt="icon" />
          <span>
            {heading}
          </span>
        </div>
        <div className="info-detail">
          {detail}
        </div>
      </div>
    </div>
  );

  const JobInfoCard = ({ heading, content }) => (
    <div className="info-card-wrap">
      <div className="info-heading">{heading}</div>
      <div>{content}</div>
    </div>
  );

  const handleBack = () => {
    navigate(-1);
  };

  if (!auth || (auth && isRecruiter)) {
    return (
      <Modal
        openDialog={openModal}
        closeDialog={handleCloseDialoag}
        dialogContent={modalContent()}
        noHeader
        dialogCss="logoutDialogWrap"
       />
    );
  }

  return (
    <StyledJobDetailsPage>
      <div className="page-wrap">
        <div className="page-title-mobile-wrap">
          <div className="content">
            <div className="left"> <img alt="arrow" src={backArrowMobile} onClick={() => handleBack()} /> <span>Job Details</span></div>
            <div className="right">
              <img className="icon-image" src={share} alt="" onClick={() => handleOpenShareDialog()} />
              <img
                className="icon-image"
                src={jobDetails?.isJobSaved ? saved : save}
                alt=""
                onClick={() => (jobDetails?.isJobSaved ?
                  handleSaveJob(jobDetails, false)
                  : handleSaveJob(jobDetails, true))}
                  />
            </div>
          </div>
        </div>
        <div className="page-title"><img alt="arrow" src={backArrow} onClick={() => handleBack()} /> <span>Job Details</span></div>
        <div className="job-details-wrap">
          <div className="top-space" />
          <div className="job-details-content-wrap">
            <div className="job-header">
              <div className="left">
                <div>
                  {jobDetails?.companyLogo ?
                    <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${jobDetails?.companyLogo}`} alt="" />
                    : <Avatar {...stringAvatar(jobDetails?.companyName)} />}
                </div>
                <div className="commpany-info">
                  <div className="job-title">{jobDetails?.jobRole}
                    {jobDetails?.portalLink ?
                      <CustomTooltip title="Check the job posting on company’s portal" maxWidth="148px">
                        <img src={arrowIcon} alt="" onClick={() => window.open(jobDetails.portalLink, '_blank', 'rel=noopener noreferrer')} />
                      </CustomTooltip>
                      : null}
                  </div>
                  <div className="job-industry"> <img src={industryIcon} alt="" /> {jobDetails?.companyName}</div>
                </div>
              </div>
              <div className="right">
                <div>
                  <div className="date-title">Date Posted</div>
                  <div className="date-desc">{moment(jobDetails.createdAt).format('DD MMMM YYYY')}</div>
                </div>
                <img className="icon-image" src={share} alt="" onClick={() => handleOpenShareDialog()} />
                <img
                  className="icon-image"
                  src={jobDetails?.isJobSaved ? saved : save}
                  alt=""
                  onClick={() => (jobDetails?.isJobSaved ?
                    handleSaveJob(jobDetails, false)
                    : handleSaveJob(jobDetails, true))}
                  />
                <PrimaryButton
                  disabled={jobDetails?.isApplied}
                  onClick={handleJobApply}
                  startIcon={<TickCircle />}
                  sx={{
                    width: {
                      xs: '134px',
                      sm: '134px',
                    },
                    '&:disabled': {
                      backgroundColor: '#00AF31',
                    } }}>
                  {jobDetails?.isApplied ? 'Applied' : 'Apply Job' }
                </PrimaryButton>
              </div>
            </div>
            <div className="job-header-mobile">
              <div className="left">
                <div>
                  {jobDetails?.companyLogo ?
                    <img className="brand-image" src={`${process.env.REACT_APP_IMAGE_URL}/${jobDetails?.companyLogo}`} alt="" />
                    : <Avatar {...stringAvatar(jobDetails?.companyName)} />}
                </div>
                <div className="commpany-info">
                  <div className="job-title">{jobDetails?.jobRole}
                    {!jobDetails?.portalLink ?
                      <CustomTooltip title="Check the job posting on company’s portal" maxWidth="148px">
                        <img src={arrowIcon} alt="" onClick={() => window.open(jobDetails.portalLink, '_blank', 'rel=noopener noreferrer')} />
                      </CustomTooltip>
                      : null}
                  </div>
                  <div className="job-industry"> <img src={industryIcon} alt="" /> {jobDetails?.companyName}</div>
                  <div className="date-title">Date Posted :<span className="date-desc">{moment(jobDetails.createdAt).format('DD MMMM YYYY')}</span></div>
                </div>
              </div>
            </div>
            <Grid container columnSpacing={2}>
              <Grid item md={8.5} xs={12}>
                <div className="section-title">Job Details</div>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={experienceIcon} heading="Experience Required" detail={`${jobDetails?.jobExperience} year(s)`} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={domainIcon} heading="Domain" detail={jobDetails?.domain} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={industryIcon} heading="Industry" detail={jobDetails?.industry} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={locationIcon} heading="Job Location" detail={`${jobDetails?.jobCity}, ${jobDetails?.jobState}`} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={industryIcon} heading="Job Type" detail={capitalizeWorkPreference(jobDetails?.locationType)} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <JobDetaisInfo src={locationIcon} heading="Preferred Job Location" detail={jobDetails?.preferredJobLocation} />
                  </Grid>
                  <div className="divider" />
                  <Grid item xs={12}>
                    <div className="about-wrap">
                      <div className="about-heading">Job Description</div>
                      <div className="about-detail">{jobDetails?.jobDescription}</div>
                    </div>
                  </Grid>
                  {/* {!jobDetails?.isApplied ?
                    <Grid mt={2} pb={4}>
                      <PrimaryButton
                        disabled={jobDetails.isApplied}
                        startIcon={<TickCircle />}
                        sx={{
                          width: {
                            xs: '134px',
                            sm: '134px',
                          },
                        }}>
                        Apply Job
                      </PrimaryButton>
                    </Grid> : null} */}
                </Grid>
              </Grid>
              <Grid item md={3.5} xs={12} mt={4}>
                <Grid container rowSpacing={3}>
                  <Grid item xs={12}>
                    <JobInfoCard
                      heading="Charges"
                      content={
                        <div className="charges-wrap">
                          <div className="price">
                            <img className="info-icon" src={coinIcon} alt="icon" /> <span>₹{jobDetails?.compensationRate}</span>
                          </div>
                          <div className="fees"> Fees per {`${checkCompensationType(jobDetails?.compensationType)}`}</div>
                          <div className="negotiable"> <img className="info-icon" src={infoIcon} alt="icon" /> <span>This price is </span><span className="blue-text">{`${jobDetails?.isRateNegotiable ? 'Negotiable' : 'Non-Negotiable'}!`}</span></div>
                        </div>
                    } />
                  </Grid>
                  <Grid item xs={12}>
                    <JobInfoCard
                      heading="Availability"
                      content={
                        <div className="availability-wrap">
                          <div className="availability-heading">
                            <img className="info-icon" src={tickIcon} alt="icon" /> <span>Available for</span>
                          </div>
                          <div className="availability-detail">
                            {durationCheck(jobDetails?.availableFor, jobDetails?.availabilityPeriod)}
                          </div>
                          <div className="mt-16">
                            <div className="availability-heading">
                              <img className="info-icon" src={calendarIcon} alt="icon" /> <span>Active Days in a Month</span>
                            </div>
                            <div className="availability-detail">
                              {jobDetails?.availabilityPeriod}
                            </div>
                          </div>
                        </div>
                    } />
                  </Grid>
                  <Grid item xs={12}>
                    <JobInfoCard
                      heading="About Company"
                      content={
                        <div className="company-wrap">
                          <div>
                            <div className="company-heading">
                              <img className="info-icon" src={userIcon} alt="icon" /> <span>Designation</span>
                            </div>
                            <div className="company-detail">
                              {jobDetails?.designation}
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={smsIcon} alt="icon" /> <span>Contact Details</span>
                            </div>
                            <div className="company-detail contact">
                              {jobDetails?.phoneNumber}
                              <a
                                className="job-links"
                                href={jobDetails?.linkedinProfile}
                                target="_blank"
                                rel="noreferrer">
                                <img className="linkdin-icon" src={LinkdinIcon} alt="icon" />
                              </a>
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={locationSmallIcon} alt="icon" /> <span>Company Address</span>
                            </div>
                            <div className="company-detail">
                              {jobDetails?.companyAddress}
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={companyIcon} alt="icon" /> <span>Company Type</span>
                            </div>
                            <div className="company-detail">
                              {jobDetails?.companyType}
                            </div>
                          </div>
                          <div className="mt-16">
                            <div className="company-heading">
                              <img className="info-icon" src={websiteIcon} alt="icon" /> <span>Website</span>
                            </div>
                            <div className="company-detail">
                              <a
                                className="job-links"
                                href={jobDetails?.companyWebsite}
                                target="_blank"
                                rel="noreferrer">
                                {jobDetails?.companyWebsite}
                              </a>
                            </div>
                          </div>
                        </div>
                    } />
                  </Grid>
                  <Grid item xs={12}>
                    <JobInfoCard
                      heading="Skills Required"
                      content={
                        <div className="skill-wrap">
                          {jobDetails?.skills?.map((skill) => (
                            <span key={skill} className="chips">
                              {skill}
                            </span>
                          ))}
                        </div>
                    } />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="action-buttons">
          <PrimaryButton
            disabled={jobDetails?.isApplied}
            onClick={handleJobApply}
            startIcon={jobDetails?.isApplied ? <TickCircle /> : null}
            sx={{
              '&:disabled': {
                backgroundColor: '#00AF31',
              } }}>
            {jobDetails?.isApplied ? 'Applied' : 'Apply Job' }
          </PrimaryButton>
        </div>
      </div>
      <StyledJobsOverview>
        <JobShareModal
          openDialog={openDialogShare}
          closeDialog={handleCloseShareDialog}
          id={shareId}
        />
      </StyledJobsOverview>
    </StyledJobDetailsPage>
  );
};

export default Jobdetails;
